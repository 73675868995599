import {
	Module,
	VuexModule,
	getModule,
	Mutation,
	Action,
	MutationAction,
} from "vuex-module-decorators";
import axios from "axios";
import router from "@/router";
import { socket } from "@/main";
import { store } from "@/store";
import { AuthModule } from "./Auth";
import { DateTime } from "ts-luxon";
import { IRoom } from "@/types/messages";
import { StringNumber } from "vue-advanced-chat";
import { env } from "@/config";
import { MessageModule } from "./Message";
import { AppModule } from "./app";
import { ITicket, ITicketModule, ITicketView, IWallet } from "@/types/tickets";
import { Filters } from "@/types/control";
import {
	formatRoomFromTicket,
	getInterfaceColor,
	getInterfaceName,
	updateRoom,
} from "../actions/ticket.actions";
import { ConversationModule } from "./Conversation";

const coreApi = axios.create({ baseURL: env.CoreApi });

@Module({ dynamic: true, store, name: "ticket" })
export class Ticket extends VuexModule implements ITicketModule {
	mapTickets: ITicketModule["mapTickets"] = {
		[Filters.OPEN]: [],
		[Filters.CLOSED]: [],
		[Filters.CLAIMED]: [],
		[Filters.ARCHIVED]: [],
		[Filters.BOT]: [],
		[Filters.OVERVIEW]: [],
	};
	mapRooms: ITicketModule["mapRooms"] = {
		[Filters.OPEN]: [],
		[Filters.CLOSED]: [],
		[Filters.CLAIMED]: [],
		[Filters.ARCHIVED]: [],
		[Filters.BOT]: [],
		[Filters.OVERVIEW]: [],
	};
	tickets: ITicket[] = [];
	lastTicketsFromClient: ITicket[] = [];
	currentTicketsFromClient: ITicket[] = [];
	lastTicketsPagination: { totalPages: number; totalTickets: number } = {
		totalPages: 0,
		totalTickets: 0,
	};
	wallets: IWallet[] = [];
	rooms: IRoom[] = [];
	messages: Map<string, string> = new Map<string, string>([]);
	currentRoomId: string | null = null;
	currentNewestRoom: { id: string | null; lastMessageDate: string | null } = {
		id: null,
		lastMessageDate: null,
	};
	currentNewestTicket: ITicket | null = null;
	filter: Filters = Filters.CLAIMED;
	sidebar = AppModule.userSettings.showSidebar;
	search = "";
	searchSidebar = false;
	loading = false;
	fetched = false;
	changeFilter = false;
	privateRepliesStatus: Map<string, boolean> = new Map<string, boolean>([]);

	/* 	get isExpire() {
		return (this.currentRoom?.expireAt?.getTime() ?? 0) - Date.now() < 0;
	} */
	get isOldTicket() {
		return (
			this.currentRoomId !== this.currentNewestRoom.id ||
			this.Filter === Filters.CLOSED ||
			this.Filter === Filters.ARCHIVED
		);
	}

	get loaded() {
		if (this.filter !== Filters.CLOSED) return true;
		return this.fetched;
	}

	get Wallets() {
		return this.wallets;
	}

	get allTickets() {
		return this.mapTickets;
	}

	get Rooms() {
		if (this.filter === Filters.OPEN || !this.filter)
			return this.mapRooms.open.sort(
				(a, b) =>
					DateTime.fromISO(b.lastMessageDate ?? "").valueOf() -
					DateTime.fromISO(a.lastMessageDate ?? "").valueOf()
			);
		else if (this.filter === Filters.CLOSED)
			return this.mapRooms.closed.sort(
				(a, b) =>
					DateTime.fromISO(b.updatedAt ?? "").valueOf() -
					DateTime.fromISO(a.updatedAt ?? "").valueOf()
			);
		return this.mapRooms[this.filter].sort(
			(a, b) =>
				DateTime.fromISO(b.lastMessageDate ?? "").valueOf() -
				DateTime.fromISO(a.lastMessageDate ?? "").valueOf() +
				((b.pinned ? 1e17 : 0) - (a.pinned ? 1e17 : 0))
		);
	}
	get Tickets() {
		return this.mapTickets[this.filter];
	}
	get currentRoom() {
		return this.mapRooms[this.filter].find(
			r => r.roomId === this.currentRoomId
		);
	}
	get currentTicket() {
		return this.mapTickets[this.filter].find(t => t._id === this.currentRoomId);
	}
	get currentRoomID() {
		return this.currentRoomId;
	}

	get Loading() {
		return this.loading;
	}

	get openedSidebar() {
		return this.sidebar;
	}

	get openedSearchSidebar() {
		return this.searchSidebar;
	}

	get comment() {
		return this.currentTicket?.comentario ?? "";
	}

	get updated() {
		return this.currentTicket?.updatedAt ?? "";
	}

	get currentTicketClient() {
		return this.currentTicket &&
			!this.currentTicket.isGroup &&
			this.currentTicket.client &&
			this.currentTicket.client.length == 1
			? this.currentTicket.client[0]
			: false;
	}

	get LastTicketsFromClient(): ITicketView[] {
		return this.lastTicketsFromClient.map(t => ({
			createdAt:
				DateTime.fromISO(t.createdAt.toString())
					.setLocale("es-SV")
					.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) ?? "",
			lastMessageDate: t.last_msg_date.toString(),
			interface: getInterfaceName(t.channel.interface),
			color: getInterfaceColor(t.channel.interface),
			_id: t._id,
		}));
	}

	get CurrentTicketsFromClient(): ITicketView[] {
		return this.currentTicketsFromClient.map(t => ({
			createdAt:
				DateTime.fromISO(t.createdAt.toString())
					.setLocale("es-SV")
					.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) ?? "",
			lastMessageDate: t.last_msg_date.toString(),
			interface: getInterfaceName(t.channel.interface),
			color: getInterfaceColor(t.channel.interface),
			_id: t._id,
		}));
	}

	get CurrentClientTickets() {
		return this.currentTicketsFromClient;
	}

	get CurrentNewestRoom() {
		return this.currentNewestRoom;
	}

	get LastTickets(): ITicket[] {
		return this.lastTicketsFromClient;
	}

	get LastTicketsPagination() {
		return this.lastTicketsPagination;
	}

	get Filter(): Filters {
		return this.filter;
	}

	get typingMessage() {
		return this.messages.has(this.currentRoomId ?? "")
			? this.messages.get(this.currentRoomId ?? "")
			: "";
	}

	get totalOpen() {
		return this.mapRooms.open.length;
	}

	get privateReplyStatus(): boolean {
		return this.privateRepliesStatus.has(this.currentRoomId ?? "")
			? (this.privateRepliesStatus.get(this.currentRoomId ?? "") as boolean)
			: false;
	}

	get privateReplySubmit() {
		return (
			this.currentTicket?.channel.interface === "COMMENT_INTERFACE" &&
			!this.currentTicket.private_replied
		);
	}

	get publicReplySubmitted() {
		return (
			this.currentTicket?.channel.interface === "COMMENT_INTERFACE" &&
			this.currentTicket.public_replied &&
			!this.currentTicket.private_replied
		);
	}

	get disableCommentReplies() {
		return (
			this.currentTicket?.channel.interface === "COMMENT_INTERFACE" &&
			this.currentTicket.private_replied &&
			this.currentTicket.public_replied
		);
	}

	@Mutation
	clearTickets() {
		this.mapTickets[Filters.OVERVIEW] = [];
		this.mapRooms[Filters.OVERVIEW] = [];
		this.lastTicketsFromClient = [];
		this.currentTicketsFromClient = [];
	}
	@Mutation
	setPrivateReplyStatus({
		roomId,
		status,
	}: {
		roomId: string;
		status: boolean;
	}) {
		this.privateRepliesStatus.set(roomId, status);
	}

	@Mutation
	deletePrivateReplyStatus({ roomId }: { roomId: string }) {
		this.privateRepliesStatus.delete(roomId);
	}

	@Mutation
	setTypingMessage({ roomId, message }: { roomId: string; message: string }) {
		this.messages.set(roomId, message);
	}

	@Mutation
	setRoomId(id: string | null) {
		this.currentRoomId = id;
	}

	@Mutation
	previewOldTicket({
		ticketId,
		date = new Date().toISOString(),
		setCurrRoom = true,
		viewActualTicket = false,
	}: {
		ticketId: string;
		date?: string;
		setCurrRoom?: boolean;
		viewActualTicket?: boolean;
	}) {
		const idxActual = this.mapRooms[this.filter].findIndex(
			t => t.roomId === this.currentRoomId
		);
		const idxActualT = this.mapTickets[this.filter].findIndex(
			t => t._id === this.currentRoomId
		);
		const oldIdxTicket = viewActualTicket
			? this.currentTicketsFromClient.findIndex(t => t._id === ticketId)
			: this.lastTicketsFromClient.findIndex(t => t._id === ticketId);

		if (TicketModule.currentRoomID !== this.currentNewestRoom.id)
			socket.emit("LEAVE_ROOM", {
				token: AuthModule.token,
				ticketID: TicketModule.currentRoomID,
			});

		const pinned =
			this.mapRooms[this.filter].find(r => r.roomId === this.currentRoomId)
				?.pinned ?? false;

		if (this.currentNewestTicket === null)
			this.currentNewestTicket = this.mapTickets[this.filter][idxActualT];

		if (ticketId !== this.currentNewestRoom.id) {
			this.mapTickets[this.filter].splice(
				idxActualT,
				1,
				viewActualTicket
					? this.currentTicketsFromClient[oldIdxTicket]
					: this.lastTicketsFromClient[oldIdxTicket]
			);
		} else {
			if (this.currentNewestTicket) {
				this.mapTickets[this.filter].splice(
					idxActualT,
					1,
					this.currentNewestTicket
				);
				this.currentNewestTicket = null;
			}
		}

		const newestRoom = ticketId === this.currentNewestRoom.id;
		const room = formatRoomFromTicket(
			this.mapTickets[this.filter][idxActualT],
			pinned,
			newestRoom,
			this.currentNewestRoom?.lastMessageDate ?? ""
		);

		if (room) {
			this.mapRooms[this.filter].splice(idxActual, 1, room);
			if (setCurrRoom) {
				this.currentRoomId = room?.roomId;
				MessageModule.requestMessages({
					token: AuthModule.token ?? "",
					reset: true,
					ticket: room.roomId,
					date: date,
				});
			}
		}
		// if (room) this.rooms[idxActualRoom] = room;
	}

	@Mutation
	upsert(ticket: ITicket) {
		const status: Filters =
			ticket.bot && ticket.status !== "closed" ? Filters.BOT : ticket.status;
		const exists = this.mapTickets[status].findIndex(t => t._id === ticket._id);
		const existsRoom = this.mapRooms[status].findIndex(
			r => r.roomId == ticket._id
		);
		const room: IRoom | null =
			existsRoom !== -1
				? updateRoom(this.mapRooms[status][existsRoom], ticket)
				: formatRoomFromTicket(ticket);
		if (!room) return;
		const channel = ticket.client[0].channels.find(
			ch =>
				ch.channel === ticket.channel._id &&
				(ticket.channel.interface !== "WHATSAPP_OFFICIAL" ||
					ch.service_id === ticket.thread)
		);
		if (channel?.expireAt && this.currentRoomId === ticket._id) {
			console.log(channel);
			ConversationModule.setConversation({
				id: channel?.cId,
				replied: channel?.replied,
				expireAt: channel?.expireAt ? new Date(channel?.expireAt) : undefined,
			});
		}
		if (
			status === Filters.CLAIMED &&
			ticket.archived &&
			ticket.archived === true
		) {
			if (exists === -1) this.mapTickets[Filters.ARCHIVED].push(ticket);
			else {
				this.mapTickets[status].splice(exists, 1);
				this.mapTickets[Filters.ARCHIVED].unshift(ticket);
			}
			if (existsRoom === -1) this.mapRooms[Filters.ARCHIVED].unshift(room);
			else {
				this.mapRooms[status].splice(existsRoom, 1);
				this.mapRooms[Filters.ARCHIVED].unshift(room);
			}
		} else {
			const existTicketArchived = this.mapTickets[Filters.ARCHIVED].findIndex(
				t => t._id == ticket._id
			);
			const existRoomArchived = this.mapRooms[Filters.ARCHIVED].findIndex(
				r => r.roomId == ticket._id
			);
			if (existTicketArchived !== -1)
				this.mapTickets[Filters.ARCHIVED].splice(existTicketArchived, 1);
			if (existRoomArchived !== -1)
				this.mapRooms[Filters.ARCHIVED].splice(existRoomArchived, 1);

			if (exists === -1) this.mapTickets[status].push(ticket);
			else {
				this.mapTickets[status][exists] = ticket;
				if (
					ticket.last_msg.origin === "client" &&
					ticket._id !== this.currentRoomId &&
					!ticket.bot
				)
					AppModule.notificationsElement["NEW_TICKET"]?.play();
			}
			if (existsRoom === -1) {
				this.mapRooms[status].unshift(room);
				AppModule.notificationsElement["NEW_TICKET"]?.play();
			} else {
				this.mapRooms[status].splice(existsRoom, 1);
				this.mapRooms[status].unshift(room);
			}
		}
	}

	@Mutation
	setFilter(status: Filters) {
		this.filter = status;
	}
	@Mutation
	upsertNewestTicket(ticket: ITicket) {
		this.currentNewestTicket = ticket;
	}

	@Mutation
	delete({ ticket, status }: { ticket: string; status: Filters }) {
		if (status === "claimed") {
			const closedTicket = this.mapTickets[status].find(t => t._id === ticket);
			const closedRoom = this.mapRooms[status].find(r => r.roomId === ticket);
			if (closedTicket) this.mapTickets[Filters.CLOSED].unshift(closedTicket);
			if (closedRoom) this.mapRooms[Filters.CLOSED].unshift(closedRoom);
		}

		this.mapTickets[status] = this.mapTickets[status].filter(
			t => t._id !== ticket
		);
		this.mapRooms[status] = this.mapRooms[status].filter(
			r => r.roomId != ticket
		);
	}

	@Mutation
	SOCKET_TICKETS_FROM_CLIENT({
		tickets,
		totalPages,
		totalTickets,
		append,
		ticketId,
	}: {
		tickets: ITicket[];
		totalPages: number;
		totalTickets: number;
		append: boolean;
		ticketId?: string;
	}) {
		// Overview to list tickets from a specific client
		if (ticketId) {
			this.loading = false;
			this.mapTickets[Filters.OVERVIEW] = tickets;
			this.mapRooms[Filters.OVERVIEW] = [];
			this.mapTickets[Filters.OVERVIEW].forEach(t => {
				const room: IRoom | null = formatRoomFromTicket(t);
				if (!room) return;
				this.mapRooms[Filters.OVERVIEW].push(room);
			});
			TicketModule.setActualRoom(ticketId);
			return; // Don't do any more
		}

		// List current and last tickets for an actual ticket selected (Details of sidebar)
		const lastTickets = tickets.filter(ticket => ticket.status === "closed");
		this.currentTicketsFromClient = tickets.filter(
			ticket => ticket.status !== "closed"
		);

		if (append) this.lastTicketsFromClient.push(...lastTickets);
		else this.lastTicketsFromClient = lastTickets;

		this.lastTicketsPagination.totalPages = totalPages;
		this.lastTicketsPagination.totalTickets = totalTickets;
	}

	@Mutation
	lastTicketUpsert(ticket: ITicket) {
		this.lastTicketsFromClient.push(ticket);
	}

	@Mutation
	SOCKET_TICKET_LIST(tickets: ITicket[]) {
		this.loading = false;
		this.mapTickets[Filters.BOT] = [];
		this.mapTickets[Filters.OPEN] = [];
		this.mapTickets[Filters.CLOSED] = [];
		this.mapTickets[Filters.CLAIMED] = [];
		this.mapTickets[Filters.ARCHIVED] = [];
		tickets.forEach(ticket => {
			if (ticket.bot) this.mapTickets[Filters.BOT].push(ticket);
			else if (ticket.archived) this.mapTickets[Filters.ARCHIVED].push(ticket);
			else this.mapTickets[ticket.status].push(ticket);
		});
		this.mapRooms[Filters.BOT] = [];
		this.mapRooms[Filters.OPEN] = [];
		this.mapRooms[Filters.CLAIMED] = [];
		this.mapRooms[Filters.CLOSED] = [];
		this.mapRooms[Filters.ARCHIVED] = [];
		tickets.forEach(ticket => {
			const room: IRoom | null = formatRoomFromTicket(ticket);
			if (!room) return;
			if (ticket.bot) this.mapRooms[Filters.BOT].unshift(room);
			else if (ticket.status === Filters.OPEN)
				this.mapRooms[Filters.OPEN].unshift(room);
			else if (
				ticket.status === Filters.CLAIMED &&
				ticket.archived &&
				ticket.archived === true
			)
				this.mapRooms[Filters.ARCHIVED].unshift(room);
			else if (ticket.status === Filters.CLAIMED)
				this.mapRooms[Filters.CLAIMED].unshift(room);
			else if (ticket.status === Filters.CLOSED)
				this.mapRooms[Filters.CLOSED].unshift(room);
		});
		//this.currentRoomId = this.rooms?.[0]?.roomId;
	}

	@Mutation
	SOCKET_TICKET_LIST_PAGED(tickets: ITicket[]) {
		this.loading = false;
		if (tickets.length < 30) this.fetched = true;
		if (this.mapTickets[this.filter].at(-1)?._id === tickets[0]?._id)
			tickets.shift();
		this.mapTickets[this.filter].push(...tickets);
		tickets.forEach(ticket => {
			const room: IRoom | null = formatRoomFromTicket(ticket);
			if (room) this.mapRooms[this.filter].push(room);
		});
	}

	@Mutation
	SOCKET_ADD_TICKET_LIST({
		tickets,
		status,
	}: {
		tickets: ITicket[];
		status: Filters;
	}) {
		this.mapTickets[status].push(...tickets);
		tickets.forEach(ticket => {
			const room: IRoom | null = formatRoomFromTicket(ticket);
			if (room) this.mapRooms[status].push(room);
		});
	}

	@Mutation
	SOCKET_REMOVE_TICKET_LIST({
		tickets,
		status,
	}: {
		tickets: string[];
		status: Filters;
	}) {
		this.mapTickets[status] = this.mapTickets[status].filter(
			t => !tickets.includes(t._id)
		);
		this.mapRooms[status] = this.mapRooms[status].filter(
			t => !tickets.includes(t.roomId)
		);
	}
	@Mutation
	SOCKET_WALLET_LIST(wallets: IWallet[]) {
		this.wallets = wallets;
	}

	@Mutation
	setLoading(loading: boolean) {
		this.loading = loading;
	}
	@Mutation
	setFetched(fetched: boolean) {
		this.fetched = fetched;
	}
	@Mutation
	setTickets({ filter, tickets }: { filter: Filters; tickets: ITicket[] }) {
		this.mapTickets[filter] = tickets;
		this.mapRooms[filter] = [];
		tickets.forEach(ticket => {
			const room = formatRoomFromTicket(ticket);
			if (!room) return;
			this.mapRooms[filter].unshift(room);
		});
	}

	@MutationAction
	async setActualRoom(id: string | null) {
		if (
			this.currentNewestRoom.id &&
			this.currentRoomId !== this.currentNewestRoom.id
		)
			this.context.commit("previewOldTicket", {
				ticketId: this.currentNewestRoom.id,
				setCurrRoom: false,
			});
		const room = this.mapRooms[this.filter].find(r => r.roomId === id);
		socket.emit("CLIENT_WALLETS", {
			token: AuthModule.token,
			client: this.mapTickets[this.filter].find(t => t._id === id)?.client[0],
		});
		return {
			currentRoomId: id, // Seteando id
			currentNewestRoom: {
				id: room?.newestRoom ? id : this.currentNewestRoom.id,
				lastMessageDate: room?.newestRoom
					? room?.lastMessageDate ?? null
					: this.currentNewestRoom.lastMessageDate,
			},
		};
	}

	@Mutation
	unsetRoom() {
		this.currentRoomId = null;
		this.currentNewestRoom.id = null;
		this.currentNewestRoom.lastMessageDate = null;
	}

	@Mutation
	setOpenedSidebar(value: boolean) {
		this.sidebar = this.currentRoomId ? value : false;
	}

	@Mutation
	setOpenedSearchSidebar(value: boolean) {
		this.searchSidebar = this.currentRoomId ? value : false;
	}

	@Mutation
	ticketClaimed({
		ticket,
		status = Filters.OPEN,
	}: {
		ticket: ITicket;
		status?: Filters;
	}) {
		ticket.bot = false;
		this.mapTickets[status] = this.mapTickets[status].filter(
			t => t._id !== ticket._id
		);
		this.mapRooms[status] = this.mapRooms[status].filter(
			r => r.roomId !== ticket._id
		);
		this.mapTickets.claimed.push(ticket);
		MessageModule.deleteFromTicket(ticket._id);
		const room = formatRoomFromTicket(ticket);
		if (room) this.mapRooms.claimed.push(room);
		router.push("/omnichannel/messages/assigned");
	}

	@Action
	ticketExists(ticketID: string) {
		const room = this.mapRooms[this.filter].find(r => r.roomId === ticketID);
		return !!room;
	}

	@Action
	ticketClaimedByAgent({ ticket, bot }: { ticket: ITicket; bot: boolean }) {
		if (
			ticket.agent.length > 0 &&
			ticket.agent.some(a => a._id == AuthModule.id)
		)
			this.ticketClaimed({
				ticket,
				status: bot ? Filters.BOT : Filters.OPEN,
			});
		else {
			this.delete({
				ticket: ticket._id,
				status: bot ? Filters.BOT : Filters.OPEN,
			});
			if (ticket._id === this.currentRoomId) {
				this.resetConfig();
			}
		}
	}

	@Mutation
	resetConfig(index?: number) {
		console.log("======> reset config", index);
		if (!index) this.currentRoomId = null;
		else
			this.currentRoomId =
				this.mapRooms[this.filter][index + 1]?.roomId ??
				this.mapRooms[this.filter][index - 1]?.roomId;
		this.currentNewestRoom = {
			id: null,
			lastMessageDate: null,
		};
		this.sidebar = false;
		this.searchSidebar = false;
	}

	@MutationAction
	async requestListTickets(token: string) {
		socket.emit("TICKET_LIST", { token });
		return {
			loading: true,
		};
	}

	@Action
	resetSearch() {
		this.setFetched(false);
		this.setLoading(true);
		this.setTickets({ filter: this.filter, tickets: [] });
	}

	@MutationAction
	async requestPageTickets({
		token,
		query,
	}: {
		token: string;
		query?: string;
	}) {
		socket.emit("TICKET_PAGE", {
			token,
			query: query ?? this.search,
			params: {
				status: this.filter,
			},
			beforeDate:
				this.mapTickets.closed[this.mapTickets.closed.length - 1]?.updatedAt ??
				new Date().toISOString(),
		});
		if (query)
			return { loading: true, fetched: false, search: query ?? this.search };
		return {
			loading: !this.mapTickets.closed.length,
			search: query ?? this.search,
		};
	}

	@Action
	ticketUpsert(ticket: ITicket) {
		if (
			this.currentNewestTicket !== null &&
			ticket._id === this.currentNewestRoom.id
		)
			this.context.commit("upsertNewestTicket", ticket);
		else this.context.commit("upsert", ticket);
	}

	@Action
	ticketDelete(ticket: string) {
		// console.log("evento de ticket_delete");
		if (ticket === this.currentRoomId) this.resetConfig();
		this.delete({ ticket, status: Filters.CLAIMED });
	}

	@Action
	ticketDeleteFrom({ ticket, status }: { ticket: string; status: Filters }) {
		if (ticket === this.currentRoomId) this.resetConfig();
		this.delete({ ticket, status });
	}

	@Action
	ticketEnd({
		token,
		ticket,
		rate,
	}: {
		token: string;
		ticket: string;
		rate?: boolean;
	}) {
		// Cambiando a la siguiente room o a la anterior
		if (this.currentRoomId === ticket) this.resetConfig();
		// Emitiendo evento para cerrar el ticket
		socket.emit("TICKET_END", { token, ticket, rate });
	}

	@Action
	async ticketPinned(id: StringNumber) {
		const room = this.mapRooms[this.filter].find(r => {
			return r.roomId == id;
		});

		coreApi.defaults.headers.common["access-token"] = AuthModule.token ?? "";
		room?.pinned
			? await coreApi.post("api/ticket/pin/unpinned", { ticketID: id })
			: await coreApi.post("api/ticket/pin/pinned", { ticketID: id });
	}

	@Action
	async ticketArchived(id: StringNumber) {
		let _id = id;
		if (
			id === this.currentRoomId &&
			this.currentNewestRoom.id &&
			this.currentRoomId !== this.currentNewestRoom.id
		) {
			_id = this.currentNewestRoom.id;
			await this.context.commit("previewOldTicket", {
				ticketId: this.currentNewestRoom.id,
			});
		}

		if (_id === this.currentRoomId) this.resetConfig();

		const room = this.mapRooms[this.filter].find(r => {
			return r.roomId == _id;
		});

		coreApi.defaults.headers.common["access-token"] = AuthModule.token ?? "";
		room?.archived
			? await coreApi.post("api/ticket/archive/unarchived", { ticketID: _id })
			: await coreApi.post("api/ticket/archive/archived", { ticketID: _id });

		if (room?.archived) this.setFilter(Filters.CLAIMED);
	}
}

export const TicketModule = getModule(Ticket);
