import { IEnv } from "@/config/model";

const {
	VUE_APP_AX_URL = location.origin,
	VUE_APP_AX_PATH = "",
	VUE_APP_SOCKET_SERVER = location.origin,
	VUE_APP_CORE_API = location.origin,
	VUE_APP_CORE_PATH = "",
	VUE_APP_ID_PAUSE = "1",
	VUE_APP_ADMIN_PAUSE = "Pausa Inicial",
	VUE_APP_ORGANIZATION = "",
	VUE_APP_OMNI_URL = location.origin,
	VUE_APP_OMNI_PATH = "",
	VUE_APP_OMNI_USERNAME = "",
	VUE_APP_OMNI_PASSWORD = "",
	VUE_APP_ADMIN_USERNAME = "",
	VUE_APP_ADMIN_PASSWORD = "",
	VUE_APP_MAX_ATT_SIZE = "5",
	VUE_APP_AGENT_API = location.origin,
	VUE_APP_REQUIRED_CALLCENTER = "",
	VUE_APP_ONLY_CALLCENTER = "",
	VUE_APP_REQUIRED_START_TICKET_ALL_CHANNELS = "",
	VUE_APP_MINUTES_INACTIVITY = "30",
	VUE_APP_FULL_CLIENT_EDIT = "",
	VUE_APP_ONLY_CONNECTED_AGENTS = "",
	VUE_APP_REQUIRED_BOT = "false",
	VUE_APP_SOCKET_PATH = "/socket.io",
	VUE_APP_RING_VOLUME = 100,
	VUE_APP_AX_IP = location.hostname,
	VUE_APP_AX_WS_PATH = "",
	VUE_APP_AX_SIP_PASS = "",
	VUE_APP_AX_WS_PORT = location.port,
	VUE_APP_PHONE_LOGGER = "false",
	VUE_APP_DYNAMIC_TYPIFICATION = "false",
	VUE_APP_AGENT_SIP_PASS = "false",
	VUE_APP_MULTIPLE_TYPIFICATION = "false",
	VUE_APP_DMS_API = location.origin,
	VUE_APP_DMS_PATH = "",
	VUE_APP_CALLBLENDING_INCOMING_CAMPAIGN_ORIGIN = "campania",
	VUE_APP_CAMPAING_INFO = "false",
	VUE_APP_DMS_API_USER = "",
	VUE_APP_DMS_API_PASS = "",
	VUE_APP_SHOW_FULL_NAME = true,
	VUE_APP_VALIDATE_TYPIFICATION_CONTROLS = false,
	VUE_APP_EXIT_DIGIT = "",
	VUE_APP_FORCE_AUTO_ANSWER = false,
	VUE_APP_GET_QUEUE_INCOMMING = false,
	VUE_APP_READY_ON_LOGIN_CALL_CENTER = false,
	VUE_APP_DYNAMIC_USER_FORM = "false",
	VUE_APP_ONLY_OUT_CALLS_GLOBAL_CALL_HISTORY = "false",
	// Dashboard API
	VUE_APP_DASHBOARD_API = location.origin,
	VUE_APP_DASHBOARD_PATH = "",
	VUE_APP_DASHBOARD_USERNAME = "",
	VUE_APP_DASHBOARD_PASSWORD = "",
	VUE_APP_NEED_EVALUATION_TO_CLEAR_ACTIVE_CALL = "false",
	VUE_APP_HISTORICAL_TICKET = "false",
	//Remote Login
	VUE_APP_REMOTE_LOGIN = "false", //Esta variable activa el logín remoto a otro aplicativo.
	VUE_APP_REMOTE_LOGIN_IP = "", //Login de la IP
	VUE_APP_REMOTE_BACKEND_PORT = "",
	VUE_APP_REMOTE_FRONTEND_PORT = "",
	VUE_APP_REMOTE_USERNAME = "",
	VUE_APP_REMOTE_PASSWORD = "",
	VUE_APP_REMOTE_CLIENT_URL = "",
	VUE_APP_ATTACHMENTS_URL = "",
	VUE_APP_ATTACHMENTS_PATH = "",
} = process.env;
export const env: IEnv = {
	AxUrl: VUE_APP_AX_URL + VUE_APP_AX_PATH,
	DashboardApi: VUE_APP_DASHBOARD_API + VUE_APP_DASHBOARD_PATH,
	DynamicTypification: VUE_APP_DYNAMIC_TYPIFICATION === "true",
	DynamicUser: VUE_APP_DYNAMIC_USER_FORM === "true",
	MultipleTypification: VUE_APP_MULTIPLE_TYPIFICATION == "true",
	SocketServer: VUE_APP_SOCKET_SERVER,
	SocketPath: VUE_APP_SOCKET_PATH,
	ringVolume: parseInt(VUE_APP_RING_VOLUME.toString()) / 100,
	CoreApi: VUE_APP_CORE_API + VUE_APP_CORE_PATH,
	AgentApi: VUE_APP_AGENT_API,
	AdminID: VUE_APP_ID_PAUSE,
	AdminPause: VUE_APP_ADMIN_PAUSE,
	Organization: VUE_APP_ORGANIZATION,
	OmniUrl: VUE_APP_OMNI_URL + VUE_APP_OMNI_PATH,
	OmniUsernameAgent: VUE_APP_OMNI_USERNAME,
	OmniPasswordAgent: VUE_APP_OMNI_PASSWORD,
	adminUsername: VUE_APP_ADMIN_USERNAME,
	adminPassword: VUE_APP_ADMIN_PASSWORD,
	dashboardUsername: VUE_APP_DASHBOARD_USERNAME,
	dashboardPassword: VUE_APP_DASHBOARD_PASSWORD,
	requiredCallcenter: VUE_APP_REQUIRED_CALLCENTER === "true",
	readyOnLoginCallCenter: VUE_APP_READY_ON_LOGIN_CALL_CENTER === "true",
	onlyCallcenter: VUE_APP_ONLY_CALLCENTER === "true",
	requiredStartTicketAllChannels:
		VUE_APP_REQUIRED_START_TICKET_ALL_CHANNELS === "true",
	MaxAttachmentSize: parseInt(VUE_APP_MAX_ATT_SIZE),
	FullClientEdit: VUE_APP_FULL_CLIENT_EDIT === "true",
	Colors: [
		"primary",
		"orange",
		"teal",
		"purple",
		"pink",
		"info",
		"warning",
		"indigo",
		"red",
	],
	minForInactive: parseInt(VUE_APP_MINUTES_INACTIVITY),
	onlyConnectedAgents: VUE_APP_ONLY_CONNECTED_AGENTS === "true",
	showFullName: VUE_APP_SHOW_FULL_NAME === "true",
	Bot: VUE_APP_REQUIRED_BOT === "true",
	asteriskSocketIP: VUE_APP_AX_IP,
	asteriskWebSocketPath: VUE_APP_AX_WS_PATH,
	asteriskWebSocketPassword: VUE_APP_AX_SIP_PASS,
	asteriskWebSocketPort: VUE_APP_AX_WS_PORT,
	phoneLogger: VUE_APP_PHONE_LOGGER === "true",
	agentSipPass: VUE_APP_AGENT_SIP_PASS === "true",
	DMSAPI: VUE_APP_DMS_API + VUE_APP_DMS_PATH,
	CallblendingIncomingCampaignOrigin:
		VUE_APP_CALLBLENDING_INCOMING_CAMPAIGN_ORIGIN,
	CampaingInfo: VUE_APP_CAMPAING_INFO === "true",
	DmsApiPass: VUE_APP_DMS_API_PASS,
	DmsApiUser: VUE_APP_DMS_API_USER,
	ValidateTypificationControls:
		VUE_APP_VALIDATE_TYPIFICATION_CONTROLS === "true",
	ExitDigits: VUE_APP_EXIT_DIGIT.split(","),
	ForceAutoAnswer: VUE_APP_FORCE_AUTO_ANSWER === "true",
	GetQueueIncoming: VUE_APP_GET_QUEUE_INCOMMING === "true",
	onlyOutCallsGlobalCallHistory:
		VUE_APP_ONLY_OUT_CALLS_GLOBAL_CALL_HISTORY == "true",
	needEvaluationToClearActiveCall:
		VUE_APP_NEED_EVALUATION_TO_CLEAR_ACTIVE_CALL === "true",
	historicalTicket: VUE_APP_HISTORICAL_TICKET == "true",
	remoteLogin: VUE_APP_REMOTE_LOGIN === "true",
	remoteLoginFronted:
		VUE_APP_REMOTE_LOGIN_IP + ":" + VUE_APP_REMOTE_FRONTEND_PORT,
	remoteLoginBackend:
		VUE_APP_REMOTE_LOGIN_IP + ":" + VUE_APP_REMOTE_BACKEND_PORT,
	remoteLoginUsername: VUE_APP_REMOTE_USERNAME,
	remoteLoginPassword: VUE_APP_REMOTE_PASSWORD,
	remoteClientURL: VUE_APP_REMOTE_CLIENT_URL,
	attachmentURL:
		VUE_APP_ATTACHMENTS_URL || VUE_APP_ATTACHMENTS_PATH
			? `${
					VUE_APP_ATTACHMENTS_URL ? VUE_APP_ATTACHMENTS_URL : location.origin
			  }${VUE_APP_ATTACHMENTS_PATH}`
			: undefined,
};

export const AttachmentTypes: { [k: string]: RegExp } = {
	image: /image/i,
	video: /video/i,
	audio: /audio|mp3|audio\/mp3|oga/i,
	file: /text|plain|doc|docx|pdf|msword|powerpoint|vnd.ms-excel/i,
};
