<template src="@/components/webPhone/template/phone.html"></template>
<style scoped>
@import "css/phone.css";
@import "css/textAnimation.css";
</style>
<script>
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-redeclare */
/* eslint-disable */
//global.Buddies = [];
import SoftPhoneButton from "@/components/webPhone/components/soft-phone-button.vue";
import Avatar from "@/views/Components/Navbar/Avatar.vue";

//Librerias funcionamiento webRTC
import * as moment from "moment";
//import * as fabric from "fabric";
//import * as moment from "@/lib/Moment/moment-with-locales-2.24.0.min";
//import * as fabric from "@/lib/FabricJS/fabric-2.4.6.min.js";
import { AuthModule } from "@/store/modules/Auth";
import { AlertModule } from "@/store/modules/Alert";
import * as SIP from "@/lib/SipJS/sip-0.20.0.min.js";
import {
	dragElement,
	watchHeigth,
} from "@/components/webPhone/components/js/draggBox.ts";

import { CallDataModule } from "@/store/modules/CallData";

import langJson from "@/lang/es.json";
import { env } from "@/config";
import { PauseModule } from "@/store/modules/Pause";
import { CampaingModule } from "@/store/modules/Campaign";
import { cleanPhoneInput } from "@/util/utils";
import { ElMessageBox } from "element-plus";
import { PhoneModule } from "@/store/modules/Phone";
import { coreApi } from "@/conection";
import { OutputDeviceModule } from "@/store/modules/OutputDevice";

function FindLineByNumber(Lines, lineNum) {
	for (var l = 0; l < Lines.length; l++) {
		if (Lines[l].LineNumber == lineNum) return Lines[l];
	}
	return null;
}
function uID() {
	return (
		Date.now() +
		Math.floor(Math.random() * 10000)
			.toString(16)
			.toUpperCase()
	);
}
function SaveQosData(QosData, sessionId, buddy) {
	var indexedDB = window.indexedDB;
	var request = indexedDB.open("CallQosData", 1);
	request.onerror = function (event) {
		console.error("IndexDB Request Error:", event);
	};
	request.onupgradeneeded = function (event) {
		console.warn(
			"Upgrade Required for IndexDB... probably because of first time use."
		);
		var IDB = event.target.result;

		// Create Object Store
		if (IDB.objectStoreNames.contains("CallQos") == false) {
			var objectStore = IDB.createObjectStore("CallQos", { keyPath: "uID" });
			objectStore.createIndex("sessionid", "sessionid", { unique: false });
			objectStore.createIndex("buddy", "buddy", { unique: false });
			objectStore.createIndex("QosData", "QosData", { unique: false });
		} else {
			console.warn("IndexDB requested upgrade, but object store was in place");
		}
	};
	request.onsuccess = function (event) {
		console.log("IndexDB connected to CallQosData");

		var IDB = event.target.result;
		if (IDB.objectStoreNames.contains("CallQos") == false) {
			console.warn("IndexDB CallQosData.CallQos does not exists");
			IDB.close();
			window.indexedDB.deleteDatabase("CallQosData"); // This should help if the table structure has not been created.
			return;
		}
		IDB.onerror = function (event) {
			console.error("IndexDB Error:", event);
		};

		// Prepare data to write
		// var data = {
		// 	uID: uID(),
		// 	sessionid: sessionId,
		// 	buddy: buddy,
		// 	QosData: QosData,
		// };
		// Commit Transaction
		// var transaction = IDB.transaction(["CallQos"], "readwrite");
		// var objectStoreAdd = transaction.objectStore("CallQos").add(data);
		// objectStoreAdd.onsuccess = function (event) {
		// 	console.log("Call CallQos Sucess: ", sessionId);
		// };
	};
}
class SoundMeter {
	constructor(sessionId, lineNum, Lines) {
		var audioContext = null;
		try {
			window.AudioContext = window.AudioContext || window.webkitAudioContext;
			audioContext = new AudioContext();
		} catch (e) {
			console.warn("AudioContext() LocalAudio not available... its fine.");
		}
		if (audioContext == null) return null;
		this.context = audioContext;
		this.source = null;
		this.Lines = Lines;
		this.lineNum = lineNum;
		this.sessionId = sessionId;
		this.captureInterval = null;
		this.levelsInterval = null;
		this.networkInterval = null;
		this.startTime = 0;

		this.ReceiveBitRateChart = null;
		this.ReceiveBitRate = [];
		this.ReceivePacketRateChart = null;
		this.ReceivePacketRate = [];
		this.ReceivePacketLossChart = null;
		this.ReceivePacketLoss = [];
		this.ReceiveJitterChart = null;
		this.ReceiveJitter = [];
		this.ReceiveLevelsChart = null;
		this.ReceiveLevels = [];
		this.SendBitRateChart = null;
		this.SendBitRate = [];
		this.SendPacketRateChart = null;
		this.SendPacketRate = [];

		this.instant = 0; // Primary Output indicator

		this.AnalyserNode = this.context.createAnalyser();
		this.AnalyserNode.minDecibels = -90;
		this.AnalyserNode.maxDecibels = -10;
		this.AnalyserNode.smoothingTimeConstant = 0.85;
	}
	connectToSource(stream, callback) {
		console.log("SoundMeter connecting...");
		try {
			this.source = this.context.createMediaStreamSource(stream);
			this.source.connect(this.AnalyserNode);
			//this.AnalyserNode.connect(this.context.destination); // Can be left unconnected
			//this._start();

			callback(null);
		} catch (e) {
			console.error(e); // Probably not audio track
			callback(e);
		}
	}
	_start() {
		this.instant = 0;
		this.AnalyserNode.fftSize = 32; // 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, and 32768. Defaults to 2048
		this.dataArray = new Uint8Array(this.AnalyserNode.frequencyBinCount);

		this.captureInterval = window.setInterval(function () {
			//this.AnalyserNode.getByteFrequencyData(this.dataArray); // Populate array with data from 0-255
			// Just take the maximum value of this data
			this.instant = 0;
			for (var d = 0; d < this.dataArray.length; d++) {
				if (this.dataArray[d] > this.instant) this.instant = this.dataArray[d];
			}
		}, 1);
	}
	stop(lineNumber) {
		console.log("Disconnecting SoundMeter...");
		if (lineNumber > 0) $("#line-" + lineNumber + "-remoteAudio").remove();
		window.clearInterval(this.captureInterval);
		this.captureInterval = null;
		window.clearInterval(this.levelsInterval);
		this.levelsInterval = null;
		window.clearInterval(this.networkInterval);
		this.networkInterval = null;
		try {
			this.source.disconnect();
		} catch (e) {
			console.log(e);
		}
		this.source = null;
		try {
			this.AnalyserNode.disconnect();
		} catch (e) {
			console.log(e);
		}
		this.AnalyserNode = null;
		try {
			this.context.close();
		} catch (e) {
			console.log(e);
		}
		this.context = null;

		// Save to IndexDb
		var lineObj = FindLineByNumber(this.Lines, this.lineNum);
		var QosData = {
			ReceiveBitRate: this.ReceiveBitRate,
			ReceivePacketRate: this.ReceivePacketRate,
			ReceivePacketLoss: this.ReceivePacketLoss,
			ReceiveJitter: this.ReceiveJitter,
			ReceiveLevels: this.ReceiveLevels,
			SendBitRate: this.SendBitRate,
			SendPacketRate: this.SendPacketRate,
		};
		if (this.sessionId != null) {
			SaveQosData(QosData, this.sessionId, lineObj.BuddyObj.identity);
		}
	}
}
export default {
	components: {
		Avatar,
		SoftPhoneButton,
	},
	data() {
		return {
			model: {
				barCount: 15,
				barLevel: 0,
				muted: false,
				calling: false,
				onCall: false,
				duration: 0,
				show: false,
				input: "",
				automatic: false,
				showChannels: false,
			},
			phoneLines: {
				currentContainerSize: 262.5, //Tamaño del contenedor actual
				colLinesClass: "", //Clase de la columna de lineas activas
				colPhoneClass: "col-12 px-0", //Clase de la columna de webPhone
				colBodyPhoneClass: "padding-15", //Clase Body de la columna del WebPhone
				smallContainerSize: 262.5, //Tamaño pequeño del contenedor
				bigContainerSize: 525, //Tamaño Grande del contenedor
			},
			interval: undefined,
			barLevelInterval: undefined,
			//EMPIEZA LOS DATOS DEL TELEFONO
			localDB: window.localStorage,
			telefono_tranferencia: "",
			telefono_conferencia: "",
			llamada_entrante: 0,
			newLineNumber: 1,
			lineas_activas: true,
			finalizar_linea_activas: false,
			lineas_activas_llamadas: false,
			datos_lineas_activas: [],
			lienea_uno_activa: true,
			audioBlobs: {},
			Buddies: [],
			quitar_en_espera: false,
			activarmute: false,
			tranferencia_cancelada: false,
			tranferencia_atendida: false,
			opciones_transferencia: false,
			opciones_conferencia: false,
			contestar_automatico: env.ForceAutoAnswer,
			cancelar_conferencia: false,
			cancelar_transferencia: false,
			desactivarmute: false,
			poner_en_espera: false,
			realizo_llamada_saliente: 0,
			tiempo_llamada: "",
			digitar_telefono: true,
			digitar_transferencia: false,
			digitar_conferencia: false,
			linea_conferencia: false,
			botones_telefono: true,
			botones_dtmf: false,
			datos_llamada: false,
			numero_hablando: "",
			nombre_largo: "",
			nombre_corto: "",
			numero_linea: "",
			lineObj: [],
			sesionSIP: [],
			drawer: null,
			search: "",
			//ESTADOS PROPIOS
			numero_telefonico: "",
			colgar: false,
			cancelar_llamada_saliente: false,
			finalizar_llamada: false,
			llamadaSaliente: true,
			respuesta_automatica: true,
			transferencia: false,
			conferencia: false,
			desactivar_conferencia: false,
			contestar: false,
			Lines: [],
			lang: [],
			global: this,
			userAgent: null,
			nueva_linea: false,
			cancelar_nueva_linea: false,
			digitar_nueva_linea: false,
			telefono_nueva_linea: "",
			opciones_nueva_linea: false,
			nueva_linea_cancelada: false,
			nueva_linea_atendida: false,
			transfiriendo_multi_linea: false,
			linea_a_transferir: -1,
			conferenciando_multi_linea: false,
			linea_a_conferenciar: -1,
			en_conferencia: false,
			conectando_conferencia: false,
			numero_conferenciar: "",
			// disable buttons
			conf_disable: false,
			transfer_disable: false,
			//TEMA TOOLTIP
			tooltip_effect: "dark",
			tooltip_show_after: 500,
			tooltip_placement: "top",
			force_auto_answer: env.ForceAutoAnswer,
			unregisterMethod: null,
			AudioinputDevices: [],
		};
	},
	methods: {
		async getNames(number) {
			return {};
		},
		startCall() {
			this.model.duration = 0;
			if (!this.model.onCall) this.startDuration();
			this.model.onCall = true;
		},
		endCall() {
			this.stopDuration();
		},
		startDuration() {
			clearInterval(this.barLevelInterval);
			this.barLevelInterval = setInterval(() => {
				if (this.model.muted) this.model.barLevel = 0;
				else
					this.model.barLevel = Math.round(Math.random() * this.model.barCount);
			}, 200);
		},

		changeMuted() {
			this.model.muted = !this.model.muted;
		},
		isNumber(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46 &&
				charCode !== 42
			) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		pasteHandle(event, model) {
			event.preventDefault();
			let valorOriginal = event.clipboardData.getData("text");
			this[model] += cleanPhoneInput(valorOriginal);
		},
		concatNumbers(numero_linea, n) {
			if (this.digitar_telefono) {
				this.numero_telefonico = this.numero_telefonico.concat("", n);
			} else if (this.digitar_conferencia) {
				this.telefono_conferencia = this.telefono_conferencia.concat("", n);
			} else if (this.digitar_transferencia) {
				this.telefono_tranferencia = this.telefono_tranferencia.concat("", n);
				this.sendDTMF(numero_linea, n);
			} else if (this.digitar_nueva_linea) {
				this.telefono_nueva_linea = this.telefono_nueva_linea.concat("", n);
			}
			if (this.botones_dtmf) {
				this.numero_hablando = this.numero_hablando.concat("", n);
				this.sendDTMF(numero_linea, n);
			}
		},
		//METODOS CALL CENTER
		FindBuddyByIdentity(identity) {
			for (var b = 0; b < this.Buddies.length; b++) {
				if (this.Buddies[b].identity == identity) return this.Buddies[b];
			}
			return null;
		},
		utcDateNow() {
			return moment().utc().format("YYYY-MM-DD HH:mm:ss UTC");
		},
		uID() {
			return (
				Date.now() +
				Math.floor(Math.random() * 10000)
					.toString(16)
					.toUpperCase()
			);
		},
		IncreaseMissedBadge(buddy) {
			var buddyObj = this.FindBuddyByIdentity(buddy);
			if (buddyObj == null) return;

			// Up the Missed Count
			// ===================
			buddyObj.missed += 1;

			// Take Out
			var json = JSON.parse(
				this.localDB.getItem(this.profileUserID + "-Buddies")
			);
			if (json != null) {
				$.each(json.DataCollection, function (i, item) {
					if (item.uID == buddy || item.cID == buddy || item.gID == buddy) {
						item.missed = item.missed + 1;
						return false;
					}
				});
				// Put Back
				this.localDB.setItem(
					this.profileUserID + "-Buddies",
					JSON.stringify(json)
				);
			}

			// Update Badge
			// ============
			//	$("#contact-" + buddy + "-missed").text(buddyObj.missed);
			//	$("#contact-" + buddy + "-missed").show();

			// console.log(
			// 	"Set Missed badge for " +
			// 		buddyObj.CallerIDName +
			// 		" to: " +
			// 		buddyObj.missed
			// );
		},
		KeyPress(num) {
			this.numero_telefonico += num.substring(0, 16);
		},

		getAudioSrcID() {
			var id = this.localDB.getItem("AudioSrcId");
			return id != null ? id : "default";
		},
		getAudioOutputID() {
			var selectDevice = OutputDeviceModule.CallOutputDevice;
			var id = this.localDB.getItem("AudioOutputId");
			return selectDevice ? selectDevice : id != null ? id : "default";
		},
		onTrackAddedEvent(lineObj, includeVideo) {
			// Gets remote tracks
			var session = lineObj.SipSession;
			this.sesionSIP = session;
			// TODO: look at detecting video, so that UI switches to audio/video automatically.

			var pc = session.sessionDescriptionHandler.peerConnection;

			var remoteAudioStream = new MediaStream();
			var remoteVideoStream = new MediaStream();

			pc.getTransceivers().forEach(function (transceiver) {
				// Add Media
				var receiver = transceiver.receiver;
				if (receiver.track) {
					if (receiver.track.kind == "audio") {
						console.log("Adding Remote Audio Track");
						remoteAudioStream.addTrack(receiver.track);
					}
					if (includeVideo && receiver.track.kind == "video") {
						if (transceiver.mid) {
							receiver.track.mid = transceiver.mid;
							console.log(
								"Adding Remote Video Track - ",
								receiver.track.readyState,
								"MID:",
								receiver.track.mid
							);
							remoteVideoStream.addTrack(receiver.track);
						}
					}
				}
			});

			// Attach Audio
			if (remoteAudioStream.getAudioTracks().length >= 1) {
				var global = this;
				var remoteAudio = $("#line-" + lineObj.LineNumber + "-remoteAudio").get(
					0
				);
				console.log("ReemoteAudio", remoteAudio);
				remoteAudio.srcObject = remoteAudioStream;
				remoteAudio.onloadedmetadata = function (e) {
					if (typeof remoteAudio.sinkId !== "undefined") {
						remoteAudio
							.setSinkId(global.getAudioOutputID())
							.then(function () {
								console.log("sinkId applied: " + global.getAudioOutputID());
							})
							.catch(function (e) {
								console.warn("Error using setSinkId: ", e);
							});
					}
					remoteAudio.play();
				};
			}

			if (includeVideo) {
				// Single Or Multiple View
				$("#line-" + lineObj.LineNumber + "-remote-videos").empty();
				if (remoteVideoStream.getVideoTracks().length >= 1) {
					var remoteVideoStreamTracks = remoteVideoStream.getVideoTracks();
					remoteVideoStreamTracks.forEach(function (remoteVideoStreamTrack) {
						var thisRemoteVideoStream = new MediaStream();
						thisRemoteVideoStream.trackID = remoteVideoStreamTrack.id;
						thisRemoteVideoStream.mid = remoteVideoStreamTrack.mid;
						remoteVideoStreamTrack.onended = function () {
							console.log("Video Track Ended: ", this.mid);
							RedrawStage(lineObj.LineNumber, true);
						};
						thisRemoteVideoStream.addTrack(remoteVideoStreamTrack);

						var wrapper = $("<span />", {
							class: "VideoWrapper",
						});
						wrapper.css("width", "1px");
						wrapper.css("heigh", "1px");
						wrapper.hide();

						var callerID = $("<div />", {
							class: "callerID",
						});
						wrapper.append(callerID);

						var Actions = $("<div />", {
							class: "Actions",
						});
						wrapper.append(Actions);

						var videoEl = $("<video />", {
							id: remoteVideoStreamTrack.id,
							mid: remoteVideoStreamTrack.mid,
							muted: true,
							autoplay: true,
							playsinline: true,
							controls: false,
						});
						videoEl.hide();

						var videoObj = videoEl.get(0);
						videoObj.srcObject = thisRemoteVideoStream;
						videoObj.onloadedmetadata = function (e) {
							// videoObj.play();
							videoEl.show();
							videoEl.parent().show();
							console.log(
								"Playing Video Stream MID:",
								thisRemoteVideoStream.mid
							);
							RedrawStage(lineObj.LineNumber, true);
						};
						wrapper.append(videoEl);

						$("#line-" + lineObj.LineNumber + "-remote-videos").append(wrapper);

						console.log("Added Video Element MID:", thisRemoteVideoStream.mid);
					});
				} else {
					console.log("No Video Streams");
					RedrawStage(lineObj.LineNumber, true);
				}
			}

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("trackAdded", session);
		},
		HidePopup(timeout) {
			let menuObj = null;
			if (timeout) {
				window.setTimeout(function () {
					if (menuObj != null) {
						menuObj.menu("destroy");
						try {
							menuObj.empty();
						} catch (e) {
							console.log(e);
						}
						try {
							menuObj.remove();
						} catch (e) {
							console.log(e);
						}
						menuObj = null;
					}
				}, timeout);
			} else {
				if (menuObj != null) {
					menuObj.menu("destroy");
					try {
						menuObj.empty();
					} catch (e) {
						console.log(e);
					}
					try {
						menuObj.remove();
					} catch (e) {
						console.log(e);
					}
					menuObj = null;
				}
			}
		},
		teardownSession(lineObj) {
			let global = this;

			if (lineObj == null || lineObj.SipSession == null) return;

			var session = lineObj.SipSession;
			if (session.data.teardownComplete == true) return;
			session.data.teardownComplete = true; // Run this code only once

			// Call UI
			if (session.data.earlyReject != true) {
				this.HidePopup();
			}

			// End any child calls
			if (session.data.childsession && session.data.childsession.state) {
				session.data.childsession
					.dispose()
					.then(function () {
						session.data.childsession = null;
					})
					.catch(function (error) {
						session.data.childsession = null;
						console.log(error);
					});
			}

			// Mixed Tracks
			if (
				session.data.AudioSourceTrack &&
				session.data.AudioSourceTrack.kind == "audio"
			) {
				session.data.AudioSourceTrack.stop(lineObj?.LineNumber);
				session.data.AudioSourceTrack = null;
			}
			// Stop any Early Media
			if (session.data.earlyMedia) {
				session.data.earlyMedia.pause();
				session.data.earlyMedia.removeAttribute("src");
				session.data.earlyMedia.load();
				session.data.earlyMedia = null;
			}
			// Stop any ringing calls
			if (session.data.rinngerObj) {
				session.data.rinngerObj.pause();
				session.data.rinngerObj.removeAttribute("src");
				session.data.rinngerObj.load();
				session.data.rinngerObj = null;
			}

			// Stop Recording if we are
			//StopRecording(lineObj.LineNumber,true);

			// Audio Meters
			if (lineObj.LocalSoundMeter != null) {
				lineObj.LocalSoundMeter.stop(lineObj?.LineNumber);
				lineObj.LocalSoundMeter = null;
			}
			if (lineObj.RemoteSoundMeter != null) {
				lineObj.RemoteSoundMeter.stop(lineObj?.LineNumber);
				lineObj.RemoteSoundMeter = null;
			}

			// Make sure you have released the microphone
			if (
				session &&
				session.sessionDescriptionHandler &&
				session.sessionDescriptionHandler.peerConnection
			) {
				var pc = session.sessionDescriptionHandler.peerConnection;
				pc.getSenders().forEach(function (RTCRtpSender) {
					if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
						RTCRtpSender.track.stop(lineObj.LineNumber);
					}
				});
			}

			// End timers
			window.clearInterval(session.data.videoResampleInterval);
			window.clearInterval(session.data.callTimer);

			// Add to stream
			//AddCallMessage(lineObj.BuddyObj.identity, session);

			// Check if this call was missed
			if (
				session.data.calldirection == "inbound" &&
				session.data.terminateby == "them" &&
				lineObj.SipSession.data.startTime == null
			) {
				this.IncreaseMissedBadge(session.data.buddyId);
			}
			delete this.datos_lineas_activas[lineObj.LineNumber];

			// Custom Web hook
			if (typeof web_hook_on_terminate !== "undefined")
				web_hook_on_terminate(session);
		},
		async AnswerAudioCall(lineNumber) {
			console.log("Realiza AnswerAudio Call");

			lineNumber =
				isNaN(lineNumber["numero_linea"]) == true
					? lineNumber
					: lineNumber["numero_linea"];
			// var lineObj = this.lineObj; // se comento porque contestaba la linea que no era
			var lineObj = this.FindLineByNumber(lineNumber) ?? this.lineObj;
			const totalActiveCalls = Object.keys(this.datos_lineas_activas).length;
			const lineSelected =
				totalActiveCalls > 1
					? this.Lines?.find(l => l.IsSelected)
					: this.Lines[0];

			if (totalActiveCalls === 0) {
				return;
			}
			console.log(
				"En answeraudio call efectua: ",
				lineSelected?.LineNumber,
				lineObj?.LineNumber,
				Object.keys(this.datos_lineas_activas).length,
				this.datos_lineas_activas
			);
			let HasAudioDevice = false;
			var global = this;
			if (lineSelected?.LineNumber == lineObj?.LineNumber) {
				this.botones_telefono = false;
				this.botones_dtmf = true;
				this.llamadaSaliente = false;
				this.respuesta_automatica = false;
				this.realizo_llamada_saliente = 0;
				this.llamada_entrante = 1;
				this.contestar = false;
				this.transferencia = true;
				this.nueva_linea = true;
				this.cancelar_nueva_linea = false;
				this.conferencia = true;
				this.activarmute = true;
				this.poner_en_espera = true;
				// this.quitar_en_espera = false; // se revisa en holdSession
				this.lienea_uno_activa = false;
				this.finalizar_llamada = true;
				this.colgar = false;
				this.datos_lineas_activas[lineNumber].estadado_llamada = true;
				this.lineas_activas = false;
				this.finalizar_linea_activas = true;
			}

			//ALMACENAR EN BASE DE DATOS REGISTRO DE LLAMADA.
			// CallDataModule.saveCall({
			// 	telefono: this.numero_hablando,
			// 	tipo_llamada: "ENTRANTE",
			// });

			var html = "";
			html += "<div class='crear-audio'>";
			html += '<audio id="line-' + lineNumber + '-remoteAudio"></audio>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				lineNumber +
				'-AudioSendBitRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				lineNumber +
				'-AudioSendPacketRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				lineNumber +
				'-AudioReceiveBitRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				lineNumber +
				'-AudioReceivePacketRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				lineNumber +
				'-AudioReceivePacketLoss" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				lineNumber +
				'-AudioReceiveJitter" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				lineNumber +
				'-AudioReceiveLevels" class=audioGraph></canvas></div>';
			html += "</div>";
			$("#formagentemain").append(html);

			// lineObj = this.FindLineByNumber(lineNumber);
			if (lineObj == null) {
				console.warn("Failed to get line (" + lineNumber + ")");
				return;
			}
			var session = lineObj.SipSession;
			// Stop the ringtone
			if (session.data.rinngerObj) {
				session.data.rinngerObj.pause();
				session.data.rinngerObj.removeAttribute("src");
				session.data.rinngerObj.load();
				session.data.rinngerObj = null;
			}
			// Check vitals
			if (HasAudioDevice == true) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Lo sentimos, no tienes ningún micrófono conectado a este ordenador. No puede recibir llamadas.",
					type: "error",
				});
				$("#line-" + lineObj.LineNumber + "-msg").html(this.lang.call_failed);
				$("#line-" + lineObj.LineNumber + "-AnswerCall").hide();
				return;
			}

			// Update UI
			$("#line-" + lineObj.LineNumber + "-AnswerCall").hide();

			// Start SIP handling
			var supportedConstraints =
				navigator.mediaDevices.getSupportedConstraints();
			var spdOptions = {
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: { deviceId: "default" },
						video: false,
					},
				},
			};

			// Configure Audio
			var currentAudioDevice = this.getAudioSrcID();
			if (currentAudioDevice != "default") {
				var confirmedAudioDevice = false;
				for (var i = 0; i < this.AudioinputDevices.length; ++i) {
					if (currentAudioDevice == this.AudioinputDevices[i].deviceId) {
						confirmedAudioDevice = true;
						break;
					}
				}
				if (confirmedAudioDevice) {
					spdOptions.sessionDescriptionHandlerOptions.constraints.audio.deviceId =
						{ exact: currentAudioDevice };
				} else {
					console.warn(
						"The audio device you used before is no longer available, default settings applied."
					);
					this.localDB.setItem("AudioSrcId", "default");
				}
			}
			// Add additional Constraints
			if (supportedConstraints.autoGainControl) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl = 0;
			}
			if (supportedConstraints.echoCancellation) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation = 0;
			}
			if (supportedConstraints.noiseSuppression) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression = 0;
			}

			// Save Devices
			lineObj.SipSession.data.withvideo = false;
			lineObj.SipSession.data.VideoSourceDevice = null;
			lineObj.SipSession.data.AudioSourceDevice = this.getAudioSrcID();
			lineObj.SipSession.data.AudioOutputDevice = this.getAudioOutputID();

			// Send Answer
			lineObj.SipSession.accept(spdOptions)
				.then(resp => {
					global.onInviteAccepted(lineObj, false);
				})
				.catch(error => {
					const totalLines = Object.keys(this.datos_lineas_activas).length;
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text:
							error.message.indexOf("Permission denied") > -1
								? "Llamada no pudo ser contestada por falta de permisos en el navegador!"
								: error.message.indexOf("connection closed") > -1
								? "Llamada no pudo ser contestada, la otra línea colgó antes de enlazar!"
								: "LLamada no pudo ser contestada!",
						type: "warning",
					});
					coreApi.post("/api/log", {
						error,
						message: error.message,
					});
					if (totalLines > 1) {
						if (totalLines === 2) this.closeWebLines();
						this.Lines = this.Lines.filter(
							data => data.LineNumber != lineNumber
						);
						this.lineObj = this.Lines.slice(-1)[0];
						this.SelectLine(this.lineObj.LineNumber, { unhold: false });
					} else {
						// Equal 1 this call failed
						this.lienea_uno_activa = true;
						this.numero_telefonico = "";
						this.tiempo_llamada = "";
						this.nombre_largo = "";
						this.numero_hablando = "";
						this.nombre_corto = "";
						this.llamadaSaliente = true;
						this.botones_telefono = true;
						this.botones_dtmf = false;
						this.respuesta_automatica = true;
						this.linea_conferencia = false;
						this.desactivar_conferencia = false;
						this.digitar_conferencia = false;
						this.telefono_conferencia = "";
						this.transferencia = false;
						this.nueva_linea = false;
						this.conferencia = false;
						this.cancelar_transferencia = false;
						this.cancelar_nueva_linea = false;
						this.contestar = false;
						this.activarmute = false;
						this.poner_en_espera = false;
						this.quitar_en_espera = false;
						this.desactivarmute = false;
						this.finalizar_llamada = false;
						this.colgar = false;
						this.cancelar_llamada_saliente = false;
						this.datos_llamada = false;
						this.digitar_transferencia = false;
						this.digitar_nueva_linea = false;
						this.digitar_telefono = true;
						this.stopDuration();
						this.Buddies = [];
						this.closeWebLines();
						this.Lines = [];
						this.lineObj = [];
					}
					console.warn("Failed to answer call", error, lineObj.SipSession);
					lineObj.SipSession.data.reasonCode = 500;
					lineObj.SipSession.data.reasonText = "Client Error";
					global.teardownSession(lineObj);
				});
			if (this.nombre_corto === env.CallblendingIncomingCampaignOrigin) {
				await CampaingModule.loadClientData({ number: this.numero_hablando });
				await CampaingModule.setShowDrawer(true);
			}
		},
		formatShortDuration(seconds) {
			var sec = Math.floor(parseFloat(seconds));
			if (sec < 0) {
				return sec;
			} else if (sec >= 0 && sec < 60) {
				return "00:" + (sec > 9 ? sec : "0" + sec);
			} else if (sec >= 60 && sec < 60 * 60) {
				// greater then a minute and less then an hour
				let duration = moment.duration(sec, "seconds");
				return (
					(duration.minutes() > 9
						? duration.minutes()
						: "0" + duration.minutes()) +
					":" +
					(duration.seconds() > 9
						? duration.seconds()
						: "0" + duration.seconds())
				);
			} else if (sec >= 60 * 60 && sec < 24 * 60 * 60) {
				// greater than an hour and less then a day
				let duration2 = moment.duration(sec, "seconds");
				return (
					(duration2.hours() > 9
						? duration2.hours()
						: "0" + duration2.hours()) +
					":" +
					(duration2.minutes() > 9
						? duration2.minutes()
						: "0" + duration2.minutes()) +
					":" +
					(duration2.seconds() > 9
						? duration2.seconds()
						: "0" + duration2.seconds())
				);
			}
			//  Otherwise.. this is just too long
		},
		FindLineByNumber(lineNum) {
			for (var l = 0; l < this.Lines.length; l++) {
				if (this.Lines[l].LineNumber == lineNum) return this.Lines[l];
			}
			return null;
		},
		RejectCall(lineNumber) {
			console.log("Reject Call Line Number", lineNumber);
			lineNumber =
				isNaN(lineNumber["numero_linea"]) == true
					? this.numero_linea
					: lineNumber["numero_linea"];
			$("#line-" + lineNumber + "-remoteAudio").remove();
			var lineObj = this.FindLineByNumber(lineNumber);
			if (lineObj == null || lineObj.SipSession == null) return;
			delete this.datos_lineas_activas[lineNumber];

			if (lineObj == null) {
				console.warn("Unable to find line (" + lineNumber + ")");
				return;
			}
			var session = lineObj.SipSession;
			if (session == null) {
				console.warn("Reject failed, null session");
				$("#line-" + lineObj.LineNumber + "-msg").html(this.lang.call_failed);
				$("#line-" + lineObj.LineNumber + "-AnswerCall").hide();
			}
			if (session.state == SIP.SessionState.Established) {
				session.bye().catch(function (e) {
					console.warn(
						"Problem in RejectCall(), could not bye() call",
						e,
						session
					);
				});
			} else {
				session
					.reject({
						statusCode: 486,
						reasonPhrase: "Busy Here",
					})
					.catch(function (e) {
						console.warn(
							"Problem in RejectCall(), could not reject() call",
							e,
							session
						);
					});
			}
			$("#line-" + lineObj.LineNumber + "-msg").html(this.lang.call_rejected);

			session.data.terminateby = "us";
			session.data.reasonCode = 486;
			session.data.reasonText = "Busy Here";
			this.teardownSession(lineObj);
			if (Object.keys(this.datos_lineas_activas).length > 1) {
				this.Lines = this.Lines.filter(data => data.LineNumber != lineNumber);
				this.lineObj = this.Lines.slice(-1)[0];

				this.SelectLine(this.lineObj.LineNumber);
			} else if (Object.keys(this.datos_lineas_activas).length == 1) {
				this.Lines = this.Lines.filter(data => data.LineNumber != lineNumber);
				this.lineObj = this.Lines.slice(-1)[0];
				this.lineas_activas_llamadas = false;
				this.closeWebLines();
				this.lienea_uno_activa = true;
				for (let datos in this.datos_lineas_activas) {
					if (
						this.datos_lineas_activas[
							datos
						].lineas_llamadas_activas.toString() !== lineNumber.toString()
					) {
						this.SelectLine(this.lineObj.LineNumber, { unhold: false }); // necesario para poder desmutear la primer llamada
						this.numero_hablando = this.datos_lineas_activas[datos].title;
						/* 						this.unholdSession(
							this.datos_lineas_activas[datos].lineas_llamadas_activas
						); */
						this.numero_linea =
							this.datos_lineas_activas[datos].lineas_llamadas_activas;
						this.nombre_largo =
							"nombre_largo" in this.datos_lineas_activas[datos]
								? this.datos_lineas_activas[datos].nombre_largo
								: "";
						this.nombre_corto =
							"nombre_corto" in this.datos_lineas_activas[datos]
								? this.datos_lineas_activas[datos].nombre_corto
								: "";
					}
				}
			} else {
				$(".crear-audio").remove();
				this.drawer = false;
				this.stopDuration();
				this.telefono_tranferencia = "";
				this.contestar = false;
				this.colgar = false;
				this.activarmute = false;
				this.transferencia = false;
				this.nueva_linea = false;
				this.telefono_nueva_linea = "";
				this.conferencia = false;
				this.poner_en_espera = false;
				this.quitar_en_espera = false;
				this.desactivarmute = false;
				this.finalizar_llamada = false;
				this.cancelar_llamada_saliente = false;
				this.cancelar_nueva_linea = false;
				this.llamadaSaliente = true;
				this.botones_telefono = true;
				this.botones_dtmf = false;
				this.respuesta_automatica = true;
				this.desactivar_conferencia = false;
				this.digitar_conferencia = false;
				this.telefono_conferencia = "";
				this.numero_telefonico = "";
				this.tiempo_llamada = "";
				this.datos_llamada = false;
				this.digitar_telefono = true;
				this.Buddies = [];
				this.nombre_corto = "";
				this.nombre_largo = "";
				this.numero_hablando = "";
				this.lienea_uno_activa = true;
				this.Lines = [];
				this.lineObj = [];
			}
		},
		cancelSession(lineNumber) {
			lineNumber =
				isNaN(lineNumber["numero_linea"]) == true
					? this.numero_linea
					: lineNumber["numero_linea"];
			var lang = this.lang;
			//var lineObj = this.lineObj;
			var lineObj = this.FindLineByNumber(lineNumber);

			console.log("CANCELAR LLAMADA", lineObj);

			// if(lineObj == null || lineObj.SipSession == null) return;
			this.nombre_corto = "";
			this.nombre_largo = "";
			this.numero_hablando = "";
			lineObj.SipSession.data.terminateby = "us";
			lineObj.SipSession.data.reasonCode = 0;
			lineObj.SipSession.data.reasonText = "Call Cancelled";

			console.log(
				"Cancelling session : " + lineNumber,
				`${lineObj.SipSession.state}`
			);
			if (
				lineObj.SipSession.state == SIP.SessionState.Initial ||
				lineObj.SipSession.state == SIP.SessionState.Establishing
			) {
				lineObj.SipSession.cancel();
				delete this.datos_lineas_activas[lineObj.LineNumber];
				console.log(Object.keys(this.datos_lineas_activas).length);
				if (Object.keys(this.datos_lineas_activas).length > 0) {
					this.Lines = this.Lines.filter(data => data.LineNumber != lineNumber);
					this.lineObj = this.Lines.slice(-1)[0];

					this.SelectLine(this.lineObj.LineNumber, { unhold: false });
				} else {
					$(".crear-audio").remove();
					this.drawer = false;
					this.tiempo_llamada = "";
					this.numero_telefonico = "";
					this.numero_hablando = "";
					this.cancelar_llamada_saliente = false;
					this.cancelar_nueva_linea = false;
					this.activarmute = false;
					this.desactivarmute = false;
					this.datos_llamada = false;
					this.digitar_transferencia = false;
					this.digitar_nueva_linea = false;
					// this.realizo_llaada_saliente = 0;
					this.poner_en_espera = false;
					this.quitar_en_espera = false;
					this.finalizar_llamada = false;
					this.transferencia = false;
					this.nueva_linea = false;
					this.cancelar_transferencia = false;
					this.cancelar_nueva_linea = false;
					this.conferencia = false;
					this.llamadaSaliente = true;
					this.botones_telefono = true;
					this.botones_dtmf = false;
					this.respuesta_automatica = true;
					this.linea_conferencia = false;
					this.desactivar_conferencia = false;
					this.digitar_conferencia = false;
					this.telefono_conferencia = "";
					this.quitar_en_espera = false;
					this.digitar_telefono = true;
					this.Lines = [];
					this.lineObj = [];
					this.stopDuration();
				}
				if (Object.keys(this.datos_lineas_activas).length == 1) {
					this.lienea_uno_activa = true;
					this.closeWebLines();
				} else if (Object.keys(this.datos_lineas_activas).length == 0) {
					this.closeWebLines();
					this.Buddies = [];
				}
			} else {
				console.warn(
					"Session not in correct state for cancel.",
					lineObj.SipSession.state
				);
				console.log("Attempting teardown : " + lineNumber);
			}
			this.teardownSession(lineObj);

			$("#line-" + lineNumber + "-msg").html(lang.call_cancelled);
		},
		StartLocalAudioMediaMonitoring(lineNum, session) {
			var Lines = this.Lines;
			// lineNum = this.numero_linea; // comente porque con multilinea esto no se cumple
			const lineSelected =
				this.Lines.length > 1
					? this.Lines?.find(l => l.IsSelected)
					: this.Lines[0];
			if (lineSelected?.LineNumber.toString() === lineNum.toString()) {
				console.log("Activa botones de llamada:", lineNum.toString());
				this.cancelar_llamada_saliente = false;
				this.cancelar_nueva_linea = false;
				this.activarmute = true;
				this.poner_en_espera = true;
				this.transferencia = true;
				this.nueva_linea = true;
				this.conferencia = true;
				this.finalizar_llamada = true;
				this.botones_dtmf = true;
				this.startDuration();
			}
			//console.log(this.numero_linea);
			console.log("Creating LocalAudio AudioContext on line " + lineNum);
			// Create local SoundMeter
			var global = this;
			var soundMeter = new SoundMeter(session.id, lineNum, Lines);
			if (soundMeter == null) {
				console.warn("AudioContext() LocalAudio not available... its fine.");
				return null;
			}
			// Ready the getStats request
			var localAudioStream = new MediaStream();
			var audioSender = null;
			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getSenders().forEach(function (RTCRtpSender) {
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
					if (audioSender == null) {
						console.log("Adding Track to Monitor: ", RTCRtpSender.track.label);
						localAudioStream.addTrack(RTCRtpSender.track);
						audioSender = RTCRtpSender;
					} else {
						console.log("Found another Track, but audioSender not null");
						console.log(RTCRtpSender);
						console.log(RTCRtpSender.track);
					}
				}
			});

			// Setup Charts
			var maxDataLength = 100;
			soundMeter.startTime = Date.now();
			Chart.defaults.global.defaultFontSize = 12;
			var ChatHistoryOptions = {
				responsive: true,
				maintainAspectRatio: false,
				// devicePixelRatio: 1,
				animation: false,
				scales: {
					yAxes: [
						{
							ticks: { beginAtZero: true },
						},
					],
					xAxes: [
						{
							display: false,
						},
					],
				},
			};

			// Connect to Source
			var global = this;
			soundMeter.connectToSource(localAudioStream, function (e) {
				if (e != null) return;
				console.log(
					"SoundMeter for LocalAudio Connected, displaying levels for Line: " +
						lineNum
				);
				soundMeter.levelsInterval = window.setInterval(function () {
					// Calculate Levels (0 - 255)
					var instPercent = (soundMeter.instant / 255) * 100;
					$("#line-" + lineNum + "-Mic").css(
						"height",
						instPercent.toFixed(2) + "%"
					);
				}, 50);
			});
			return soundMeter;
		},
		StartRemoteAudioMediaMonitoring(lineNum, session) {
			//lineNum = this.numero_linea; // con multilinea auto answer mostraba 3
			var Lines = this.Lines;
			console.log("Creating RemoteAudio AudioContext on Line:" + lineNum);
			// Create local SoundMeter
			var soundMeter = new SoundMeter(session.id, lineNum, Lines);
			if (soundMeter == null) {
				console.warn("AudioContext() RemoteAudio not available... it fine.");
				return null;
			}
			// Ready the getStats request
			var remoteAudioStream = new MediaStream();
			var audioReceiver = null;
			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getReceivers().forEach(function (RTCRtpReceiver) {
				if (RTCRtpReceiver.track && RTCRtpReceiver.track.kind == "audio") {
					if (audioReceiver == null) {
						remoteAudioStream.addTrack(RTCRtpReceiver.track);
						audioReceiver = RTCRtpReceiver;
					} else {
						console.log("Found another Track, but audioReceiver not null");
						console.log(RTCRtpReceiver);
						console.log(RTCRtpReceiver.track);
					}
				}
			});

			// Setup Charts
			var maxDataLength = 100;
			soundMeter.startTime = Date.now();
			Chart.defaults.global.defaultFontSize = 12;

			var ChatHistoryOptions = {
				responsive: true,
				maintainAspectRatio: false,
				// devicePixelRatio: 1,
				animation: false,
				scales: {
					yAxes: [
						{
							ticks: { beginAtZero: true }, //, min: 0, max: 100
						},
					],
					xAxes: [
						{
							display: false,
						},
					],
				},
			};

			// Connect to Source
			var global = this;
			soundMeter.connectToSource(remoteAudioStream, function (e) {
				if (e != null) return;
				// Create remote SoundMeter
				console.log(
					"SoundMeter for RemoteAudio Connected, displaying levels for Line: " +
						lineNum
				);
			});
			return soundMeter;
		},
		onInviteAccepted(lineObj, includeVideo, response) {
			console.log("onInviteAccepted");
			// Call in progress
			var global = this;
			var session = lineObj.SipSession;
			if (session.data.earlyMedia) {
				session.data.earlyMedia.pause();
				session.data.earlyMedia.removeAttribute("src");
				session.data.earlyMedia.load();
				session.data.earlyMedia = null;
			}
			session.stateChange.once(newState => {
				if (
					!session.data.teardownComplete &&
					!session.data.terminateby &&
					newState === SIP.SessionState.Terminated
				) {
					coreApi.post("/api/log", {
						message: "No se ha enlazado la llamada",
					});
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: "La llamada no se ha podido enlazar",
						type: "warning",
					});
					const totalLines = Object.keys(this.datos_lineas_activas).length;
					this.teardownSession(lineObj);
					// delete global.datos_lineas_activas[lineObj.LineNumber];
					if (totalLines > 1) {
						if (totalLines === 2) this.closeWebLines();
						this.Lines = this.Lines.filter(
							data => data.LineNumber != lineObj.LineNumber
						);
						this.lineObj = this.Lines.slice(-1)[0];
						this.SelectLine(this.lineObj.LineNumber, { unhold: false });
					} else {
						// Equal 1 this call failed
						this.lienea_uno_activa = true;
						this.numero_telefonico = "";
						this.tiempo_llamada = "";
						this.nombre_largo = "";
						this.numero_hablando = "";
						this.nombre_corto = "";
						this.llamadaSaliente = true;
						this.botones_telefono = true;
						this.botones_dtmf = false;
						this.respuesta_automatica = true;
						this.linea_conferencia = false;
						this.desactivar_conferencia = false;
						this.digitar_conferencia = false;
						this.telefono_conferencia = "";
						this.transferencia = false;
						this.nueva_linea = false;
						this.conferencia = false;
						this.cancelar_transferencia = false;
						this.cancelar_nueva_linea = false;
						this.contestar = false;
						this.activarmute = false;
						this.poner_en_espera = false;
						this.quitar_en_espera = false;
						this.desactivarmute = false;
						this.finalizar_llamada = false;
						this.colgar = false;
						this.cancelar_llamada_saliente = false;
						this.datos_llamada = false;
						this.digitar_transferencia = false;
						this.digitar_nueva_linea = false;
						this.digitar_telefono = true;
						this.stopDuration();
						this.Buddies = [];
						this.closeWebLines();
						this.Lines = [];
						this.lineObj = [];
					}
				}
			});

			window.clearInterval(session.data.callTimer);
			$("#line-" + lineObj.LineNumber + "-timer").show();
			var startTime = moment.utc();
			session.data.startTime = startTime;
			console.log("Llamada linea", lineObj.LineNumber);

			session.data.callTimer = window.setInterval(function () {
				var now = moment.utc();
				var duration = moment.duration(now.diff(startTime));
				var timeStr =
					(session._dialog &&
					(session._dialog?.ackWait === true ||
						session._dialog?._transport?._state === "Connected")
						? "Conectando "
						: "") + global.formatShortDuration(duration.asSeconds());
				$("#line-" + lineObj.LineNumber + "-timer").html(timeStr);
				$("#line-" + lineObj.LineNumber + "-datetime").html(timeStr);
				global.tiempo_llamada = timeStr;
				if (global.datos_lineas_activas[lineObj.LineNumber])
					global.datos_lineas_activas[lineObj.LineNumber].llamada_tiempo =
						timeStr;
			}, 1000);
			session.isOnHold = false;
			session.data.started = true;

			if (includeVideo) {
				// Preview our stream from peer conneciton
				var localVideoStream = new MediaStream();
				var pc = session.sessionDescriptionHandler.peerConnection;
				pc.getSenders().forEach(function (sender) {
					if (sender.track && sender.track.kind == "video") {
						localVideoStream.addTrack(sender.track);
					}
				});
				var localVideo = $("#line-" + lineObj.LineNumber + "-localVideo").get(
					0
				);
				localVideo.srcObject = localVideoStream;
				localVideo.onloadedmetadata = function (e) {
					localVideo.play();
				};

				// Apply Call Bandwidth Limits
				if (MaxVideoBandwidth > -1) {
					pc.getSenders().forEach(function (sender) {
						if (sender.track && sender.track.kind == "video") {
							var parameters = sender.getParameters();
							if (!parameters.encodings) parameters.encodings = [{}];
							parameters.encodings[0].maxBitrate = MaxVideoBandwidth * 1000;

							console.log(
								"Applying limit for Bandwidth to: ",
								MaxVideoBandwidth + "kb per second"
							);

							// Only going to try without re-negotiations
							sender.setParameters(parameters).catch(function (e) {
								console.warn("Cannot apply Bandwidth Limits", e);
							});
						}
					});
				}
			}
			let RecordAllCalls = 0;
			let CallRecordingPolicy = "allow";
			// Start Call Recording
			if (RecordAllCalls || CallRecordingPolicy == "enabled") {
				StartRecording(lineObj.LineNumber);
			}
			// Put on Hold other calls
			let disable = false; // Detecta si hay mas llamadas activas para activar o desactivar transferencia
			if (Object.keys(global.datos_lineas_activas).length > 1) {
				$.each(global.userAgent.sessions, function (i, session) {
					// All the other calls, not on hold
					if (session.state == SIP.SessionState.Established) {
						if (
							session.isOnHold == false &&
							session.data.line != lineObj.LineNumber
						) {
							global.holdSession(session.data.line);
						}
					} else if (session.state == SIP.SessionState.Initial) disable = true;
					session.data.IsCurrentCall = false;
				});
			}
			global.transfer_disable = disable;
			global.conf_disable = disable;
			if (includeVideo) {
				// Layout for Video Call
				$("#line-" + lineObj.LineNumber + "-progress").hide();
				$("#line-" + lineObj.LineNumber + "-VideoCall").show();
				$("#line-" + lineObj.LineNumber + "-ActiveCall").show();

				$("#line-" + lineObj.LineNumber + "-btn-Conference").hide(); // Cannot conference a Video Call (Yet...)
				$("#line-" + lineObj.LineNumber + "-btn-CancelConference").hide();
				$("#line-" + lineObj.LineNumber + "-Conference").hide();

				$("#line-" + lineObj.LineNumber + "-btn-Transfer").hide(); // Cannot transfer a Video Call (Yet...)
				$("#line-" + lineObj.LineNumber + "-btn-CancelTransfer").hide();
				$("#line-" + lineObj.LineNumber + "-Transfer").hide();

				// Default to use Camera
				$("#line-" + lineObj.LineNumber + "-src-camera").prop("disabled", true);
				$("#line-" + lineObj.LineNumber + "-src-canvas").prop(
					"disabled",
					false
				);
				$("#line-" + lineObj.LineNumber + "-src-desktop").prop(
					"disabled",
					false
				);
				$("#line-" + lineObj.LineNumber + "-src-video").prop("disabled", false);
			} else {
				// Layout for Audio Call
				$("#line-" + lineObj.LineNumber + "-AudioCall").show();
				/* 				$("#line-" + lineObj.LineNumber + "-progress").hide();
				$("#line-" + lineObj.LineNumber + "-VideoCall").hide();
				// Call Control
				$("#line-" + lineObj.LineNumber + "-btn-Mute").show();
				$("#line-" + lineObj.LineNumber + "-btn-Unmute").hide();
				$("#line-" + lineObj.LineNumber + "-btn-start-recording").show();
				$("#line-" + lineObj.LineNumber + "-btn-stop-recording").hide();
				$("#line-" + lineObj.LineNumber + "-btn-Hold").show();
				$("#line-" + lineObj.LineNumber + "-btn-Unhold").hide();
				$("#line-" + lineObj.LineNumber + "-btn-Transfer").show();
				$("#line-" + lineObj.LineNumber + "-btn-CancelTransfer").hide();
				$("#line-" + lineObj.LineNumber + "-btn-Conference").show();
				$("#line-" + lineObj.LineNumber + "-btn-CancelConference").hide();
				$("#line-" + lineObj.LineNumber + "-btn-ShowDtmf").show();
				$("#line-" + lineObj.LineNumber + "-btn-settings").show();
				$("#line-" + lineObj.LineNumber + "-btn-ShowCallStats").show();
				$("#line-" + lineObj.LineNumber + "-btn-HideCallStats").hide();
				$("#line-" + lineObj.LineNumber + "-btn-ShowTimeline").show();
				$("#line-" + lineObj.LineNumber + "-btn-HideTimeline").hide();
				$("#line-" + lineObj.LineNumber + "-btn-present-src").hide();
				$("#line-" + lineObj.LineNumber + "-btn-expand").hide();
				$("#line-" + lineObj.LineNumber + "-btn-restore").hide();
				$("#line-" + lineObj.LineNumber + "-btn-End").show();
				// Show the Call
				$("#line-" + lineObj.LineNumber + "-ActiveCall").show(); */
			}

			//UpdateBuddyList()
			// updateLineScroll(lineObj.LineNumber);

			// Start Audio Monitoring
			lineObj.LocalSoundMeter = this.StartLocalAudioMediaMonitoring(
				lineObj.LineNumber,
				session
			);
			lineObj.RemoteSoundMeter = this.StartRemoteAudioMediaMonitoring(
				lineObj.LineNumber,
				session
			);
			$("#line-" + lineObj.LineNumber + "-msg").html(
				this.lang.call_in_progress
			);

			if (includeVideo && StartVideoFullScreen)
				ExpandVideoArea(lineObj.LineNumber);
			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("accepted", session);
		},
		MakeDataArray(defaultValue, count) {
			var rtnArray = new Array(count);
			for (var i = 0; i < rtnArray.length; i++) {
				rtnArray[i] = defaultValue;
			}
			return rtnArray;
		},
		async FilterDialByLine() {
			console.log("filterDialByLine");
			if (this.llamadaSaliente) this.DialByLine("audio");
		},
		async DialByLine(type, numToDial, buddy, CallerID, extraHeaders) {
			if (this.searchInObjectNumber(numToDial)) return;
			if (numToDial) this.numero_telefonico = numToDial;
			if (!this.numero_telefonico) return;
			this.numero_telefonico =
				!!this.numero_telefonico == true ? this.numero_telefonico : false;
			//Validar se intenta llamar a si mismo.
			let re = new RegExp("^[2|6|7][0-9]{7}|[0-9]{3,4}|(/*[0-9]{2,3})$");
			let message = !re.test(this.numero_telefonico)
				? "Numero de teléfono inválido"
				: "";
			message =
				AuthModule.extension == this.numero_telefonico
					? "No se puede llamar a si mismo"
					: "";
			console.log("MENSAJE1", message);

			if (message) {
				this.numero_telefonico = "";
				console.log("MENSAJE", message);
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: message + "",
					type: "error",
				});
				return;
			}
			this.model.show = false;
			type = "audio";
			this.llamada_entrante = 0;
			this.botones_telefono = false;
			this.poner_en_espera = false;
			this.botones_dtmf = true;
			this.numero_hablando =
				!!this.numero_telefonico == false ? "" : this.numero_telefonico;
			//BUSCAR NOMBRES E INSERTAR AQUÍ.
			const names = await this.getNames(this.numero_hablando);
			if (Object.keys(names).length) {
				this.nombre_largo = names.name + " " + names.lastname;
			} else {
				this.nombre_largo = "Desconocido";
			}

			this.datos_llamada = true;
			this.digitar_telefono = false;
			this.cancelar_llamada_saliente = true;
			this.realizo_llamada_saliente = 1;
			let Lines = this.Lines;
			let DidLength = 6;
			this.contestar = false;
			var numDial = numToDial ? numToDial : this.numero_telefonico;
			// Create a Buddy if one is not already existing
			var buddyObj = buddy
				? FindBuddyByIdentity(buddy)
				: this.FindBuddyByDid(numDial);
			if (buddyObj == null) {
				var buddyType = numDial.length > DidLength ? "contact" : "extension";
				// Assumption but anyway: If the number starts with a * or # then its probably not a subscribable did,
				// and is probably a feature code.
				if (
					buddyType.substring(0, 1) == "*" ||
					buddyType.substring(0, 1) == "#"
				)
					buddyType = "contact";
				buddyObj = this.MakeBuddy(
					buddyType,
					true,
					false,
					false,
					CallerID ? CallerID : numDial,
					numDial
				);
			}

			// Create a Line
			this.newLineNumber = this.newLineNumber + 1;
			var lineObj = {
				LineNumber: this.newLineNumber,
				DisplayName: buddyObj.CallerIDName,
				DisplayNumber: numDial,
				BuddyObj: buddyObj,
			};
			Lines.push(lineObj);
			this.AddLineHtml(lineObj);
			this.lineObj = lineObj;
			this.datos_lineas_activas = {
				[this.newLineNumber]: {
					action: "fa fa-phone-alt",
					items: [{ title: this.newLineNumber, la_linea: this.newLineNumber }],
					title: `${this.numero_hablando}`,
					llamada_tiempo: "",
					lineas_llamadas_activas: this.newLineNumber,
					estadado_llamada: true,
					nombre_largo: this.nombre_largo,
					nombre_corto: this.nombre_corto,
				},
			};
			this.SelectLine(this.newLineNumber);
			this.UpdateBuddyList();
			this.numero_linea = this.newLineNumber;
			//this.almacenarllamada(this.numero_hablando, "SALIENTE");
			// CallDataModule.saveCall({
			// 	telefono: this.numero_hablando,
			// 	tipo_llamada: "SALIENTE",
			// });
			var html = "";
			html += "<div class='crear-audio'>";
			html +=
				'<audio id="line-' + this.newLineNumber + '-remoteAudio"></audio>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioSendBitRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioSendPacketRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceiveBitRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceivePacketRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceivePacketLoss" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceiveJitter" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceiveLevels" class=audioGraph></canvas></div>';
			html += "</div>";
			$("#formagentemain").append(html);
			// Start Call Invite
			if (type == "audio") {
				this.AudioCall(lineObj, numDial, extraHeaders);
			}
		},

		FindBuddyByDid(did) {
			// Used only in Inboud
			let Buddies = this.Buddies;
			for (var b = 0; b < Buddies.length; b++) {
				if (
					Buddies[b].ExtNo == did ||
					Buddies[b].MobileNumber == did ||
					Buddies[b].ContactNumber1 == did ||
					Buddies[b].ContactNumber2 == did
				) {
					return Buddies[b];
				}
			}
			return null;
		},
		AddLineHtml(lineObj) {
			console.log("Agregando botones de nueva llamada Method2: ", lineObj);
			// Call Answer UI
			this.numero_linea = lineObj.LineNumber;
			this.drawer = true;
			this.telefono_tranferencia = "";
			console.log(
				"Ha realizado llamada saliente: ",
				this.realizo_llamada_saliente
			);
			this.realizo_llamada_saliente == 1
				? (this.contestar = false)
				: (this.contestar = true);
			this.activarmute = false;
			this.poner_en_espera = false;
			this.quitar_en_espera = false;
			this.transferencia = false;
			this.nueva_linea = false;
			this.conferencia = false;
			this.desactivarmute = false;
			this.finalizar_llamada = false;
			this.colgar = false;
			this.llamadaSaliente = false;
			this.respuesta_automatica = false;
			this.numero_telefonico = lineObj.DisplayNumber;
		},
		endSession(lineNum) {
			console.log("Doing end Session: ", lineNum);
			var lang = this.lang;
			var lineNum =
				!!lineNum["numero_linea"] == true ? lineNum["numero_linea"] : lineNum;

			$("#line-" + lineNum + "-remoteAudio").remove();

			//this.drawer = false
			//OBTENER EL LINE OBJ ACTUAL
			var lineObj =
				Object.keys(this.datos_lineas_activas).length > 1
					? this.FindLineByNumber(lineNum)
					: this.lineObj;

			//ACTUALIZAR EL LINE OBJ Y EL THIS.LINES
			if (Object.keys(this.datos_lineas_activas).length > 0) {
				this.Lines = this.Lines.filter(data => data.LineNumber != lineNum);
				this.lineObj = this.Lines.slice(-1)[0];
			} else {
				this.Lines = [];
				this.lineObj = [];
			}

			delete this.datos_lineas_activas[lineNum];
			this.transfiriendo_multi_linea = false;
			this.linea_a_transferir = -1;
			this.conferenciando_multi_linea = false;
			this.en_conferencia = false;
			this.linea_a_conferenciar = -1;
			this.cancelar_transferencia = false;
			this.tranferencia_cancelada = false;
			this.transferencia = true;
			this.conferencia = true;
			this.desactivar_conferencia = false;
			this.nueva_linea = true;
			this.cancelar_nueva_linea = false;
			//Este if else anidado se puede sustituir por un swtich , pero según
			//estudio realizado 2021-MAY-05, else if es 3 veces mas rapido que un switch range
			if (Object.keys(this.datos_lineas_activas).length == 1) {
				this.lineas_activas_llamadas = false;
				this.closeWebLines();
				this.lienea_uno_activa = true;
				this.datos_llamada = true;
				const firstKey = Object.keys(this.datos_lineas_activas)[0];

				for (let datos in this.datos_lineas_activas) {
					this.lineObj = this.FindLineByNumber(firstKey);
					this.SelectLine(firstKey, { unhold: false });
					this.numero_hablando = this.datos_lineas_activas[datos].title;
					// No quitar pausa automaticamente
					/* 					this.unholdSession(
						this.datos_lineas_activas[datos].lineas_llamadas_activas
					); */
					this.numero_linea =
						this.datos_lineas_activas[datos].lineas_llamadas_activas;
					this.nombre_largo =
						"nombre_largo" in this.datos_lineas_activas[datos]
							? this.datos_lineas_activas[datos].nombre_largo
							: "";
					this.nombre_corto =
						"nombre_corto" in this.datos_lineas_activas[datos]
							? this.datos_lineas_activas[datos].nombre_corto
							: "";
				}
			} else if (Object.keys(this.datos_lineas_activas).length == 0) {
				$(".crear-audio").remove();
				this.stopDuration();
				this.lineas_activas_llamadas = false;
				this.telefono_tranferencia = "";
				this.contestar = false;
				this.activarmute = false;
				this.desactivarmute = false;
				this.transferencia = false;
				this.nueva_linea = false;
				this.cancelar_transferencia = false;
				this.cancelar_nueva_linea = false;
				this.conferencia = false;
				this.poner_en_espera = false;
				this.quitar_en_espera = false;
				this.finalizar_llamada = false;
				this.cancelar_llamada_saliente = false;
				this.cancelar_nueva_linea = false;
				this.colgar = false;
				this.digitar_transferencia = false;
				this.digitar_nueva_linea = false;
				this.llamadaSaliente = true;
				this.botones_telefono = true;
				this.botones_dtmf = false;
				this.respuesta_automatica = true;
				this.desactivar_conferencia = false;
				this.digitar_conferencia = false;
				this.telefono_conferencia = "";
				this.numero_telefonico = "";
				this.numero_hablando = "";
				this.nombre_corto = "";
				this.nombre_largo = "";
				this.tiempo_llamada = "";
				this.datos_llamada = false;
				this.digitar_telefono = true;
				this.tranferencia_atendida = false;
				this.linea_conferencia = false;
				this.Buddies = [];
				this.closeWebLines();
				this.lienea_uno_activa = true;
			} else if (Object.keys(this.datos_lineas_activas).length > 1) {
				this.SelectLine(this.lineObj.LineNumber, { unhold: false });
				this.datos_llamada = true;
				this.numero_hablando =
					this.datos_lineas_activas[this.lineObj.LineNumber].title;
				this.nombre_largo =
					"nombre_largo" in this.datos_lineas_activas[this.lineObj.LineNumber]
						? this.datos_lineas_activas[this.lineObj.LineNumber].nombre_largo
						: "";
				this.nombre_corto =
					"nombre_corto" in this.datos_lineas_activas[this.lineObj.LineNumber]
						? this.datos_lineas_activas[this.lineObj.LineNumber].nombre_corto
						: "";
				// this.unholdSession(this.lineObj.LineNumber); // quitaba espera a llamada que regresa
				this.numero_linea =
					this.datos_lineas_activas[
						this.lineObj.LineNumber
					].lineas_llamadas_activas;
			} else {
				this.datos_llamada = true;
			}
			if (lineObj == null) return;
			else if (lineObj.SipSession == null) lineObj = lineObj[0];

			if (lineObj.SipSession == null) return;

			console.log("Ending call with: " + lineNum);

			lineObj.SipSession.data.terminateby = "us";
			lineObj.SipSession.data.reasonCode = 16;
			lineObj.SipSession.data.reasonText = "Normal Call clearing";

			lineObj.SipSession.bye().catch(function (e) {
				console.warn("Failed to bye the session!", e);
			});

			$("#line-" + lineNum + "-msg").html(lang.call_ended);
			$("#line-" + lineNum + "-ActiveCall").hide();

			this.teardownSession(lineObj);

			this.updateLineScroll(lineNum);
			if (Object.keys(this.datos_lineas_activas).length === 0) {
				this.Lines = [];
				this.lineObj = [];
			}
			// this.lineObj = [];
			// if (this.realizo_llamada_saliente == 1) {
			// 	this.Lines = [];
			// }
		},
		MakeBuddy(
			type,
			update,
			focus,
			subscribe,
			callerID,
			did,
			jid,
			AllowDuringDnd
		) {
			var json = JSON.parse(
				this.localDB.getItem(this.profileUserID + "-Buddies")
			);
			if (json == null) json = this.InitUserBuddies();
			var dateNow = this.utcDateNow();
			var buddyObj = null;
			var id = this.uID();

			if (type == "extension") {
				json.DataCollection.push({
					Type: "extension",
					LastActivity: dateNow,
					ExtensionNumber: did,
					MobileNumber: "",
					ContactNumber1: "",
					ContactNumber2: "",
					uID: id,
					cID: null,
					gID: null,
					jid: null,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: AllowDuringDnd,
					Subscribe: subscribe,
				});
				buddyObj = [
					"extension",
					id,
					callerID,
					did,
					"",
					"",
					"",
					dateNow,
					"",
					"",
					null,
					AllowDuringDnd,
					subscribe,
				];
				this.AddBuddy(buddyObj, update, focus, subscribe);
			}
			if (type == "xmpp") {
				json.DataCollection.push({
					Type: "xmpp",
					LastActivity: dateNow,
					ExtensionNumber: did,
					MobileNumber: "",
					ContactNumber1: "",
					ContactNumber2: "",
					uID: id,
					cID: null,
					gID: null,
					jid: jid,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: AllowDuringDnd,
					Subscribe: subscribe,
				});
				buddyObj = new Buddy(
					"xmpp",
					id,
					callerID,
					did,
					"",
					"",
					"",
					dateNow,
					"",
					"",
					jid,
					AllowDuringDnd,
					subscribe
				);
				AddBuddy(buddyObj, update, focus, subscribe);
			}
			if (type == "contact") {
				json.DataCollection.push({
					Type: "contact",
					LastActivity: dateNow,
					ExtensionNumber: "",
					MobileNumber: "",
					ContactNumber1: did,
					ContactNumber2: "",
					uID: null,
					cID: id,
					gID: null,
					jid: null,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: AllowDuringDnd,
					Subscribe: false,
				});
				buddyObj = [
					"contact",
					id,
					callerID,
					"",
					"",
					did,
					"",
					dateNow,
					"",
					"",
					null,
					AllowDuringDnd,
					false,
				];
				this.AddBuddy(buddyObj, update, focus, false);
			}
			if (type == "group") {
				json.DataCollection.push({
					Type: "group",
					LastActivity: dateNow,
					ExtensionNumber: did,
					MobileNumber: "",
					ContactNumber1: "",
					ContactNumber2: "",
					uID: null,
					cID: null,
					gID: id,
					jid: null,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: false,
					Subscribe: false,
				});
				buddyObj = new Buddy(
					"group",
					id,
					callerID,
					did,
					"",
					"",
					"",
					dateNow,
					"",
					"",
					null,
					false,
					false
				);
				AddBuddy(buddyObj, update, focus, false);
			}
			// Update Size:
			json.TotalRows = json.DataCollection.length;

			// Save To DB
			this.localDB.setItem(
				this.profileUserID + "-Buddies",
				JSON.stringify(json)
			);

			// Return new buddy
			return buddyObj;
		},
		InitUserBuddies() {
			var template = { TotalRows: 0, DataCollection: [] };
			this.localDB.setItem(
				this.profileUserID + "-Buddies",
				JSON.stringify(template)
			);
			return JSON.parse(this.localDB.getItem(this.profileUserID + "-Buddies"));
		},
		AddBuddy(buddyObj, update, focus, subscribe) {
			this.Buddies.push(buddyObj);
			if (update == true) this.UpdateBuddyList();
			this.AddBuddyMessageStream(buddyObj);
			if (subscribe == true) SubscribeBuddy(buddyObj);
			if (focus == true) SelectBuddy(buddyObj.identity);
		},
		UpdateBuddyList() {
			let lang = this.lang;
			let DisableBuddies = true;
			var Lines = this.Lines;
			var Buddies = this.Buddies;
			var filter = $("#txtFindBuddy").val();

			$("#myContacts").empty();

			// Show Lines
			var callCount = 0;
			for (var l = 0; l < Lines.length; l++) {
				var classStr = Lines[l].IsSelected ? "buddySelected" : "buddy";
				if (Lines[l].SipSession != null)
					classStr = Lines[l].SipSession.isOnHold
						? "buddyActiveCallHollding"
						: "buddyActiveCall";

				var html =
					'<div id="line-' +
					Lines[l].LineNumber +
					'" class=' +
					classStr +
					" onclick=\"SelectLine('" +
					Lines[l].LineNumber +
					"')\">";
				if (
					Lines[l].IsSelected == false &&
					Lines[l].SipSession &&
					Lines[l].SipSession.data.started != true &&
					Lines[l].SipSession.data.calldirection == "inbound"
				) {
					html +=
						'<span id="line-' +
						Lines[l].LineNumber +
						'-ringing" class=missedNotifyer style="padding-left: 5px; padding-right: 5px; width:unset"><i class="fa fa-phone"></i> ' +
						lang.state_ringing +
						"</span>";
				}
				html += "<div class=lineIcon>" + (l + 1) + "</div>";
				html +=
					'<div class=contactNameText><i class="fa fa-phone"></i> ' +
					lang.line +
					" " +
					(l + 1) +
					"</div>";
				html +=
					'<div id="line-' +
					Lines[l].LineNumber +
					'-datetime" class=contactDate>&nbsp;</div>';
				html +=
					"<div class=presenceText>" +
					Lines[l].DisplayName +
					" <" +
					Lines[l].DisplayNumber +
					">" +
					"</div>";
				html += "</div>";
				// SIP.Session.C.STATUS_TERMINATED
				if (
					Lines[l].SipSession &&
					Lines[l].SipSession.data.earlyReject != true
				) {
					callCount++;
				}
			}

			// End here if they are not using the buddy system
			if (DisableBuddies == true) {
				// If there are no calls, this could look fi=unny
				if (callCount == 0) {
					this.ShowDial();
				}
				return;
			}

			// Draw a line if there are calls
			if (callCount > 0) {
				$("#myContacts").append("<hr class=hrline>");
			}

			// Sort and shuffle Buddy List
			// ===========================
			Buddies.sort(function (a, b) {
				var aMo = moment.utc(a.lastActivity.replace(" UTC", ""));
				var bMo = moment.utc(b.lastActivity.replace(" UTC", ""));
				if (aMo.isSameOrAfter(bMo, "second")) {
					return -1;
				} else return 1;
			});

			for (var b = 0; b < Buddies.length; b++) {
				var buddyObj = Buddies[b];

				if (filter && filter.length >= 1) {
					// Perform Filter Display
					var display = false;
					if (
						buddyObj.CallerIDName.toLowerCase().indexOf(filter.toLowerCase()) >
						-1
					)
						display = true;
					if (buddyObj.ExtNo.toLowerCase().indexOf(filter.toLowerCase()) > -1)
						display = true;
					if (buddyObj.Desc.toLowerCase().indexOf(filter.toLowerCase()) > -1)
						display = true;
					if (!display) continue;
				}

				var today = moment.utc();
				var lastActivity = moment.utc(
					buddyObj.lastActivity.replace(" UTC", "")
				);
				var displayDateTime = "";
				if (lastActivity.isSame(today, "day")) {
					displayDateTime = lastActivity.local().format(DisplayTimeFormat);
				} else {
					displayDateTime = lastActivity.local().format(DisplayDateFormat);
				}

				var classStr = buddyObj.IsSelected ? "buddySelected" : "buddy";
				if (buddyObj.type == "extension") {
					var friendlyState = buddyObj.presence;
					if (friendlyState == "Unknown") friendlyState = lang.state_unknown;
					if (friendlyState == "Not online")
						friendlyState = lang.state_not_online;
					if (friendlyState == "Ready") friendlyState = lang.state_ready;
					if (friendlyState == "On the phone")
						friendlyState = lang.state_on_the_phone;
					if (friendlyState == "Ringing") friendlyState = lang.state_ringing;
					if (friendlyState == "On hold") friendlyState = lang.state_on_hold;
					if (friendlyState == "Unavailable")
						friendlyState = lang.state_unavailable;
					if (buddyObj.EnableSubscribe != true) friendlyState = buddyObj.Desc;
					var html =
						'<div id="contact-' +
						buddyObj.identity +
						'" class=' +
						classStr +
						" onclick=\"SelectBuddy('" +
						buddyObj.identity +
						"', 'extension')\">";
					if (buddyObj.missed && buddyObj.missed > 0) {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer>' +
							buddyObj.missed +
							"</span>";
					} else {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer style="display:none">' +
							buddyObj.missed +
							"</span>";
					}
					html +=
						"<div class=buddyIcon style=\"background-image: url('" +
						getPicture(buddyObj.identity, buddyObj.type) +
						"')\"></div>";
					html += "<div class=contactNameText>";
					html +=
						'<span id="contact-' +
						buddyObj.identity +
						'-devstate" class="' +
						buddyObj.devState +
						'"></span>';
					html += " " + buddyObj.ExtNo + " - " + buddyObj.CallerIDName;
					html += "</div>";
					html +=
						'<div id="contact-' +
						buddyObj.identity +
						'-datetime" class=contactDate>' +
						displayDateTime +
						"</div>";
					html +=
						'<div id="contact-' +
						buddyObj.identity +
						'-presence" class=presenceText>' +
						friendlyState +
						"</div>";
					html += "</div>";
					$("#myContacts").append(html);
				} else if (buddyObj.type == "xmpp") {
					var friendlyState = buddyObj.presenceText;
					var html =
						'<div id="contact-' +
						buddyObj.identity +
						'" class=' +
						classStr +
						" onclick=\"SelectBuddy('" +
						buddyObj.identity +
						"', 'extension')\">";
					if (buddyObj.missed && buddyObj.missed > 0) {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer>' +
							buddyObj.missed +
							"</span>";
					} else {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer style="display:none">' +
							buddyObj.missed +
							"</span>";
					}
					html +=
						"<div class=buddyIcon style=\"background-image: url('" +
						getPicture(buddyObj.identity, buddyObj.type) +
						"')\"></div>";
					html += "<div class=contactNameText>";
					html +=
						'<span id="contact-' +
						buddyObj.identity +
						'-devstate" class="' +
						buddyObj.devState +
						'"></span>';
					html += " " + buddyObj.ExtNo + " - " + buddyObj.CallerIDName;
					html += "</div>";
					html +=
						'<div id="contact-' +
						buddyObj.identity +
						'-datetime" class=contactDate>' +
						displayDateTime +
						"</div>";
					html +=
						'<div id="contact-' +
						buddyObj.identity +
						'-presence" class=presenceText><i class="fa fa-comments"></i> ' +
						friendlyState +
						"</div>";
					html +=
						'<div id="contact-' +
						buddyObj.identity +
						'-chatstate-menu" class=presenceText style="display:none"><i class="fa fa-keyboard-o"></i> ' +
						buddyObj.CallerIDName +
						" " +
						lang.is_typing +
						"...</div>";
					html += "</div>";
					$("#myContacts").append(html);
				} else if (buddyObj.type == "contact") {
					var html =
						'<div id="contact-' +
						buddyObj.identity +
						'" class=' +
						classStr +
						" onclick=\"SelectBuddy('" +
						buddyObj.identity +
						"', 'contact')\">";
					if (buddyObj.missed && buddyObj.missed > 0) {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer>' +
							buddyObj.missed +
							"</span>";
					} else {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer style="display:none">' +
							buddyObj.missed +
							"</span>";
					}
					html +=
						"<div class=buddyIcon style=\"background-image: url('" +
						getPicture(buddyObj.identity, buddyObj.type) +
						"')\"></div>";
					html +=
						'<div class=contactNameText><i class="fa fa-address-card"></i> ' +
						buddyObj.CallerIDName +
						"</div>";
					html +=
						'<div id="contact-' +
						buddyObj.identity +
						'-datetime" class=contactDate>' +
						displayDateTime +
						"</div>";
					html += "<div class=presenceText>" + buddyObj.Desc + "</div>";
					html += "</div>";
					$("#myContacts").append(html);
				} else if (buddyObj.type == "group") {
					var html =
						'<div id="contact-' +
						buddyObj.identity +
						'" class=' +
						classStr +
						" onclick=\"SelectBuddy('" +
						buddyObj.identity +
						"', 'group')\">";
					if (buddyObj.missed && buddyObj.missed > 0) {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer>' +
							buddyObj.missed +
							"</span>";
					} else {
						html +=
							'<span id="contact-' +
							buddyObj.identity +
							'-missed" class=missedNotifyer style="display:none">' +
							buddyObj.missed +
							"</span>";
					}
					html +=
						"<div class=buddyIcon style=\"background-image: url('" +
						getPicture(buddyObj.identity, buddyObj.type) +
						"')\"></div>";
					html +=
						'<div class=contactNameText><i class="fa fa-users"></i> ' +
						buddyObj.CallerIDName +
						"</div>";
					html +=
						'<div id="contact-' +
						buddyObj.identity +
						'-datetime" class=contactDate>' +
						displayDateTime +
						"</div>";
					html += "<div class=presenceText>" + buddyObj.Desc + "</div>";
					html += "</div>";
					$("#myContacts").append(html);
				}
			}

			// Make Select
			// ===========
			for (var b = 0; b < Buddies.length; b++) {
				if (Buddies[b].IsSelected) {
					SelectBuddy(Buddies[b].identity, Buddies[b].type);
					break;
				}
			}
		},
		/**
		 * @param {{unhold: boolean}} options Opciones al momento de cambiar de linea
		 */
		SelectLine(lineNum, options = null) {
			console.log("Entra Select line: ", lineNum);
			var Buddies = this.Buddies;
			var lang = this.lang;
			var Lines = this.Lines;

			var lineActiveNumber = Object.keys(this.datos_lineas_activas).length;
			var lineObj = this.Lines.length
				? this.FindLineByNumber(lineNum)
				: this.lineObj;

			if (lineActiveNumber > 1) {
				if (
					this.datos_lineas_activas[lineNum].lineas_llamadas_activas ==
						lineNum &&
					this.datos_lineas_activas[lineNum].estadado_llamada == false
				) {
					this.finalizar_linea_activas = false;
					this.lineas_activas = true;
				} else {
					this.finalizar_linea_activas = true;
					this.lineas_activas = false;
				}
			}
			this.numero_hablando =
				"DisplayNumber" in lineObj ? lineObj.DisplayNumber : lineObj.title;
			this.numero_linea = lineNum;
			this.nombre_largo =
				"nombre_largo" in this.datos_lineas_activas[lineNum]
					? this.datos_lineas_activas[lineNum].nombre_largo
					: "";
			this.nombre_corto =
				"nombre_corto" in this.datos_lineas_activas[lineNum]
					? this.datos_lineas_activas[lineNum].nombre_corto
					: "";
			if (lineObj.SipSession?.state === SIP.SessionState.Established) {
				this.transfer_disable = false;
				this.conf_disable = false;
			}
			if (lineObj == null) return;
			var displayLineNumber = 0;
			for (var l = 0; l < Lines.length; l++) {
				if (Lines[l].LineNumber == lineObj.LineNumber)
					displayLineNumber = l + 1;
				if (
					Lines[l].IsSelected == true &&
					Lines[l].LineNumber == lineObj.LineNumber
				) {
					// Nothing to do, you re-selected the same buddy;
					return;
				}
			}

			console.log("Selecting Line : " + lineObj.LineNumber);

			// Can only display one thing on the Right
			$(".streamSelected").each(function () {
				$(this).prop("class", "stream");
			});
			$("#line-ui-" + lineObj.LineNumber).prop("class", "streamSelected");
			$("#line-ui-" + lineObj.LineNumber + "-DisplayLineNo").html(
				'<i class="fa fa-phone"></i> ' + lang.line + " " + displayLineNumber
			);
			$("#line-ui-" + lineObj.LineNumber + "-LineIcon").html(displayLineNumber);

			// Switch the SIP Sessions
			this.SwitchLines(
				lineObj.LineNumber,
				options ? { unhold: options?.unhold } : undefined
			);
			// Update Lines List
			for (var l = 0; l < Lines.length; l++) {
				var classStr =
					Lines[l].LineNumber == lineObj.LineNumber ? "buddySelected" : "buddy";
				if (Lines[l].SipSession != null)
					classStr = Lines[l].SipSession.isOnHold
						? "buddyActiveCallHollding"
						: "buddyActiveCall";

				$("#line-" + Lines[l].LineNumber).prop("class", classStr);
				Lines[l].IsSelected = Lines[l].LineNumber == lineObj.LineNumber;
			}
			// Update Buddy List
			for (var b = 0; b < Buddies.length; b++) {
				$("#contact-" + Buddies[b].identity).prop("class", "buddy");
				Buddies[b].IsSelected = false;
			}

			console.log(
				"Estaba on hold: ",
				{ ...lineObj.SipSession },
				lineObj.SipSession?._state
			);
			console.log("Nuevo sip session detectado: ", lineObj.SipSession?.state);
			if (
				lineObj.SipSession &&
				lineObj.SipSession?._state === "Established" &&
				Object.keys(this.datos_lineas_activas).length === 1
			) {
				// Es la última llamada y está activa
				console.log("Si realiza reseteo de botones");
				this.botones_telefono = false;
				this.botones_dtmf = true;
				this.cancelar_llamada_saliente = false;
				this.llamadaSaliente = false;
				this.respuesta_automatica = false;
				this.realizo_llamada_saliente = 0;
				this.transfer_disable = false;
				this.conf_disable = false;
				this.llamada_entrante = 1;
				this.contestar = false;
				this.transferencia = true;
				this.nueva_linea = true;
				this.cancelar_nueva_linea = false;
				this.conferencia = true;
				this.activarmute = !lineObj.SipSession.data.ismute;
				this.desactivarmute = lineObj.SipSession.data.ismute;
				this.poner_en_espera = !lineObj.SipSession.isOnHold;
				this.quitar_en_espera = lineObj.SipSession.isOnHold;
				this.finalizar_llamada = true;
				this.colgar = false;
			} else if (
				lineObj.SipSession &&
				lineObj.SipSession?.state === SIP.SessionState.Established
			) {
				// Voy a una llamada ya conectada
				this.model.show = true;
				this.botones_telefono = false;
				this.botones_dtmf = true;
				this.llamadaSaliente = false;
				this.respuesta_automatica = false;
				this.realizo_llamada_saliente = 0;
				this.cancelar_llamada_saliente = false;
				this.llamada_entrante = 1;
				this.contestar = false;
				this.transferencia = true;
				this.nueva_linea = true;
				this.cancelar_nueva_linea = false;
				this.conferencia = true;
				this.activarmute = !lineObj.SipSession.data.ismute;
				this.desactivarmute = lineObj.SipSession.data.ismute;
				this.poner_en_espera = !lineObj.SipSession.isOnHold;
				this.quitar_en_espera = lineObj.SipSession.isOnHold;
				this.finalizar_llamada = true;
				this.colgar = false;
			} else if (lineObj.SipSession?.state === SIP.SessionState.Initial) {
				// alguien me esta llamando
				this.botones_telefono = false;
				this.botones_dtmf = false;
				this.llamadaSaliente = false;
				this.respuesta_automatica = false;
				this.contestar = false;
				this.transferencia = false;
				this.nueva_linea = false;
				this.cancelar_llamada_saliente = false;
				this.cancelar_nueva_linea = false;
				this.conferencia = false;
				this.desactivarmute = false;
				this.activarmute = false;
				this.poner_en_espera = false;
				this.quitar_en_espera = false;
				this.lienea_uno_activa = true;
				this.finalizar_llamada = false;
				this.contestar = true;
				this.colgar = true;
			} else {
				// es llamada saliente
				this.botones_telefono = false;
				this.botones_dtmf = false;
				this.llamadaSaliente = false;
				this.respuesta_automatica = false;
				this.contestar = false;
				this.transferencia = false;
				this.nueva_linea = false;
				this.cancelar_nueva_linea = false;
				this.conferencia = false;
				this.desactivarmute = false;
				this.activarmute = false;
				this.poner_en_espera = false;
				this.quitar_en_espera = false;
				this.lienea_uno_activa = false;
				this.cancelar_llamada_saliente = true;
				this.finalizar_llamada = false;
				this.contestar = false;
				this.colgar = false;
			}

			// Change to Stream if in Narrow view
			//this.UpdateUI();
		},
		/**
		 * @param {{unhold: boolean}} options Config for switchlines action
		 */
		SwitchLines(lineNum, options) {
			var global = this;
			var lineObj = this.FindLineByNumber(lineNum);

			let disable = false;
			$.each(this.userAgent.sessions, function (i, session) {
				// All the other calls, not on hold
				if (session.state == SIP.SessionState.Established) {
					if (
						session.isOnHold == false &&
						session.data.line != lineNum &&
						(!lineObj.SipSession ||
							lineObj.SipSession.state !== SIP.SessionState.Initial) // verifica si la linea a la que voy ya conectó o no
					) {
						global.holdSession(session.data.line);
					}
				} else if (session.state == SIP.SessionState.Initial) disable = true;
				session.data.IsCurrentCall = false;
			});

			this.conf_disable = disable;
			this.transfer_disable = disable;

			if (lineObj != null && lineObj.SipSession != null) {
				var session = lineObj.SipSession;

				if (session.state == SIP.SessionState.Established) {
					if (session.isOnHold == true && (!options || options?.unhold)) {
						global.unholdSession(lineNum);
					}
				}
				session.data.IsCurrentCall = true;
			}
			var selectedLine = lineNum;
			console.log("LINE NUM :", selectedLine);

			this.RefreshLineActivity(lineNum);
		},
		UpdateUI() {
			let UiMaxWidth = 1240;
			let selectedBuddy = null;
			let Lines = this.Lines;
			let selectedLine = null;
			var windowWidth = $(window).outerWidth();
			if (windowWidth > UiMaxWidth) {
				$("#leftContentTable").css("border-left-width", "1px");
				if (selectedBuddy == null && selectedLine == null) {
					$("#leftContentTable").css("border-right-width", "1px");
				} else {
					$("#rightContent").css("border-right-width", "1px");
				}
			} else {
				// Touching Edges
				$("#leftContentTable").css("border-left-width", "0px");
				if (selectedBuddy == null && selectedLine == null) {
					$("#leftContentTable").css("border-right-width", "0px");
				} else {
					$("#leftContentTable").css("border-right-width", "1px");
				}
				$("#rightContent").css("border-right-width", "0px");
			}

			if (windowWidth < 920) {
				// Narrow Layout

				if ((selectedBuddy == null) & (selectedLine == null)) {
					// Nobody Selected (SHow Only Left Table)
					$("#rightContent").hide();

					$("#leftContent").css("width", "100%");
					$("#leftContent").show();
				} else {
					// Nobody Selected (SHow Only Buddy / Line)
					$("#rightContent").css("margin-left", "0px");
					$("#rightContent").show();

					$("#leftContent").hide();

					if (selectedBuddy != null) updateScroll(selectedBuddy.identity);
				}
			} else {
				// Wide Screen Layout
				if ((selectedBuddy == null) & (selectedLine == null)) {
					$("#leftContent").css("width", "100%");
					$("#rightContent").css("margin-left", "0px");
					$("#leftContent").show();
					$("#rightContent").hide();
				} else {
					$("#leftContent").css("width", "320px");
					$("#rightContent").css("margin-left", "320px");
					$("#leftContent").show();
					$("#rightContent").show();

					if (selectedBuddy != null) updateScroll(selectedBuddy.identity);
				}
			}
			for (var l = 0; l < Lines.length; l++) {
				this.updateLineScroll(Lines[l].LineNumber);
				this.RedrawStage(Lines[l].LineNumber, false);
			}
			this.HidePopup();
		},
		// eslint-disable-next-line no-dupe-keys
		HidePopup2(timeout) {
			let menuObj = null;
			if (timeout) {
				window.setTimeout(function () {
					if (menuObj != null) {
						menuObj.menu("destroy");
						try {
							menuObj.empty();
						} catch (e) {
							console.log(e);
						}
						try {
							menuObj.remove();
						} catch (e) {
							console.log(e);
						}
						menuObj = null;
					}
				}, timeout);
			} else {
				if (menuObj != null) {
					menuObj.menu("destroy");
					try {
						menuObj.empty();
					} catch (e) {
						console.log(e);
					}
					try {
						menuObj.remove();
					} catch (e) {
						console.log(e);
					}
					menuObj = null;
				}
			}
		},
		RedrawStage(lineNum, videoChanged) {
			var stage = $("#line-" + lineNum + "-VideoCall");
			var container = $("#line-" + lineNum + "-stage-container");
			var previewContainer = $("#line-" + lineNum + "-preview-container");
			var videoContainer = $("#line-" + lineNum + "-remote-videos");

			var lineObj = this.FindLineByNumber(lineNum);
			if (lineObj == null) return;
			var session = lineObj.SipSession;
			if (session == null) return;

			var isVideoPinned = false;
			var pinnedVideoID = "";

			// Preview Area
			previewContainer.find("video").each(function (i, video) {
				$(video).hide();
			});
			previewContainer.css("width", "");

			// Count and Tag Videos
			var videoCount = 0;
			videoContainer.find("video").each(function (i, video) {
				var thisRemoteVideoStream = video.srcObject;
				var videoTrack = thisRemoteVideoStream.getVideoTracks()[0];
				var videoTrackSettings = videoTrack.getSettings();
				var srcVideoWidth = videoTrackSettings.width
					? videoTrackSettings.width
					: video.videoWidth;
				var srcVideoHeight = videoTrackSettings.height
					? videoTrackSettings.height
					: video.videoHeight;

				if (thisRemoteVideoStream.mid) {
					thisRemoteVideoStream.channel = "unknown"; // Asterisk Channel
					thisRemoteVideoStream.CallerIdName = "";
					thisRemoteVideoStream.CallerIdNumber = "";
					thisRemoteVideoStream.isAdminMuted = false;
					thisRemoteVideoStream.isAdministrator = false;
					if (session && session.data && session.data.videoChannelNames) {
						session.data.videoChannelNames.forEach(function (videoChannelName) {
							if (thisRemoteVideoStream.mid == videoChannelName.mid) {
								thisRemoteVideoStream.channel = videoChannelName.channel;
							}
						});
					}
					if (session && session.data && session.data.ConfbridgeChannels) {
						session.data.ConfbridgeChannels.forEach(function (
							ConfbridgeChannel
						) {
							if (ConfbridgeChannel.id == thisRemoteVideoStream.channel) {
								thisRemoteVideoStream.CallerIdName =
									ConfbridgeChannel.caller.name;
								thisRemoteVideoStream.CallerIdNumber =
									ConfbridgeChannel.caller.number;
								thisRemoteVideoStream.isAdminMuted = ConfbridgeChannel.muted;
								thisRemoteVideoStream.isAdministrator = ConfbridgeChannel.admin;
							}
						});
					}
					// console.log("Track MID :", thisRemoteVideoStream.mid, thisRemoteVideoStream.channel);
				}

				// Remove any in the preview area
				if (videoChanged) {
					$("#line-" + lineNum + "-preview-container")
						.find("video")
						.each(function (i, video) {
							if (video.id.indexOf("copy-") == 0) {
								video.remove();
							}
						});
				}

				// Prep Videos
				$(video).parent().off("click");
				$(video).parent().css("width", "1px");
				$(video).parent().css("height", "1px");
				$(video).hide();
				$(video).parent().hide();

				// Count Videos
				if (
					lineObj.pinnedVideo &&
					lineObj.pinnedVideo == thisRemoteVideoStream.trackID &&
					videoTrack.readyState == "live" &&
					srcVideoWidth > 10 &&
					srcVideoHeight >= 10
				) {
					// A valid and live video is pinned
					isVideoPinned = true;
					pinnedVideoID = lineObj.pinnedVideo;
				}
				// Count All the videos
				if (
					videoTrack.readyState == "live" &&
					srcVideoWidth > 10 &&
					srcVideoHeight >= 10
				) {
					videoCount++;
					console.log(
						"Display Video - ",
						videoTrack.readyState,
						"MID:",
						thisRemoteVideoStream.mid,
						"channel:",
						thisRemoteVideoStream.channel,
						"src width:",
						srcVideoWidth,
						"src height",
						srcVideoHeight
					);
				} else {
					console.log(
						"Hide Video - ",
						videoTrack.readyState,
						"MID:",
						thisRemoteVideoStream.mid
					);
				}
			});
			if (videoCount == 0) {
				// If you are the only one in the conference, just display your self
				previewContainer.css("width", previewWidth + "px");
				previewContainer.find("video").each(function (i, video) {
					$(video).show();
				});
				return;
			}
			if (isVideoPinned) videoCount = 1;

			if (!videoContainer.outerWidth() > 0) return;
			if (!videoContainer.outerHeight() > 0) return;

			// videoAspectRatio (1|1.33|1.77) is for the peer video, so can tencianlly be used here
			// default ia 4:3
			var Margin = 3;
			var videoRatio = 0.75; // 0.5625 = 9/16 (16:9) | 0.75   = 3/4 (4:3)
			if (videoAspectRatio == "" || videoAspectRatio == "1.33")
				videoRatio = 0.75;
			if (videoAspectRatio == "1.77") videoRatio = 0.5625;
			if (videoAspectRatio == "1") videoRatio = 1;
			var stageWidth = videoContainer.outerWidth() - Margin * 2;
			var stageHeight = videoContainer.outerHeight() - Margin * 2;
			var previewWidth = previewContainer.outerWidth();
			var maxWidth = 0;
			let i = 1;
			while (i < 5000) {
				let w = StageArea(
					i,
					videoCount,
					stageWidth,
					stageHeight,
					Margin,
					videoRatio
				);
				if (w === false) {
					maxWidth = i - 1;
					break;
				}
				i++;
			}
			maxWidth = maxWidth - Margin * 2;

			// Layout Videos
			videoContainer.find("video").each(function (i, video) {
				var thisRemoteVideoStream = video.srcObject;
				var videoTrack = thisRemoteVideoStream.getVideoTracks()[0];
				var videoTrackSettings = videoTrack.getSettings();
				var srcVideoWidth = videoTrackSettings.width
					? videoTrackSettings.width
					: video.videoWidth;
				var srcVideoHeight = videoTrackSettings.height
					? videoTrackSettings.height
					: video.videoHeight;

				var videoWidth = maxWidth;
				var videoHeight = maxWidth * videoRatio;

				// Set & Show
				if (isVideoPinned) {
					// One of the videos are pinned
					if (pinnedVideoID == video.srcObject.trackID) {
						$(video)
							.parent()
							.css("width", videoWidth + "px");
						$(video)
							.parent()
							.css("height", videoHeight + "px");
						$(video).show();
						$(video).parent().show();
						// Pinned Actions
						var unPinButton = $("<button />", {
							class: "videoOverlayButtons",
						});
						unPinButton.html('<i class="fa fa-th-large"></i>');
						unPinButton.on("click", function () {
							UnPinVideo(lineNum, video);
						});
						$(video).parent().find(".Actions").empty();
						$(video).parent().find(".Actions").append(unPinButton);
					} else {
						// Put the videos in the preview area
						if (
							videoTrack.readyState == "live" &&
							srcVideoWidth > 10 &&
							srcVideoHeight >= 10
						) {
							if (videoChanged) {
								var videoEl = $("<video/>", {
									id: "copy-" + thisRemoteVideoStream.id,
									muted: true,
									autoplay: true,
									playsinline: true,
									controls: false,
								});
								var videoObj = videoEl.get(0);
								videoObj.srcObject = thisRemoteVideoStream;
								$("#line-" + lineNum + "-preview-container").append(videoEl);
							}
						}
					}
				} else {
					// None of the videos are pinned
					if (
						videoTrack.readyState == "live" &&
						srcVideoWidth > 10 &&
						srcVideoHeight >= 10
					) {
						// Unpinned
						$(video)
							.parent()
							.css("width", videoWidth + "px");
						$(video)
							.parent()
							.css("height", videoHeight + "px");
						$(video).show();
						$(video).parent().show();
						// Unpinned Actions
						var pinButton = $("<button />", {
							class: "videoOverlayButtons",
						});
						pinButton.html('<i class="fa fa-thumb-tack"></i>');
						pinButton.on("click", function () {
							PinVideo(lineNum, video, video.srcObject.trackID);
						});
						$(video).parent().find(".Actions").empty();
						if (videoCount > 1) {
							// More then one video, nothing pinned
							$(video).parent().find(".Actions").append(pinButton);
						}
					}
				}

				// Polulate Caller ID
				var adminMuteIndicator = "";
				var administratorIndicator = "";
				if (thisRemoteVideoStream.isAdminMuted == true) {
					adminMuteIndicator =
						'<i class="fa fa-microphone-slash" style="color:red"></i>&nbsp;';
				}
				if (thisRemoteVideoStream.isAdministrator == true) {
					administratorIndicator =
						'<i class="fa fa-user" style="color:orange"></i>&nbsp;';
				}
				if (thisRemoteVideoStream.CallerIdName == "") {
					thisRemoteVideoStream.CallerIdName = FindBuddyByIdentity(
						session.data.buddyId
					).CallerIDName;
				}
				$(video)
					.parent()
					.find(".callerID")
					.html(
						administratorIndicator +
							adminMuteIndicator +
							thisRemoteVideoStream.CallerIdName
					);
			});

			// Preview Area
			previewContainer.css("width", previewWidth + "px");
			previewContainer.find("video").each(function (i, video) {
				$(video).show();
			});
		},
		updateLineScroll(lineNum) {
			this.RefreshLineActivity(lineNum);

			var element = $("#line-" + lineNum + "-CallDetails").get(0);
			if (element) element.scrollTop = element.scrollHeight;
		},
		RefreshLineActivity(lineNum) {
			var lang = this.lang;
			let DisplayTimeFormat = "h:mm:ss A";
			var lineObj = this.FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				return;
			}
			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-CallDetails").empty();

			var callDetails = [];

			var ringTime = 0;
			var CallStart = moment.utc(session.data.callstart.replace(" UTC", ""));
			var CallAnswer = null;
			if (session.data.startTime) {
				CallAnswer = moment.utc(session.data.startTime);
				ringTime = moment.duration(CallAnswer.diff(CallStart));
			}
			CallStart = CallStart.format("YYYY-MM-DD HH:mm:ss UTC");
			(CallAnswer = CallAnswer
				? CallAnswer.format("YYYY-MM-DD HH:mm:ss UTC")
				: null),
				(ringTime = ringTime != 0 ? ringTime.asSeconds() : 0);

			var srcCallerID = "";
			var dstCallerID = "";
			if (session.data.calldirection == "inbound") {
				srcCallerID =
					"<" +
					session.remoteIdentity.uri.user +
					"> " +
					session.remoteIdentity.displayName;
			} else if (session.data.calldirection == "outbound") {
				dstCallerID = session.data.dst;
			}

			var withVideo = session.data.withvideo ? "(" + lang.with_video + ")" : "";
			var startCallMessage =
				session.data.calldirection == "inbound"
					? lang.you_received_a_call_from + " " + srcCallerID + " " + withVideo
					: lang.you_made_a_call_to + " " + dstCallerID + " " + withVideo;
			callDetails.push({
				Message: startCallMessage,
				TimeStr: CallStart,
			});
			if (CallAnswer) {
				var answerCallMessage =
					session.data.calldirection == "inbound"
						? lang.you_answered_after +
						  " " +
						  ringTime +
						  " " +
						  lang.seconds_plural
						: lang.they_answered_after +
						  " " +
						  ringTime +
						  " " +
						  lang.seconds_plural;
				callDetails.push({
					Message: answerCallMessage,
					TimeStr: CallAnswer,
				});
			}

			var Transfers = session.data.transfer ? session.data.transfer : [];
			$.each(Transfers, function (item, transfer) {
				var msg =
					transfer.type == "Blind"
						? lang.you_started_a_blind_transfer_to + " " + transfer.to + ". "
						: lang.you_started_an_attended_transfer_to +
						  " " +
						  transfer.to +
						  ". ";
				if (transfer.accept && transfer.accept.complete == true) {
					msg += lang.the_call_was_completed;
				} else if (transfer.accept.disposition != "") {
					msg +=
						lang.the_call_was_not_completed +
						" (" +
						transfer.accept.disposition +
						")";
				}
				callDetails.push({
					Message: msg,
					TimeStr: transfer.transferTime,
				});
			});
			var Mutes = session.data.mute ? session.data.mute : [];
			$.each(Mutes, function (item, mute) {
				callDetails.push({
					Message:
						mute.event == "mute"
							? lang.you_put_the_call_on_mute
							: lang.you_took_the_call_off_mute,
					TimeStr: mute.eventTime,
				});
			});
			var Holds = session.data.hold ? session.data.hold : [];
			$.each(Holds, function (item, hold) {
				callDetails.push({
					Message:
						hold.event == "hold"
							? lang.you_put_the_call_on_hold
							: lang.you_took_the_call_off_hold,
					TimeStr: hold.eventTime,
				});
			});
			var ConfbridgeEvents = session.data.ConfbridgeEvents
				? session.data.ConfbridgeEvents
				: [];
			$.each(ConfbridgeEvents, function (item, event) {
				callDetails.push({
					Message: event.event,
					TimeStr: event.eventTime,
				});
			});
			var Recordings = session.data.recordings ? session.data.recordings : [];
			$.each(Recordings, function (item, recording) {
				var msg = lang.call_is_being_recorded;
				if (recording.startTime != recording.stopTime) {
					msg += "(" + lang.now_stopped + ")";
				}
				callDetails.push({
					Message: msg,
					TimeStr: recording.startTime,
				});
			});
			var ConfCalls = session.data.confcalls ? session.data.confcalls : [];
			$.each(ConfCalls, function (item, confCall) {
				var msg =
					lang.you_started_a_conference_call_to + " " + confCall.to + ". ";
				if (confCall.accept && confCall.accept.complete == true) {
					msg += lang.the_call_was_completed;
				} else if (confCall.accept.disposition != "") {
					msg +=
						lang.the_call_was_not_completed +
						" (" +
						confCall.accept.disposition +
						")";
				}
				callDetails.push({
					Message: msg,
					TimeStr: confCall.startTime,
				});
			});

			callDetails.sort(function (a, b) {
				var aMo = moment.utc(a.TimeStr.replace(" UTC", ""));
				var bMo = moment.utc(b.TimeStr.replace(" UTC", ""));
				if (aMo.isSameOrAfter(bMo, "second")) {
					return -1;
				} else return 1;
			});

			$.each(callDetails, function (item, detail) {
				var Time = moment
					.utc(detail.TimeStr.replace(" UTC", ""))
					.local()
					.format(DisplayTimeFormat);
				var messageString =
					"<table class=timelineMessage cellspacing=0 cellpadding=0><tr>";
				messageString += "<td class=timelineMessageArea>";
				messageString +=
					'<div class=timelineMessageDate><i class="fa fa-circle timelineMessageDot"></i>' +
					Time +
					"</div>";
				messageString +=
					"<div class=timelineMessageText>" + detail.Message + "</div>";
				messageString += "</td>";
				messageString += "</tr></table>";
				$("#line-" + lineNum + "-CallDetails").prepend(messageString);
			});
		},
		ShowDial() {
			//ShowContacts();
			var EnableVideoCalling = 0;
			var lang = this.lang;
			$("#myContacts").hide();
			$("#actionArea").empty();

			var html =
				'<div style="text-align:right"><button class=roundButtons onclick="ShowContacts()"><i class="fa fa-close"></i></button></div>';
			html +=
				'<div style="text-align:center"><input id=dialText class=dialTextInput oninput="handleDialInput(this, event)" onkeydown="dialOnkeydown(event, this)" style="width:160px; margin-top:15px"></div>';
			html +=
				'<table cellspacing=10 cellpadding=0 style="margin-left:auto; margin-right: auto">';
			html +=
				"<tr><td><button class=dialButtons onclick=\"KeyPress('1')\"><div>1</div><span>&nbsp;</span></button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('2')\"><div>2</div><span>ABC</span></button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('3')\"><div>3</div><span>DEF</span></button></td></tr>";
			html +=
				"<tr><td><button class=dialButtons onclick=\"KeyPress('4')\"><div>4</div><span>GHI</span></button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('5')\"><div>5</div><span>JKL</span></button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('6')\"><div>6</div><span>MNO</span></button></td></tr>";
			html +=
				"<tr><td><button class=dialButtons onclick=\"KeyPress('7')\"><div>7</div><span>PQRS</span></button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('8')\"><div>8</div><span>TUV</span></button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('9')\"><div>9</div><span>WXYZ</span></button></td></tr>";
			html +=
				"<tr><td><button class=dialButtons onclick=\"KeyPress('*')\">*</button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('0')\">0</button></td>";
			html +=
				"<td><button class=dialButtons onclick=\"KeyPress('#')\">#</button></td></tr>";
			html += "</table>";
			html += '<div style="text-align: center; margin-bottom:15px">';
			html +=
				'<button class="dialButtons dialButtonsDial" id=dialAudio title="' +
				lang.audio_call +
				'" onclick="DialByLine(\'audio\')"><i class="fa fa-phone"></i></button>';
			if (EnableVideoCalling) {
				html +=
					'<button class="dialButtons dialButtonsDial" id=dialVideo style="margin-left:20px" title="' +
					lang.video_call +
					'" onclick="DialByLine(\'video\')"><i class="fa fa-video-camera"></i></button>';
			}
			html += "</div>";
			$("#actionArea").html(html);
			$("#actionArea").show();
			$("#dialText").focus();
		},
		AddBuddyMessageStream(buddyObj) {
			let UiMessageLayout = "middle";
			// Profile Etc Row
			// ----------------------------------------------------------
			var lang = this.lang;
			var profileRow = "";
			profileRow +=
				'<tr><td id="contact-' +
				buddyObj.identity +
				'-ProfileCell" class="streamSection highlightSection buddyProfileSection" style="height: 48px;">';

			// Left Content - Profile
			profileRow +=
				'<table cellpadding=0 cellspacing=0 border=0 style="width:100%; table-layout: fixed;">';
			profileRow += "<tr>";
			// Close|Return|Back Button
			profileRow += '<td style="width:48px; text-align: center;">';
			profileRow +=
				'<button id="contact-' +
				buddyObj.identity +
				'-btn-back" onclick="CloseBuddy(\'' +
				buddyObj.identity +
				'\')" class=roundButtons title="' +
				lang.back +
				'"><i class="fa fa-chevron-left"></i></button> ';
			profileRow += "</td>";

			// Profile UI
			profileRow += '<td style="width:100%">';
			profileRow += '<div class=contact style="cursor: unset">';
			if (buddyObj.type == "extension" || buddyObj.type == "xmpp") {
				profileRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-picture-main" class=buddyIcon style="background-image: url(\'' +
					getPicture(buddyObj.identity) +
					"')\"></div>";
			} else if (buddyObj.type == "contact") {
				profileRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-picture-main" class=buddyIcon style="background-image: url(\'' +
					getPicture(buddyObj.identity, "contact") +
					"')\"></div>";
			} else if (buddyObj.type == "group") {
				profileRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-picture-main" class=buddyIcon style="background-image: url(\'' +
					getPicture(buddyObj.identity, "group") +
					"')\"></div>";
			}

			if (buddyObj.type == "extension" || buddyObj.type == "xmpp") {
				profileRow += '<div class=contactNameText style="margin-right: 0px;">';
				profileRow +=
					'<span id="contact-' +
					buddyObj.identity +
					'-devstate-main" class="' +
					buddyObj.devState +
					'"></span>';
				profileRow += " " + buddyObj.ExtNo + " - " + buddyObj.CallerIDName;
				profileRow += "</div>";
			} else if (buddyObj.type == "contact") {
				profileRow +=
					'<div class=contactNameText style="margin-right: 0px;"><i class="fa fa-address-card"></i> ' +
					buddyObj.CallerIDName +
					"</div>";
			} else if (buddyObj.type == "group") {
				profileRow +=
					'<div class=contactNameText style="margin-right: 0px;"><i class="fa fa-users"></i> ' +
					buddyObj.CallerIDName +
					"</div>";
			}
			if (buddyObj.type == "extension") {
				var friendlyState = buddyObj.presence;
				if (friendlyState == "Unknown") friendlyState = lang.state_unknown;
				if (friendlyState == "Not online")
					friendlyState = lang.state_not_online;
				if (friendlyState == "Ready") friendlyState = lang.state_ready;
				if (friendlyState == "On the phone")
					friendlyState = lang.state_on_the_phone;
				if (friendlyState == "Ringing") friendlyState = lang.state_ringing;
				if (friendlyState == "On hold") friendlyState = lang.state_on_hold;
				if (friendlyState == "Unavailable")
					friendlyState = lang.state_unavailable;
				profileRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-presence-main" class=presenceText>' +
					friendlyState +
					"</div>";
			} else if (buddyObj.type == "xmpp") {
				profileRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-presence-main" class=presenceText><i class="fa fa-comments"></i> ' +
					buddyObj.presenceText +
					"</div>";
				profileRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-chatstate-main" class=presenceText style="display:none"><i class="fa fa-keyboard-o"></i> ' +
					buddyObj.CallerIDName +
					" " +
					lang.is_typing +
					"...</div>";
			} else {
				profileRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-presence-main" class=presenceText>' +
					buddyObj.Desc +
					"</div>";
			}
			profileRow += "</div>";
			profileRow += "</td>";

			// Right Content - Action Buttons
			var buttonsWidth = 80; // 1 button = 34px ~40px
			if (
				(buddyObj.type == "extension" || buddyObj.type == "xmpp") &&
				EnableVideoCalling
			) {
				buttonsWidth = 120;
			}
			var fullButtonsWidth = 200;
			if (
				(buddyObj.type == "extension" || buddyObj.type == "xmpp") &&
				EnableVideoCalling
			) {
				fullButtonsWidth = 240;
			}
			profileRow +=
				'<td id="contact-' +
				buddyObj.identity +
				'-action-buttons" style="width: ' +
				buttonsWidth +
				'px; text-align: right">';
			profileRow +=
				'<button id="contact-' +
				buddyObj.identity +
				'-btn-audioCall" onclick="AudioCallMenu(\'' +
				buddyObj.identity +
				'\', this)" class=roundButtons title="' +
				lang.audio_call +
				'"><i class="fa fa-phone"></i></button>';
			if (
				(buddyObj.type == "extension" || buddyObj.type == "xmpp") &&
				EnableVideoCalling
			) {
				profileRow +=
					' <button id="contact-' +
					buddyObj.identity +
					"-btn-videoCall\" onclick=\"DialByLine('video', '" +
					buddyObj.identity +
					"', '" +
					buddyObj.ExtNo +
					'\');" class=roundButtons title="' +
					lang.video_call +
					'"><i class="fa fa-video-camera"></i></button>';
			}
			profileRow +=
				'<span id="contact-' +
				buddyObj.identity +
				'-extra-buttons" style="display:none">';
			profileRow +=
				' <button id="contact-' +
				buddyObj.identity +
				'-btn-edit" onclick="EditBuddyWindow(\'' +
				buddyObj.identity +
				'\')" class=roundButtons title="' +
				lang.edit +
				'"><i class="fa fa-pencil"></i></button>';
			profileRow +=
				' <button id="contact-' +
				buddyObj.identity +
				'-btn-search" onclick="FindSomething(\'' +
				buddyObj.identity +
				'\')" class=roundButtons title="' +
				lang.find_something +
				'"><i class="fa fa-search"></i></button>';
			profileRow +=
				' <button id="contact-' +
				buddyObj.identity +
				'-btn-remove" onclick="RemoveBuddy(\'' +
				buddyObj.identity +
				'\')" class=roundButtons title="' +
				lang.remove +
				'"><i class="fa fa-trash"></i></button>';
			profileRow += "</span>";
			profileRow +=
				' <button id="contact-' +
				buddyObj.identity +
				'-btn-toggle-extra" onclick="ToggleExtraButtons(\'' +
				buddyObj.identity +
				"', " +
				buttonsWidth +
				", " +
				fullButtonsWidth +
				')" class=roundButtons><i class="fa fa-ellipsis-h"></i></button>';
			profileRow += "</td>";

			profileRow += "</tr></table>";
			profileRow += "</div>";

			// Separator
			profileRow += '<div style="clear:both; height:0px"></div>';

			// Search & Related Elements
			profileRow +=
				'<div id="contact-' +
				buddyObj.identity +
				'-search" style="margin-top:6px; display:none">';
			profileRow +=
				'<span class=searchClean style="width:100%"><input type=text style="width:80%" autocomplete=none oninput=SearchStream(this,\'' +
				buddyObj.identity +
				"') placeholder=\"" +
				lang.find_something_in_the_message_stream +
				'"></span>';
			profileRow += "</div>";

			profileRow += "</td></tr>";

			// Messages Row
			// ----------------------------------------------------------
			var messagesRow = "";
			messagesRow +=
				'<tr><td id="contact-' +
				buddyObj.identity +
				'-MessagesCell" class="streamSection streamSectionBackground wallpaperBackground buddyMessageSection">';
			messagesRow +=
				'<div id="contact-' +
				buddyObj.identity +
				'-ChatHistory" class="chatHistory cleanScroller" ondragenter="setupDragDrop(event, \'' +
				buddyObj.identity +
				"')\" ondragover=\"setupDragDrop(event, '" +
				buddyObj.identity +
				"')\" ondragleave=\"cancelDragDrop(event, '" +
				buddyObj.identity +
				"')\" ondrop=\"onFileDragDrop(event, '" +
				buddyObj.identity +
				"')\">";
			// Previous Chat messages
			messagesRow += "</div>";
			messagesRow += "</td></tr>";

			// // Interaction row
			// ----------------------------------------------------------
			var textRow = "";
			if (
				(buddyObj.type == "extension" ||
					buddyObj.type == "xmpp" ||
					buddyObj.type == "group") &&
				EnableTextMessaging
			) {
				textRow +=
					'<tr><td id="contact-' +
					buddyObj.identity +
					'-InteractionCell" class="streamSection highlightSection buddyInteractionSection" style="height:80px">';

				// Send Paste Image
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-imagePastePreview" class=sendImagePreview style="display:none" tabindex=0></div>';
				// Preview
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-msgPreview" class=sendMessagePreview style="display:none">';
				textRow +=
					"<table class=sendMessagePreviewContainer cellpadding=0 cellspacing=0><tr>";
				textRow +=
					'<td style="text-align:right"><div id="contact-' +
					buddyObj.identity +
					'-msgPreviewhtml" class="sendMessagePreviewHtml cleanScroller"></div></td>';
				textRow +=
					'<td style="width:40px"><button onclick="SendChatMessage(\'' +
					buddyObj.identity +
					'\')" class="roundButtons" title="' +
					lang.send +
					'"><i class="fa fa-paper-plane"></i></button></td>';
				textRow += "</tr></table>";
				textRow += "</div>";

				// Send File
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-fileShare" style="display:none">';
				textRow += '<input type=file multiple onchange="console.log(this)" />';
				textRow += "</div>";

				// Send Audio Recording
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-audio-recording" style="display:none"></div>';

				// Send Video Recording
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-video-recording" style="display:none"></div>';

				// Dictate Message
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-dictate-message" style="display:none"></div>';

				// Emoji Menu Bar
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-emoji-menu" style="display:none"></div>';

				// ChatState
				textRow +=
					'<div id="contact-' +
					buddyObj.identity +
					'-chatstate" style="display:none"><i class="fa fa-keyboard-o"></i> ' +
					buddyObj.CallerIDName +
					" " +
					lang.is_typing +
					"...</div>";

				// Type Area
				textRow +=
					"<table class=sendMessageContainer cellpadding=0 cellspacing=0><tr>";
				textRow +=
					'<td id="contact-' +
					buddyObj.identity +
					'-add-menu" class=MessageActions style="width:40px"><button onclick="AddMenu(this, \'' +
					buddyObj.identity +
					'\')" class=roundButtons title="' +
					lang.menu +
					'"><i class="fa fa-ellipsis-h"></i></button></td>';
				textRow +=
					'<td><textarea id="contact-' +
					buddyObj.identity +
					'-ChatMessage" class="chatMessage cleanScroller" placeholder="' +
					lang.type_your_message_here +
					'" onkeydown="chatOnkeydown(event, this,\'' +
					buddyObj.identity +
					"')\" oninput=\"chatOnInput(event, this,'" +
					buddyObj.identity +
					"')\" onpaste=\"chatOnbeforepaste(event, this,'" +
					buddyObj.identity +
					"')\"></textarea></td>";
				textRow +=
					'<td id="contact-' +
					buddyObj.identity +
					'-sendMessageButtons" style="width:40px; display:none"><button onclick="SendChatMessage(\'' +
					buddyObj.identity +
					'\')" class="roundButtons" title="' +
					lang.send +
					'"><i class="fa fa-paper-plane"></i></button></td>';
				textRow += "</tr></table>";

				textRow += "</td></tr>";
			}

			var html =
				'<table id="stream-' +
				buddyObj.identity +
				'" class=stream cellspacing=0 cellpadding=0>';
			if (UiMessageLayout == "top") {
				html += messagesRow;
				html += profileRow;
			} else {
				html += profileRow;
				html += messagesRow;
			}
			html += textRow;
			html += "</table>";

			$("#rightContent").append(html);
			if (UiMessageLayout == "top") {
				$("#contact-" + buddyObj.identity + "-MessagesCell").addClass("");
				$("#contact-" + buddyObj.identity + "-ProfileCell").addClass(
					"sectionBorderTop"
				);
				$("#contact-" + buddyObj.identity + "-InteractionCell").addClass("");
			} else {
				$("#contact-" + buddyObj.identity + "-ProfileCell").addClass(
					"sectionBorderBottom"
				);
				$("#contact-" + buddyObj.identity + "-MessagesCell").addClass("");
				$("#contact-" + buddyObj.identity + "-InteractionCell").addClass(
					"sectionBorderTop"
				);
			}
		},

		AudioCall(lineObj, dialledNumber, extraHeaders) {
			let HasAudioDevice = true;
			var lang = this.lang;
			var userAgent = this.userAgent;
			let AutoGainControl = 1;
			let EchoCancellation = 1;
			let NoiseSuppression = 1;
			let wssServer = env.asteriskSocketIP;
			var global = this;

			if (userAgent == null) return;
			if (userAgent.isRegistered() == false) return;
			if (lineObj == null) return;

			if (HasAudioDevice == false) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Lo sentimos, no tienes ningún micrófono conectado a este ordenador. No puede recibir llamadas.",
					type: "error",
				});
				return;
			}

			var supportedConstraints =
				navigator.mediaDevices.getSupportedConstraints();

			var spdOptions = {
				earlyMedia: true,
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: { deviceId: "default" },
						video: false,
					},
				},
			};
			// Configure Audio
			var currentAudioDevice = this.getAudioSrcID();
			if (currentAudioDevice != "default") {
				var confirmedAudioDevice = false;
				for (var i = 0; i < this.AudioinputDevices.length; ++i) {
					if (currentAudioDevice == this.AudioinputDevices[i].deviceId) {
						confirmedAudioDevice = true;
						break;
					}
				}
				if (confirmedAudioDevice) {
					spdOptions.sessionDescriptionHandlerOptions.constraints.audio.deviceId =
						{ exact: currentAudioDevice };
				} else {
					console.warn(
						"The audio device you used before is no longer available, default settings applied."
					);
					localDB.setItem("AudioSrcId", "default");
				}
			}
			// Add additional Constraints
			if (supportedConstraints.autoGainControl) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl =
					AutoGainControl;
			}
			if (supportedConstraints.echoCancellation) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation =
					EchoCancellation;
			}
			if (supportedConstraints.noiseSuppression) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression =
					NoiseSuppression;
			}
			// Extra Headers
			if (extraHeaders) {
				spdOptions.extraHeaders = extraHeaders;
			}

			$("#line-" + lineObj.LineNumber + "-msg").html(lang.starting_audio_call);
			$("#line-" + lineObj.LineNumber + "-timer").show();

			var startTime = moment.utc();

			// Invite
			console.log("INVITE (audio): " + dialledNumber + "@" + wssServer);
			var targetURI = SIP.UserAgent.makeURI(
				"sip:" + dialledNumber + "@" + wssServer
			);
			console.log("INVITE (audio): ", targetURI);
			lineObj.SipSession = new SIP.Inviter(userAgent, targetURI, spdOptions);
			lineObj.SipSession.data = {};
			lineObj.SipSession.data.line = lineObj.LineNumber;
			// lineObj.SipSession.data.buddyId = lineObj.BuddyObj.identity;
			lineObj.SipSession.data.calldirection = "outbound";
			lineObj.SipSession.data.dst = dialledNumber;
			lineObj.SipSession.data.callstart = startTime.format(
				"YYYY-MM-DD HH:mm:ss UTC"
			);
			lineObj.SipSession.data.callTimer = window.setInterval(function () {
				var now = moment.utc();
				var duration = moment.duration(now.diff(startTime));
				var timeStr = global.formatShortDuration(duration.asSeconds());
				$("#line-" + lineObj.LineNumber + "-timer").html(timeStr);
				$("#line-" + lineObj.LineNumber + "-datetime").html(timeStr);
				global.tiempo_llamada = timeStr;
				global.datos_lineas_activas[lineObj.LineNumber].llamada_tiempo =
					timeStr;
			}, 1000);
			lineObj.SipSession.data.VideoSourceDevice = null;
			lineObj.SipSession.data.AudioSourceDevice = this.getAudioSrcID();
			lineObj.SipSession.data.AudioOutputDevice = this.getAudioOutputID();
			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.withvideo = false;
			lineObj.SipSession.data.earlyReject = false;
			lineObj.SipSession.isOnHold = false;
			lineObj.SipSession.delegate = {
				onBye: function (sip) {
					global.onSessionRecievedBye(lineObj, sip);
				},
				onMessage: function (sip) {
					onSessionRecievedMessage(lineObj, sip);
				},
				onInvite: function (sip) {
					onSessionReinvited(lineObj, sip);
				},
				onSessionDescriptionHandler: function (sdh, provisional) {
					global.onSessionDescriptionHandlerCreated(
						lineObj,
						sdh,
						provisional,
						false
					);
				},
			};
			var inviterOptions = {
				requestDelegate: {
					// OutgoingRequestDelegate
					onTrying: function (sip) {
						global.onInviteTrying(lineObj, sip);
					},
					onProgress: function (sip) {
						global.onInviteProgress(lineObj, sip);
					},
					onRedirect: function (sip) {
						onInviteRedirected(lineObj, sip);
					},
					onAccept: function (sip) {
						global.onInviteAccepted(lineObj, false, sip);
					},
					onReject: function (sip) {
						global.onInviteRejected(lineObj, sip);
					},
				},
			};
			console.log("************* REALIZA INVITE ***********");
			console.log(`${lineObj.SipSession.state}`);
			lineObj.SipSession.invite(inviterOptions).catch(function (e) {
				if (e.message.indexOf("Terminated to Terminated") === -1)
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: "Error al enviar invitación, revise su microfono, diadema y conexión ",
						type: "error",
					});
				console.warn("Failed to send INVITE:", e);
			});

			$("#line-" + lineObj.LineNumber + "-btn-settings").removeAttr("disabled");
			$("#line-" + lineObj.LineNumber + "-btn-audioCall").prop(
				"disabled",
				"disabled"
			);
			$("#line-" + lineObj.LineNumber + "-btn-videoCall").prop(
				"disabled",
				"disabled"
			);
			$("#line-" + lineObj.LineNumber + "-btn-search").removeAttr("disabled");
			$("#line-" + lineObj.LineNumber + "-btn-remove").prop(
				"disabled",
				"disabled"
			);

			$("#line-" + lineObj.LineNumber + "-progress").show();
			$("#line-" + lineObj.LineNumber + "-msg").show();

			//this.UpdateUI();
			this.UpdateBuddyList();
			this.updateLineScroll(lineObj.LineNumber);

			// Custom Web hook
			if (typeof web_hook_on_invite !== "undefined")
				web_hook_on_invite(lineObj.SipSession);
		},
		/**
		 * When my invite was rejected by them
		 */
		onInviteRejected(lineObj, response) {
			console.log("INVITE Rejected:1", response.message.reasonPhrase);
			delete this.datos_lineas_activas[lineObj.LineNumber];
			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.reasonCode = response.message.statusCode;
			lineObj.SipSession.data.reasonText = response.message.reasonPhrase;
			if (Object.keys(this.datos_lineas_activas).length < 1) {
				console.log("Ninguna linea activa");
				this.lineObj = [];
				this.Buddies = [];
				this.drawer = false;
				this.tiempo_llamada = "";
				this.numero_telefonico = "";
				this.numero_hablando = "";
				this.cancelar_llamada_saliente = false;
				this.cancelar_nueva_linea = false;
				this.activarmute = false;
				this.desactivarmute = false;
				this.datos_llamada = false;
				this.digitar_transferencia = false;
				this.digitar_nueva_linea = false;
				// this.realizo_llaada_saliente = 0;
				this.poner_en_espera = false;
				this.quitar_en_espera = false;
				this.finalizar_llamada = false;
				this.transferencia = false;
				this.nueva_linea = false;
				this.cancelar_transferencia = false;
				this.cancelar_nueva_linea = false;
				this.conferencia = false;
				this.llamadaSaliente = true;
				this.botones_telefono = true;
				this.botones_dtmf = false;
				this.respuesta_automatica = true;
				this.linea_conferencia = false;
				this.desactivar_conferencia = false;
				this.digitar_conferencia = false;
				this.telefono_conferencia = "";
				this.quitar_en_espera = false;
				this.digitar_telefono = true;
				this.teardownSession(lineObj);
				this.stopDuration();
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Llamada no atendida",
					type: "warning",
				});
			} else {
				console.log(
					"Hay lineas activas y esta en espera: ",
					lineObj.SipSession.isOnHold
				);
				this.drawer = false;
				this.numero_telefonico = "";
				this.cancelar_llamada_saliente = false;
				this.cancelar_nueva_linea = false;
				this.datos_llamada = true;
				this.digitar_transferencia = false;
				this.digitar_nueva_linea = false;
				this.poner_en_espera = false;
				this.quitar_en_espera = true;
				this.activarmute = true;
				this.desactivarmute = false;
				this.finalizar_llamada = true;
				this.transferencia = true;
				this.nueva_linea = true;
				this.cancelar_transferencia = false;
				this.cancelar_nueva_linea = false;
				this.conferencia = true;
				this.llamadaSaliente = false;
				this.botones_telefono = true;
				this.respuesta_automatica = false;
				this.linea_conferencia = false;
				this.desactivar_conferencia = false;
				this.digitar_conferencia = false;
				this.telefono_conferencia = "";
				this.digitar_telefono = false;
				this.teardownSession(lineObj);
			}
			if (Object.keys(this.datos_lineas_activas).length >= 1) {
				this.Lines = this.Lines.filter(
					data => data.LineNumber != lineObj.LineNumber
				);
				this.lineObj = this.Lines.slice(-1)[0];

				if (Object.keys(this.datos_lineas_activas).length === 1) {
					this.lineas_activas_llamadas = false;
					this.lienea_uno_activa = true;
					this.closeWebLines();
				}
				/* 				for (let datos in this.datos_lineas_activas) {
					defaultLine = this.FindLineByNumber(
						this.datos_lineas_activas[datos].lineas_llamadas_activas
					);
					this.lineObj = defaultLine;
					this.numero_hablando = this.datos_lineas_activas[datos].title;
					console.log(
						"Linea a conectar",
						this.datos_lineas_activas[datos].lineas_llamadas_activas
					);
					 					//this.unholdSession(
						//this.datos_lineas_activas[datos].lineas_llamadas_activas
					//); // Quita pausa de la linea restante
					this.nombre_largo =
						"nombre_largo" in this.datos_lineas_activas[datos]
							? this.datos_lineas_activas[datos].nombre_largo
							: "";
					this.nombre_corto =
						"nombre_corto" in this.datos_lineas_activas[datos]
							? this.datos_lineas_activas[datos].nombre_corto
							: "";
				} */
				if (this.lineObj)
					this.SelectLine(this.lineObj.LineNumber, { unhold: false });
			} else {
				this.Lines = [];
				this.lineObj = [];
			}
		},
		onSessionRecievedBye(lineObj, response) {
			console.log("RecievedBye: ", lineObj);
			// They Ended the call
			delete this.datos_lineas_activas[lineObj.LineNumber];
			console.log(
				"Conferenciando on bye: ",
				JSON.stringify(lineObj.SipSession.data.confcalls)
			);
			if (
				lineObj.LineNumber == this.linea_a_conferenciar ||
				lineObj.LineNumber == this.lineObj?.LineNumber ||
				this.en_conferencia
			) {
				this.conferenciando_multi_linea = false;
				this.en_conferencia = false;
				this.linea_a_conferenciar = -1;
				this.conferencia = true;
				this.desactivar_conferencia = false;
			}
			if (
				lineObj.LineNumber == this.linea_a_transferir ||
				lineObj.LineNumber == this.lineObj?.LineNumber
			) {
				this.transfiriendo_multi_linea = false;
				this.linea_a_transferir = -1;
				this.transferencia = true;
				this.cancelar_transferencia = false;
			}

			//  $("#line-" + lineObj.LineNumber + "-msg").html(lang.call_ended);
			console.log("Call ended, bye! Methods");
			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.reasonCode = 16;
			lineObj.SipSession.data.reasonText = "Normal Call clearing";

			this.teardownSession(lineObj);
			if (Object.keys(this.datos_lineas_activas).length > 0) {
				this.datos_llamada = true;
				this.Lines = this.Lines.filter(
					data => data.LineNumber != lineObj.LineNumber
				);
				this.lineObj = this.Lines.slice(-1)[0];
			} else {
				$(".crear-audio").remove();
				this.Lines = [];
				this.lineObj = [];
			}

			const totalCalls = Object.keys(this.datos_lineas_activas).length;
			if (totalCalls > 1) {
				const lastCall = Object.values(this.datos_lineas_activas)[
					totalCalls - 1
				]; // obtiene la ultima llamada
				this.SelectLine(lastCall.lineas_llamadas_activas, { unhold: false });
			} else if (totalCalls == 1) {
				this.SelectLine(
					Object.values(this.datos_lineas_activas)[0].lineas_llamadas_activas,
					{ unhold: false }
				);
				this.ineas_activas_llamadas = false;
				this.closeWebLines();
				this.lienea_uno_activa = true;
				console.log("Entra despues de recibir un bye");
				for (let datos in this.datos_lineas_activas) {
					console.log("Intentando conseguir el numero hablando");
					this.numero_hablando = this.datos_lineas_activas[datos].title;
					console.log("Intentando unholdSession");
					console.log(
						"Linea a conectar",
						this.datos_lineas_activas[datos].lineas_llamadas_activas
					);
					/* 					this.unholdSession(
						this.datos_lineas_activas[datos].lineas_llamadas_activas
					); */
					this.nombre_largo =
						"nombre_largo" in this.datos_lineas_activas[datos]
							? this.datos_lineas_activas[datos].nombre_largo
							: "";
					this.nombre_corto =
						"nombre_corto" in this.datos_lineas_activas[datos]
							? this.datos_lineas_activas[datos].nombre_corto
							: "";
				}
			} else if (Object.keys(this.datos_lineas_activas).length == 0) {
				this.lienea_uno_activa = true;
				this.numero_telefonico = "";
				this.tiempo_llamada = "";
				this.nombre_largo = "";
				this.numero_hablando = "";
				this.nombre_corto = "";
				this.llamadaSaliente = true;
				this.botones_telefono = true;
				this.botones_dtmf = false;
				this.respuesta_automatica = true;
				this.linea_conferencia = false;
				this.desactivar_conferencia = false;
				this.digitar_conferencia = false;
				this.telefono_conferencia = "";
				this.transferencia = false;
				this.nueva_linea = false;
				this.conferencia = false;
				this.cancelar_transferencia = false;
				this.cancelar_nueva_linea = false;
				this.contestar = false;
				this.activarmute = false;
				this.poner_en_espera = false;
				this.quitar_en_espera = false;
				this.desactivarmute = false;
				this.finalizar_llamada = false;
				this.colgar = false;
				this.cancelar_llamada_saliente = false;
				this.datos_llamada = false;
				this.digitar_transferencia = false;
				this.digitar_nueva_linea = false;
				this.digitar_telefono = true;
				this.stopDuration();
				this.Buddies = [];
				this.closeWebLines();
			}
		},
		onInviteProgress(lineObj, response) {
			var lang = this.lang;
			var audioBlobs = this.audioBlobs;
			var global = this;
			console.log("Call Progress:", response.message.statusCode);
			// Provisional 1xx
			// response.message.reasonPhrase
			if (response.message.statusCode == 180) {
				$("#line-" + lineObj.LineNumber + "-msg").html(lang.ringing);
				var soundFile = audioBlobs.EarlyMedia_European;
				if (this.UserLocale().indexOf("us") > -1)
					soundFile = audioBlobs.EarlyMedia_US;
				if (this.UserLocale().indexOf("gb") > -1)
					soundFile = audioBlobs.EarlyMedia_UK;
				if (this.UserLocale().indexOf("au") > -1)
					soundFile = audioBlobs.EarlyMedia_Australia;
				if (this.UserLocale().indexOf("jp") > -1)
					soundFile = audioBlobs.EarlyMedia_Japan;

				// Play Early Media
				//console.log("Audio:", soundFile.url);
				if (lineObj.SipSession.data.earlyMedia) {
					// There is already early media playing
					// onProgress can be called multiple times
					// Dont add it again
					console.log("Early Media already playing");
				} else {
					var earlyMedia = new Audio(soundFile.blob);
					earlyMedia.preload = "auto";
					earlyMedia.loop = true;
					earlyMedia.oncanplaythrough = function (e) {
						if (
							typeof earlyMedia.sinkId !== "undefined" &&
							global.getAudioOutputID() != "default"
						) {
							earlyMedia
								.setSinkId(global.getAudioOutputID())
								.then(function () {
									console.log("Set sinkId to:", global.getAudioOutputID());
								})
								.catch(function (e) {
									console.warn("Failed not apply setSinkId.", e);
								});
						}
						earlyMedia
							.play()
							.then(function () {
								// Audio Is Playing
							})
							.catch(function (e) {
								console.warn("Unable to play audio file.", e);
							});
					};
					lineObj.SipSession.data.earlyMedia = earlyMedia;
				}
			} else if (response.message.statusCode === 183) {
				$("#line-" + lineObj.LineNumber + "-msg").html(
					response.message.reasonPhrase + "..."
				);

				// Add UI to allow DTMF
				$("#line-" + lineObj.LineNumber + "-early-dtmf").show();
			} else {
				// 181 = Call is Being Forwarded
				// 182 = Call is queued (Busy server!)
				// 199 = Call is Terminated (Early Dialog)

				$("#line-" + lineObj.LineNumber + "-msg").html(
					response.message.reasonPhrase + "..."
				);
			}

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("progress", lineObj.SipSession);
		},
		onInviteTrying(lineObj, response) {
			var lang = this.lang;
			$("#line-" + lineObj.LineNumber + "-msg").html(lang.trying);

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("trying", lineObj.SipSession);
		},
		UserLocale() {
			var language = window.navigator.userLanguage || window.navigator.language; // "en", "en-US", "fr", "fr-FR", "es-ES", etc.
			// langtag = language["-"script]["-" region] *("-" variant) *("-" extension) ["-" privateuse]
			// TODO Needs work
			var langtag = language.split("-");
			if (langtag.length == 1) {
				return "";
			} else if (langtag.length == 2) {
				return langtag[1].toLowerCase(); // en-US => us
			} else if (langtag.length >= 3) {
				return langtag[1].toLowerCase(); // en-US => us
			}
		},
		onSessionDescriptionHandlerCreated(
			lineObj,
			sdh,
			provisional,
			includeVideo
		) {
			var global = this;
			if (sdh) {
				if (sdh.peerConnection) {
					// console.log(sdh);
					sdh.peerConnection.ontrack = function (event) {
						global.onTrackAddedEvent(lineObj, includeVideo);
					};
					// sdh.peerConnectionDelegate = {
					//     ontrack: function(event){
					//         console.log(event);
					//         onTrackAddedEvent(lineObj, includeVideo);
					//     }
					// }
				} else {
					console.warn(
						"onSessionDescriptionHandler fired without a peerConnection"
					);
				}
			} else {
				console.warn(
					"onSessionDescriptionHandler fired without a sessionDescriptionHandler"
				);
			}
		},
		getAudioSrcID2() {
			var id = this.localDB.getItem("AudioSrcId");
			return id != null ? id : "default";
		},
		MuteSession(lineNum, loop = 1) {
			this.activarmute = false;
			this.desactivarmute = true;
			var lang = this.lang;
			var lineNum =
				isNaN(lineNum["numero_linea"]) == true
					? this.numero_linea
					: lineNum["numero_linea"];
			console.log("Intentando mutear linea numero: ", lineNum);
			var lineObj = Object.keys(this.datos_lineas_activas).length
				? this.FindLineByNumber(lineNum)
				: this.lineObj;
			if (lineObj == null || lineObj.SipSession == null) return;

			var session = lineObj.SipSession;
			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getSenders().forEach(function (RTCRtpSender) {
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
					if (RTCRtpSender.track.IsMixedTrack == true) {
						if (
							session.data.AudioSourceTrack &&
							session.data.AudioSourceTrack.kind == "audio"
						) {
							console.log(
								"Muting Mixed Audio Track : " +
									session.data.AudioSourceTrack.label
							);
							session.data.AudioSourceTrack.enabled = false;
						}
					}
					RTCRtpSender.track.enabled = false;
				}
			});

			if (!session.data.mute) session.data.mute = [];
			session.data.mute.push({ event: "mute", eventTime: this.utcDateNow() });
			session.data.ismute = true;

			$("#line-" + lineNum + "-msg").html(lang.call_on_mute);

			this.updateLineScroll(lineNum);

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("mute", session);
			console.log("Cumplio con su cometido");
			if (loop === 0) return;
			if (this.conferenciando_multi_linea) {
				this.MuteSession({ numero_linea: this.linea_a_conferenciar }, 0);
			}
		},
		UnmuteSession(lineNum, loop = 1) {
			this.activarmute = true;
			this.desactivarmute = false;
			var lang = this.lang;
			var lineNum =
				isNaN(lineNum["numero_linea"]) == true
					? this.numero_linea
					: lineNum["numero_linea"];
			var lineObj = Object.keys(this.datos_lineas_activas).length
				? this.FindLineByNumber(lineNum)
				: this.lineObj;
			console.log("Intentando desmutear linea numero: ", lineNum, lineObj);
			//var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;

			var session = lineObj.SipSession;
			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getSenders().forEach(function (RTCRtpSender) {
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
					if (RTCRtpSender.track.IsMixedTrack == true) {
						if (
							session.data.AudioSourceTrack &&
							session.data.AudioSourceTrack.kind == "audio"
						) {
							console.log(
								"Unmuting Mixed Audio Track : " +
									session.data.AudioSourceTrack.label
							);
							session.data.AudioSourceTrack.enabled = true;
						}
					}
					console.log("Unmuting Audio Track : " + RTCRtpSender.track.label);
					RTCRtpSender.track.enabled = true;
				}
			});

			if (!session.data.mute) session.data.mute = [];
			session.data.mute.push({ event: "unmute", eventTime: this.utcDateNow() });
			session.data.ismute = false;

			$("#line-" + lineNum + "-msg").html(lang.call_off_mute);

			this.updateLineScroll(lineNum);

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("unmute", session);
			if (loop === 0) return;
			if (this.conferenciando_multi_linea) {
				this.UnmuteSession({ numero_linea: this.linea_a_conferenciar }, 0);
			}
		},
		holdSession(lineNum) {
			var global = this;
			var lang = this.lang;
			const activeButtons =
				typeof lineNum === "object" ? lineNum.active : false;
			var lineNum =
				!!lineNum["numero_linea"] == true ? lineNum["numero_linea"] : lineNum;
			const selectLine = this.Lines?.find(l => l.IsSelected);
			if (selectLine?.LineNumber == lineNum || activeButtons) {
				this.poner_en_espera = false;
				this.quitar_en_espera = true;
			}
			console.log("NUMERO DE LINEA PARA PONER EN HOLD: ", lineNum);
			var lineObj =
				Object.keys(this.datos_lineas_activas).length > 1
					? this.FindLineByNumber(lineNum)
					: this.lineObj;
			// var lineObj = this.lineObj
			//var lineObj = this.FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;
			var session = lineObj.SipSession;
			if (session.isOnHold == true) {
				console.log("Call is is already on hold:", lineNum);
				return;
			}
			console.log("Putting Call on hold:", lineNum);
			session.isOnHold = true;

			var sessionDescriptionHandlerOptions =
				session.sessionDescriptionHandlerOptionsReInvite;
			sessionDescriptionHandlerOptions.hold = true;
			session.sessionDescriptionHandlerOptionsReInvite =
				sessionDescriptionHandlerOptions;

			var options = {
				requestDelegate: {
					onAccept: function () {
						if (
							session &&
							session.sessionDescriptionHandler &&
							session.sessionDescriptionHandler.peerConnection
						) {
							var pc = session.sessionDescriptionHandler.peerConnection;
							// Stop all the inbound streams
							pc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = false;
							});
							// Stop all the outbound streams (especially usefull for Conference Calls!!)
							pc.getSenders().forEach(function (RTCRtpSender) {
								// Mute Audio
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									if (RTCRtpSender.track.IsMixedTrack == true) {
										if (
											session.data.AudioSourceTrack &&
											session.data.AudioSourceTrack.kind == "audio"
										) {
											console.log(
												"Muting Mixed Audio Track : " +
													session.data.AudioSourceTrack.label
											);
											session.data.AudioSourceTrack.enabled = false;
										}
									}
									console.log(
										"Muting Audio Track : " + RTCRtpSender.track.label
									);
									RTCRtpSender.track.enabled = false;
								}
								// Stop Video
								else if (
									RTCRtpSender.track &&
									RTCRtpSender.track.kind == "video"
								) {
									RTCRtpSender.track.enabled = false;
								}
							});
						}
						session.isOnHold = true;
						console.log("Call is is on hold:", lineNum);

						$("#line-" + lineNum + "-btn-Hold").hide();
						$("#line-" + lineNum + "-btn-Unhold").show();
						$("#line-" + lineNum + "-msg").html(lang.call_on_hold);

						// Log Hold
						if (!session.data.hold) session.data.hold = [];
						session.data.hold.push({
							event: "hold",
							eventTime: global.utcDateNow(),
						});
						// Custom Web hook
						if (typeof web_hook_on_modify !== "undefined")
							web_hook_on_modify("hold", session);
					},
					onReject: function () {
						session.isOnHold = false;
						console.warn("Failed to put the call on hold:", lineNum);
					},
				},
			};
			session.invite(options).catch(function (error) {
				session.isOnHold = false;
				console.warn("Error attempting to put the call on hold: ", error);
			});
		},
		unholdSessionMulti(lineNum) {
			var global = this;
			var lang = this.lang;
			var lineNum =
				isNaN(lineNum["numero_linea"]) == true
					? lineNum
					: lineNum["numero_linea"];
			var lineObj =
				Object.keys(this.datos_lineas_activas).length > 1
					? this.FindLineByNumber(lineNum)
					: this.lineObj;
			// var lineObj = this.lineObj
			//  var lineObj = this.FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;
			var session = lineObj.SipSession;
			if (session.isOnHold == false) {
				console.log("Call is already off hold:", lineNum);
				return;
			}
			console.log("Taking call off hold:", lineNum);
			session.isOnHold = false;

			var sessionDescriptionHandlerOptions =
				session.sessionDescriptionHandlerOptionsReInvite;
			sessionDescriptionHandlerOptions.hold = false;
			session.sessionDescriptionHandlerOptionsReInvite =
				sessionDescriptionHandlerOptions;
			console.log("antes de las opciones");
			var options = {
				requestDelegate: {
					onAccept: function () {
						console.log("onAccept");

						if (
							session &&
							session.sessionDescriptionHandler &&
							session.sessionDescriptionHandler.peerConnection
						) {
							console.log("Intenta poner llamada en unhold:");
							var pc = session.sessionDescriptionHandler.peerConnection;

							// Restore all the inbound streams
							pc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = true;
							});

							// Restorte all the outbound streams
							pc.getSenders().forEach(function (RTCRtpSender) {
								// Unmute Audio
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									if (RTCRtpSender.track.IsMixedTrack == true) {
										if (
											session.data.AudioSourceTrack &&
											session.data.AudioSourceTrack.kind == "audio"
										) {
											console.log(
												"Unmuting Mixed Audio Track : " +
													session.data.AudioSourceTrack.label
											);
											session.data.AudioSourceTrack.enabled = true;
										}
									}
									console.log(
										"Unmuting Audio Track : " + RTCRtpSender.track.label
									);
									RTCRtpSender.track.enabled = true;
								} else if (
									RTCRtpSender.track &&
									RTCRtpSender.track.kind == "video"
								) {
									RTCRtpSender.track.enabled = true;
								}
							});
						}
						console.log("Call is off hold:", lineNum);
						session.isOnHold = false;
						console.log("Call is off hold:", lineNum);

						$("#line-" + lineNum + "-btn-Hold").show();
						$("#line-" + lineNum + "-btn-Unhold").hide();
						$("#line-" + lineNum + "-msg").html(lang.call_in_progress);

						// Log Hold
						if (!session.data.hold) session.data.hold = [];
						session.data.hold.push({
							event: "unhold",
							eventTime: global.utcDateNow(),
						});

						global.updateLineScroll(lineNum);

						// Custom Web hook
						if (typeof web_hook_on_modify !== "undefined")
							web_hook_on_modify("unhold", session);
					},
					onReject: function () {
						console.warn("Failed to put the call on hold", lineNum);
						session.isOnHold = true;
						console.warn("Failed to put the call on hold", lineNum);
					},
				},
			};
			console.log("despues de las opciones");
			session.invite().catch(function (error) {
				session.isOnHold = true;
				console.warn("Error attempting to take to call off hold", error);
			});
		},
		unholdSession(lineNum) {
			this.poner_en_espera = true;
			this.quitar_en_espera = false;
			var global = this;
			var lang = this.lang;
			var lineNum =
				isNaN(lineNum["numero_linea"]) == true
					? lineNum
					: lineNum["numero_linea"];

			var lineObj =
				Object.keys(this.datos_lineas_activas).length > 1
					? this.FindLineByNumber(lineNum)
					: this.lineObj;
			console.log("lines active: ", this.lineObj, this.datos_lineas_activas);
			// var lineObj = this.lineObj
			//  var lineObj = this.FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;

			var session = lineObj.SipSession;

			if (session.isOnHold == false) {
				console.log("Call is already off hold:", lineNum);
				return;
			}
			console.log("Taking call off hold:", lineNum);
			session.isOnHold = false;
			session.data.ismute = false;

			var sessionDescriptionHandlerOptions =
				session.sessionDescriptionHandlerOptionsReInvite;

			sessionDescriptionHandlerOptions.hold = false;
			session.sessionDescriptionHandlerOptionsReInvite =
				sessionDescriptionHandlerOptions;
			console.log("antes de las opciones");
			var options = {
				requestDelegate: {
					onAccept: function () {
						console.log("onAccept");

						if (
							session &&
							session.sessionDescriptionHandler &&
							session.sessionDescriptionHandler.peerConnection
						) {
							console.log("Intenta poner llamada en unhold:");
							var pc = session.sessionDescriptionHandler.peerConnection;

							// Restore all the inbound streams
							pc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = true;
							});

							// Restorte all the outbound streams
							pc.getSenders().forEach(function (RTCRtpSender) {
								// Unmute Audio
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									if (RTCRtpSender.track.IsMixedTrack == true) {
										if (
											session.data.AudioSourceTrack &&
											session.data.AudioSourceTrack.kind == "audio"
										) {
											console.log(
												"Unmuting Mixed Audio Track : " +
													session.data.AudioSourceTrack.label
											);
											session.data.AudioSourceTrack.enabled = true;
										}
									}
									console.log(
										"Unmuting Audio Track : " + RTCRtpSender.track.label
									);
									RTCRtpSender.track.enabled = true;
								} else if (
									RTCRtpSender.track &&
									RTCRtpSender.track.kind == "video"
								) {
									RTCRtpSender.track.enabled = true;
								}
							});
						}
						console.log("Call is off hold:", lineNum);
						session.isOnHold = false;
						console.log("Call is off hold:", lineNum);

						$("#line-" + lineNum + "-btn-Hold").show();
						$("#line-" + lineNum + "-btn-Unhold").hide();
						$("#line-" + lineNum + "-msg").html(lang.call_in_progress);

						// Log Hold
						if (!session.data.hold) session.data.hold = [];
						session.data.hold.push({
							event: "unhold",
							eventTime: global.utcDateNow(),
						});

						global.updateLineScroll(lineNum);

						// Custom Web hook
						if (typeof web_hook_on_modify !== "undefined")
							web_hook_on_modify("unhold", session);
					},
					onReject: function () {
						console.warn("Failed to put the call on hold", lineNum);
						session.isOnHold = true;
						console.warn("Failed to put the call on hold", lineNum);
					},
				},
			};
			console.log("despues de las opciones", session);
			session.invite(options).catch(function (error) {
				session.isOnHold = true;
				console.warn("Error attempting to take to call off hold", error);
			});
		},
		StartTransferSession(lineNum) {
			this.botones_telefono = true;
			this.botones_dtmf = false;
			this.digitar_conferencia = false;
			this.digitar_nueva_linea = false;
			this.opciones_nueva_linea = false;
			this.transferencia = false;
			this.nueva_linea = true;
			this.desactivar_conferencia = false;
			this.cancelar_nueva_linea = false;
			this.conferencia = true;
			this.cancelar_transferencia = true;
			$(".collapse").removeClass("show");
			if (this.conferenciando_multi_linea) {
				console.log("entra conferenciando multi linea");
				this.CancelConference(lineNum);
			}
			let totalEstablished = 0;
			$.each(this.userAgent.sessions, function (i, session) {
				// All the other calls, not on hold
				if (session.state == SIP.SessionState.Established) totalEstablished++;
			});
			console.log("Colocando Line Num: ", lineNum, totalEstablished);
			if (totalEstablished > 1) {
				this.transfiriendo_multi_linea = true;
				this.datos_llamada = false;
				this.linea_a_transferir = -1;
				return;
			}
			this.digitar_transferencia = true;
			this.opciones_transferencia = true;
			this.lienea_uno_activa = true;

			var lineNum = lineNum["numero_linea"];
			if ($("#line-" + lineNum + "-btn-CancelConference").is(":visible")) {
				this.CancelConference(lineNum);
				return;
			}

			$("#line-" + lineNum + "-btn-Transfer").hide();
			$("#line-" + lineNum + "-btn-CancelTransfer").show();

			this.holdSession(lineNum);
			$("#line-" + lineNum + "-txt-FindTransferBuddy").val("");
			$("#line-" + lineNum + "-txt-FindTransferBuddy")
				.parent()
				.show();

			$("#line-" + lineNum + "-btn-blind-transfer").show();
			$("#line-" + lineNum + "-btn-attended-transfer").show();
			$("#line-" + lineNum + "-btn-complete-transfer").hide();
			$("#line-" + lineNum + "-btn-cancel-transfer").hide();

			$("#line-" + lineNum + "-btn-complete-attended-transfer").hide();
			$("#line-" + lineNum + "-btn-cancel-attended-transfer").hide();
			$("#line-" + lineNum + "-btn-terminate-attended-transfer").hide();

			$("#line-" + lineNum + "-transfer-status").hide();

			$("#line-" + lineNum + "-Transfer").show();

			this.updateLineScroll(lineNum);
		},
		StartNewLineSession(lineNum) {
			this.botones_telefono = true;
			this.botones_dtmf = false;
			this.digitar_transferencia = false;
			this.digitar_nueva_linea = true;
			this.digitar_conferencia = false;
			this.opciones_transferencia = false;
			this.opciones_nueva_linea = true;
			this.transferencia = true;
			this.nueva_linea = false;
			this.cancelar_nueva_linea = true;
			this.desactivar_conferencia = false;
			this.cancelar_transferencia = false;
			this.conferencia = true;
			if (this.transfiriendo_multi_linea) {
				console.log("entra transfiriendo multilinea");
				this.CancelTransferSession(lineNum);
			}
			if (this.conferenciando_multi_linea) {
				console.log("entra conferenciando multi linea");
				this.CancelConference(lineNum);
			}
			var lineNum = lineNum["numero_linea"];
			this.holdSession(lineNum);
			this.updateLineScroll(lineNum);
			this.telefono_nueva_linea = "";
		},
		CancelTransferSession(lineNum) {
			this.telefono_tranferencia = "";
			this.transferencia = true;
			this.digitar_transferencia = false;
			this.cancelar_transferencia = false;
			this.transfiriendo_multi_linea = false;
			this.datos_llamada = true;
			this.linea_a_transferir = -1;
			this.conferenciando_multi_linea = false;
			this.en_conferencia = false;
			this.linea_a_conferenciar = -1;
			var lineNum = lineNum["numero_linea"];
			var lineObj = this.lineObj;
			//var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Null line or session");
				return;
			}
			var session = lineObj.SipSession;
			if (session.data.childsession) {
				console.log(
					"Child Transfer call detected:",
					session.data.childsession.state
				);
				session.data.childsession
					.dispose()
					.then(function () {
						session.data.childsession = null;
					})
					.catch(function (error) {
						session.data.childsession = null;
						// Supress message
					});
			}

			$("#line-" + lineNum + "-btn-Transfer").show();
			$("#line-" + lineNum + "-btn-CancelTransfer").hide();

			this.unholdSession(lineNum);
			$("#line-" + lineNum + "-Transfer").hide();

			this.updateLineScroll(lineNum);
		},
		CancelNewLine(lineNum) {
			this.telefono_nueva_linea = "";
			this.nueva_linea = true;
			this.digitar_nueva_linea = false;
			this.cancelar_nueva_linea = false;
			var lineNum = lineNum["numero_linea"];
			var lineObj = this.lineObj;
			//var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Null line or session");
				return;
			}
			var session = lineObj.SipSession;
			if (session.data.childsession) {
				console.log(
					"Child new call detected:",
					session.data.childsession.state
				);
				session.data.childsession
					.dispose()
					.then(function () {
						session.data.childsession = null;
					})
					.catch(function (error) {
						session.data.childsession = null;
						// Supress message
					});
			}

			this.unholdSession(lineNum);

			this.updateLineScroll(lineNum);
		},
		BlindTransfer(lineNum) {
			var lineNum = lineNum["numero_linea"];
			var dstNo = this.telefono_tranferencia;
			let wssServer = env.asteriskSocketIP;
			let lang = this.lang;
			var global = this;
			var lineObj =
				Object.keys(this.datos_lineas_activas).length > 1
					? this.FindLineByNumber(lineNum)
					: this.lineObj;
			this.endCall();
			// var dstNo = $("#line-"+ lineNum +"-txt-FindTransferBuddy").val().replace(/[^0-9\*\#\+]/g,'');
			if (dstNo == "") {
				console.warn("Cannot transfer, must be [0-9*+#]");
				return;
			}
			//var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Null line or session");
				return;
			}
			var session = lineObj.SipSession;

			if (!session.data.transfer) session.data.transfer = [];
			session.data.transfer.push({
				type: "Blind",
				to: dstNo,
				transferTime: this.utcDateNow(),
				disposition: "refer",
				dispositionTime: this.utcDateNow(),
				accept: {
					complete: null,
					eventTime: null,
					disposition: "",
				},
			});
			var transferid = session.data.transfer.length - 1;

			var transferOptions = {
				requestDelegate: {
					onAccept: function (sip) {
						console.log("Blind transfer Accepted");

						session.data.terminateby = "us";
						session.data.reasonCode = 202;
						session.data.reasonText = "Transfer";

						session.data.transfer[transferid].accept.complete = true;
						session.data.transfer[transferid].accept.disposition =
							sip.message.reasonPhrase;
						session.data.transfer[transferid].accept.eventTime =
							global.utcDateNow();

						// TODO: use lang pack
						$("#line-" + lineNum + "-msg").html(
							"Call Blind Transfered (Accepted)"
						);

						global.updateLineScroll(lineNum);

						session.bye().catch(function (error) {
							console.warn("Could not BYE after blind transfer:", error);
						});
						global.teardownSession(lineObj);
						global.drawer = false;
						global.telefono_tranferencia = "";
						global.contestar = false;
						global.activarmute = false;
						global.desactivarmute = false;
						global.transferencia = false;
						global.nueva_linea = false;
						global.cancelar_transferencia = false;
						global.cancelar_nueva_linea = false;
						global.conferencia = false;
						global.poner_en_espera = false;
						global.quitar_en_espera = false;
						global.finalizar_llamada = false;
						global.cancelar_llamada_saliente = false;
						global.colgar = false;
						global.digitar_transferencia = false;
						global.digitar_nueva_linea = false;
						global.llamadaSaliente = true;
						global.botones_telefono = true;
						global.botones_dtmf = false;
						global.respuesta_automatica = true;
						global.linea_conferencia = false;
						global.desactivar_conferencia = false;
						global.desactivar_nueva_linea = false;
						global.digitar_conferencia = false;
						global.telefono_conferencia = "";
						global.numero_telefonico = "";
						global.numero_hablando = "";
						global.nombre_corto = "";
						global.nombre_largo = "";
						global.tiempo_llamada = "";
						global.telefono_nueva_linea = "";
						global.datos_llamada = false;
						global.digitar_telefono = true;
						global.opciones_transferencia = false;
						global.opciones_nueva_linea = false;
						global.endCall();
						console.log(
							"blind transfer accepted, total lines: ",
							global.datos_lineas_activas
						);
						if (Object.keys(global.datos_lineas_activas).length === 0) {
							global.Lines = [];
							global.lineObj;
						}
					},
					onReject: function (sip) {
						console.warn("REFER rejected:", sip);

						session.data.transfer[transferid].accept.complete = false;
						session.data.transfer[transferid].accept.disposition =
							sip.message.reasonPhrase;
						session.data.transfer[transferid].accept.eventTime = utcDateNow();

						$("#line-" + lineNum + "-msg").html("Call Blind Failed!");

						global.updateLineScroll(lineNum);

						// Session should still be up, so just allow them to try again
					},
				},
			};
			console.log("REFER: ", dstNo + "@" + wssServer);
			var referTo = SIP.UserAgent.makeURI("sip:" + dstNo + "@" + wssServer);
			session.refer(referTo, transferOptions).catch(function (error) {
				console.warn("Failed to REFER", error);
			});

			$("#line-" + lineNum + "-msg").html(lang.call_blind_transfered);
			global.updateLineScroll(lineNum);
		},
		AttendedTransfer(lineNum) {
			var dstNo = this.telefono_tranferencia;
			if (!dstNo) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Por favor ingrese un numero de destino",
					type: "error",
				});
				return;
			}
			this.opciones_transferencia = false;
			this.tranferencia_cancelada = true;
			var lineNum = lineNum["numero_linea"];
			let wssServer = env.asteriskSocketIP;
			let lang = this.lang;
			let HasAudioDevice = true;
			var userAgent = this.userAgent;
			let AutoGainControl = 1;
			let EchoCancellation = 1;
			let NoiseSuppression = 1;
			var global = this;
			var lineObj = this.lineObj;
			var html = "";
			html += "<div class='crear-audio'>";
			html +=
				'<audio id="line-' +
				lineNum +
				'-transfer-remoteAudio" style="display:none"></audio>';
			html += "</div>";
			$("#formagentemain").append(html);
			//var dstNo = $("#line-"+ lineNum +"-txt-FindTransferBuddy").val().replace(/[^0-9\*\#\+]/g,'');
			if (dstNo == "") {
				console.warn("Cannot transfer, must be [0-9*+#]");
				return;
			}

			// var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Null line or session");
				return;
			}
			var session = lineObj.SipSession;

			this.HidePopup();

			$("#line-" + lineNum + "-txt-FindTransferBuddy")
				.parent()
				.hide();
			$("#line-" + lineNum + "-btn-blind-transfer").hide();
			$("#line-" + lineNum + "-btn-attended-transfer").hide();

			$("#line-" + lineNum + "-btn-complete-attended-transfer").hide();
			$("#line-" + lineNum + "-btn-cancel-attended-transfer").hide();
			$("#line-" + lineNum + "-btn-terminate-attended-transfer").hide();

			var newCallStatus = $("#line-" + lineNum + "-transfer-status");
			newCallStatus.html(lang.connecting);
			newCallStatus.show();

			if (!session.data.transfer) session.data.transfer = [];
			session.data.transfer.push({
				type: "Attended",
				to: dstNo,
				transferTime: this.utcDateNow(),
				disposition: "invite",
				dispositionTime: this.utcDateNow(),
				accept: {
					complete: null,
					eventTime: null,
					disposition: "",
				},
			});
			var transferid = session.data.transfer.length - 1;

			this.updateLineScroll(lineNum);

			// SDP options
			var supportedConstraints =
				navigator.mediaDevices.getSupportedConstraints();
			var spdOptions = {
				earlyMedia: true,
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: { deviceId: "default" },
						video: false,
					},
				},
			};
			if (session.data.AudioSourceDevice != "default") {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.deviceId =
					{ exact: session.data.AudioSourceDevice };
			}
			// Add additional Constraints
			if (supportedConstraints.autoGainControl) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl =
					AutoGainControl;
			}
			if (supportedConstraints.echoCancellation) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation =
					EchoCancellation;
			}
			if (supportedConstraints.noiseSuppression) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression =
					NoiseSuppression;
			}

			// Not sure if its possible to transfer a Video call???
			if (session.data.withvideo) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.video = true;
				if (session.data.VideoSourceDevice != "default") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.deviceId =
						{ exact: session.data.VideoSourceDevice };
				}
				// Add additional Constraints
				if (supportedConstraints.frameRate && maxFrameRate != "") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.frameRate =
						maxFrameRate;
				}
				if (supportedConstraints.height && videoHeight != "") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.height =
						videoHeight;
				}
				if (supportedConstraints.aspectRatio && videoAspectRatio != "") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.aspectRatio =
						videoAspectRatio;
				}
			}

			// Create new call session
			console.log("TRANSFER INVITE: ", "sip:" + dstNo + "@" + wssServer);
			var targetURI = SIP.UserAgent.makeURI("sip:" + dstNo + "@" + wssServer);
			console.log("TRANSFER INVITE:2 ", targetURI);

			var newSession = new SIP.Inviter(userAgent, targetURI, spdOptions);
			newSession.data = {};
			newSession.delegate = {
				onBye: function (sip) {
					console.log("New call session ended with BYE");
					global.telefono_tranferencia = "";
					global.tranferencia_atendida = false;
					global.opciones_transferencia = true;
					newCallStatus.html(lang.call_ended);
					session.data.transfer[transferid].disposition = "bye";
					session.data.transfer[transferid].dispositionTime =
						global.utcDateNow();

					$("#line-" + lineNum + "-txt-FindTransferBuddy")
						.parent()
						.show();
					$("#line-" + lineNum + "-btn-blind-transfer").show();
					$("#line-" + lineNum + "-btn-attended-transfer").show();

					$("#line-" + lineNum + "-btn-complete-attended-transfer").hide();
					$("#line-" + lineNum + "-btn-cancel-attended-transfer").hide();
					$("#line-" + lineNum + "-btn-terminate-attended-transfer").hide();

					$("#line-" + lineNum + "-msg").html(
						lang.attended_transfer_call_terminated
					);

					global.updateLineScroll(lineNum);

					window.setTimeout(function () {
						newCallStatus.hide();
						global.updateLineScroll(lineNum);
					}, 1000);
				},
				onSessionDescriptionHandler: function (sdh, provisional) {
					if (sdh) {
						if (sdh.peerConnection) {
							sdh.peerConnection.ontrack = function (event) {
								var pc = sdh.peerConnection;

								// Gets Remote Audio Track (Local audio is setup via initial GUM)
								var remoteStream = new MediaStream();
								pc.getReceivers().forEach(function (receiver) {
									if (receiver.track && receiver.track.kind == "audio") {
										remoteStream.addTrack(receiver.track);
									}
								});
								var remoteAudio = $(
									"#line-" + lineNum + "-transfer-remoteAudio"
								).get(0);
								console.log("EL REMOTE STREAM MAN", remoteAudio);
								// remoteAudio = {'srcObject':''}
								remoteAudio.srcObject = remoteStream;

								remoteAudio.onloadedmetadata = function (e) {
									if (typeof remoteAudio.sinkId !== "undefined") {
										remoteAudio
											.setSinkId(session.data.AudioOutputDevice)
											.then(function () {
												console.log(
													"sinkId applied: " + session.data.AudioOutputDevice
												);
											})
											.catch(function (e) {
												console.warn("Error using setSinkId: ", e);
											});
									}
									remoteAudio.play();
								};
							};
						} else {
							console.warn(
								"onSessionDescriptionHandler fired without a peerConnection"
							);
						}
					} else {
						console.warn(
							"onSessionDescriptionHandler fired without a sessionDescriptionHandler"
						);
					}
				},
			};
			session.data.childsession = newSession;
			var inviterOptions = {
				requestDelegate: {
					onTrying: function (sip) {
						newCallStatus.html(lang.trying);
						session.data.transfer[transferid].disposition = "trying";
						session.data.transfer[transferid].dispositionTime =
							global.utcDateNow();

						$("#line-" + lineNum + "-msg").html(
							lang.attended_transfer_call_started
						);
					},
					onProgress: function (sip) {
						newCallStatus.html(lang.ringing);
						session.data.transfer[transferid].disposition = "progress";
						session.data.transfer[transferid].dispositionTime =
							global.utcDateNow();

						$("#line-" + lineNum + "-msg").html(
							lang.attended_transfer_call_started
						);

						// var CancelAttendedTransferBtn = this.cancelar_transferencia
						var CancelAttendedTransferBtn = $("#cancelar_transferencia");
						console.log("CANCELAR TRANSFERENCIA", CancelAttendedTransferBtn);
						CancelAttendedTransferBtn.off("click");
						CancelAttendedTransferBtn.on("click", function () {
							newSession.cancel().catch(function (error) {
								console.warn("Failed to CANCEL", error);
							});
							newCallStatus.html(lang.call_cancelled);
							console.log("New call session canceled");
							global.opciones_transferencia = true;
							global.tranferencia_cancelada = false;
							session.data.transfer[transferid].accept.complete = false;
							session.data.transfer[transferid].accept.disposition = "cancel";
							session.data.transfer[transferid].accept.eventTime =
								global.utcDateNow();

							$("#line-" + lineNum + "-msg").html(
								lang.attended_transfer_call_cancelled
							);

							global.updateLineScroll(lineNum);
						});
						CancelAttendedTransferBtn.show();

						global.updateLineScroll(lineNum);
					},
					onRedirect: function (sip) {
						console.log("Redirect received:", sip);
					},
					onAccept: function (sip) {
						global.tranferencia_atendida = true;
						global.tranferencia_cancelada = false;
						newCallStatus.html(lang.call_in_progress);
						$("#line-" + lineNum + "-btn-cancel-attended-transfer").hide();
						session.data.transfer[transferid].disposition = "accepted";
						session.data.transfer[transferid].dispositionTime =
							global.utcDateNow();

						var CompleteTransferBtn = $("#realizar_transferencia");
						// var CompleteTransferBtn = $("#line-"+ lineNum +"-btn-complete-attended-transfer");
						CompleteTransferBtn.off("click");
						CompleteTransferBtn.on("click", function () {
							var transferOptions = {
								requestDelegate: {
									onAccept: function (sip) {
										global.drawer = false;
										global.telefono_tranferencia = "";
										global.datos_llamada = false;
										global.digitar_transferencia = false;
										global.tiempo_llamada = "";
										global.numero_telefonico = "";
										global.activarmute = false;
										global.desactivarmute = false;
										global.poner_en_espera = false;
										global.quitar_en_espera = false;
										global.transferencia = false;
										global.cancelar_transferencia = false;
										global.conferencia = false;
										global.llamadaSaliente = true;
										global.botones_telefono = true;
										global.botones_dtmf = false;
										global.respuesta_automatica = true;
										global.linea_conferencia = false;
										global.desactivar_conferencia = false;
										global.digitar_conferencia = false;
										global.telefono_conferencia = "";
										global.contestar = false;
										global.colgar = false;
										global.cancelar_llamada_saliente = false;
										global.finalizar_llamada = false;
										global.digitar_telefono = true;
										global.numero_hablando = "";
										global.numero_telefonico = "";
										global.nueva_linea = false;
										global.nombre_corto = ""

										session.data.terminateby = "us";
										session.data.reasonCode = 202;
										session.data.reasonText = "Attended Transfer";

										session.data.transfer[transferid].accept.complete = true;
										session.data.transfer[transferid].accept.disposition =
											sip.message.reasonPhrase;
										session.data.transfer[transferid].accept.eventTime =
											global.utcDateNow();

										$("#line-" + lineNum + "-msg").html(
											lang.attended_transfer_complete_accepted
										);

										global.updateLineScroll(lineNum);

										// We must end this session manually
										session.bye().catch(function (error) {
											console.warn(
												"Could not BYE after blind transfer:",
												error
											);
										});
										global.tranferencia_atendida = false;
										global.opciones_transferencia = true;
										global.teardownSession(lineObj);
										console.log(
											"Attended transfer Accepted",
											Object.keys(global.datos_lineas_activas).length
										);
										if (Object.keys(global.datos_lineas_activas).length === 0) {
											global.Lines = [];
											global.lineObj = [];
										}
									},
									onReject: function (sip) {
										console.warn("Attended transfer rejected:", sip);

										session.data.transfer[transferid].accept.complete = false;
										session.data.transfer[transferid].accept.disposition =
											sip.message.reasonPhrase;
										session.data.transfer[transferid].accept.eventTime =
											utcDateNow();

										$("#line-" + lineNum + "-msg").html(
											"Attended Transfer Failed!"
										);
										global.tranferencia_atendida = false;
										global.opciones_transferencia = true;
										global.updateLineScroll(lineNum);
									},
								},
							};

							// Send REFER
							session
								.refer(newSession, transferOptions)
								.catch(function (error) {
									console.warn("Failed to REFER", error);
								});

							newCallStatus.html(lang.attended_transfer_complete);

							global.updateLineScroll(lineNum);
						});
						CompleteTransferBtn.show();

						global.updateLineScroll(lineNum);

						// var TerminateAttendedTransferBtn = $("#line-"+ lineNum +"-btn-terminate-attended-transfer");
						var TerminateAttendedTransferBtn = $("#colgar_transferencia");
						TerminateAttendedTransferBtn.off("click");
						TerminateAttendedTransferBtn.on("click", function () {
							newSession.bye().catch(function (error) {
								console.warn("Failed to BYE", error);
							});
							newCallStatus.html(lang.call_ended);
							console.log("New call session end");
							global.telefono_tranferencia = "";
							session.data.transfer[transferid].accept.complete = false;
							session.data.transfer[transferid].accept.disposition = "bye";
							session.data.transfer[transferid].accept.eventTime =
								global.utcDateNow();

							$("#line-" + lineNum + "-btn-complete-attended-transfer").hide();
							$("#line-" + lineNum + "-btn-cancel-attended-transfer").hide();
							$("#line-" + lineNum + "-btn-terminate-attended-transfer").hide();

							$("#line-" + lineNum + "-msg").html(
								lang.attended_transfer_call_ended
							);

							global.updateLineScroll(lineNum);
							global.tranferencia_atendida = false;
							global.opciones_transferencia = true;
							window.setTimeout(function () {
								newCallStatus.hide();
								//CancelTransferSession(lineNum);
								global.updateLineScroll(lineNum);
							}, 1000);
						});
						TerminateAttendedTransferBtn.show();

						global.updateLineScroll(lineNum);
					},
					onReject: function (sip) {
						console.log(
							"New call session rejected: ",
							sip.message.reasonPhrase
						);
						global.telefono_tranferencia = "";
						global.tranferencia_cancelada = false;
						global.opciones_transferencia = true;
						newCallStatus.html(lang.call_rejected);
						session.data.transfer[transferid].disposition =
							sip.message.reasonPhrase;
						session.data.transfer[transferid].dispositionTime =
							global.utcDateNow();

						AlertModule.SOCKET_PUSH_NOTIFICATION({
							text: "El número que quería transferir rechazó la llamada!",
							type: "warning",
						});
						$("#line-" + lineNum + "-txt-FindTransferBuddy")
							.parent()
							.show();
						$("#line-" + lineNum + "-btn-blind-transfer").show();
						$("#line-" + lineNum + "-btn-attended-transfer").show();

						$("#line-" + lineNum + "-btn-complete-attended-transfer").hide();
						$("#line-" + lineNum + "-btn-cancel-attended-transfer").hide();
						$("#line-" + lineNum + "-btn-terminate-attended-transfer").hide();

						$("#line-" + lineNum + "-msg").html(
							lang.attended_transfer_call_rejected
						);

						global.updateLineScroll(lineNum);

						window.setTimeout(function () {
							newCallStatus.hide();
							global.updateLineScroll(lineNum);
						}, 1000);
					},
				},
			};
			newSession.invite(inviterOptions).catch(function (e) {
				console.warn("Failed to send INVITE:", e);
			});
		},
		BlindTransferMultiline(lineNum) {
			var lineNum = lineNum["numero_linea"];
			let wssServer = env.asteriskSocketIP;
			let lang = this.lang;
			var global = this;
			//Obteniendo linea , primera session
			let lineObj = this.getLineSipSession(lineNum);
			if (Boolean(lineObj) == false) return;
			let session = lineObj.SipSession;
			//Obteniendo segunda linea, segunda session
			let lineObjTo = this.getLineSipSession(this.linea_a_transferir);
			if (Boolean(lineObjTo) == false) return;
			let sessionTo = lineObjTo.SipSession;

			let dstNo = lineObjTo.DisplayNumber;
			if (!session.data.transfer) session.data.transfer = [];
			session.data.transfer.push({
				type: "Blind",
				to: lineObjTo.DisplayNumber,
				transferTime: this.utcDateNow(),
				disposition: "refer",
				dispositionTime: this.utcDateNow(),
				accept: {
					complete: null,
					eventTime: null,
					disposition: "",
				},
			});
			var transferid = session.data.transfer.length - 1;

			var transferOptions = {
				requestDelegate: {
					onAccept: function (sip) {
						console.log("Blind transfer Accepted");

						session.data.terminateby = "us";
						session.data.reasonCode = 202;
						session.data.reasonText = "Transfer";

						session.data.transfer[transferid].accept.complete = true;
						session.data.transfer[transferid].accept.disposition =
							sip.message.reasonPhrase;
						session.data.transfer[transferid].accept.eventTime =
							global.utcDateNow();
						//global.updateLineScroll(lineNum);

						session.bye().catch(function (error) {
							console.warn("Could not BYE after blind transfer:", error);
						});
						//global.teardownSession(lineObj);
						global.drawer = false;
						global.telefono_tranferencia = "";
						global.contestar = false;
						global.desactivarmute = false;
						global.cancelar_transferencia = false;
						global.cancelar_nueva_linea = false;
						global.quitar_en_espera = false;
						global.cancelar_llamada_saliente = false;
						global.colgar = false;
						global.digitar_transferencia = false;
						global.digitar_nueva_linea = false;
						global.botones_telefono = true;
						global.botones_dtmf = false;
						global.linea_conferencia = false;
						global.desactivar_conferencia = false;
						global.desactivar_nueva_linea = false;
						global.digitar_conferencia = false;
						global.telefono_conferencia = "";
						global.numero_telefonico = "";
						global.numero_hablando = "";
						global.nombre_corto = "";
						global.nombre_largo = "";
						global.tiempo_llamada = "";
						global.telefono_nueva_linea = "";
						global.opciones_transferencia = false;
						global.opciones_nueva_linea = false;
						global.transfiriendo_multi_linea = false;
						global.linea_a_transferir = -1;
						if (Object.keys(global.datos_lineas_activas).length > 0) {
							global.activarmute = true;
							global.transferencia = true;
							global.nueva_linea = true;
							global.conferencia = true;
							global.poner_en_espera = true;
							global.finalizar_llamada = true;
							global.respuesta_automatica = false;
							global.digitar_telefono = false;
							global.datos_llamada = true;
							global.llamadaSaliente = false;
						} else {
							global.activarmute = false;
							global.transferencia = false;
							global.nueva_linea = false;
							global.conferencia = false;
							global.poner_en_espera = false;
							global.finalizar_llamada = false;
							global.respuesta_automatica = true;
							global.digitar_telefono = true;
							global.datos_llamada = false;
							global.llamadaSaliente = true;
							global.Buddies = [];
							global.lineas_activas_llamadas = false;
							global.closeWebLines();
						}
						global.endCall();
					},
					onReject: function (sip) {
						console.warn("REFER rejected:", sip);

						session.data.transfer[transferid].accept.complete = false;
						session.data.transfer[transferid].accept.disposition =
							sip.message.reasonPhrase;
						session.data.transfer[transferid].accept.eventTime = utcDateNow();

						$("#line-" + lineNum + "-msg").html("Call Blind Failed!");

						global.updateLineScroll(lineNum);

						// Session should still be up, so just allow them to try again
					},
				},
			};
			console.log("REFER: ", dstNo + "@" + wssServer);
			// var referTo = SIP.UserAgent.makeURI("sip:" + dstNo + "@" + wssServer);
			// session.refer(referTo, transferOptions).catch(function (error) {
			// 	console.warn("Failed to REFER", error);
			// });
			session.refer(sessionTo, transferOptions).catch(function (error) {
				console.warn("Failed to REFER", error);
			});

			$("#line-" + lineNum + "-msg").html(lang.call_blind_transfered);
			//global.updateLineScroll(lineNum);
			delete global.datos_lineas_activas[lineObj.LineNumber];
			delete global.datos_lineas_activas[lineObjTo.LineNumber];

			if (Object.keys(global.datos_lineas_activas).length > 0) {
				let _lineNumber = lineObj.LineNumber;
				let _lineToNumber = lineObjTo.LineNumber;
				global.Lines = global.Lines.filter(function (data, index, arr) {
					return (
						data.LineNumber != _lineNumber && data.LineNumber != _lineToNumber
					);
				});

				global.lineObj = global.Lines.slice(-1)[0];
				global.SelectLine(global.lineObj.LineNumber);
			} else {
				this.Buddies = [];
				$(".crear-audio").remove();
				global.endCall();
				this.Lines = [];

				this.lineObj = [];
			}
		},
		searchInObjectNumber(number) {
			for (const key in this.datos_lineas_activas) {
				if (this.datos_lineas_activas[key].title == number) {
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: "No se puede llamar a ese número, ya se esta contactando.",
						type: "error",
					});
					return true;
				}
			}
			return false;
		},
		async DialMultiLine(type, numToDial, buddy, CallerID, extraHeaders) {
			if (this.searchInObjectNumber(numToDial)) return;
			if (numToDial) this.telefono_nueva_linea = numToDial;
			console.log("DialMultiLine");
			if (!this.telefono_nueva_linea) return;
			this.numero_telefonico =
				!!this.telefono_nueva_linea == true ? this.telefono_nueva_linea : false;
			type = "audio";
			//Validar se intenta llamar a si mismo.
			if (AuthModule.extension == this.telefono_nueva_linea) {
				this.telefono_nueva_linea = "";
				return;
			}
			let re = new RegExp("^[2|6|7][0-9]{7}|[0-9]{3,4}|(/*[0-9]{2,3})$");
			// ^[2|6|7][0-9]{7}|\*[0-9]{2,3}|[0-9]{3,5}$
			if (
				!re.test(this.telefono_nueva_linea) ||
				AuthModule.extension == this.telefono_nueva_linea
			) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Número de teléfono inválido" + this.telefono_nueva_linea,
					type: "error",
				});
				this.telefono_nueva_linea = "";

				return;
			}
			this.llamada_entrante = 0;
			this.botones_telefono = true;
			this.poner_en_espera = false;
			this.quitar_en_espera = false;
			this.botones_dtmf = true;
			this.numero_hablando =
				!!this.telefono_nueva_linea == false ? "" : this.telefono_nueva_linea;

			const names = await this.getNames(this.numero_hablando);
			if (Object.keys(names).length) {
				this.nombre_largo = names.name + " " + names.lastname;
			} else {
				this.nombre_largo = "Desconocido";
				this.nombre_corto = this.numero_hablando;
			}
			this.datos_llamada = true;
			this.digitar_nueva_linea = false;
			this.realizo_llamada_saliente = 1;
			let Lines = this.Lines;
			let DidLength = 6;
			this.contestar = false;
			var numDial = numToDial ? numToDial : this.numero_telefonico;
			// Create a Buddy if one is not already existing
			var buddyObj = buddy
				? FindBuddyByIdentity(buddy)
				: this.FindBuddyByDid(numDial);

			if (buddyObj == null) {
				var buddyType = numDial.length > DidLength ? "contact" : "extension";
				// Assumption but anyway: If the number starts with a * or # then its probably not a subscribable did,
				// and is probably a feature code.
				if (
					buddyType.substring(0, 1) == "*" ||
					buddyType.substring(0, 1) == "#"
				)
					buddyType = "contact";
				buddyObj = this.MakeBuddy(
					buddyType,
					true,
					false,
					false,
					CallerID ? CallerID : numDial,
					numDial,
					null,
					false
				);
				console.log("after make buddy");
			}

			// Create a Line
			this.newLineNumber = this.newLineNumber + 1;

			//var session = lineObj.SipSession;
			var lineObj = {
				LineNumber: this.newLineNumber,
				DisplayName: buddyObj.CallerIDName,
				DisplayNumber: numDial,
				BuddyObj: buddyObj,
			};
			//CODIGO EXTRA INTENTANDO HACER UNA NUEVA SESIÓN BASADO EN LAS SESIONES DE LA LLAMADA ENTRANTE
			console.log(
				"NUEVA SESIÓN LINEA DE LLAMADA SALIENTE ",
				this.newLineNumber
			);
			//FIN CODIGO EXTRA
			Lines.push(lineObj);
			this.AddLineHtml(lineObj);
			this.lineObj = lineObj;
			Object.assign(this.datos_lineas_activas, {
				[this.newLineNumber]: {
					action: "fa fa-phone-alt",
					items: [{ title: this.newLineNumber, la_linea: this.newLineNumber }],
					title: `${this.numero_hablando}`,
					llamada_tiempo: "",
					lineas_llamadas_activas: this.newLineNumber,
					estadado_llamada: true,
					nombre_largo: this.nombre_largo,
					nombre_corto: this.nombre_corto,
				},
			});
			this.SelectLine(this.newLineNumber);
			this.UpdateBuddyList();
			this.numero_linea = this.newLineNumber;
			//FUNCIONABILIDAD NUEVA LINEA
			this.opciones_nueva_linea = false;
			this.cancelar_nueva_linea = false;
			this.lineas_activas_llamadas = true;
			this.lienea_uno_activa = false;
			this.cancelar_llamada_saliente = true;
			this.openWebLines();

			//ALMACENAR EN BASE DE DATOS REGISTRO DE LLAMADA.
			// CallDataModule.saveCall({
			// 	telefono: this.numero_hablando,
			// 	tipo_llamada: "SALIENTE",
			// });
			var html = "";
			html += "<div class='crear-audio'>";
			html +=
				'<audio id="line-' + this.newLineNumber + '-remoteAudio"></audio>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioSendBitRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioSendPacketRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceiveBitRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceivePacketRate" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceivePacketLoss" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceiveJitter" class=audioGraph></canvas></div>';
			html +=
				"<div style='display:none'><canvas id=\"line-" +
				this.newLineNumber +
				'-AudioReceiveLevels" class=audioGraph></canvas></div>';
			html += "</div>";
			$("#formagentemain").append(html);
			// Start Call Invite
			if (type == "audio") {
				console.log("Send audio call");
				this.NewAudioCall2(lineObj, numDial, extraHeaders);
				//this.AudioCallLog();
				console.log("Send audio call2");
			}
			this.quitar_en_espera = false;
		},
		AudioCallLog() {
			console.log("Entra en audioCallLog");
		},
		NewAudioCall2(lineObj, dialledNumber, extraHeaders) {
			let HasAudioDevice = true;
			var lang = this.lang;
			var userAgent = this.userAgent;
			let AutoGainControl = 1;
			let EchoCancellation = 1;
			let NoiseSuppression = 1;
			let wssServer = env.asteriskSocketIP;
			var global = this;

			if (userAgent == null) return;
			if (userAgent.isRegistered() == false) return;
			if (lineObj == null) return;

			if (HasAudioDevice == false) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Lo sentimos, no tienes ningún micrófono conectado a este ordenador. No puede recibir llamadas.",
					type: "error",
				});
				return;
			}

			var supportedConstraints =
				navigator.mediaDevices.getSupportedConstraints();

			var spdOptions = {
				earlyMedia: true,
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: { deviceId: "default" },
						video: false,
					},
				},
			};
			// Configure Audio
			var currentAudioDevice = this.getAudioSrcID();
			if (currentAudioDevice != "default") {
				var confirmedAudioDevice = false;
				for (var i = 0; i < this.AudioinputDevices.length; ++i) {
					if (currentAudioDevice == this.AudioinputDevices[i].deviceId) {
						confirmedAudioDevice = true;
						break;
					}
				}
				if (confirmedAudioDevice) {
					spdOptions.sessionDescriptionHandlerOptions.constraints.audio.deviceId =
						{ exact: currentAudioDevice };
				} else {
					console.warn(
						"The audio device you used before is no longer available, default settings applied."
					);
					localDB.setItem("AudioSrcId", "default");
				}
			}
			// Add additional Constraints
			if (supportedConstraints.autoGainControl) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl =
					AutoGainControl;
			}
			if (supportedConstraints.echoCancellation) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation =
					EchoCancellation;
			}
			if (supportedConstraints.noiseSuppression) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression =
					NoiseSuppression;
			}
			// Extra Headers
			if (extraHeaders) {
				spdOptions.extraHeaders = extraHeaders;
			}

			$("#line-" + lineObj.LineNumber + "-msg").html(lang.starting_audio_call);
			$("#line-" + lineObj.LineNumber + "-timer").show();

			var startTime = moment.utc();

			// Invite
			console.log("INVITE (audio): " + dialledNumber + "@" + wssServer);
			var targetURI = SIP.UserAgent.makeURI(
				"sip:" + dialledNumber + "@" + wssServer
			);
			lineObj.SipSession = new SIP.Inviter(userAgent, targetURI, spdOptions);
			lineObj.SipSession.data = {};
			lineObj.SipSession.data.line = lineObj.LineNumber;
			lineObj.SipSession.data.buddyId = lineObj.BuddyObj.identity;
			lineObj.SipSession.data.calldirection = "outbound";
			lineObj.SipSession.data.dst = dialledNumber;
			lineObj.SipSession.data.callstart = startTime.format(
				"YYYY-MM-DD HH:mm:ss UTC"
			);
			lineObj.SipSession.data.callTimer = window.setInterval(function () {
				var now = moment.utc();
				var duration = moment.duration(now.diff(startTime));
				var timeStr = global.formatShortDuration(duration.asSeconds());
				$("#line-" + lineObj.LineNumber + "-timer").html(timeStr);
				$("#line-" + lineObj.LineNumber + "-datetime").html(timeStr);
				global.tiempo_llamada = timeStr;
				if (global.datos_lineas_activas[lineObj.LineNumber]) {
					global.datos_lineas_activas[lineObj.LineNumber].llamada_tiempo =
						timeStr;
				}
			}, 1000);
			lineObj.SipSession.data.VideoSourceDevice = null;
			lineObj.SipSession.data.AudioSourceDevice = this.getAudioSrcID();
			lineObj.SipSession.data.AudioOutputDevice = this.getAudioOutputID();
			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.withvideo = false;
			lineObj.SipSession.data.earlyReject = false;
			lineObj.SipSession.isOnHold = false;
			lineObj.SipSession.delegate = {
				onBye: function (sip) {
					global.onSessionRecievedBye(lineObj, sip);
				},
				onMessage: function (sip) {
					onSessionRecievedMessage(lineObj, sip);
				},
				onInvite: function (sip) {
					onSessionReinvited(lineObj, sip);
				},
				onSessionDescriptionHandler: function (sdh, provisional) {
					global.onSessionDescriptionHandlerCreated(
						lineObj,
						sdh,
						provisional,
						false
					);
				},
			};
			var inviterOptions = {
				requestDelegate: {
					// OutgoingRequestDelegate
					onTrying: function (sip) {
						global.onInviteTrying(lineObj, sip);
					},
					onProgress: function (sip) {
						global.onInviteProgress(lineObj, sip);
					},
					onRedirect: function (sip) {
						onInviteRedirected(lineObj, sip);
					},
					onAccept: function (sip) {
						global.onInviteAccepted(lineObj, false, sip);
					},
					onReject: function (sip) {
						global.onInviteRejected(lineObj, sip);
					},
				},
			};
			lineObj.SipSession.invite(inviterOptions).catch(function (e) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Error al enviar invitación " + e,
					type: "error",
				});
				console.warn("Failed to send INVITE:", e);
			});

			$("#line-" + lineObj.LineNumber + "-btn-settings").removeAttr("disabled");
			$("#line-" + lineObj.LineNumber + "-btn-audioCall").prop(
				"disabled",
				"disabled"
			);
			$("#line-" + lineObj.LineNumber + "-btn-videoCall").prop(
				"disabled",
				"disabled"
			);
			$("#line-" + lineObj.LineNumber + "-btn-search").removeAttr("disabled");
			$("#line-" + lineObj.LineNumber + "-btn-remove").prop(
				"disabled",
				"disabled"
			);

			$("#line-" + lineObj.LineNumber + "-progress").show();
			$("#line-" + lineObj.LineNumber + "-msg").show();

			//this.UpdateUI();
			this.UpdateBuddyList();
			this.updateLineScroll(lineObj.LineNumber);

			// Custom Web hook
			if (typeof web_hook_on_invite !== "undefined")
				web_hook_on_invite(lineObj.SipSession);
		},
		StartConferenceCall(lineNum) {
			this.botones_telefono = true;
			this.botones_dtmf = false;
			this.conferencia = false;
			this.desactivar_conferencia = true;
			this.opciones_transferencia = false;
			this.opciones_nueva_linea = false;
			this.digitar_transferencia = false;
			this.digitar_nueva_linea = false;
			this.linea_conferencia = false;
			this.transferencia = true;
			this.nueva_linea = true;
			this.cancelar_transferencia = false;
			this.cancelar_nueva_linea = false;
			$(".collapse").removeClass("show");
			if (this.transfiriendo_multi_linea) {
				console.log("entra transfiriendo multilinea");
				this.CancelTransferSession(lineNum);
			}
			if (Object.keys(this.datos_lineas_activas).length > 1) {
				this.conferenciando_multi_linea = true;
				this.en_conferencia = false;
				this.datos_llamada = false;
				this.linea_a_conferenciar = -1;
				this.numero_conferenciar = "";
				return;
			}
			this.opciones_conferencia = true;
			this.digitar_conferencia = true;
			this.lienea_uno_activa = true;

			var lineNum = lineNum["numero_linea"];

			if ($("#line-" + lineNum + "-btn-CancelTransfer").is(":visible")) {
				this.CancelTransferSession(lineNum);
				return;
			}

			$("#line-" + lineNum + "-btn-Conference").hide();
			$("#line-" + lineNum + "-btn-CancelConference").show();
			this.holdSession({ numero_linea: lineNum, active: true }); // pasandole un objeto se obliga a activar los botones
			$("#line-" + lineNum + "-txt-FindConferenceBuddy").val("");
			$("#line-" + lineNum + "-txt-FindConferenceBuddy")
				.parent()
				.show();

			$("#line-" + lineNum + "-btn-conference-dial").show();
			$("#line-" + lineNum + "-btn-cancel-conference-dial").hide();
			$("#line-" + lineNum + "-btn-join-conference-call").hide();
			$("#line-" + lineNum + "-btn-terminate-conference-call").hide();

			$("#line-" + lineNum + "-conference-status").hide();

			$("#line-" + lineNum + "-Conference").show();

			this.updateLineScroll(lineNum);
		},
		CancelConference(lineNum) {
			this.conferencia = true;
			this.telefono_conferencia = "";
			this.desactivar_conferencia = false;
			this.linea_conferencia = false;
			this.digitar_conferencia = false;
			this.conferenciando_multi_linea = false;
			this.unholdSession(lineNum);
			if (
				!this.en_conferencia &&
				Object.keys(this.datos_lineas_activas).length > 0
			) {
				this.datos_llamada = true;
				this.en_conferencia = false;
				this.linea_a_conferenciar = -1;
				return;
			}

			this.en_conferencia = false;

			var lineNum = lineNum["numero_linea"];
			var lineObj = this.lineObj;
			// var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Null line or session");
				return;
			}
			var session = lineObj.SipSession;
			if (session.data.childsession) {
				console.log(
					"Child Conference call detected:",
					session.data.childsession.state
				);
				session.data.childsession
					.dispose()
					.then(function () {
						session.data.childsession = null;
					})
					.catch(function (error) {
						session.data.childsession = null;
						// Supress message
					});
			}
			if (Object.keys(this.datos_lineas_activas).length > 1) {
				this.endSession(this.linea_a_conferenciar);
			}

			this.linea_a_conferenciar = -1;

			$("#line-" + lineNum + "-btn-Conference").show();
			$("#line-" + lineNum + "-btn-CancelConference").hide();

			this.unholdSession(lineNum);
			$("#line-" + lineNum + "-Conference").hide();

			this.updateLineScroll(lineNum);
			this.opciones_conferencia = false;
		},
		ConferenceMultiLine2(lineNum) {
			try {
				this.conectando_conferencia = true;
				let lineObj = this.getLineSipSession(lineNum);
				if (Boolean(lineObj) == false) return;
				let session = lineObj.SipSession;
				this.holdSession(lineNum);
				this.updateLineScroll(lineNum);
				//Obteniendo segunda linea, segunda session
				console.log(
					"============> Linea a conferenciar: ",
					this.linea_a_conferenciar,
					lineNum
				);
				let lineObjTo = this.getLineSipSession(this.linea_a_conferenciar);
				if (Boolean(lineObjTo) == false) return;
				let sessionTo = lineObjTo.SipSession;

				let global = this;
				if (!session.data.confcalls) session.data.confcalls = [];
				session.data.confcalls.push({
					to: sessionTo.DisplayNumber,
					startTime: this.utcDateNow(),
					disposition: "invite",
					dispositionTime: this.utcDateNow(),
					accept: {
						complete: null,
						eventTime: null,
						disposition: "",
					},
				});
				if (session.data.AudioSourceDevice != "default") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.audio.deviceId =
						{ exact: session.data.AudioSourceDevice };
				}

				let confcallid = session.data.confcalls.length - 1;

				this.updateLineScroll(lineNum);

				//newCallStatus.html(lang.call_in_progress);
				//Nuevo intento
				sessionTo.delegate = {
					onBye: function (sip) {
						console.log("Call conference child session ended with BYE");
						global.conferenciando_multi_linea = false;
						global.en_conferencia = false;
						global.datos_llamada = true;
						global.linea_a_conferenciar = -1;
						global.conferencia = true;
						global.desactivar_conferencia = false;
						session.data.confcalls[confcallid].disposition = "bye";
						session.data.confcalls[confcallid].dispositionTime =
							global.utcDateNow();

						global.linea_conferencia = false;
						global.opciones_conferencia = true;
						global.telefono_conferencia = "";
						if (Object.keys(global.datos_lineas_activas).length == 1) {
							//this.lienea_uno_activa = true;
							global.closeWebLines();
						}
					},
					onSessionDescriptionHandler: function (sdh, provisional) {
						if (sdh) {
							if (sdh.peerConnection) {
								sdh.peerConnection.ontrack = function (event) {
									var pc = sdh.peerConnection;

									// Gets Remote Audio Track (Local audio is setup via initial GUM)
									var remoteStream = new MediaStream();
									pc.getReceivers().forEach(function (receiver) {
										if (receiver.track && receiver.track.kind == "audio") {
											remoteStream.addTrack(receiver.track);
										}
									});
									var remoteAudio = $("#line-" + lineNum + "-remoteAudio").get(
										0
									);
									remoteAudio.srcObject = remoteStream;
									remoteAudio.onloadedmetadata = function (e) {
										if (typeof remoteAudio.sinkId !== "undefined") {
											remoteAudio
												.setSinkId(session.data.AudioOutputDevice)
												.then(function () {
													console.log(
														"sinkId applied: " + session.data.AudioOutputDevice
													);
												})
												.catch(function (e) {
													console.warn("Error using setSinkId: ", e);
												});
										}
										remoteAudio.play();
									};
								};
							} else {
								console.warn(
									"onSessionDescriptionHandler fired without a peerConnection"
								);
							}
						} else {
							console.warn(
								"onSessionDescriptionHandler fired without a sessionDescriptionHandler"
							);
						}
					},
				};
				//Make sure we always resore audio paths
				sessionTo.stateChange.addListener(newState => {
					if (newState == SIP.SessionState.Terminated) {
						// Ends the mixed audio, and releases the mic
						if (
							session.data.childsession.data.AudioSourceTrack &&
							session.data.childsession.data.AudioSourceTrack.kind == "audio"
						) {
							session.data.childsession.data.AudioSourceTrack.stop(-1);
						}
						// Restore Audio Stream as it was changed
						if (
							session.data.AudioSourceTrack &&
							session.data.AudioSourceTrack.kind == "audio" &&
							!session.data.teardownComplete
						) {
							var pc = session.sessionDescriptionHandler.peerConnection;
							pc.getSenders().forEach(function (RTCRtpSender) {
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									RTCRtpSender.replaceTrack(session.data.AudioSourceTrack)
										.then(function () {
											if (session.data.ismute) {
												RTCRtpSender.track.enabled = false;
											} else {
												RTCRtpSender.track.enabled = true;
											}
										})
										.catch(function () {
											console.error(e);
										});
									session.data.AudioSourceTrack = null;
								}
							});
						} else {
							var pc = session.sessionDescriptionHandler.peerConnection;
							pc.getSenders().forEach(function (RTCRtpSender) {
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									RTCRtpSender.track.stop(lineNum);
								}
							});
						}
					}
				});
				//Fin Nuevo intento
				session.data.childsession = sessionTo;
				// Join Call
				//var JoinCallBtn = $("#line-"+ lineNum +"-btn-join-conference-call");
				// Merge Call Audio
				if (!session.data.childsession) {
					console.warn("Conference session lost");
					return;
				}
				var inviterOptions = {
					requestDelegate: {
						onTrying: function (sip) {
							session.data.confcalls[confcallid].disposition = "trying";
							session.data.confcalls[confcallid].dispositionTime =
								global.utcDateNow();
						},
						onProgress: function (sip) {
							session.data.confcalls[confcallid].disposition = "progress";
							session.data.confcalls[confcallid].dispositionTime =
								global.utcDateNow();
							var CancelConferenceDialBtn = $("#conferencia_cancelar");
							// var CancelConferenceDialBtn = $("#line-"+ lineNum +"-btn-cancel-conference-dial");
							CancelConferenceDialBtn.off("click");
							CancelConferenceDialBtn.on("click", function () {
								sessionTo.cancel().catch(function (error) {
									console.warn("Failed to CANCEL", error);
								});
								console.log("New call session canceled");

								session.data.confcalls[confcallid].accept.complete = false;
								session.data.confcalls[confcallid].accept.disposition =
									"cancel";
								session.data.confcalls[confcallid].accept.eventTime =
									global.utcDateNow();

								$("#line-" + lineNum + "-msg").html(
									lang.canference_call_cancelled
								);
								global.opciones_conferencia = true;
								global.cancelar_conferencia = false;
								global.updateLineScroll(lineNum);
							});
							CancelConferenceDialBtn.show();

							global.updateLineScroll(lineNum);
						},
						onRedirect: function (sip) {
							console.log("Redirect received:", sip);
						},
						onAccept: function (sip) {
							delete global.datos_lineas_activas[global.linea_a_conferenciar];
							session.data.confcalls[confcallid].complete = true;
							session.data.confcalls[confcallid].disposition = "accepted";
							session.data.confcalls[confcallid].dispositionTime =
								global.utcDateNow();

							// Merge Call Audio
							if (!session.data.childsession) {
								console.warn("Conference session lost");
								return;
							}
							session.data.childsession.isOnHold = false;
							sessionTo.isOnHold = false;
							var outputStreamForSession = new MediaStream();
							var outputStreamForConfSession = new MediaStream();

							var pc = session.sessionDescriptionHandler.peerConnection;
							if (!session.data.childsession.data.hold)
								session.data.childsession.data.hold = [];
							session.data.childsession.data.hold.push({
								event: "unhold",
								eventTime: global.utcDateNow(),
							});
							var confPc =
								session.data.childsession.sessionDescriptionHandler
									.peerConnection;

							// Get conf call input channel
							confPc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (
									RTCRtpReceiver.track &&
									RTCRtpReceiver.track.kind == "audio"
								) {
									console.log(
										"Adding conference session:",
										RTCRtpReceiver.track.label
									);
									if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = true;
									outputStreamForSession.addTrack(RTCRtpReceiver.track);
								}
							});

							// Get session input channel
							pc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (
									RTCRtpReceiver.track &&
									RTCRtpReceiver.track.kind == "audio"
								) {
									console.log(
										"Adding conference session:",
										RTCRtpReceiver.track.label
									);
									outputStreamForConfSession.addTrack(RTCRtpReceiver.track);
								}
							});

							// Replace tracks of Parent Call
							pc.getSenders().forEach(function (RTCRtpSender) {
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									console.log("Switching to mixed Audio track on session");

									session.data.AudioSourceTrack = RTCRtpSender.track;
									outputStreamForSession.addTrack(RTCRtpSender.track);
									var mixedAudioTrack = global
										.MixAudioStreams(outputStreamForSession)
										.getAudioTracks()[0];
									mixedAudioTrack.IsMixedTrack = true;

									RTCRtpSender.replaceTrack(mixedAudioTrack);
								}
							});
							// Replace tracks of Child Call
							confPc.getSenders().forEach(function (RTCRtpSender) {
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									console.log("Switching to mixed Audio track on conf call");

									session.data.childsession.data.AudioSourceTrack =
										RTCRtpSender.track;
									session.data.childsession.data.AudioSourceTrack.enabled = true;
									outputStreamForConfSession.addTrack(RTCRtpSender.track);
									var mixedAudioTrackForConf = global
										.MixAudioStreams(outputStreamForConfSession)
										.getAudioTracks()[0];
									mixedAudioTrackForConf.IsMixedTrack = true;

									RTCRtpSender.replaceTrack(mixedAudioTrackForConf);
								}
							});

							console.log(
								"Conference Call In Progress",
								Object.keys(global.datos_lineas_activas).length,
								global.datos_lineas_activas
							);

							if (Object.keys(global.datos_lineas_activas).length <= 1) {
								global.lineas_activas_llamadas = false;
								global.closeWebLines();
							}

							session.data.confcalls[confcallid].accept.complete = true;
							session.data.confcalls[confcallid].accept.disposition = "join";
							session.data.confcalls[confcallid].accept.eventTime =
								global.utcDateNow();

							$("#line-" + lineNum + "-btn-terminate-conference-call").show();

							global.updateLineScroll(lineNum);

							// Take the parent call off hold after a second
							window.setTimeout(function () {
								console.log("unHoldLINE SESSION", lineNum);
								global.unholdSession(lineNum);
								global.updateLineScroll(lineNum);
							}, 1000);
							global.updateLineScroll(lineNum);

							// End Call
							// var TerminateConfCallBtn = $("#line-"+ lineNum +"-btn-terminate-conference-call");
							var TerminateConfCallBtn = $("#conferencia_finalizar");
							TerminateConfCallBtn.off("click");
							TerminateConfCallBtn.on("click", function () {
								sessionTo.bye().catch(function (e) {
									console.warn("Failed to BYE", e);
								});
								console.log("New call session end");

								// session.data.confcalls[confcallid].accept.complete = false;
								session.data.confcalls[confcallid].accept.disposition = "bye";
								session.data.confcalls[confcallid].accept.eventTime =
									global.utcDateNow();

								$("#line-" + lineNum + "-msg").html(lang.conference_call_ended);

								global.updateLineScroll(lineNum);
								global.linea_conferencia = false;
								global.opciones_conferencia = true;
								global.telefono_conferencia = "";
								window.setTimeout(function () {
									//global.CancelConference(lineNum);
									global.updateLineScroll(lineNum);
								}, 1000);
							});
							TerminateConfCallBtn.show();

							global.updateLineScroll(lineNum);
						},
						onReject: function (sip) {
							global.numero_conferenciar = "";
							console.log(
								"Call multi conference session  rejected: ",
								sip.message.reasonPhrase
							);
							session.data.confcalls[confcallid].disposition =
								sip.message.reasonPhrase;
							session.data.confcalls[confcallid].dispositionTime =
								global.utcDateNow();

							global.updateLineScroll(lineNum);
							global.telefono_conferencia = "";
							global.cancelar_conferencia = false;
							global.opciones_conferencia = true;
							global.conferenciando_multi_linea = false;
							global.en_conferencia = false;
							global.datos_llamada = true;
							global.linea_a_conferenciar = -1;
							window.setTimeout(function () {
								global.updateLineScroll(lineNum);
							}, 1000);
						},
					},
				};
				console.log("Inicio invite Conference Dial, personalizado", sessionTo);
				sessionTo.isOnHold = false;
				var sessionDescriptionHandlerOptions =
					sessionTo.sessionDescriptionHandlerOptionsReInvite;
				sessionDescriptionHandlerOptions.hold = false;
				sessionTo.sessionDescriptionHandlerOptionsReInvite =
					sessionDescriptionHandlerOptions;
				sessionTo.invite(inviterOptions).catch(function (e) {
					sessionTo.isOnHold = true;
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: "Error al evniar invitación " + e,
						type: "error",
					});
					console.warn("Failed to send INVITE:", e);
				});
				// Custom Web hook
				if (typeof web_hook_on_modify !== "undefined")
					web_hook_on_modify("unhold", sessionTo);
				this.conectando_conferencia = false;
				this.en_conferencia = true;
			} catch (e) {
				this.conectando_conferencia = false;
				console.warn("ERROR://", e);
			}
		},
		ConferenceDail(lineNum) {
			var dstNo = this.telefono_conferencia;
			var lineNum = lineNum["numero_linea"];
			var lineObj = this.lineObj;
			this.opciones_conferencia = false;
			this.cancelar_conferencia = true;
			let wssServer = env.asteriskSocketIP;
			var userAgent = this.userAgent;
			let AutoGainControl = 1;
			let EchoCancellation = 1;
			let NoiseSuppression = 1;
			var lang = this.lang;
			var global = this;
			var html = "";
			html += "<div class='crear-audio'>";
			html +=
				'<audio id="line-' +
				lineNum +
				'-conference-remoteAudio" style="display:none"></audio>';
			html += "</div>";
			$("#formagentemain").append(html);
			///var dstNo = $("#line-"+ lineNum +"-txt-FindConferenceBuddy").val().replace(/[^0-9\*\#\+]/g,'');
			if (dstNo == "") {
				console.warn("Cannot transfer, must be [0-9*+#]");
				return;
			}

			//var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Null line or session");
				return;
			}
			var session = lineObj.SipSession;

			this.HidePopup2();

			$("#line-" + lineNum + "-txt-FindConferenceBuddy")
				.parent()
				.hide();

			$("#line-" + lineNum + "-btn-conference-dial").hide();
			$("#line-" + lineNum + "-btn-cancel-conference-dial");
			$("#line-" + lineNum + "-btn-join-conference-call").hide();
			$("#line-" + lineNum + "-btn-terminate-conference-call").hide();

			var newCallStatus = $("#line-" + lineNum + "-conference-status");
			newCallStatus.html(lang.connecting);
			newCallStatus.show();

			if (!session.data.confcalls) session.data.confcalls = [];
			session.data.confcalls.push({
				to: dstNo,
				startTime: this.utcDateNow(),
				disposition: "invite",
				dispositionTime: this.utcDateNow(),
				accept: {
					complete: null,
					eventTime: null,
					disposition: "",
				},
			});
			var confcallid = session.data.confcalls.length - 1;

			this.updateLineScroll(lineNum);

			// SDP options
			var supportedConstraints =
				navigator.mediaDevices.getSupportedConstraints();
			var spdOptions = {
				earlyMedia: true,
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: { deviceId: "default" },
						video: false,
					},
				},
			};
			if (session.data.AudioSourceDevice != "default") {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.deviceId =
					{ exact: session.data.AudioSourceDevice };
			}
			// Add additional Constraints
			if (supportedConstraints.autoGainControl) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl =
					AutoGainControl;
			}
			if (supportedConstraints.echoCancellation) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation =
					EchoCancellation;
			}
			if (supportedConstraints.noiseSuppression) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression =
					NoiseSuppression;
			}

			// Unlikely this will work
			if (session.data.withvideo) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.video = true;
				if (session.data.VideoSourceDevice != "default") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.deviceId =
						{ exact: session.data.VideoSourceDevice };
				}
				// Add additional Constraints
				if (supportedConstraints.frameRate && maxFrameRate != "") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.frameRate =
						maxFrameRate;
				}
				if (supportedConstraints.height && videoHeight != "") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.height =
						videoHeight;
				}
				if (supportedConstraints.aspectRatio && videoAspectRatio != "") {
					spdOptions.sessionDescriptionHandlerOptions.constraints.video.aspectRatio =
						videoAspectRatio;
				}
			}

			// Create new call session
			console.log("CONFERENCE INVITE: ", "sip:" + dstNo + "@" + wssServer);

			var targetURI = SIP.UserAgent.makeURI("sip:" + dstNo + "@" + wssServer);
			var newSession = new SIP.Inviter(userAgent, targetURI, spdOptions);
			newSession.data = {};
			newSession.delegate = {
				onBye: function (sip) {
					console.log("New call session ended with BYE");

					session.data.confcalls[confcallid].disposition = "bye";
					session.data.confcalls[confcallid].dispositionTime =
						global.utcDateNow();

					$("#line-" + lineNum + "-txt-FindConferenceBuddy")
						.parent()
						.show();
					$("#line-" + lineNum + "-btn-conference-dial").show();

					$("#line-" + lineNum + "-btn-cancel-conference-dial").hide();
					$("#line-" + lineNum + "-btn-join-conference-call").hide();
					$("#line-" + lineNum + "-btn-terminate-conference-call").hide();

					$("#line-" + lineNum + "-msg").html(lang.conference_call_terminated);

					global.updateLineScroll(lineNum);
					global.linea_conferencia = false;
					global.opciones_conferencia = true;
					global.telefono_conferencia = "";
					window.setTimeout(function () {
						newCallStatus.hide();
						global.updateLineScroll(lineNum);
					}, 1000);
				},
				onSessionDescriptionHandler: function (sdh, provisional) {
					if (sdh) {
						if (sdh.peerConnection) {
							sdh.peerConnection.ontrack = function (event) {
								var pc = sdh.peerConnection;

								// Gets Remote Audio Track (Local audio is setup via initial GUM)
								var remoteStream = new MediaStream();
								pc.getReceivers().forEach(function (receiver) {
									if (receiver.track && receiver.track.kind == "audio") {
										remoteStream.addTrack(receiver.track);
									}
								});
								var remoteAudio = $(
									"#line-" + lineNum + "-conference-remoteAudio"
								).get(0);
								remoteAudio.srcObject = remoteStream;
								remoteAudio.onloadedmetadata = function (e) {
									if (typeof remoteAudio.sinkId !== "undefined") {
										remoteAudio
											.setSinkId(session.data.AudioOutputDevice)
											.then(function () {
												console.log(
													"sinkId applied: " + session.data.AudioOutputDevice
												);
											})
											.catch(function (e) {
												console.warn("Error using setSinkId: ", e);
											});
									}
									remoteAudio.play();
								};
							};
						} else {
							console.warn(
								"onSessionDescriptionHandler fired without a peerConnection"
							);
						}
					} else {
						console.warn(
							"onSessionDescriptionHandler fired without a sessionDescriptionHandler"
						);
					}
				},
			};
			session.data.childsession = newSession;
			// Make sure we always resore audio paths
			newSession.stateChange.addListener(function (newState) {
				if (newState == SIP.SessionState.Terminated) {
					// Ends the mixed audio, and releases the mic
					if (
						session.data.childsession.data.AudioSourceTrack &&
						session.data.childsession.data.AudioSourceTrack.kind == "audio"
					) {
						session.data.childsession.data.AudioSourceTrack.stop(-1);
					}
					// Restore Audio Stream as it was changed
					if (
						session.data.AudioSourceTrack &&
						session.data.AudioSourceTrack.kind == "audio"
					) {
						var pc = session.sessionDescriptionHandler.peerConnection;
						pc.getSenders().forEach(function (RTCRtpSender) {
							if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
								RTCRtpSender.replaceTrack(session.data.AudioSourceTrack)
									.then(function () {
										if (session.data.ismute) {
											RTCRtpSender.track.enabled = false;
										} else {
											RTCRtpSender.track.enabled = true;
										}
									})
									.catch(function () {
										console.error(e);
									});
								session.data.AudioSourceTrack = null;
							}
						});
					}
				}
			});
			var inviterOptions = {
				requestDelegate: {
					onTrying: function (sip) {
						newCallStatus.html(lang.trying);
						session.data.confcalls[confcallid].disposition = "trying";
						session.data.confcalls[confcallid].dispositionTime =
							global.utcDateNow();

						$("#line-" + lineNum + "-msg").html(lang.conference_call_started);
					},
					onProgress: function (sip) {
						console.log("Onprogress");
						newCallStatus.html(lang.ringing);
						session.data.confcalls[confcallid].disposition = "progress";
						session.data.confcalls[confcallid].dispositionTime =
							global.utcDateNow();

						$("#line-" + lineNum + "-msg").html(lang.conference_call_started);

						var CancelConferenceDialBtn = $("#conferencia_cancelar");
						// var CancelConferenceDialBtn = $("#line-"+ lineNum +"-btn-cancel-conference-dial");
						CancelConferenceDialBtn.off("click");
						CancelConferenceDialBtn.on("click", function () {
							newSession.cancel().catch(function (error) {
								console.warn("Failed to CANCEL", error);
							});
							newCallStatus.html(lang.call_cancelled);
							console.log("New call session canceled");

							session.data.confcalls[confcallid].accept.complete = false;
							session.data.confcalls[confcallid].accept.disposition = "cancel";
							session.data.confcalls[confcallid].accept.eventTime =
								global.utcDateNow();

							$("#line-" + lineNum + "-msg").html(
								lang.canference_call_cancelled
							);
							global.opciones_conferencia = true;
							global.cancelar_conferencia = false;
							global.updateLineScroll(lineNum);
						});
						CancelConferenceDialBtn.show();

						global.updateLineScroll(lineNum);
					},
					onRedirect: function (sip) {
						console.log("Redirect received:", sip);
					},
					onAccept: function (sip) {
						newCallStatus.html(lang.call_in_progress);
						global.cancelar_conferencia = false;
						global.linea_conferencia = true;
						$("#line-" + lineNum + "-btn-cancel-conference-dial").hide();
						session.data.confcalls[confcallid].complete = true;
						session.data.confcalls[confcallid].disposition = "accepted";
						session.data.confcalls[confcallid].dispositionTime =
							global.utcDateNow();

						// Join Call
						var JoinCallBtn = $("#realizar_conferencia");
						//var JoinCallBtn = $("#line-"+ lineNum +"-btn-join-conference-call");
						JoinCallBtn.off("click");
						JoinCallBtn.on("click", function () {
							// Merge Call Audio
							if (!session.data.childsession) {
								console.warn("Conference session lost");
								return;
							}

							var outputStreamForSession = new MediaStream();
							var outputStreamForConfSession = new MediaStream();

							var pc = session.sessionDescriptionHandler.peerConnection;
							var confPc =
								session.data.childsession.sessionDescriptionHandler
									.peerConnection;

							// Get conf call input channel
							confPc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (
									RTCRtpReceiver.track &&
									RTCRtpReceiver.track.kind == "audio"
								) {
									console.log(
										"Adding conference session:",
										RTCRtpReceiver.track.label
									);
									outputStreamForSession.addTrack(RTCRtpReceiver.track);
								}
							});

							// Get session input channel
							pc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (
									RTCRtpReceiver.track &&
									RTCRtpReceiver.track.kind == "audio"
								) {
									console.log(
										"Adding conference session:",
										RTCRtpReceiver.track.label
									);
									outputStreamForConfSession.addTrack(RTCRtpReceiver.track);
								}
							});

							// Replace tracks of Parent Call
							pc.getSenders().forEach(function (RTCRtpSender) {
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									console.log("Switching to mixed Audio track on session");

									session.data.AudioSourceTrack = RTCRtpSender.track;
									outputStreamForSession.addTrack(RTCRtpSender.track);
									var mixedAudioTrack = global
										.MixAudioStreams(outputStreamForSession)
										.getAudioTracks()[0];
									mixedAudioTrack.IsMixedTrack = true;

									RTCRtpSender.replaceTrack(mixedAudioTrack);
								}
							});
							// Replace tracks of Child Call
							confPc.getSenders().forEach(function (RTCRtpSender) {
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									console.log("Switching to mixed Audio track on conf call");

									session.data.childsession.data.AudioSourceTrack =
										RTCRtpSender.track;
									outputStreamForConfSession.addTrack(RTCRtpSender.track);
									var mixedAudioTrackForConf = global
										.MixAudioStreams(outputStreamForConfSession)
										.getAudioTracks()[0];
									mixedAudioTrackForConf.IsMixedTrack = true;

									RTCRtpSender.replaceTrack(mixedAudioTrackForConf);
								}
							});

							newCallStatus.html(lang.call_in_progress);
							console.log("Conference Call In Progress");

							session.data.confcalls[confcallid].accept.complete = true;
							session.data.confcalls[confcallid].accept.disposition = "join";
							session.data.confcalls[confcallid].accept.eventTime =
								global.utcDateNow();

							$("#line-" + lineNum + "-btn-terminate-conference-call").show();

							$("#line-" + lineNum + "-msg").html(
								lang.conference_call_in_progress
							);

							JoinCallBtn.hide();
							global.updateLineScroll(lineNum);

							// Take the parent call off hold after a second
							window.setTimeout(function () {
								global.unholdSession(lineNum);
								global.updateLineScroll(lineNum);
							}, 1000);
						});
						JoinCallBtn.show();

						global.updateLineScroll(lineNum);

						// End Call
						// var TerminateConfCallBtn = $("#line-"+ lineNum +"-btn-terminate-conference-call");
						var TerminateConfCallBtn = $("#conferencia_finalizar");
						TerminateConfCallBtn.off("click");
						TerminateConfCallBtn.on("click", function () {
							newSession.bye().catch(function (e) {
								console.warn("Failed to BYE", e);
							});
							newCallStatus.html(lang.call_ended);
							console.log("New call session end");

							// session.data.confcalls[confcallid].accept.complete = false;
							session.data.confcalls[confcallid].accept.disposition = "bye";
							session.data.confcalls[confcallid].accept.eventTime =
								global.utcDateNow();

							$("#line-" + lineNum + "-msg").html(lang.conference_call_ended);

							global.updateLineScroll(lineNum);
							global.linea_conferencia = false;
							global.opciones_conferencia = true;
							global.telefono_conferencia = "";
							window.setTimeout(function () {
								newCallStatus.hide();
								global.CancelConference(lineNum);
								global.updateLineScroll(lineNum);
							}, 1000);
						});
						TerminateConfCallBtn.show();

						global.updateLineScroll(lineNum);
					},
					onReject: function (sip) {
						console.log(
							"New call session rejected: ",
							sip.message.reasonPhrase
						);
						newCallStatus.html(lang.call_rejected);
						session.data.confcalls[confcallid].disposition =
							sip.message.reasonPhrase;
						session.data.confcalls[confcallid].dispositionTime =
							global.utcDateNow();

						$("#line-" + lineNum + "-txt-FindConferenceBuddy")
							.parent()
							.show();
						$("#line-" + lineNum + "-btn-conference-dial").show();

						$("#line-" + lineNum + "-btn-cancel-conference-dial").hide();
						$("#line-" + lineNum + "-btn-join-conference-call").hide();
						$("#line-" + lineNum + "-btn-terminate-conference-call").hide();

						$("#line-" + lineNum + "-msg").html(lang.conference_call_rejected);

						global.updateLineScroll(lineNum);
						global.telefono_conferencia = "";
						global.cancelar_conferencia = false;
						global.opciones_conferencia = true;
						window.setTimeout(function () {
							newCallStatus.hide();
							global.updateLineScroll(lineNum);
						}, 1000);
					},
				},
			};
			newSession.invite(inviterOptions).catch(function (e) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Error al evniar invitación " + e,
					type: "error",
				});
				console.warn("Failed to send INVITE:", e);
			});
		},
		MixAudioStreams(MultiAudioTackStream) {
			// Takes in a MediaStream with any mumber of audio tracks and mixes them together

			var audioContext = null;

			try {
				window.AudioContext = window.AudioContext || window.webkitAudioContext;
				audioContext = new AudioContext();
			} catch (e) {
				console.warn("AudioContext() not available, cannot record");
				return MultiAudioTackStream;
			}
			var mixedAudioStream = audioContext.createMediaStreamDestination();
			MultiAudioTackStream.getAudioTracks().forEach(function (audioTrack) {
				var srcStream = new MediaStream();
				srcStream.addTrack(audioTrack);
				var streamSourceNode = audioContext.createMediaStreamSource(srcStream);
				streamSourceNode.connect(mixedAudioStream);
			});

			return mixedAudioStream.stream;
		},
		sendDTMF(lineNum, itemStr) {
			var global = this;
			var lang = this.lang;
			var lineObj =
				this.Lines.find(l => l.IsSelected || l.LineNumber == lineNum) ??
				this.lineObj;
			var lineNum = lineNum["numero_linea"];

			if (lineObj == null || lineObj.SipSession == null) return;

			// https://developer.mozilla.org/en-US/docs/Web/API/RTCDTMFSender/insertDTMF
			var options = {
				duration: 100,
				interToneGap: 70,
			};

			if (lineObj.SipSession.isOnHold == true) {
				if (lineObj.SipSession.data.childsession) {
					if (
						lineObj.SipSession.data.childsession.state ==
						SIP.SessionState.Established
					) {
						console.log(
							"Sending DTMF (" +
								itemStr +
								"): " +
								lineObj.LineNumber +
								" child session"
						);

						var result =
							lineObj.SipSession.data.childsession.sessionDescriptionHandler.sendDtmf(
								itemStr,
								options
							);
						if (result) {
							console.log("Sent DTMF (" + itemStr + ") child session");
						} else {
							console.log(
								"Failed to send DTMF (" + itemStr + ") child session"
							);
						}
					} else {
						console.warn(
							"Cannot Send DTMF (" +
								itemStr +
								"): " +
								lineObj.LineNumber +
								" is on hold, and the child session is not established"
						);
					}
				} else {
					console.warn(
						"Cannot Send DTMF (" +
							itemStr +
							"): " +
							lineObj.LineNumber +
							" is on hold, and there is no child session"
					);
				}
			} else {
				if (
					lineObj.SipSession.state == SIP.SessionState.Established ||
					lineObj.SipSession.state == SIP.SessionState.Establishing
				) {
					console.log("Sending DTMF (" + itemStr + "): " + lineObj.LineNumber);

					var result = lineObj.SipSession.sessionDescriptionHandler.sendDtmf(
						itemStr,
						options
					);
					if (result) {
						console.log("Sent DTMF (" + itemStr + ")");
					} else {
						console.log("Failed to send DTMF (" + itemStr + ")");
					}

					$("#line-" + lineNum + "-msg").html(lang.send_dtmf + ": " + itemStr);

					global.updateLineScroll(lineNum);

					// Custom Web hook
					if (typeof web_hook_on_dtmf !== "undefined")
						web_hook_on_dtmf(itemStr, lineObj.SipSession);
				} else {
					console.warn(
						"Cannot Send DTMF (" +
							itemStr +
							"): " +
							lineObj.LineNumber +
							" session is not establishing or established"
					);
				}
			}
		},
		closeWebLines() {
			this.phoneLines.colLinesClass = "";
			this.phoneLines.colPhoneClass = "col-12 px-0";
			this.phoneLines.colBodyPhoneClass = "padding-15";
			this.lineas_activas_llamadas = false;
			this.phoneLines.currentContainerSize = this.phoneLines.smallContainerSize;
		},
		openWebLines() {
			this.phoneLines.currentContainerSize = this.phoneLines.bigContainerSize;

			this.phoneLines.colLinesClass = "col-6 px-0";
			this.phoneLines.colPhoneClass = "col-6 px-0";
			this.phoneLines.colBodyPhoneClass = "";
			this.lineas_activas_llamadas = true;
		},
		pin() {
			const webphoneWitdh = this.lineas_activas_llamadas
				? this.phoneLines.bigContainerSize
				: this.phoneLines.smallContainerSize;
			console.log(webphoneWitdh, "WIDTH WEBPHONE");
			console.log(window.innerWidth, "WIDTH BROWSER WINDOW");
			document.getElementById("draggBox").removeAttribute("style");
			document.getElementById("draggBox").style.width = webphoneWitdh + "px";
			document.getElementById("draggBox").style.bottom = "0px";
			document.getElementById("draggBox").style.left = "0px";
		},
		consoles(cons) {
			console.log(cons);
		},
		stopDuration() {
			if (this.lineas_activas_llamadas) {
				if (Object.keys(this.datos_lineas_activas).length == 0) {
					clearInterval(this.barLevelInterval);
					this.model.barLevel = 0;
				}
			} else {
				clearInterval(this.barLevelInterval);
				this.model.barLevel = 0;
			}
		},
		changeLines(line) {
			if (this.transfiriendo_multi_linea && line != this.numero_linea) {
				this.linea_a_transferir = line;
			} else if (this.conferenciando_multi_linea && line != this.numero_linea) {
				this.numero_conferenciar = this.FindLineByNumber(line)?.DisplayNumber;
				this.linea_a_conferenciar = line;
			} else {
				this.SelectLine(line);
			}
		},
		getLineSipSession(lineNum) {
			let lineObj = this.FindLineByNumber(lineNum);
			if (lineObj == null) {
				console.warn("Null line", lineObj);
				return false;
			} else if (lineObj.SipSession == null) {
				lineObj = lineObj[0];
			}
			if (lineObj.SipSession == null) {
				console.warn("Null Session", lineObj.SipSession);
				return false;
			}
			return lineObj;
		},
		validateConference() {
			console.log("Status conferenciando", this.conferenciando_multi_linea);
			if (this.conferenciando_multi_linea) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: langJson.validation_conference_multiline,
					type: "error",
				});
				return false;
			} else {
				return true;
			}
		},
		copyText(callNumber) {
			navigator.clipboard.writeText(callNumber);
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: "Numero Copiado en el portapapeles",
				type: "info",
			});
		},
	},
	computed: {
		durationText() {
			if (!this.model.onCall) return "--:--";
			const minutes = Math.floor(this.model.duration / 60);
			const seconds = this.model.duration % 60;
			const pad = v => (v < 10 ? "0" + v : v);
			return `${pad(minutes)}:${pad(seconds)}`;
		},
		extensionComputed() {
			return AuthModule.extension;
		},
		pinKeyComputed() {
			return PhoneModule.getPinKey;
		},
	},
	watch: {
		phoneNumer(num) {
			if (num !== null) {
				if (Object.keys(this.datos_lineas_activas).length > 0) {
					console.log(
						"🚀 ~ file: phone.vue ~ line 6929 ~ phoneNumer ~ Multiline",
						num
					);
					this.DialMultiLine("audio", num);
				} else {
					this.DialByLine("audio", num);
					console.log(
						"🚀 ~ file: phone.vue ~ line 6940 ~ phoneNumer ~ OneLine",
						num
					);
				}
			}
		},
		extensionComputed(newExt, oldExt) {
			console.log("newExt", newExt);
			console.log("oldExt", oldExt);

			if (oldExt != undefined && newExt == undefined) this.unregisterMethod();
		},
		pinKeyComputed() {
			this.pin();
		},
	},
	mounted() {
		//JQUERY
		window.$ = window.jQuery = require("@/lib/jquery/jquery-3.3.1.min.js");
		require("@/lib/jquery/jquery.md5-min.js");
		require("@/lib/jquery/jquery-ui.min.js");
		//Funcionamiento webRTC

		require("@/lib/Chart/Chart.bundle-2.7.2.js");
		require("@/lib/Croppie/Croppie-2.6.4/croppie.min.js");
		//DraggBox
		dragElement(
			document.getElementById("draggBox"),
			document.getElementById("formagentemain")
		);
		watchHeigth();

		//DESDE ACA COMIENZA EL TELEFONO
		// Global Settings
		// ===============
		const appversion = "0.3.5";
		const sipjsversion = "0.20.0";

		// Set the following to null to disable
		let welcomeScreen = "";
		const availableLang = ["ja", "zh-hans", "zh", "ru", "tr", "nl", "es", "de"]; // Defines the language packs avaialbe in /lang/ folder
		let loadAlternateLang = getDbItem("loadAlternateLang", "0") == "1"; // Enables searching and loading for the additional languge packs other thAan /en.json

		// User Settings & Defaults
		// ========================
		let profileUserID = getDbItem("profileUserID", null); // Internal reference ID. (DON'T CHANGE THIS!)
		let profileUser = getDbItem("profileUser", AuthModule.extension); // eg: 100
		let profileName = getDbItem("profileName", AuthModule.extension); // eg: Keyla James
		let wssServer = getDbItem("wssServer", env.asteriskSocketIP); // eg: raspberrypi.local
		let WebSocketPort = getDbItem("WebSocketPort", env.asteriskWebSocketPort); // eg: 444 | 4443
		let ServerPath = getDbItem("ServerPath", env.asteriskWebSocketPath); // eg: /ws
		let SipUsername = getDbItem("SipUsername", AuthModule.extension); // eg: webrtc
		let SipPassword = getDbItem(
			"SipPassword",
			env.agentSipPass ? AuthModule.getPjsipPass : env.asteriskWebSocketPassword
		);
		if (env.phoneLogger) {
			console.group("Auth Credentials");
			console.log("Boolean agent Sip Pass", env.agentSipPass);
			console.log("String agent Sip Pass", AuthModule.getPjsipPass);
			console.log("Env asterisk password", env.asteriskWebSocketPassword);
			console.log(
				"Result",
				env.agentSipPass
					? AuthModule.getPjsipPass
					: env.asteriskWebSocketPassword
			);
			console.groupEnd();
		}

		// let SipPassword = getDbItem("SipPassword", env.asteriskWebSocketPassword); // eg: 1234

		let TransportConnectionTimeout = parseInt(
			getDbItem("TransportConnectionTimeout", 15)
		); // The timeout in seconds for the initial connection to make on the web socket port
		let TransportReconnectionAttempts = parseInt(
			getDbItem("TransportReconnectionAttempts", 999)
		); // The number of times to attempt to reconnect to a WebSocket when the connection drops.
		let TransportReconnectionTimeout = parseInt(
			getDbItem("TransportReconnectionTimeout", 3)
		); // The time in seconds to wait between WebSocket reconnection attempts.

		let VoiceMailSubscribe = getDbItem("VoiceMailSubscribe", "0") == "1"; // Enable Subscribe to voicemail
		let userAgentStr = getDbItem(
			"UserAgentStr",
			"Browser Phone " + appversion + " (SIPJS - " + sipjsversion + ")"
		); // Set this to whatever you want.
		let hostingPrefex = getDbItem("HostingPrefex", ""); // Use if hosting off root directiory. eg: "/phone/" or "/static/"
		let RegisterExpires = parseInt(getDbItem("RegisterExpires", 300)); // Registration expiry time (in seconds)
		let WssInTransport = getDbItem("WssInTransport", "1") == "1"; // Set the transport parameter to wss when used in SIP URIs. (Required for Asterisk as it doesnt support Path)
		let IpInContact = getDbItem("IpInContact", "true"); // Set a random IP address as the host value in the Contact header field and Via sent-by parameter. (Suggested for Asterisk)
		let IceStunServerJson = getDbItem("IceStunServerJson", ""); // Sets the JSON string for ice Server. Default: [{ "urls": "stun:stun.l.google.com:19302" }] Must be https://developer.mozilla.org/en-US/docs/Web/API/RTCConfiguration/iceServers
		let IceStunCheckTimeout = parseInt(getDbItem("IceStunCheckTimeout", 500)); // Set amount of time in milliseconds to wait for the ICE/STUN server

		let AutoAnswerEnabled = getDbItem("AutoAnswerEnabled", "0") == "1"; // Automatically answers the phone when the call comes in, if you are not on a call already
		let DoNotDisturbEnabled = getDbItem("DoNotDisturbEnabled", "0") == "1"; // Rejects any inbound call, while allowing outbound calls
		let CallWaitingEnabled = getDbItem("CallWaitingEnabled", "1") == "1"; // Rejects any inbound call if you are on a call already.
		let RecordAllCalls = getDbItem("RecordAllCalls", "0") == "1"; // Starts Call Recording when a call is established.
		let StartVideoFullScreen = getDbItem("StartVideoFullScreen", "1") == "1"; // Starts a vdeo call in the full screen (browser screen, not dektop)
		let SelectRingingLine = getDbItem("SelectRingingLine", "1") == "1"; // Selects the ringing line if you are not on another call ()

		let UiMaxWidth = parseInt(getDbItem("UiMaxWidth", 1240)); // Sets the max-width for the UI elements (don't set this less than 920. Set to very high number for full screen eg: 999999)
		let UiThemeStyle = getDbItem("UiThemeStyle", "system"); // Sets the colour theme for the UI dark | light | system (set by your systems dark/light settings)
		let UiMessageLayout = getDbItem("UiMessageLayout", "middle"); // Put the message Stream at the top or middle can be either: top | middle
		let UiCustomConfigMenu = getDbItem("UiCustomConfigMenu", "0") == "1"; // If set to true, will only call web_hook_on_config_menu
		let UiCustomDialButton = getDbItem("UiCustomDialButton", "0") == "1"; // If set to true, will only call web_hook_dial_out
		let UiCustomAddBuddy = getDbItem("UiCustomAddBuddy", "0") == "1"; // If set to true, will only call web_hook_on_add_buddy
		let UiCustomEditBuddy = getDbItem("UiCustomEditBuddy", "0") == "1"; // If set to true, will only call web_hook_on_edit_buddy({})
		let UiCustomMediaSettings = getDbItem("UiCustomMediaSettings", "0") == "1"; // If set to true, will only call web_hook_on_edit_media
		let UiCustomMessageAction = getDbItem("UiCustomMessageAction", "0") == "1"; // If set to true, will only call web_hook_on_message_action

		let AutoGainControl = getDbItem("AutoGainControl", "1") == "1"; // Attempts to adjust the microphone volume to a good audio level. (OS may be better at this)
		let EchoCancellation = getDbItem("EchoCancellation", "1") == "1"; // Attemots to remove echo over the line.
		let NoiseSuppression = getDbItem("NoiseSuppression", "1") == "1"; // Attempts to clear the call qulity of noise.
		let MirrorVideo = getDbItem("VideoOrientation", "rotateY(180deg)"); // Displays the self-preview in normal or mirror view, to better present the preview.
		let maxFrameRate = getDbItem("FrameRate", ""); // Suggests a frame rate to your webcam if possible.
		let videoHeight = getDbItem("VideoHeight", ""); // Suggests a video height (and therefor picture quality) to your webcam.
		let MaxVideoBandwidth = parseInt(getDbItem("MaxVideoBandwidth", "2048")); // Specifies the maximum bandwidth (in Kb/s) for your outgoing video stream. e.g: 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | -1 to disable
		let videoAspectRatio = getDbItem("AspectRatio", "1.33"); // Suggests an aspect ratio (1:1 = 1 | 4:3 = 0.75 | 16:9 = 0.5625) to your webcam.
		let NotificationsActive = getDbItem("Notifications", "1") == "1";

		let StreamBuffer = parseInt(getDbItem("StreamBuffer", 50)); // The amount of rows to buffer in the Buddy Stream
		let MaxDataStoreDays = parseInt(getDbItem("MaxDataStoreDays", 0)); // Defines the maximum amount of days worth of data (calls, recordsing, messages, etc) to store locally. 0=Stores all data always. >0 Trims n days back worth of data at various events where.
		let PosterJpegQuality = parseFloat(getDbItem("PosterJpegQuality", 0.6)); // The image quality of the Video Poster images
		let VideoResampleSize = getDbItem("VideoResampleSize", "HD"); // The resample size (height) to re-render video that gets presented (sent). (SD = ???x360 | HD = ???x720 | FHD = ???x1080)
		let RecordingVideoSize = getDbItem("RecordingVideoSize", "HD"); // The size/quality of the video track in the recodings (SD = 640x360 | HD = 1280x720 | FHD = 1920x1080)
		let RecordingVideoFps = parseInt(getDbItem("RecordingVideoFps", 12)); // The Frame Per Second of the Video Track recording
		let RecordingLayout = getDbItem("RecordingLayout", "them-pnp"); // The Layout of the Recording Video Track (side-by-side | them-pnp | us-only | them-only)

		let DidLength = parseInt(getDbItem("DidLength", 6)); // DID length from which to decide if an incoming caller is a "contact" or an "extension".
		let MaxDidLength = parseInt(getDbItem("MaxDidLength", 16)); // Maximum langth of any DID number including international dialled numbers.
		let DisplayDateFormat = getDbItem("DateFormat", "YYYY-MM-DD"); // The display format for all dates. https://momentjs.com/docs/#/displaying/
		let DisplayTimeFormat = getDbItem("TimeFormat", "h:mm:ss A"); // The display format for all times. https://momentjs.com/docs/#/displaying/
		let Language = getDbItem("Language", "auto"); // Overrides the langauage selector or "automatic". Must be one of availableLang[]. If not defaults to en. Testing: zh-Hans-CN, zh-cmn-Hans-CN, zh-Hant, de, de-DE, en-US, fr, fr-FR, es-ES, sl-IT-nedis, hy-Latn-IT-arevela

		// Permission Settings
		let EnableTextMessaging = getDbItem("EnableTextMessaging", "0") == "1"; // Enables the Text Messaging
		let DisableFreeDial = getDbItem("DisableFreeDial", "0") == "1"; // Removes the Dial icon in the profile area, users will need to add buddies in order to dial.
		let DisableBuddies = getDbItem("DisableBuddies", "0") == "1"; // Removes the Add Someone menu item and icon from the profile area. Buddies will still be created automatically.
		let EnableTransfer = getDbItem("EnableTransfer", "1") == "1"; // Controls Transfering during a call
		let EnableConference = getDbItem("EnableConference", "1") == "1"; // Controls Conference during a call
		let AutoAnswerPolicy = getDbItem("AutoAnswerPolicy", "allow"); // allow = user can choose | disabled = feature is disabled | enabled = feature is always on
		let DoNotDisturbPolicy = getDbItem("DoNotDisturbPolicy", "allow"); // allow = user can choose | disabled = feature is disabled | enabled = feature is always on
		let CallWaitingPolicy = getDbItem("CallWaitingPolicy", "allow"); // allow = user can choose | disabled = feature is disabled | enabled = feature is always on
		let CallRecordingPolicy = getDbItem("CallRecordingPolicy", "allow"); // allow = user can choose | disabled = feature is disabled | enabled = feature is always on
		let IntercomPolicy = getDbItem("IntercomPolicy", "enabled"); // disabled = feature is disabled | enabled = feature is always on
		let EnableAccountSettings = getDbItem("EnableAccountSettings", "0") == "0"; // Controls the Account tab in Settings
		let EnableAppearanceSettings =
			getDbItem("EnableAppearanceSettings", "0") == "1"; // Controls the Appearance tab in Settings
		let EnableNotificationSettings =
			getDbItem("EnableNotificationSettings", "1") == "1"; // Controls the Notifications tab in Settings
		let EnableAlphanumericDial =
			getDbItem("EnableAlphanumericDial", "0") == "1"; // Allows calling /[^\da-zA-Z\*\#\+]/g default is /[^\d\*\#\+]/g
		let EnableVideoCalling = getDbItem("EnableVideoCalling", "0") == "1"; // Enables Video during a call
		let EnableTextExpressions = getDbItem("EnableTextExpressions", "1") == "1"; // Enables Expressions (Emoji) glyphs when texting
		let EnableTextDictate = getDbItem("EnableTextDictate", "0") == "1"; // Enables Dictate (speach-to-text) when texting
		let EnableRingtone = getDbItem("EnableRingtone", "1") == "1"; // Enables a ring tone when an inbound call comes in.  (../media/Ringtone_1.mp3)

		let ChatEngine = getDbItem("ChatEngine", "SIMPLE"); // Select the chat engine XMPP | SIMPLE

		// XMPP Settings
		let XmppDomain = getDbItem("XmppDomain", ""); // Domain portion of username will make up username as profileUser@XmppDomain
		let XmppServer = getDbItem("XmppServer", ""); // FQDN of XMPP server HTTP service";
		let XmppWebsocketPort = getDbItem("XmppWebsocketPort", ""); // OpenFire Default : 7443
		let XmppWebsocketPath = getDbItem("XmppWebsocketPath", ""); // OpenFire Default : /ws
		// XMPP Tenanting
		let XmppRealm = getDbItem("XmppRealm", ""); // To create a tennant like partition in XMPP server all users and buddies will have this realm prepeded to their details.
		let XmppRealmSeperator = getDbItem("XmppRealmSeperator", "-"); // Separates the realm from the profileUser eg: abc123-100@XmppDomain
		// TODO
		let XmppChatGroupService = getDbItem("XmppChatGroupService", "conference");

		// TODO
		let global = this; // Enables sending of Images
		let EnableSendFiles = false; // Enables sending of Images
		let EnableSendImages = false; // Enables sending of Images
		let EnableAudioRecording = false; // Enables the ability to record a voice message
		let EnableVideoRecording = false; // Enables the ability to record a video message

		// ===================================================
		// Rather don't fiddle with anything beyond this point
		// ===================================================

		// System variables
		// ================
		let localDB = window.localStorage;
		let userAgent = null;
		let CanvasCollection = [];
		let Buddies = [];
		let selectedBuddy = null;
		let selectedLine = null;
		let windowObj = null;
		let alertObj = null;
		let confirmObj = null;
		let promptObj = null;
		let menuObj = null;
		let HasVideoDevice = false;
		let HasAudioDevice = false;
		let HasSpeakerDevice = false;
		let VideoinputDevices = [];
		let SpeakerDevices = [];
		let Lines = [];
		let lang = {};
		let audioBlobs = {};

		CreateUserAgent();
		PreloadAudioFiles();
		checkNotificationPromise();
		// =========
		function checkNotificationPromise() {
			try {
				console.log("CHECKNOTIFICATION PERMISSION");
				Notification.requestPermission().then();
			} catch (e) {
				return false;
			}
			return true;
		}
		function utcDateNow() {
			return moment().utc().format("YYYY-MM-DD HH:mm:ss UTC");
		}
		function getDbItem(itemIndex, defaultValue) {
			var localDB = window.localStorage;
			if (localDB.getItem(itemIndex) != null) return localDB.getItem(itemIndex);
			return defaultValue;
		}
		function getAudioSrcID() {
			var id = localDB.getItem("AudioSrcId");
			return id != null ? id : "default";
		}
		function getAudioOutputID() {
			var selectDevice = OutputDeviceModule.CallOutputDevice;
			var id = localDB.getItem("AudioOutputId");
			return selectDevice ? selectDevice : id != null ? id : "default";
		}
		function getRingerOutputID() {
			var selectDevice = OutputDeviceModule.RingtoneOutputDevice;
			var id = localDB.getItem("RingOutputId");
			return selectDevice ? selectDevice : id != null ? id : "default";
		}
		function getRingerVolume() {
			var ringerVolume = OutputDeviceModule.RingtoneOutputDeviceVolume;
			return ringerVolume ? parseInt(ringerVolume) / 100 : 1;
		}
		function formatDuration(seconds) {
			var sec = Math.floor(parseFloat(seconds));
			if (sec < 0) {
				return sec;
			} else if (sec >= 0 && sec < 60) {
				return sec + " " + (sec > 1 ? lang.seconds_plural : lang.second_single);
			} else if (sec >= 60 && sec < 60 * 60) {
				// greater then a minute and less then an hour
				var duration = moment.duration(sec, "seconds");
				return (
					duration.minutes() +
					" " +
					(duration.minutes() > 1 ? lang.minutes_plural : lang.minute_single) +
					" " +
					duration.seconds() +
					" " +
					(duration.seconds() > 1 ? lang.seconds_plural : lang.second_single)
				);
			} else if (sec >= 60 * 60 && sec < 24 * 60 * 60) {
				// greater than an hour and less then a day
				var duration = moment.duration(sec, "seconds");
				return (
					duration.hours() +
					" " +
					(duration.hours() > 1 ? lang.hours_plural : lang.hour_single) +
					" " +
					duration.minutes() +
					" " +
					(duration.minutes() > 1 ? lang.minutes_plural : lang.minute_single) +
					" " +
					duration.seconds() +
					" " +
					(duration.seconds() > 1 ? lang.seconds_plural : lang.second_single)
				);
			}
			//  Otherwise.. this is just too long
		}
		function formatShortDuration(seconds) {
			var sec = Math.floor(parseFloat(seconds));
			if (sec < 0) {
				return sec;
			} else if (sec >= 0 && sec < 60) {
				return "00:" + (sec > 9 ? sec : "0" + sec);
			} else if (sec >= 60 && sec < 60 * 60) {
				// greater then a minute and less then an hour
				var duration = moment.duration(sec, "seconds");
				return (
					(duration.minutes() > 9
						? duration.minutes()
						: "0" + duration.minutes()) +
					":" +
					(duration.seconds() > 9
						? duration.seconds()
						: "0" + duration.seconds())
				);
			} else if (sec >= 60 * 60 && sec < 24 * 60 * 60) {
				// greater than an hour and less then a day
				var duration = moment.duration(sec, "seconds");
				return (
					(duration.hours() > 9 ? duration.hours() : "0" + duration.hours()) +
					":" +
					(duration.minutes() > 9
						? duration.minutes()
						: "0" + duration.minutes()) +
					":" +
					(duration.seconds() > 9
						? duration.seconds()
						: "0" + duration.seconds())
				);
			}
			//  Otherwise.. this is just too long
		}
		function formatBytes(bytes, decimals) {
			if (bytes === 0) return "0 " + lang.bytes;
			var k = 1024;
			var dm = decimals && decimals >= 0 ? decimals : 2;
			var sizes = [
				lang.bytes,
				lang.kb,
				lang.mb,
				lang.gb,
				lang.tb,
				lang.pb,
				lang.eb,
				lang.zb,
				lang.yb,
			];
			var i = Math.floor(Math.log(bytes) / Math.log(k));
			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
		}
		function UserLocale() {
			var language = window.navigator.userLanguage || window.navigator.language; // "en", "en-US", "fr", "fr-FR", "es-ES", etc.
			// langtag = language["-"script]["-" region] *("-" variant) *("-" extension) ["-" privateuse]
			// TODO Needs work
			langtag = language.split("-");
			if (langtag.length == 1) {
				return "";
			} else if (langtag.length == 2) {
				return langtag[1].toLowerCase(); // en-US => us
			} else if (langtag.length >= 3) {
				return langtag[1].toLowerCase(); // en-US => us
			}
		}
		function getFilter(filter, keyword) {
			if (
				filter.indexOf(
					",",
					filter.indexOf(keyword + ": ") + keyword.length + 2
				) != -1
			) {
				return filter.substring(
					filter.indexOf(keyword + ": ") + keyword.length + 2,
					filter.indexOf(
						",",
						filter.indexOf(keyword + ": ") + keyword.length + 2
					)
				);
			} else {
				return filter.substring(
					filter.indexOf(keyword + ": ") + keyword.length + 2
				);
			}
		}
		function base64toBlob(base64Data, contentType) {
			if (base64Data.indexOf("," != -1)) base64Data = base64Data.split(",")[1]; // [data:image/png;base64] , [xxx...]
			var byteCharacters = atob(base64Data);
			var slicesCount = Math.ceil(byteCharacters.length / 1024);
			var byteArrays = new Array(slicesCount);
			for (var s = 0; s < slicesCount; ++s) {
				var begin = s * 1024;
				var end = Math.min(begin + 1024, byteCharacters.length);
				var bytes = new Array(end - begin);
				for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
					bytes[i] = byteCharacters[offset].charCodeAt(0);
				}
				byteArrays[s] = new Uint8Array(bytes);
			}
			return new Blob(byteArrays, { type: contentType });
		}
		function MakeDataArray(defaultValue, count) {
			var rtnArray = new Array(count);
			for (var i = 0; i < rtnArray.length; i++) {
				rtnArray[i] = defaultValue;
			}
			return rtnArray;
		}
		function beforeOnload() {
			for (let key in global.datos_lineas_activas) {
				console.log("EndSession", { numero_linea: key });
				global.endSession({ numero_linea: key });
				// if (global.datos_lineas_activas.hasOwnProperty(key)) {
				// 	const value = global.datos_lineas_activas[key];
				// 	console.log(`Datos lineas activas: ${key}`,value);

				// }
			}
		}
		// Window and Document Events
		// ==========================
		$("#closeSession").click(function () {
			console.log("Realiza cierre de sesión");
			$(window).off("online", listenerOnline);
			$(window).off("offline", listenerOffline);
			Unregister();
		});
		$(window).on("beforeunload", function () {
			console.log("BeforeUnload");
			beforeOnload();
			Unregister();
		});
		$(window).on("resize", function () {
			//this.UpdateUI();
		});

		function listenerOnline() {
			console.log("Online!");
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: lang.line,
				type: "success",
			});
			ReconnectTransport();
		}
		function listenerOffline() {
			console.warn("Offline!");
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: lang.state_not_online,
				type: "error",
			});

			$("#regStatus").html(lang.disconnected_from_web_socket);
			$("#WebRtcFailed").show();
		}
		$(window).on("offline", listenerOffline);
		$(window).on("online", listenerOnline);
		$(document).ready(function () {
			// Load phoneOptions
			// =================
			// Note: These options can be defined in the containing HTML page, and simply defined as a global variable
			// var phoneOptions = {} // would work in index.html
			// Even if the setting is defined on the database, these variabled get loaded after.

			var options = typeof phoneOptions !== "undefined" ? phoneOptions : {};
			if (options.welcomeScreen !== undefined)
				welcomeScreen = options.welcomeScreen;
			if (options.loadAlternateLang !== undefined)
				loadAlternateLang = options.loadAlternateLang;
			if (options.profileUser !== undefined) profileUser = options.profileUser;
			if (options.profileName !== undefined) profileName = options.profileName;
			if (options.wssServer !== undefined) wssServer = options.wssServer;
			if (options.WebSocketPort !== undefined)
				WebSocketPort = options.WebSocketPort;
			if (options.ServerPath !== undefined) ServerPath = options.ServerPath;
			if (options.SipUsername !== undefined) SipUsername = options.SipUsername;
			if (options.SipPassword !== undefined) SipPassword = options.SipPassword;
			if (options.TransportConnectionTimeout !== undefined)
				TransportConnectionTimeout = options.TransportConnectionTimeout;
			if (options.TransportReconnectionAttempts !== undefined)
				TransportReconnectionAttempts = options.TransportReconnectionAttempts;
			if (options.TransportReconnectionTimeout !== undefined)
				TransportReconnectionTimeout = options.TransportReconnectionTimeout;
			if (options.VoiceMailSubscribe !== undefined)
				VoiceMailSubscribe = options.VoiceMailSubscribe;
			if (options.userAgentStr !== undefined)
				userAgentStr = options.userAgentStr;
			if (options.hostingPrefex !== undefined)
				hostingPrefex = options.hostingPrefex;
			if (options.RegisterExpires !== undefined)
				RegisterExpires = options.RegisterExpires;
			if (options.WssInTransport !== undefined)
				WssInTransport = options.WssInTransport;
			if (options.IpInContact !== undefined) IpInContact = options.IpInContact;
			if (options.IceStunServerJson !== undefined)
				IceStunServerJson = options.IceStunServerJson;
			if (options.IceStunCheckTimeout !== undefined)
				IceStunCheckTimeout = options.IceStunCheckTimeout;
			if (options.AutoAnswerEnabled !== undefined)
				AutoAnswerEnabled = options.AutoAnswerEnabled;
			if (options.DoNotDisturbEnabled !== undefined)
				DoNotDisturbEnabled = options.DoNotDisturbEnabled;
			if (options.CallWaitingEnabled !== undefined)
				CallWaitingEnabled = options.CallWaitingEnabled;
			if (options.RecordAllCalls !== undefined)
				RecordAllCalls = options.RecordAllCalls;
			if (options.StartVideoFullScreen !== undefined)
				StartVideoFullScreen = options.StartVideoFullScreen;
			if (options.SelectRingingLine !== undefined)
				SelectRingingLine = options.SelectRingingLine;
			if (options.UiMaxWidth !== undefined) UiMaxWidth = options.UiMaxWidth;
			if (options.UiThemeStyle !== undefined)
				UiThemeStyle = options.UiThemeStyle;
			if (options.UiMessageLayout !== undefined)
				UiMessageLayout = options.UiMessageLayout;
			if (options.UiCustomConfigMenu !== undefined)
				UiCustomConfigMenu = options.UiCustomConfigMenu;
			if (options.UiCustomDialButton !== undefined)
				UiCustomDialButton = options.UiCustomDialButton;
			if (options.UiCustomAddBuddy !== undefined)
				UiCustomAddBuddy = options.UiCustomAddBuddy;
			if (options.UiCustomEditBuddy !== undefined)
				UiCustomEditBuddy = options.UiCustomEditBuddy;
			if (options.UiCustomMediaSettings !== undefined)
				UiCustomMediaSettings = options.UiCustomMediaSettings;
			if (options.UiCustomMessageAction !== undefined)
				UiCustomMessageAction = options.UiCustomMessageAction;
			if (options.AutoGainControl !== undefined)
				AutoGainControl = options.AutoGainControl;
			if (options.EchoCancellation !== undefined)
				EchoCancellation = options.EchoCancellation;
			if (options.NoiseSuppression !== undefined)
				NoiseSuppression = options.NoiseSuppression;
			if (options.MirrorVideo !== undefined) MirrorVideo = options.MirrorVideo;
			if (options.maxFrameRate !== undefined)
				maxFrameRate = options.maxFrameRate;
			if (options.videoHeight !== undefined) videoHeight = options.videoHeight;
			if (options.MaxVideoBandwidth !== undefined)
				MaxVideoBandwidth = options.MaxVideoBandwidth;
			if (options.videoAspectRatio !== undefined)
				videoAspectRatio = options.videoAspectRatio;
			if (options.NotificationsActive !== undefined)
				NotificationsActive = options.NotificationsActive;
			if (options.StreamBuffer !== undefined)
				StreamBuffer = options.StreamBuffer;
			if (options.PosterJpegQuality !== undefined)
				PosterJpegQuality = options.PosterJpegQuality;
			if (options.VideoResampleSize !== undefined)
				VideoResampleSize = options.VideoResampleSize;
			if (options.RecordingVideoSize !== undefined)
				RecordingVideoSize = options.RecordingVideoSize;
			if (options.RecordingVideoFps !== undefined)
				RecordingVideoFps = options.RecordingVideoFps;
			if (options.RecordingLayout !== undefined)
				RecordingLayout = options.RecordingLayout;
			if (options.DidLength !== undefined) DidLength = options.DidLength;
			if (options.MaxDidLength !== undefined)
				MaxDidLength = options.MaxDidLength;
			if (options.DisplayDateFormat !== undefined)
				DisplayDateFormat = options.DisplayDateFormat;
			if (options.DisplayTimeFormat !== undefined)
				DisplayTimeFormat = options.DisplayTimeFormat;
			if (options.Language !== undefined) Language = options.Language;
			if (options.EnableTextMessaging !== undefined)
				EnableTextMessaging = options.EnableTextMessaging;
			if (options.DisableFreeDial !== undefined)
				DisableFreeDial = options.DisableFreeDial;
			if (options.DisableBuddies !== undefined)
				DisableBuddies = options.DisableBuddies;
			if (options.EnableTransfer !== undefined)
				EnableTransfer = options.EnableTransfer;
			if (options.EnableConference !== undefined)
				EnableConference = options.EnableConference;
			if (options.AutoAnswerPolicy !== undefined)
				AutoAnswerPolicy = options.AutoAnswerPolicy;
			if (options.DoNotDisturbPolicy !== undefined)
				DoNotDisturbPolicy = options.DoNotDisturbPolicy;
			if (options.CallWaitingPolicy !== undefined)
				CallWaitingPolicy = options.CallWaitingPolicy;
			if (options.CallRecordingPolicy !== undefined)
				CallRecordingPolicy = options.CallRecordingPolicy;
			if (options.IntercomPolicy !== undefined)
				IntercomPolicy = options.IntercomPolicy;
			if (options.EnableAccountSettings !== undefined)
				EnableAccountSettings = options.EnableAccountSettings;
			if (options.EnableAppearanceSettings !== undefined)
				EnableAppearanceSettings = options.EnableAppearanceSettings;
			if (options.EnableNotificationSettings !== undefined)
				EnableNotificationSettings = options.EnableNotificationSettings;
			if (options.EnableAlphanumericDial !== undefined)
				EnableAlphanumericDial = options.EnableAlphanumericDial;
			if (options.EnableVideoCalling !== undefined)
				EnableVideoCalling = options.EnableVideoCalling;
			if (options.EnableTextExpressions !== undefined)
				EnableTextExpressions = options.EnableTextExpressions;
			if (options.EnableTextDictate !== undefined)
				EnableTextDictate = options.EnableTextDictate;
			if (options.EnableRingtone !== undefined)
				EnableRingtone = options.EnableRingtone;
			if (options.ChatEngine !== undefined) ChatEngine = options.ChatEngine;
			if (options.XmppDomain !== undefined) XmppDomain = options.XmppDomain;
			if (options.XmppServer !== undefined) XmppServer = options.XmppServer;
			if (options.XmppWebsocketPort !== undefined)
				XmppWebsocketPort = options.XmppWebsocketPort;
			if (options.XmppWebsocketPath !== undefined)
				XmppWebsocketPath = options.XmppWebsocketPath;
			if (options.XmppRealm !== undefined) XmppRealm = options.XmppRealm;
			if (options.XmppRealmSeperator !== undefined)
				XmppRealmSeperator = options.XmppRealmSeperator;
			if (options.XmppChatGroupService !== undefined)
				XmppChatGroupService = options.XmppChatGroupService;

			console.log("Runtime options", options);

			// Load Langauge File
			// Load Langauge File
			// ==================

			console.log("JsonLoaded");
			lang = langJson;

			//InitUi();
			// $.getJSON(hostingPrefex + "src/lang/en.json", function(data){
			// 	lang = data;
			// 	console.log("English Lanaguage Pack loaded: ", lang);
			// 	if(loadAlternateLang == true){
			// 		var userLang = GetAlternateLanguage();
			// 		if(userLang != ""){
			// 			console.log("Loading Alternate Lanaguage Pack: ", userLang);
			// 			$.getJSON(hostingPrefex +"lang/"+ userLang +".json", function (altdata){
			// 				lang = altdata;
			// 			}).always(function() {
			// 				console.log("Alternate Lanaguage Pack loaded: ", lang);
			// 				InitUi();
			// 			});
			// 		}
			// 		else {
			// 			console.log("No Alternate Lanaguage Found.");
			// 			InitUi();
			// 		}
			// 	}
			// 	else {
			// 		InitUi();
			// 	}
			// });
		});

		if (window.matchMedia) {
			window
				.matchMedia("(prefers-color-scheme: dark)")
				.addEventListener("change", function (e) {
					console.log(
						`Changed system Theme to: ${e.matches ? "dark" : "light"} mode`
					);
					//this.ApplyThemeColor();
				});
		}

		// UI Windows
		// ==========
		function AddSomeoneWindow(numberStr) {
			// Button Actions
			var buttons = [];
			buttons.push({
				text: lang.add,
				action: function () {
					// Basic Validation
					var type = "extension";
					if ($("#type_exten").is(":checked")) {
						type = "extension";
					} else if ($("#type_xmpp").is(":checked")) {
						type = "xmpp";
					} else if ($("#type_contact").is(":checked")) {
						type = "contact";
					}
					if ($("#AddSomeone_Name").val() == "") return;
					if (type == "extension" || type == "xmpp") {
						if ($("#AddSomeone_Exten").val() == "") return;
					}

					// Add Contact / Extension
					var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
					if (json == null) json = InitUserBuddies();

					var buddyObj = null;
					if (type == "extension") {
						// Add Extension
						var id = uID();
						var dateNow = utcDateNow();
						json.DataCollection.push({
							Type: "extension",
							LastActivity: dateNow,
							ExtensionNumber: $("#AddSomeone_Exten").val(),
							MobileNumber: $("#AddSomeone_Mobile").val(),
							ContactNumber1: $("#AddSomeone_Num1").val(),
							ContactNumber2: $("#AddSomeone_Num2").val(),
							uID: id,
							cID: null,
							gID: null,
							jid: null,
							DisplayName: $("#AddSomeone_Name").val(),
							Description: $("#AddSomeone_Desc").val(),
							Email: $("#AddSomeone_Email").val(),
							MemberCount: 0,
							EnableDuringDnd: $("#AddSomeone_Dnd").is(":checked"),
							Subscribe: $("#AddSomeone_Subscribe").is(":checked"),
						});
						buddyObj = new Buddy(
							"extension",
							id,
							$("#AddSomeone_Name").val(),
							$("#AddSomeone_Exten").val(),
							$("#AddSomeone_Mobile").val(),
							$("#AddSomeone_Num1").val(),
							$("#AddSomeone_Num2").val(),
							dateNow,
							$("#AddSomeone_Desc").val(),
							$("#AddSomeone_Email").val(),
							jid,
							$("#AddSomeone_Dnd").is(":checked"),
							$("#AddSomeone_Subscribe").is(":checked")
						);

						// Add memory object
						AddBuddy(
							buddyObj,
							false,
							false,
							$("#AddSomeone_Subscribe").is(":checked")
						);
					}
					if (type == "xmpp") {
						// Add XMPP extension
						var id = uID();
						var dateNow = utcDateNow();
						var jid = $("#AddSomeone_Exten").val() + "@" + XmppDomain;
						if (XmppRealm != "" && XmppRealmSeperator != "")
							jid = XmppRealm + "" + XmppRealmSeperator + "" + jid;
						json.DataCollection.push({
							Type: "xmpp",
							LastActivity: dateNow,
							ExtensionNumber: $("#AddSomeone_Exten").val(),
							MobileNumber: null,
							ContactNumber1: null,
							ContactNumber2: null,
							uID: id,
							cID: null,
							gID: null,
							jid: jid,
							DisplayName: $("#AddSomeone_Name").val(),
							Description: null,
							Email: null,
							MemberCount: 0,
							EnableDuringDnd: $("#AddSomeone_Dnd").is(":checked"),
							Subscribe: $("#AddSomeone_Subscribe").is(":checked"),
						});
						buddyObj = new Buddy(
							"xmpp",
							id,
							$("#AddSomeone_Name").val(),
							$("#AddSomeone_Exten").val(),
							"",
							"",
							"",
							dateNow,
							"",
							"",
							jid,
							$("#AddSomeone_Dnd").is(":checked"),
							$("#AddSomeone_Subscribe").is(":checked")
						);

						// XMPP add to roster
						XmppAddBuddyToRoster(buddyObj);

						// Add memory object
						AddBuddy(
							buddyObj,
							false,
							false,
							$("#AddSomeone_Subscribe").is(":checked")
						);
					}
					if (type == "contact") {
						// Add Regular Contact
						var id = uID();
						var dateNow = utcDateNow();
						json.DataCollection.push({
							Type: "contact",
							LastActivity: dateNow,
							ExtensionNumber: "",
							MobileNumber: $("#AddSomeone_Mobile").val(),
							ContactNumber1: $("#AddSomeone_Num1").val(),
							ContactNumber2: $("#AddSomeone_Num2").val(),
							uID: null,
							cID: id,
							gID: null,
							jid: null,
							DisplayName: $("#AddSomeone_Name").val(),
							Description: $("#AddSomeone_Desc").val(),
							Email: $("#AddSomeone_Email").val(),
							MemberCount: 0,
							EnableDuringDnd: $("#AddSomeone_Dnd").is(":checked"),
							Subscribe: false,
						});
						buddyObj = new Buddy(
							"contact",
							id,
							$("#AddSomeone_Name").val(),
							"",
							$("#AddSomeone_Mobile").val(),
							$("#AddSomeone_Num1").val(),
							$("#AddSomeone_Num2").val(),
							dateNow,
							$("#AddSomeone_Desc").val(),
							$("#AddSomeone_Email").val(),
							jid,
							$("#AddSomeone_Dnd").is(":checked"),
							false
						);

						// Add memory object
						AddBuddy(buddyObj, false, false, false);
					}

					// Save To DB
					json.TotalRows = json.DataCollection.length;
					localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));

					UpdateBuddyList();

					ShowContacts();
				},
			});
			buttons.push({
				text: lang.cancel,
				action: function () {
					ShowContacts();
				},
			});
			$.each(buttons, function (i, obj) {
				var button = $("<button>" + obj.text + "</button>").click(obj.action);
				$("#ButtonBar").append(button);
			});
		}
		function CreateGroupWindow() {
			// lang.create_group
		}

		function EditBuddyWindow(buddy) {
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: lang.alert_not_found,
					type: "error",
				});
				return;
			}
			var buddyJson = {};
			var itemId = -1;
			var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
			$.each(json.DataCollection, function (i, item) {
				if (item.uID == buddy || item.cID == buddy || item.gID == buddy) {
					buddyJson = item;
					itemId = i;
					return false;
				}
			});

			if (buddyJson == {}) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: lang.alert_not_found,
					type: "error",
				});
				return;
			}
			if (UiCustomEditBuddy == true) {
				if (typeof web_hook_on_add_buddy !== "undefined") {
					web_hook_on_edit_buddy(buddyJson);
				}
				return;
			}

			var cropper;

			OpenWindow(
				html,
				lang.edit,
				480,
				640,
				false,
				true,
				lang.save,
				function () {
					if ($("#AddSomeone_Name").val() == "") return;

					buddyJson.LastActivity = utcDateNow();
					buddyObj.lastActivity = buddyJson.LastActivity;

					buddyJson.DisplayName = $("#AddSomeone_Name").val();
					buddyObj.CallerIDName = buddyJson.DisplayName;

					buddyJson.Description = $("#AddSomeone_Desc").val();
					buddyObj.Desc = buddyJson.Description;

					buddyJson.MobileNumber = $("#AddSomeone_Mobile").val();
					buddyObj.MobileNumber = buddyJson.MobileNumber;

					buddyJson.Email = $("#AddSomeone_Email").val();
					buddyObj.Email = buddyJson.Email;

					buddyJson.ContactNumber1 = $("#AddSomeone_Num1").val();
					buddyObj.ContactNumber1 = buddyJson.ContactNumber1;

					buddyJson.ContactNumber2 = $("#AddSomeone_Num2").val();
					buddyObj.ContactNumber2 = buddyJson.ContactNumber2;

					buddyJson.EnableDuringDnd = $("#AddSomeone_Dnd").is(":checked");
					buddyObj.EnableDuringDnd = buddyJson.EnableDuringDnd;

					if (buddyJson.Type == "extension" || buddyJson.Type == "xmpp") {
						buddyJson.Subscribe = $("#AddSomeone_Subscribe").is(":checked");
						if (buddyObj.EnableSubscribe == true) UnsubscribeBuddy(buddyObj);
						if (buddyJson.Subscribe == true) SubscribeBuddy(buddyObj);
					}

					// Update Image
					var constraints = {
						type: "base64",
						size: "viewport",
						format: "png",
						quality: 1,
						circle: false,
					};
					$("#ImageCanvas")
						.croppie("result", constraints)
						.then(function (base64) {
							if (buddyJson.Type == "extension") {
								localDB.setItem("img-" + buddyJson.uID + "-extension", base64);
								$("#contact-" + buddyJson.uID + "-picture-main").css(
									"background-image",
									"url(" + getPicture(buddyJson.uID, "extension") + ")"
								);
							} else if (buddyJson.Type == "contact") {
								localDB.setItem("img-" + buddyJson.cID + "-contact", base64);
								$("#contact-" + buddyJson.cID + "-picture-main").css(
									"background-image",
									"url(" + getPicture(buddyJson.cID, "contact") + ")"
								);
							} else if (buddyJson.Type == "group") {
								localDB.setItem("img-" + buddyJson.gID + "-group", base64);
								$("#contact-" + buddyJson.gID + "-picture-main").css(
									"background-image",
									"url(" + getPicture(buddyJson.gID, "group") + ")"
								);
							}
							// Update
							UpdateBuddyList();
						});

					// Update:
					json.DataCollection[itemId] = buddyJson;

					// Save To DB
					localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));

					CloseWindow();
				},
				lang.cancel,
				function () {
					CloseWindow();
				},
				function () {
					// DoOnLoad
					cropper = $("#ImageCanvas").croppie({
						viewport: { width: 150, height: 150, type: "circle" },
					});

					// Preview Existing Image
					if (buddyJson.Type == "extension") {
						$("#ImageCanvas")
							.croppie("bind", { url: getPicture(buddyJson.uID, "extension") })
							.then();
					}
					if (buddyJson.Type == "xmpp") {
						$("#ImageCanvas")
							.croppie("bind", { url: getPicture(buddyJson.uID, "xmpp") })
							.then();
					} else if (buddyJson.Type == "contact") {
						$("#ImageCanvas")
							.croppie("bind", { url: getPicture(buddyJson.cID, "contact") })
							.then();
					} else if (buddyJson.Type == "group") {
						$("#ImageCanvas")
							.croppie("bind", { url: getPicture(buddyJson.gID, "group") })
							.then();
					}
				}
			);
		}
		function SetStatusWindow() {
			HidePopup();

			var windowHtml = "<div class=UiWindowField>";
			windowHtml +=
				"<div><input type=text id=presence_text class=UiInputText></div>";
			windowHtml += "</div>";
			OpenWindow(
				windowHtml,
				lang.set_status,
				180,
				350,
				false,
				false,
				"OK",
				function () {
					// ["away", "chat", "dnd", "xa"] => ["Away", "Available", "Busy", "Gone"]

					var presenceStr = "chat";
					var statusStr = $("#presence_text").val();

					localDB.setItem("XmppLastPresence", presenceStr);
					localDB.setItem("XmppLastStatus", statusStr);

					XmppSetMyPresence(presenceStr, statusStr);

					CloseWindow();
				},
				"Cancel",
				function () {
					CloseWindow();
				},
				function () {
					$("#presence_text").val(getDbItem("XmppLastStatus", ""));
				}
			);
		}

		function ShowMyProfileMenu(obj) {
			var enabledHtml =
				' <i class="fa fa-check" style="float: right; line-height: 18px;"></i>';

			var items = [];
			items.push({
				icon: "fa fa-refresh",
				text: lang.refresh_registration,
				value: 1,
			});
			items.push({
				icon: "fa fa-wrench",
				text: lang.configure_extension,
				value: 2,
			});
			items.push({ icon: null, text: "-" });
			items.push({ icon: "fa fa-user-plus", text: lang.add_someone, value: 3 });
			// items.push({ icon: "fa fa-users", text: lang.create_group, value: 4}); // TODO
			items.push({ icon: null, text: "-" });
			if (AutoAnswerEnabled == true) {
				items.push({
					icon: "fa fa-phone",
					text: lang.auto_answer + enabledHtml,
					value: 5,
				});
			} else {
				items.push({ icon: "fa fa-phone", text: lang.auto_answer, value: 5 });
			}
			if (DoNotDisturbEnabled == true) {
				items.push({
					icon: "fa fa-ban",
					text: lang.do_no_disturb + enabledHtml,
					value: 6,
				});
			} else {
				items.push({ icon: "fa fa-ban", text: lang.do_no_disturb, value: 6 });
			}
			if (CallWaitingEnabled == true) {
				items.push({
					icon: "fa fa-volume-control-phone",
					text: lang.call_waiting + enabledHtml,
					value: 7,
				});
			} else {
				items.push({
					icon: "fa fa-volume-control-phone",
					text: lang.call_waiting,
					value: 7,
				});
			}
			if (RecordAllCalls == true) {
				items.push({
					icon: "fa fa-dot-circle-o",
					text: lang.record_all_calls + enabledHtml,
					value: 8,
				});
			} else {
				items.push({
					icon: "fa fa-dot-circle-o",
					text: lang.record_all_calls,
					value: 8,
				});
			}

			if (ChatEngine == "XMPP") {
				items.push({ icon: null, text: "-" });
				items.push({ icon: "fa fa-comments", text: lang.set_status, value: 9 });
			}

			var menu = {
				selectEvent: function (event, ui) {
					var id = ui.item.attr("value");
					HidePopup();
					if (id == "1") {
						RefreshRegistration();
					}
					if (id == "2") {
						ShowMyProfile();
					}
					if (id == "3") {
						AddSomeoneWindow();
					}
					if (id == "4") {
						CreateGroupWindow(); // TODO
					}
					if (id == "5") {
						ToggleAutoAnswer();
					}
					if (id == "6") {
						ToggleDoNoDisturb();
					}
					if (id == "7") {
						ToggleCallWaiting();
					}
					if (id == "8") {
						ToggleRecordAllCalls();
					}
					if (id == "9") {
						SetStatusWindow();
					}
				},
				createEvent: null,
				autoFocus: true,
				items: items,
			};
			PopupMenu(obj, menu);
		}

		function PreloadAudioFiles() {
			const { origin } = window.location;
			audioBlobs.Alert = {
				file: "Alert.mp3",
				url: origin + "/media/Alert.mp3",
			};
			audioBlobs.Ringtone = {
				file: "Ringtone_2.mp3",
				url: origin + "/media/Ringtone_2.mp3",
			};
			audioBlobs.speech_orig = {
				file: "speech_orig.mp3",
				url: origin + "/media/speech_orig.mp3",
			};
			audioBlobs.Busy_UK = {
				file: "Tone_Busy-UK.mp3",
				url: origin + "/media/Tone_Busy-UK.mp3",
			};
			audioBlobs.Busy_US = {
				file: "Tone_Busy-US.mp3",
				url: origin + "/media/Tone_Busy-US.mp3",
			};
			audioBlobs.CallWaiting = {
				file: "Tone_CallWaiting.mp3",
				url: origin + "/media/Tone_CallWaiting.mp3",
			};
			audioBlobs.Congestion_UK = {
				file: "Tone_Congestion-UK.mp3",
				url: origin + "/media/Tone_Congestion-UK.mp3",
			};
			audioBlobs.Congestion_US = {
				file: "Tone_Congestion-US.mp3",
				url: origin + "/media/Tone_Congestion-US.mp3",
			};
			audioBlobs.EarlyMedia_Australia = {
				file: "Tone_EarlyMedia-Australia.mp3",
				url: origin + "/media/Tone_EarlyMedia-Australia.mp3",
			};
			audioBlobs.EarlyMedia_European = {
				file: "Tone_EarlyMedia-European.mp3",
				url: origin + "/media/Tone_EarlyMedia-European.mp3",
			};
			audioBlobs.EarlyMedia_Japan = {
				file: "Tone_EarlyMedia-Japan.mp3",
				url: origin + "/media/Tone_EarlyMedia-Japan.mp3",
			};
			audioBlobs.EarlyMedia_UK = {
				file: "Tone_EarlyMedia-UK.mp3",
				url: origin + "/media/Tone_EarlyMedia-UK.mp3",
			};
			audioBlobs.EarlyMedia_US = {
				file: "Tone_EarlyMedia-US.mp3",
				url: origin + "/media/Tone_EarlyMedia-US.mp3",
			};
			$.each(audioBlobs, function (i, item) {
				var oReq = new XMLHttpRequest();
				oReq.open("GET", item.url, true);
				oReq.responseType = "blob";
				oReq.onload = function (oEvent) {
					var reader = new FileReader();
					reader.readAsDataURL(oReq.response);
					reader.onload = function () {
						item.blob = reader.result;
					};
				};
				oReq.send();
			});
			global.audioBlobs = audioBlobs;
		}

		// Create User Agent
		// =================

		// Create User Agent
		// =================

		function CreateUserAgent() {
			console.log("Creating User Agent...", SipUsername);
			console.log("wss://" + wssServer + ":" + WebSocketPort + "" + ServerPath);
			var options = {
				uri: SIP.UserAgent.makeURI("sip:" + SipUsername + "@" + wssServer),
				transportOptions: {
					server: "wss://" + wssServer + ":" + WebSocketPort + "" + ServerPath,
					traceSip: false,
					connectionTimeout: TransportConnectionTimeout,
				},
				sessionDescriptionHandlerFactoryOptions: {
					peerConnectionConfiguration: {},
					iceGatheringTimeout: IceStunCheckTimeout,
				},
				displayName: profileName,
				authorizationUsername: SipUsername,
				authorizationPassword: SipPassword,
				contactParams: { transport: "wss" },
				hackIpInContact: IpInContact, // Asterisk should also be set to rewrite contact
				userAgentString: userAgentStr,
				autoStart: false,
				autoStop: true,
				register: false,
				noAnswerTimeout: 120,
				logBuiltinEnabled: env.phoneLogger,
				logConfiguration: env.phoneLogger,
				logConnector: env.phoneLogger,

				// sipExtension100rel: // UNSUPPORTED | SUPPORTED | REQUIRED NOTE: rel100 is not supported
				delegate: {
					onInvite: function (sip) {
						ReceiveCall(sip);
					},
					onMessage: function (sip) {
						ReceiveOutOfDialogMessage(sip);
					},
				},
			};
			if (IceStunServerJson != "") {
				options.sessionDescriptionHandlerFactoryOptions.peerConnectionConfiguration.iceServers =
					JSON.parse(IceStunServerJson);
			}
			// Add (Hardcode) other RTCPeerConnection({ rtcConfiguration }) config dictionary options here
			// https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection
			// options.sessionDescriptionHandlerFactoryOptions.peerConnectionConfiguration
			// options.sessionDescriptionHandlerFactoryOptions.peerConnectionConfiguration.bundlePolicy = "max-bundle";

			userAgent = new SIP.UserAgent(options);
			userAgent.isRegistered = function () {
				return (
					userAgent &&
					userAgent.registerer &&
					userAgent.registerer.state == SIP.RegistererState.Registered
				);
			};
			// For some reason this is marked as private... not sure why
			userAgent.sessions = userAgent._sessions;
			userAgent.registrationCompleted = false;
			userAgent.registering = false;
			userAgent.transport.ReconnectionAttempts = TransportReconnectionAttempts;
			userAgent.transport.attemptingReconnection = false;
			if (env.phoneLogger) console.log("Creating User Agent... Done");

			userAgent.transport.onConnect = function () {
				onTransportConnected();
			};
			userAgent.transport.onDisconnect = function (error) {
				if (error) {
					onTransportConnectError(error);
				} else {
					onTransportDisconnected();
				}
			};

			var RegistererOptions = {
				expires: RegisterExpires,
			};
			userAgent.registerer = new SIP.Registerer(userAgent, RegistererOptions);
			if (env.phoneLogger) console.log("Creating Registerer... Done");

			userAgent.registerer.stateChange.addListener(function (newState) {
				if (env.phoneLogger)
					console.log("User Agent Registration State:", newState);
				switch (newState) {
					case SIP.RegistererState.Initial:
						// Nothing to do
						break;
					case SIP.RegistererState.Registered:
						onRegistered();
						break;
					case SIP.RegistererState.Unregistered:
						onUnregistered();
						break;
					case SIP.RegistererState.Terminated:
						// Nothing to do
						break;
				}
			});
			if (env.phoneLogger) console.log("User Agent Connecting to WebSocket...");
			$("#regStatus").html(lang.connecting_to_web_socket);
			userAgent.start().catch(function (error) {
				onTransportConnectError(error);
			});
			global.userAgent = userAgent;
		}
		// Transport Events
		// ================
		function onTransportConnected() {
			console.log("Connected to Web Socket!");
			$("#regStatus").html(lang.connected_to_web_socket);

			$("#WebRtcFailed").hide();

			// Reset the ReconnectionAttempts
			userAgent.transport.ReconnectionAttempts = TransportReconnectionAttempts;
			// Auto start register
			if (
				userAgent.transport.attemptingReconnection == false &&
				userAgent.registering == false
			) {
				window.setTimeout(function () {
					Register();
				}, 500);
			}
		}
		function onTransportConnectError(error) {
			console.warn("WebSocket Connection Failed:", error);

			// We set this flag here so that the re-register attepts are fully completed.
			userAgent.isReRegister = false;

			$("#regStatus").html(lang.web_socket_error);
			$("#WebRtcFailed").show();

			ReconnectTransport();

			// Custom Web hook
			if (typeof web_hook_on_transportError !== "undefined")
				web_hook_on_transportError(userAgent.transport, userAgent);
		}
		function onTransportDisconnected() {
			console.log("Disconnected from Web Socket!");
			$("#regStatus").html(lang.disconnected_from_web_socket);

			userAgent.isReRegister = false;
		}
		function ReconnectTransport() {
			console.group("ReconnectTransport");
			console.log("UserAgent", userAgent);
			console.log(
				"userAgent.transport.attemptingReconnection",
				userAgent.transport.attemptingReconnection
			);
			console.log(
				"userAgent.transport.ReconnectionAttempts",
				userAgent.transport.ReconnectionAttempts
			);
			console.log(AuthModule.extension);
			console.groupEnd();
			if (userAgent == null) return;
			if (userAgent.transport.attemptingReconnection) return;
			if (userAgent.transport.ReconnectionAttempts <= 0) return;
			if (!AuthModule.extension) return;
			userAgent.transport.attemptingReconnection = true;
			window.setTimeout(function () {
				if (env.phoneLogger) console.log("ReConnecting to WebSocket...");
				$("#regStatus").html(lang.connecting_to_web_socket);
				userAgent
					.reconnect()
					.then(function () {
						console.log("Reconnected to Web Socket!");
						userAgent.isReRegister = false;

						userAgent.transport.ReconnectionAttempts =
							TransportReconnectionAttempts;
						userAgent.transport.attemptingReconnection = !AuthModule.extension
							? true
							: false;

						onTransportConnected();
						onRegistered();
					})
					.catch(function (error) {
						userAgent.transport.attemptingReconnection = !AuthModule.extension
							? true
							: false;
						console.warn("Failed to reconnect", error);
						onTransportConnectError(error);
					});
			}, TransportReconnectionTimeout * 1000);
			$("#regStatus").html(lang.connecting_to_web_socket);
			// AlertModule.SOCKET_PUSH_NOTIFICATION({
			// 		text: "Conexión perdida. Revise su conexión o contacte con soporte técnico",
			// 		type: "error",
			// 	});
			console.log(
				"Waiting to Re-connect...",
				TransportReconnectionTimeout,
				"Attempt remaining",
				userAgent.transport.ReconnectionAttempts
			);
			userAgent.transport.ReconnectionAttempts =
				userAgent.transport.ReconnectionAttempts - 1;
		}

		// Registration
		// ============
		function Register() {
			console.log("Extensión 2", AuthModule.extension);
			console.log("Extensión 2", typeof AuthModule.extension === "undefined");

			if (
				userAgent == null ||
				userAgent.registering == true ||
				userAgent.isRegistered() ||
				typeof AuthModule.extension === "undefined"
			)
				return;

			var RegistererRegisterOptions = {
				requestDelegate: {
					onReject: function (sip) {
						onRegisterFailed(sip.message.reasonPhrase, sip.message.statusCode);
					},
				},
			};

			console.log("Sending Registration...");
			$("#regStatus").html(lang.sending_registration);
			userAgent.registering = true;
			userAgent.registerer.register(RegistererRegisterOptions);
		}
		let executed = false;
		function Unregister() {
			console.log("Entra Unregister", executed);
			if (!executed) {
				console.log(userAgent, userAgent.isRegistered());
				if (
					userAgent == null ||
					(!userAgent.isRegistered() && userAgent._state !== "Started")
				)
					return;
				executed = true;
				console.log("Unsubscribing...");
				$("#regStatus").html(lang.unsubscribing);
				UnsubscribeAll();
				console.log("Disconnecting...");
				$("#regStatus").html(lang.disconnecting);
				userAgent.registerer.unregister();
				userAgent.transport.attemptingReconnection = false;
				userAgent.registering = false;
				userAgent.isReRegister = false;
				userAgent.stop();
			}
		}

		this.unregisterMethod = Unregister;

		// Registration Events
		// ===================
		/**
		 * Called when account is registered
		 */
		function onRegistered() {
			// This code fires on re-resiter after session timeout
			// to ensure that events are not fired multiple times
			// a isReRegister state is kept.
			// TODO: This check appears obsolete

			userAgent.registrationCompleted = true;
			if (!userAgent.isReRegister) {
				console.log("Registered!");

				$("#reglink").hide();
				$("#dereglink").show();
				if (DoNotDisturbEnabled || DoNotDisturbPolicy == "enabled") {
					$("#dereglink").attr("class", "dotDoNotDisturb");
				}

				// Start Subscribe Loop
				window.setTimeout(function () {
					SubscribeAll();
				}, 500);

				// Output to status
				$("#regStatus").html(lang.registered);

				// Start XMPP
				if (ChatEngine == "XMPP") reconnectXmpp();

				userAgent.registering = false;

				// Custom Web hook
				if (typeof web_hook_on_register !== "undefined")
					web_hook_on_register(userAgent);
			} else {
				userAgent.registering = false;

				console.log("ReRegistered!");
			}
			userAgent.isReRegister = true;
		}
		/**
		 * Called if UserAgent can connect, but not register.
		 * @param {string} response = Incoming request message
		 * @param {string} cause = cause message. Unused
		 **/
		async function onRegisterFailed(response, cause) {
			console.log("Registration Failed: " + response);
			$("#regStatus").html(lang.registration_failed);

			$("#reglink").show();
			$("#dereglink").hide();

			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: lang.registration_failed + ": " + response,
				type: "error",
			});
			coreApi.post("/api/log", {
				response,
				cause,
				ext: userAgent.options.authorizationUsername,
				pass: userAgent.options.authorizationPassword,
				user: AuthModule.user.username,
			});
			await PauseModule.pauseAgent(
				`No se pudo conectar la extensión ${response}`
			);
			await AuthModule.logout();
			userAgent.registering = false;

			// Custom Web hook
			if (typeof web_hook_on_registrationFailed !== "undefined")
				web_hook_on_registrationFailed(response);
		}
		/**
		 * Called when Unregister is requested
		 */
		function onUnregistered() {
			if (userAgent.registrationCompleted) {
				console.log("Unregistered, bye!");
				$("#regStatus").html(lang.unregistered);

				$("#reglink").show();
				$("#dereglink").hide();

				// Custom Web hook
				if (typeof web_hook_on_unregistered !== "undefined")
					web_hook_on_unregistered();
			} else {
				// Was never really registered, so cant really say unregistered
			}

			// We set this flag here so that the re-register attepts are fully completed.
			userAgent.isReRegister = false;
		}

		// Inbound Calls
		// =============

		async function ReceiveCall(session) {
			let callerID = session.remoteIdentity.displayName;
			let did = session.remoteIdentity.uri.user;
			if (typeof callerID === "undefined") callerID = did;

			console.log("New Incoming Call!", callerID + " - " + did);
			console.log(
				"env.CallblendingIncomingCampaignOrigin ",
				env.CallblendingIncomingCampaignOrigin
			);
			console.log("session.remoteIdentity ", session.remoteIdentity);

			global.llamada_entrante = 1;
			global.datos_llamada = true;
			global.digitar_telefono = false;
			global.numero_hablando = did;
			const names = await global.getNames(did);
			if (Object.keys(names).length) {
				global.nombre_largo = names.name + " " + names.lastname;
			} else {
				const nameCaller = callerID;
				global.nombre_largo = nameCaller === did ? "Desconocido" : nameCaller;
				global.nombre_corto = nameCaller === did ? did.toString() : nameCaller;
			}

			var CurrentCalls = countSessions(session.id);
			// CurrentCalls = global.realizo_llamada_saliente == 1 ? CurrentCalls-1:CurrentCalls
			console.log(
				"Current Call Count:",
				CurrentCalls + " - " + global.realizo_llamada_saliente
			);
			global.realizo_llamada_saliente = 0;
			var buddyObj = FindBuddyByDid(did);

			// Make new contact of its not there
			if (buddyObj == null) {
				// Check if Privacy DND is enabled
				var buddyType = did.length > DidLength ? "contact" : "extension";
				var focusOnBuddy = CurrentCalls == 0;
				buddyObj = MakeBuddy(
					buddyType,
					true,
					focusOnBuddy,
					false,
					callerID,
					did,
					null,
					false
				);
				console.log("after make buddy");
			} else {
				// Double check that the buddy has the same caller ID as the incoming call
				// With Buddies that are contacts, eg +441234567890 <+441234567890> leave as as
				if (buddyObj.type == "extension" && buddyObj.CallerIDName != callerID) {
					UpdateBuddyCalerID(buddyObj, callerID);
				} else if (
					buddyObj.type == "contact" &&
					callerID != did &&
					buddyObj.CallerIDName != callerID
				) {
					UpdateBuddyCalerID(buddyObj, callerID);
				}
			}

			var startTime = moment.utc();

			// Create the line and add the session so we can answer or reject it.
			global.newLineNumber = global.newLineNumber + 1;
			console.log("LINEA DE LLAMADA ENTRATE--> ", global.newLineNumber);
			var lineObj = new Line(global.newLineNumber, callerID, did, buddyObj);
			lineObj.SipSession = session;
			lineObj.SipSession.data = {};
			lineObj.SipSession.data.line = lineObj.LineNumber;
			lineObj.SipSession.data.calldirection = "inbound";
			lineObj.SipSession.data.terminateby = "";
			lineObj.SipSession.data.src = did;
			lineObj.SipSession.data.buddyId = lineObj.BuddyObj.identity;
			lineObj.SipSession.data.callstart = startTime.format(
				"YYYY-MM-DD HH:mm:ss UTC"
			);
			lineObj.SipSession.data.callTimer = window.setInterval(function () {
				var now = moment.utc();
				var duration = moment.duration(now.diff(startTime));
				var timeStr = formatShortDuration(duration.asSeconds());
				//ESTE ES EL TIEMPO!!!!
				global.tiempo_llamada = timeStr;
				// console.log("LINEA DE LLAMADA ENTRATE 2--> ", lineObj.LineNumber);
				if (lineObj.LineNumber)
					global.datos_lineas_activas[lineObj.LineNumber].llamada_tiempo =
						timeStr;
			}, 1000);
			lineObj.SipSession.data.earlyReject = false;
			Lines.push(lineObj);
			global.lineObj = lineObj;
			global.Lines.push(lineObj);
			// Detect Video
			lineObj.SipSession.data.withvideo = false;
			if (EnableVideoCalling == true && lineObj.SipSession.request.body) {
				// Asterisk 13 PJ_SIP always sends m=video if endpoint has video codec,
				// even if origional invite does not specify video.
				if (lineObj.SipSession.request.body.indexOf("m=video") > -1) {
					lineObj.SipSession.data.withvideo = true;
					// The invite may have video, but the buddy may be a contact
					if (buddyObj.type == "contact") {
						// videoInvite = false;
						// TODO: Is this limitation nesessary?
					}
				}
			}

			// Session Delegates
			lineObj.SipSession.delegate = {
				onBye: function (sip) {
					onSessionRecievedBye(lineObj, sip);
				},
				onMessage: function (sip) {
					onSessionRecievedMessage(lineObj, sip);
				},
				onInvite: function (sip) {
					onSessionReinvited(lineObj, sip);
				},
				onSessionDescriptionHandler: function (sdh, provisional) {
					onSessionDescriptionHandlerCreated(
						lineObj,
						sdh,
						provisional,
						lineObj.SipSession.data.withvideo
					);
				},
			};
			// incomingInviteRequestDelegate
			lineObj.SipSession.incomingInviteRequest.delegate = {
				onCancel: function (sip) {
					onInviteCancel(lineObj, sip);
				},
			};

			// Possible Early Rejection options
			if (DoNotDisturbEnabled == true || DoNotDisturbPolicy == "enabled") {
				if (DoNotDisturbEnabled == true && buddyObj.EnableDuringDnd == true) {
					// This buddy has been allowed
					console.log("Buddy is allowed to call while you are on DND");
				} else {
					console.log("Do Not Disturb Enabled, rejecting call.");
					lineObj.SipSession.data.earlyReject = true;
					RejectCall(lineObj.LineNumber, true);
					return;
				}
			}
			if (CurrentCalls >= 1) {
				let nueva_linea_activa = "";
				nueva_linea_activa = {
					[lineObj.LineNumber]: {
						action: "fa fa-phone-alt",
						items: [
							{ title: lineObj.LineNumber, la_linea: lineObj.LineNumber },
						],
						title: `${global.numero_hablando}`,
						llamada_tiempo: "",
						lineas_llamadas_activas: lineObj.LineNumber,
						estadado_llamada: false,
						nombre_largo: global.nombre_largo,
						nombre_corto: global.nombre_corto,
					},
				};
				global.datos_lineas_activas = Object.assign(
					global.datos_lineas_activas,
					nueva_linea_activa
				);

				global.SelectLine(lineObj.LineNumber);

				global.lineas_activas_llamadas = true;
				global.openWebLines();
				global.lienea_uno_activa = true;
				global.activarmute = false;
				global.cancelar_llamada_saliente = false;
				global.cancelar_nueva_linea = false;
				global.activarmute = false;
				global.desactivarmute = false;
				global.datos_llamada = true;
				global.digitar_transferencia = false;
				global.digitar_nueva_linea = false;
				global.poner_en_espera = false;
				global.quitar_en_espera = false;
				global.finalizar_llamada = false;
				global.transferencia = false;
				global.nueva_linea = false;
				global.cancelar_transferencia = false;
				global.cancelar_nueva_linea = false;
				global.conferencia = false;
				global.llamadaSaliente = false;
				global.botones_telefono = true;
				global.respuesta_automatica = false;
				global.linea_conferencia = false;
				global.desactivar_conferencia = false;
				global.digitar_conferencia = false;
				global.telefono_conferencia = "";
				global.digitar_telefono = false;
				if (CallWaitingEnabled == false || CallWaitingEnabled == "disabled") {
					console.log("Call Waiting Disabled, rejecting call.");
					lineObj.SipSession.data.earlyReject = true;
					RejectCall(lineObj.LineNumber, true);
					return;
				}
			} else {
				global.datos_lineas_activas = {
					[lineObj.LineNumber]: {
						action: "fa fa-phone-alt",
						items: [
							{ title: lineObj.LineNumber, la_linea: lineObj.LineNumber },
						],
						title: `${global.numero_hablando}`,
						llamada_tiempo: "",
						lineas_llamadas_activas: lineObj.LineNumber,
						estadado_llamada: true,
						nombre_largo: global.nombre_largo,
						nombre_corto: global.nombre_corto,
					},
				};
			}
			// Create the call HTML
			AddLineHtml(lineObj);
			$("#line-" + lineObj.LineNumber + "-msg").html(lang.incoming_call);
			$("#line-" + lineObj.LineNumber + "-msg").show();
			$("#line-" + lineObj.LineNumber + "-timer").show();
			if (lineObj.SipSession.data.withvideo) {
				$("#line-" + lineObj.LineNumber + "-answer-video").show();
			} else {
				$("#line-" + lineObj.LineNumber + "-answer-video").hide();
			}
			$("#line-" + lineObj.LineNumber + "-AnswerCall").show();

			// Update the buddy list now so that any early rejected calls dont flash on
			UpdateBuddyList();

			// Auto Answer options
			AutoAnswerEnabled = global.force_auto_answer
				? global.force_auto_answer
				: global.contestar_automatico == true
				? 1
				: 0;
			var autoAnswerRequested = false;
			var answerTimeout = 1000;
			if (!AutoAnswerEnabled && IntercomPolicy == "enabled") {
				// Check headers only if policy is allow

				// https://github.com/InnovateAsterisk/Browser-Phone/issues/126
				// Alert-Info: info=alert-autoanswer
				// Alert-Info: answer-after=0
				// Call-info: answer-after=0; x=y
				// Call-Info: Answer-After=0
				// Alert-Info: ;info=alert-autoanswer
				// Alert-Info: <sip:>;info=alert-autoanswer
				// Alert-Info: <sip:domain>;info=alert-autoanswer

				var ci = session.request.headers["Call-Info"];
				if (ci !== undefined && ci.length > 0) {
					for (var i = 0; i < ci.length; i++) {
						var raw_ci = ci[i].raw.toLowerCase();
						if (raw_ci.indexOf("answer-after=") > 0) {
							var temp_seconds_autoanswer = parseInt(
								raw_ci
									.substring(
										raw_ci.indexOf("answer-after=") + "answer-after=".length
									)
									.split(";")[0]
							);
							if (
								Number.isInteger(temp_seconds_autoanswer) &&
								temp_seconds_autoanswer >= 0
							) {
								autoAnswerRequested = true;
								if (temp_seconds_autoanswer > 1)
									answerTimeout = temp_seconds_autoanswer * 1000;
								break;
							}
						}
					}
				}
				var ai = session.request.headers["Alert-Info"];
				if (
					autoAnswerRequested === false &&
					ai !== undefined &&
					ai.length > 0
				) {
					for (var i = 0; i < ai.length; i++) {
						var raw_ai = ai[i].raw.toLowerCase();
						if (
							raw_ai.indexOf("auto answer") > 0 ||
							raw_ai.indexOf("alert-autoanswer") > 0
						) {
							var autoAnswerRequested = true;
							break;
						}
						if (raw_ai.indexOf("answer-after=") > 0) {
							var temp_seconds_autoanswer = parseInt(
								raw_ai
									.substring(
										raw_ai.indexOf("answer-after=") + "answer-after=".length
									)
									.split(";")[0]
							);
							if (
								Number.isInteger(temp_seconds_autoanswer) &&
								temp_seconds_autoanswer >= 0
							) {
								autoAnswerRequested = true;
								if (temp_seconds_autoanswer > 1)
									answerTimeout = temp_seconds_autoanswer * 1000;
								break;
							}
						}
					}
				}
			}

			if (
				AutoAnswerEnabled ||
				AutoAnswerPolicy == "enabled" ||
				autoAnswerRequested
			) {
				if (CurrentCalls == 0) {
					if (Notification.permission === "granted") {
						var noticeOptions = {
							body: lang.incoming_call_from + " " + callerID + " <" + did + ">",
							//body: lang.incoming_call_from + did + " ",

							//body: lang.incoming_call_from + " " + callerID + " <" + did + ">",
						};
						var inComingCallNotification = new Notification(
							lang.incoming_call,
							noticeOptions
						);
						inComingCallNotification.onclick = function (event) {
							inComingCallNotification.close();
							window.parent.focus();
							return;
						};
					}
					// There are no other calls, so you can answer
					console.log("Going to Auto Answer this call...");
					window.setTimeout(function () {
						// If the call is with video, assume the auto answer is also
						// In order for this to work nicely, the recipient maut be "ready" to accept video calls
						// In order to ensure video call compatibility (i.e. the recipient must have their web cam in, and working)
						// The NULL video sould be configured
						// https://github.com/InnovateAsterisk/Browser-Phone/issues/26
						if (lineObj.SipSession.data.withvideo) {
							global.AnswerVideoCall(lineObj.LineNumber);
						} else {
							global.AnswerAudioCall(lineObj.LineNumber);
						}
					}, answerTimeout);

					// Select Buddy
					SelectLine(lineObj.LineNumber);
					return;
				} else {
					console.warn("Could not auto answer call, already on a call.");
				}
			}

			// Check if that buddy is not already on a call??
			var streamVisible = $("#stream-" + buddyObj.identity).is(":visible");
			if (streamVisible || CurrentCalls == 0) {
				// If you are already on the selected buddy who is now calling you, switch to his call.
				// NOTE: This will put other calls on hold
				if (CurrentCalls == 0) SelectLine(lineObj.LineNumber);
			}

			// Show notification / Ring / Windows Etc
			// ======================================

			// Browser Window Notification
			if ("Notification" in window) {
				if (Notification.permission === "granted") {
					var noticeOptions = {
						body: lang.incoming_call_from + " " + callerID + " <" + did + ">",
						tag: lineObj.LineNumber,
						//body: lang.incoming_call_from + did + " ",

						//body: lang.incoming_call_from + " " + callerID + " <" + did + ">",
					};
					var inComingCallNotification = new Notification(
						lang.incoming_call,
						noticeOptions
					);

					inComingCallNotification.onclick = function (event) {
						var lineNo = global.lineObj.LineNumber;
						console.log("line number of tag: ", event);
						if (
							event.target.tag.toString() === lineNo.toString() &&
							global.lineObj &&
							global.lineObj.SipSession &&
							global.lineObj.SipSession._state === "Initial"
						)
							window.setTimeout(function () {
								if (global.datos_lineas_activas[lineNo]) {
									global.AnswerAudioCall(lineNo);
									// Select Buddy
									SelectLine(lineNo);
								}
							}, 1000);
						inComingCallNotification.close();
						window.parent.focus();
						return;
					};
				}
			}

			// Play Ring Tone if not on the phone
			if (EnableRingtone == true) {
				if (CurrentCalls >= 1) {
					// Play Alert
					console.log("Audio:", audioBlobs.CallWaiting.url);
					var rinnger = new Audio(audioBlobs.CallWaiting.blob);
					rinnger.preload = "auto";
					rinnger.loop = false;
					rinnger.oncanplaythrough = function (e) {
						if (
							typeof rinnger.sinkId !== "undefined" &&
							getRingerOutputID() != "default"
						) {
							rinnger
								.setSinkId(getRingerOutputID())
								.then(function () {
									console.log("Set sinkId to:", getRingerOutputID());
								})
								.catch(function (e) {
									console.warn("Failed not apply setSinkId.", e);
								});
						}
						// If there has been no interaction with the page at all... this page will not work
						rinnger
							.play()
							.then(function () {
								// Audio Is Playing
							})
							.catch(function (e) {
								console.warn("Unable to play audio file.", e);
							});
					};
					lineObj.SipSession.data.rinngerObj = rinnger;
				} else {
					// Play Ring Tone
					//console.log("Audio:", audioBlobs.Ringtone.url);
					var rinnger = new Audio("/media/Ringtone_2.mp3");
					rinnger.volume = getRingerVolume();
					rinnger.preload = "auto";
					rinnger.loop = true;
					rinnger.oncanplaythrough = function (e) {
						if (
							typeof rinnger.sinkId !== "undefined" &&
							getRingerOutputID() != "default"
						) {
							rinnger
								.setSinkId(getRingerOutputID())
								.then(function () {
									console.log("Set sinkId to:", getRingerOutputID());
								})
								.catch(function (e) {
									console.warn("Failed not apply setSinkId.", e);
								});
						}
						// If there has been no interaction with the page at all... this page will not work
						rinnger
							.play()
							.then(function () {
								// Audio Is Playing
								console.log("Playing audio call file");
							})
							.catch(function (e) {
								console.warn("Unable to play audio file.", e);
							});
					};
					lineObj.SipSession.data.rinngerObj = rinnger;
				}
			}

			// Custom Web hook
			if (typeof web_hook_on_invite !== "undefined")
				web_hook_on_invite(session);
		}
		// Session Events
		// ==============
		// Incoming INVITE
		function onInviteCancel(lineObj, response) {
			var defaultLine = null;
			const numberLines = Object.keys(global.datos_lineas_activas);
			// Remote Party Canceled while ringing...
			if (
				numberLines.length == 1 &&
				numberLines.indexOf(lineObj.LineNumber.toString()) > -1
			) {
				global.drawer = false;
				global.telefono_tranferencia = "";
				global.datos_llamada = false;
				global.digitar_transferencia = false;
				global.digitar_nueva_linea = false;
				global.tiempo_llamada = "";
				global.numero_telefonico = "";
				global.activarmute = false;
				global.desactivarmute = false;
				global.poner_en_espera = false;
				global.quitar_en_espera = false;
				global.transferencia = false;
				global.nueva_linea = false;
				global.cancelar_transferencia = false;
				global.cancelar_nueva_linea = false;
				global.conferencia = false;
				global.llamadaSaliente = true;
				global.botones_telefono = true;
				global.botones_dtmf = false;
				global.respuesta_automatica = true;
				global.linea_conferencia = false;
				global.desactivar_conferencia = false;
				global.digitar_conferencia = false;
				global.telefono_conferencia = "";
				global.desactivar_conferencia = false;
				global.contestar = false;
				global.colgar = false;
				global.cancelar_llamada_saliente = false;
				global.finalizar_llamada = false;
				global.digitar_telefono = true;
				global.nombre_corto = "";
				global.nombre_largo = "";
				global.numero_hablando = "";
			}
			console.log("-----Call canceled by remote party before answer-----");
			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.reasonCode = 0;
			lineObj.SipSession.data.reasonText = "Call Cancelled";
			global.stopDuration();
			lineObj.SipSession.dispose().catch(function (error) {
				console.log("Failed to dispose the cancel dialog", error);
			});
			global.teardownSession(lineObj);

			if (
				numberLines.length == 2 &&
				numberLines.indexOf(lineObj.LineNumber.toString())
			) {
				global.lineas_activas_llamadas = false;
				global.closeWebLines();
				global.lienea_uno_activa = false;
				for (let datos in global.datos_lineas_activas) {
					if (datos.toString() != lineObj.LineNumber.toString()) {
						defaultLine = global.FindLineByNumber(
							global.datos_lineas_activas[datos].lineas_llamadas_activas
						);
						global.lineObj = defaultLine;
						console.log(
							"LOS DATOS DE LA UNICA LLAMADA ACTIVA ENTRANTE",
							global.datos_lineas_activas[datos]
						);
						global.numero_hablando = global.datos_lineas_activas[datos].title;

						/* 						global.unholdSession(
							global.datos_lineas_activas[datos].lineas_llamadas_activas
						); */

						global.nombre_largo =
							"nombre_largo" in global.datos_lineas_activas[datos]
								? global.datos_lineas_activas[datos].nombre_largo
								: "";
						global.nombre_corto =
							"nombre_corto" in global.datos_lineas_activas[datos]
								? global.datos_lineas_activas[datos].nombre_corto
								: "";
					}
				}
			}
			if (numberLines.length >= 2) {
				global.Lines = global.Lines.filter(
					data => data.LineNumber.toString() != lineObj.LineNumber.toString()
				);
				global.lineObj = global.Lines.slice(-1)[0];
				global.SelectLine(global.lineObj.LineNumber);
			} else {
				global.Lines = [];
				global.lineObj = [];
			}

			if (defaultLine) global.SelectLine(defaultLine.LineNumber);
		}
		// Both Incoming an doutgoing INVITE

		// Outgoing INVITE
		function onInviteTrying(lineObj, response) {
			$("#line-" + lineObj.LineNumber + "-msg").html(lang.trying);

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("trying", lineObj.SipSession);
		}
		function onInviteProgress(lineObj, response) {
			console.log("Call Progress:", response.message.statusCode);

			// Provisional 1xx
			// response.message.reasonPhrase
			if (response.message.statusCode == 180) {
				$("#line-" + lineObj.LineNumber + "-msg").html(lang.ringing);

				var soundFile = audioBlobs.EarlyMedia_European;
				if (UserLocale().indexOf("us") > -1)
					soundFile = audioBlobs.EarlyMedia_US;
				if (UserLocale().indexOf("gb") > -1)
					soundFile = audioBlobs.EarlyMedia_UK;
				if (UserLocale().indexOf("au") > -1)
					soundFile = audioBlobs.EarlyMedia_Australia;
				if (UserLocale().indexOf("jp") > -1)
					soundFile = audioBlobs.EarlyMedia_Japan;

				// Play Early Media
				console.log("Audio:", soundFile.url);
				if (lineObj.SipSession.data.earlyMedia) {
					// There is already early media playing
					// onProgress can be called multiple times
					// Dont add it again
					console.log("Early Media already playing");
				} else {
					var earlyMedia = new Audio(soundFile.blob);
					earlyMedia.preload = "auto";
					earlyMedia.loop = true;
					earlyMedia.oncanplaythrough = function (e) {
						if (
							typeof earlyMedia.sinkId !== "undefined" &&
							getAudioOutputID() != "default"
						) {
							earlyMedia
								.setSinkId(getAudioOutputID())
								.then(function () {
									console.log("Set sinkId to:", getAudioOutputID());
								})
								.catch(function (e) {
									console.warn("Failed not apply setSinkId.", e);
								});
						}
						earlyMedia
							.play()
							.then(function () {
								// Audio Is Playing
							})
							.catch(function (e) {
								console.warn("Unable to play audio file.", e);
							});
					};
					lineObj.SipSession.data.earlyMedia = earlyMedia;
				}
			} else if (response.message.statusCode === 183) {
				$("#line-" + lineObj.LineNumber + "-msg").html(
					response.message.reasonPhrase + "..."
				);

				// Add UI to allow DTMF
				$("#line-" + lineObj.LineNumber + "-early-dtmf").show();
			} else {
				// 181 = Call is Being Forwarded
				// 182 = Call is queued (Busy server!)
				// 199 = Call is Terminated (Early Dialog)

				$("#line-" + lineObj.LineNumber + "-msg").html(
					response.message.reasonPhrase + "..."
				);
			}

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("progress", lineObj.SipSession);
		}
		function onInviteRejected(lineObj, response) {
			console.log("INVITE Rejected:2", response.message.reasonPhrase);

			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.reasonCode = response.message.statusCode;
			lineObj.SipSession.data.reasonText = response.message.reasonPhrase;

			global.teardownSession(lineObj);
		}
		function onInviteRedirected(response) {
			console.log("onInviteRedirected", response);
			// Follow???
		}

		// General Sessoin delegates
		function onSessionRecievedBye(lineObj, response) {
			delete global.datos_lineas_activas[lineObj.LineNumber];
			var lang = global.lang;
			const totalCalls = Object.keys(global.datos_lineas_activas).length;
			console.log(
				"onSessionRecievedBye: ",
				JSON.stringify(totalCalls),
				global.datos_lineas_activas
			);
			if (totalCalls > 1) {
				const lastCall = Object.values(global.datos_lineas_activas)[
					totalCalls - 1
				]; // obtiene la ultima llamada
				global.SelectLine(lastCall.lineas_llamadas_activas, { unhold: false });
			} else if (totalCalls == 1) {
				global.lineas_activas_llamadas = false;
				global.closeWebLines();
				global.lienea_uno_activa = true;
				for (let datos in global.datos_lineas_activas) {
					global.numero_hablando = global.datos_lineas_activas[datos].title;
					/* 					global.unholdSession(
						global.datos_lineas_activas[datos].lineas_llamadas_activas
					); */

					global.nombre_largo =
						"nombre_largo" in global.datos_lineas_activas[datos]
							? global.datos_lineas_activas[datos].nombre_largo
							: "";
					global.nombre_corto =
						"nombre_corto" in global.datos_lineas_activas[datos]
							? global.datos_lineas_activas[datos].nombre_corto
							: "";
				}
			} else if (totalCalls == 0) {
				global.numero_telefonico = "";
				global.tiempo_llamada = "";
				global.nombre_largo = "";
				global.numero_hablando = "";
				global.nombre_corto = "";
				global.llamadaSaliente = true;
				global.botones_telefono = true;
				global.botones_dtmf = false;
				global.respuesta_automatica = true;
				global.linea_conferencia = false;
				global.desactivar_conferencia = false;
				global.digitar_conferencia = false;
				global.telefono_conferencia = "";
				global.transferencia = false;
				global.nueva_linea = false;
				global.conferencia = false;
				global.cancelar_transferencia = false;
				global.cancelar_nueva_linea = false;
				global.contestar = false;
				global.activarmute = false;
				global.poner_en_espera = false;
				global.quitar_en_espera = false;
				global.desactivarmute = false;
				global.finalizar_llamada = false;
				global.colgar = false;
				global.cancelar_llamada_saliente = false;
				// global.drawer = false
				global.datos_llamada = false;
				global.digitar_transferencia = false;
				global.digitar_nueva_linea = false;
				global.digitar_telefono = true;
				global.stopDuration();
				global.Buddies = [];
				$(".crear-audio").remove();
			}
			$("#line-" + lineObj.LineNumber + "-msg").html(lang.call_ended);
			console.log("Call ended, bye! Mounted");

			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.reasonCode = 16;
			lineObj.SipSession.data.reasonText = "Normal Call clearing";

			global.teardownSession(lineObj);

			if (totalCalls === 1) {
				global.Lines = global.Lines.filter(
					data => data.LineNumber != lineObj.LineNumber
				);
				for (let datos in global.datos_lineas_activas) {
					const numLineaDato =
						global.datos_lineas_activas[datos].lineas_llamadas_activas;
					const defaultLine = global.Lines.find(
						l => l.LineNumber.toString() === numLineaDato.toString()
					);
					global.lineObj = defaultLine;
					global.SelectLine(numLineaDato, { unhold: false });
				}
			} else if (totalCalls > 1) {
				global.Lines = global.Lines.filter(
					data => data.LineNumber != lineObj.LineNumber
				);
				global.lineObj = global.Lines.slice(-1)[0];
			} else {
				global.Lines = [];
				global.lineObj = [];
			}
		}
		function onSessionReinvited(lineObj, response) {
			// This may be used to include video streams
			var sdp = response.body;

			// All the possible streams will get
			// Note, this will probably happen after the streams are added
			lineObj.SipSession.data.videoChannelNames = [];
			var videoSections = sdp.split("m=video");
			if (videoSections.length >= 1) {
				for (var m = 0; m < videoSections.length; m++) {
					if (
						videoSections[m].indexOf("a=mid:") > -1 &&
						videoSections[m].indexOf("a=label:") > -1
					) {
						// We have a label for the media
						var lines = videoSections[m].split("\r\n");
						var channel = "";
						var mid = "";
						for (var i = 0; i < lines.length; i++) {
							if (lines[i].indexOf("a=label:") == 0) {
								channel = lines[i].replace("a=label:", "");
							}
							if (lines[i].indexOf("a=mid:") == 0) {
								mid = lines[i].replace("a=mid:", "");
							}
						}
						lineObj.SipSession.data.videoChannelNames.push({
							mid: mid,
							channel: channel,
						});
					}
				}
				console.log(
					"videoChannelNames:",
					lineObj.SipSession.data.videoChannelNames
				);
				RedrawStage(lineObj.LineNumber, false);
			}
		}
		function onSessionRecievedMessage(lineObj, response) {
			var messageType =
				response.request.headers["Content-Type"].length >= 1
					? response.request.headers["Content-Type"][0].parsed
					: "Unknown";
			if (messageType.indexOf("application/x-asterisk-confbridge-event") > -1) {
				// Conference Events JSON
				var msgJson = JSON.parse(response.request.body);

				var session = lineObj.SipSession;
				if (!session.data.ConfbridgeChannels)
					session.data.ConfbridgeChannels = [];
				if (!session.data.ConfbridgeEvents) session.data.ConfbridgeEvents = [];

				if (msgJson.type == "ConfbridgeStart") {
					console.log("ConfbridgeStart!");
				} else if (msgJson.type == "ConfbridgeWelcome") {
					console.log("Welcome to the Asterisk Conference");
					console.log("Bridge ID:", msgJson.bridge.id);
					console.log("Bridge Name:", msgJson.bridge.name);
					console.log("Created at:", msgJson.bridge.creationtime);
					console.log("Video Mode:", msgJson.bridge.video_mode);

					session.data.ConfbridgeChannels = msgJson.channels; // Write over this
					session.data.ConfbridgeChannels.forEach(function (chan) {
						// The mute and unmute status doesnt appear to be a realtime state, only what the
						// startmuted= setting of the default profile is.
						console.log(
							chan.caller.name,
							"Is in the conference. Muted:",
							chan.muted,
							"Admin:",
							chan.admin
						);
					});
				} else if (msgJson.type == "ConfbridgeJoin") {
					msgJson.channels.forEach(function (chan) {
						var found = false;
						session.data.ConfbridgeChannels.forEach(function (existingChan) {
							if (existingChan.id == chan.id) found = true;
						});
						if (!found) {
							session.data.ConfbridgeChannels.push(chan);
							session.data.ConfbridgeEvents.push({
								event:
									chan.caller.name +
									" (" +
									chan.caller.number +
									") joined the conference",
								eventTime: utcDateNow(),
							});
							console.log(
								chan.caller.name,
								"Joined the conference. Muted: ",
								chan.muted
							);
						}
					});
				} else if (msgJson.type == "ConfbridgeLeave") {
					msgJson.channels.forEach(function (chan) {
						session.data.ConfbridgeChannels.forEach(function (existingChan, i) {
							if (existingChan.id == chan.id) {
								session.data.ConfbridgeChannels.splice(i, 1);
								console.log(chan.caller.name, "Left the conference");
								session.data.ConfbridgeEvents.push({
									event:
										chan.caller.name +
										" (" +
										chan.caller.number +
										") left the conference",
									eventTime: utcDateNow(),
								});
							}
						});
					});
				} else if (msgJson.type == "ConfbridgeTalking") {
					var videoContainer = $(
						"#line-" + lineObj.LineNumber + "-remote-videos"
					);
					if (videoContainer) {
						msgJson.channels.forEach(function (chan) {
							videoContainer.find("video").each(function () {
								if (
									this.srcObject.channel &&
									this.srcObject.channel == chan.id
								) {
									if (chan.talking_status == "on") {
										console.log(chan.caller.name, "is talking.");
										this.srcObject.isTalking = true;
										$(this).css("border", "1px solid red");
									} else {
										console.log(chan.caller.name, "stopped talking.");
										this.srcObject.isTalking = false;
										$(this).css("border", "1px solid transparent");
									}
								}
							});
						});
					}
				} else if (msgJson.type == "ConfbridgeMute") {
					msgJson.channels.forEach(function (chan) {
						session.data.ConfbridgeChannels.forEach(function (existingChan) {
							if (existingChan.id == chan.id) {
								console.log(existingChan.caller.name, "is now muted");
								existingChan.muted = true;
							}
						});
					});
					RedrawStage(lineObj.LineNumber, false);
				} else if (msgJson.type == "ConfbridgeUnmute") {
					msgJson.channels.forEach(function (chan) {
						session.data.ConfbridgeChannels.forEach(function (existingChan) {
							if (existingChan.id == chan.id) {
								console.log(existingChan.caller.name, "is now unmuted");
								existingChan.muted = false;
							}
						});
					});
					RedrawStage(lineObj.LineNumber, false);
				} else if (msgJson.type == "ConfbridgeEnd") {
					console.log("The Asterisk Conference has ended, bye!");
				} else {
					console.warn(
						"Unknown Asterisk Conference Event:",
						msgJson.type,
						msgJson
					);
				}
				RefreshLineActivity(lineObj.LineNumber);
				response.accept();
			} else if (
				messageType.indexOf("application/x-myphone-confbridge-chat") > -1
			) {
				console.log("x-myphone-confbridge-chat", response);

				response.accept();
			} else {
				console.warn("Unknown message type");
				response.reject();
			}
		}

		function onSessionDescriptionHandlerCreated(
			lineObj,
			sdh,
			provisional,
			includeVideo
		) {
			if (sdh) {
				if (sdh.peerConnection) {
					// console.log(sdh);
					sdh.peerConnection.ontrack = function (event) {
						global.onTrackAddedEvent(lineObj, includeVideo);
					};
					// sdh.peerConnectionDelegate = {
					//     ontrack: function(event){
					//         console.log(event);
					//         onTrackAddedEvent(lineObj, includeVideo);
					//     }
					// }
				} else {
					console.warn(
						"onSessionDescriptionHandler fired without a peerConnection"
					);
				}
			} else {
				console.warn(
					"onSessionDescriptionHandler fired without a sessionDescriptionHandler"
				);
			}
		}

		// General end of Session

		// Mic and Speaker Levels
		// ======================

		// Sounds Meter Class
		// ==================

		// function MeterSettingsOutput(audioStream, objectId, direction, interval) {
		// 	var soundMeter = new SoundMeter(null, null);
		// 	soundMeter.startTime = Date.now();
		// 	soundMeter.connectToSource(audioStream, function (e) {
		// 		if (e != null) return;

		// 		console.log("SoundMeter Connected, displaying levels to:" + objectId);
		// 		soundMeter.levelsInterval = window.setInterval(function () {
		// 			// Calculate Levels (0 - 255)
		// 			var instPercent = (soundMeter.instant / 255) * 100;
		// 			$("#" + objectId).css(direction, instPercent.toFixed(2) + "%");
		// 		}, interval);
		// 	});

		// 	return soundMeter;
		// }

		// QOS
		// ===

		function DisplayQosData(sessionId) {
			var indexedDB = window.indexedDB;
			var request = indexedDB.open("CallQosData", 1);
			request.onerror = function (event) {
				console.error("IndexDB Request Error:", event);
			};
			request.onupgradeneeded = function (event) {
				console.warn(
					"Upgrade Required for IndexDB... probably because of first time use."
				);
			};
			request.onsuccess = function (event) {
				console.log("IndexDB connected to CallQosData");

				var IDB = event.target.result;
				if (IDB.objectStoreNames.contains("CallQos") == false) {
					console.warn("IndexDB CallQosData.CallQos does not exists");
					return;
				}

				var transaction = IDB.transaction(["CallQos"]);
				var objectStoreGet = transaction
					.objectStore("CallQos")
					.index("sessionid")
					.getAll(sessionId);
				objectStoreGet.onerror = function (event) {
					console.error("IndexDB Get Error:", event);
				};
				objectStoreGet.onsuccess = function (event) {
					if (event.target.result && event.target.result.length == 2) {
						// This is the correct data

						var QosData0 = event.target.result[0].QosData;
						// ReceiveBitRate: (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
						// ReceiveJitter: (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
						// ReceiveLevels: (9) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
						// ReceivePacketLoss: (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
						// ReceivePacketRate: (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
						// SendBitRate: []
						// SendPacketRate: []
						var QosData1 = event.target.result[1].QosData;
						// ReceiveBitRate: []
						// ReceiveJitter: []
						// ReceiveLevels: []
						// ReceivePacketLoss: []
						// ReceivePacketRate: []
						// SendBitRate: (9) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
						// SendPacketRate: (9) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]

						Chart.defaults.global.defaultFontSize = 12;

						var ChatHistoryOptions = {
							responsive: true,
							maintainAspectRatio: false,
							animation: false,
							scales: {
								yAxes: [
									{
										ticks: { beginAtZero: true }, //, min: 0, max: 100
									},
								],
								xAxes: [
									{
										display: false,
									},
								],
							},
						};

						// ReceiveBitRateChart
						var labelset = [];
						var dataset = [];
						var data =
							QosData0.ReceiveBitRate.length > 0
								? QosData0.ReceiveBitRate
								: QosData1.ReceiveBitRate;
						$.each(data, function (i, item) {
							labelset.push(
								moment
									.utc(item.timestamp.replace(" UTC", ""))
									.local()
									.format(DisplayDateFormat + " " + DisplayTimeFormat)
							);
							dataset.push(item.value);
						});

						// ReceivePacketRateChart
						var labelset = [];
						var dataset = [];
						var data =
							QosData0.ReceivePacketRate.length > 0
								? QosData0.ReceivePacketRate
								: QosData1.ReceivePacketRate;
						$.each(data, function (i, item) {
							labelset.push(
								moment
									.utc(item.timestamp.replace(" UTC", ""))
									.local()
									.format(DisplayDateFormat + " " + DisplayTimeFormat)
							);
							dataset.push(item.value);
						});

						// AudioReceivePacketLossChart
						var labelset = [];
						var dataset = [];
						var data =
							QosData0.ReceivePacketLoss.length > 0
								? QosData0.ReceivePacketLoss
								: QosData1.ReceivePacketLoss;
						$.each(data, function (i, item) {
							labelset.push(
								moment
									.utc(item.timestamp.replace(" UTC", ""))
									.local()
									.format(DisplayDateFormat + " " + DisplayTimeFormat)
							);
							dataset.push(item.value);
						});

						// AudioReceiveJitterChart
						var labelset = [];
						var dataset = [];
						var data =
							QosData0.ReceiveJitter.length > 0
								? QosData0.ReceiveJitter
								: QosData1.ReceiveJitter;
						$.each(data, function (i, item) {
							labelset.push(
								moment
									.utc(item.timestamp.replace(" UTC", ""))
									.local()
									.format(DisplayDateFormat + " " + DisplayTimeFormat)
							);
							dataset.push(item.value);
						});

						// AudioReceiveLevelsChart
						var labelset = [];
						var dataset = [];
						var data =
							QosData0.ReceiveLevels.length > 0
								? QosData0.ReceiveLevels
								: QosData1.ReceiveLevels;
						$.each(data, function (i, item) {
							labelset.push(
								moment
									.utc(item.timestamp.replace(" UTC", ""))
									.local()
									.format(DisplayDateFormat + " " + DisplayTimeFormat)
							);
							dataset.push(item.value);
						});

						// SendPacketRateChart
						var labelset = [];
						var dataset = [];
						var data =
							QosData0.SendPacketRate.length > 0
								? QosData0.SendPacketRate
								: QosData1.SendPacketRate;
						$.each(data, function (i, item) {
							labelset.push(
								moment
									.utc(item.timestamp.replace(" UTC", ""))
									.local()
									.format(DisplayDateFormat + " " + DisplayTimeFormat)
							);
							dataset.push(item.value);
						});

						// AudioSendBitRateChart
						var labelset = [];
						var dataset = [];
						var data =
							QosData0.SendBitRate.length > 0
								? QosData0.SendBitRate
								: QosData1.SendBitRate;
						$.each(data, function (i, item) {
							labelset.push(
								moment
									.utc(item.timestamp.replace(" UTC", ""))
									.local()
									.format(DisplayDateFormat + " " + DisplayTimeFormat)
							);
							dataset.push(item.value);
						});
					} else {
						console.warn("Result not expected", event.target.result);
					}
				};
			};
		}
		function DeleteQosData(buddy, stream) {
			var indexedDB = window.indexedDB;
			var request = indexedDB.open("CallQosData", 1);
			request.onerror = function (event) {
				console.error("IndexDB Request Error:", event);
			};
			request.onupgradeneeded = function (event) {
				console.warn(
					"Upgrade Required for IndexDB... probably because of first time use."
				);
				// If this is the case, there will be no call recordings
			};
			request.onsuccess = function (event) {
				console.log("IndexDB connected to CallQosData");

				var IDB = event.target.result;
				if (IDB.objectStoreNames.contains("CallQos") == false) {
					console.warn("IndexDB CallQosData.CallQos does not exists");
					return;
				}
				IDB.onerror = function (event) {
					console.error("IndexDB Error:", event);
				};

				// Loop and Delete
				// Note:  This database can only delete based on Primary Key
				// The The Primary Key is arbitary, so you must get all the rows based
				// on a lookup, and delete from there.
				$.each(stream.DataCollection, function (i, item) {
					if (
						item.ItemType == "CDR" &&
						item.SessionId &&
						item.SessionId != ""
					) {
						console.log("Deleting CallQosData: ", item.SessionId);
						var objectStore = IDB.transaction(
							["CallQos"],
							"readwrite"
						).objectStore("CallQos");
						var objectStoreGet = objectStore
							.index("sessionid")
							.getAll(item.SessionId);
						objectStoreGet.onerror = function (event) {
							console.error("IndexDB Get Error:", event);
						};
						objectStoreGet.onsuccess = function (event) {
							if (event.target.result && event.target.result.length > 0) {
								// There sre some rows to delete
								$.each(event.target.result, function (i, item) {
									// console.log("Delete: ", item.uID);
									try {
										objectStore.delete(item.uID);
									} catch (e) {
										console.log("Call CallQosData Delete failed: ", e);
									}
								});
							}
						};
					}
				});
			};
		}

		// Presence / Subscribe
		// ====================
		function SubscribeAll() {
			if (!userAgent.isRegistered()) return;

			if (VoiceMailSubscribe) {
				SubscribeVoicemail();
			}
			// Start subscribe all
			if (userAgent.BlfSubs && userAgent.BlfSubs.length > 0) {
				UnsubscribeAll();
			}
			userAgent.BlfSubs = [];
			if (Buddies.length >= 1) {
				console.log(
					"Starting Subscribe of all (" +
						Buddies.length +
						") Extension Buddies..."
				);
				for (var b = 0; b < Buddies.length; b++) {
					console.log("ACA ACABO");
					console.log(Buddies[b]);
					SubscribeBuddy(Buddies[b]);
				}
			}
		}
		function SubscribeVoicemail() {
			if (!userAgent.isRegistered()) return;

			if (userAgent.VoicemailSub) {
				console.log("Unsubscribe from old voicemail Messages...");
				UnsubscribeVoicemail();
			}

			console.log("SUBSCRIBE VOICEMAIL: " + SipUsername + "@" + wssServer);

			var vmOptions = { expires: 300 };
			var targetURI = SIP.UserAgent.makeURI(
				"sip:" + SipUsername + "@" + wssServer
			);
			userAgent.voicemailSub = new SIP.Subscriber(
				userAgent,
				targetURI,
				"message-summary",
				vmOptions
			);
			userAgent.voicemailSub.delegate = {
				onNotify: function (sip) {
					VocemailNotify(sip);
				},
			};
			userAgent.voicemailSub.subscribe().catch(function (error) {
				console.warn("Error subscribing to voimail notifications:", error);
			});
		}
		function SubscribeBuddy(buddyObj) {
			if (!userAgent.isRegistered()) return;

			if (
				(buddyObj.type == "extension" || buddyObj.type == "xmpp") &&
				buddyObj.EnableSubscribe == true
			) {
				// PIDF Subscription TODO: make this an option.
				// Dialog Subscription (This version isnt as nice as PIDF)
				// var dialogOptions = { expires: 300, extraHeaders: ['Accept: application/dialog-info+xml'] }

				var dialogOptions = {
					expires: 300,
					extraHeaders: ["Accept: application/pidf+xml"],
				};
				// var dialogOptions = { expires: 300, extraHeaders: ['Accept: application/pidf+xml', 'application/xpidf+xml', 'application/simple-message-summary', 'application/im-iscomposing+xml'] }

				console.log("SUBSCRIBE: " + buddyObj.ExtNo + "@" + wssServer);

				var targetURI = SIP.UserAgent.makeURI(
					"sip:" + buddyObj.ExtNo + "@" + wssServer
				);
				var blfSubscribe = new SIP.Subscriber(
					userAgent,
					targetURI,
					"presence",
					dialogOptions
				);
				blfSubscribe.data = {};
				blfSubscribe.data.buddyId = buddyObj.identity;
				blfSubscribe.delegate = {
					onNotify: function (sip) {
						RecieveBlf(sip);
					},
				};
				blfSubscribe.subscribe().catch(function (error) {
					console.warn("Error subscribing to Buddy notifications:", error);
				});
				userAgent.BlfSubs.push(blfSubscribe);
			}
		}

		function UnsubscribeAll() {
			if (!userAgent.isRegistered()) return;

			UnsubscribeVoicemail();

			if (userAgent.BlfSubs && userAgent.BlfSubs.length > 0) {
				console.log(
					"Unsubscribing " + userAgent.BlfSubs.length + " subscriptions..."
				);
				for (var blf = 0; blf < userAgent.BlfSubs.length; blf++) {
					UnsubscribeBlf(userAgent.BlfSubs[blf]);
				}
				userAgent.BlfSubs = [];

				for (var b = 0; b < Buddies.length; b++) {
					var buddyObj = Buddies[b];
					if (buddyObj.type == "extension" || buddyObj.type == "xmpp") {
						$("#contact-" + buddyObj.identity + "-devstate").prop(
							"class",
							"dotOffline"
						);
						$("#contact-" + buddyObj.identity + "-devstate-main").prop(
							"class",
							"dotOffline"
						);
						$("#contact-" + buddyObj.identity + "-presence").html(
							lang.state_unknown
						);
						$("#contact-" + buddyObj.identity + "-presence-main").html(
							lang.state_unknown
						);
					}
				}
			}
		}
		function UnsubscribeBlf(blfSubscribe) {
			if (!userAgent.isRegistered()) return;

			if (blfSubscribe.state == SIP.SubscriptionState.Subscribed) {
				console.log(
					"Unsubscribe to BLF Messages...",
					blfSubscribe.data.buddyId
				);
				blfSubscribe.unsubscribe().catch(function (error) {
					console.warn("Error removing BLF notifications:", error);
				});
			}
			blfSubscribe.dispose().catch(function (error) {
				console.warn("Error disposing BLF notifications:", error);
			});
			blfSubscribe = null;
		}
		function UnsubscribeVoicemail() {
			if (!userAgent.isRegistered()) return;

			if (userAgent.VoicemailSub) {
				if (userAgent.VoicemailSub.state == SIP.SubscriptionState.Subscribed) {
					console.log("Unsubscribe to voicemail Messages...");
					userAgent.VoicemailSub.unsubscribe().catch(function (error) {
						console.warn("Error removing voicemail notifications:", error);
					});
				}
				userAgent.VoicemailSub.dispose().catch(function (error) {
					console.warn("Error disposing voicemail notifications:", error);
				});
			}
			userAgent.VoicemailSub = null;
		}
		function UnsubscribeBuddy(buddyObj) {
			if (buddyObj.type == "extension" || buddyObj.type == "xmpp") {
				if (userAgent.BlfSubs && userAgent.BlfSubs.length > 0) {
					for (var blf = 0; blf < userAgent.BlfSubs.length; blf++) {
						var blfSubscribe = userAgent.BlfSubs[blf];
						if (blfSubscribe.data.buddyId == buddyObj.identity) {
							UnsubscribeBlf(userAgent.BlfSubs[blf]);
							userAgent.BlfSubs.splice(blf, 1);
							break;
						}
					}
				}
			}
		}
		// Subscription Events
		// ===================
		function VocemailNotify(notification) {
			if (notification.request.body.indexOf("Messages-Waiting: yes") > -1) {
				// Handle New Voicemail Message
				console.log("You have voicemail!");
				notification.accept();
			} else {
				notification.reject();
			}
		}
		function RecieveBlf(notification) {
			if (userAgent == null || !userAgent.isRegistered()) return;

			notification.accept();

			var buddy = "";
			var dotClass = "dotOffline";
			var Presence = "Unknown";

			var ContentType = notification.request.headers["Content-Type"][0].parsed;
			if (ContentType == "application/pidf+xml") {
				// Handle Presence
				/*
		// Asteriks chan_sip
		<?xml version="1.0" encoding="ISO-8859-1"?>
		<presence
			xmlns="urn:ietf:params:xml:ns:pidf"
			xmlns:pp="urn:ietf:params:xml:ns:pidf:person"
			xmlns:es="urn:ietf:params:xml:ns:pidf:rpid:status:rpid-status"
			xmlns:ep="urn:ietf:params:xml:ns:pidf:rpid:rpid-person"
			entity="sip:webrtc@192.168.88.98">

			<pp:person>
				<status>
					<ep:activities>
						<ep:away/>
					</ep:activities>
				</status>
			</pp:person>

			<note>Not online</note>
			<tuple id="300">
				<contact priority="1">sip:300@192.168.88.98</contact>
				<status>
					<basic>open | closed</basic>
				</status>
			</tuple>
		</presence>

		// Asterisk chan_pjsip
		<?xml version="1.0" encoding="UTF-8"?>
		<presence
			entity="sip:300@192.168.88.40:443;transport=ws"
			xmlns="urn:ietf:params:xml:ns:pidf"
			xmlns:dm="urn:ietf:params:xml:ns:pidf:data-model"
			xmlns:rpid="urn:ietf:params:xml:ns:pidf:rpid">
			<note>Ready</note>
			<tuple id="300">
				<status>
					<basic>open</basic>
				</status>
				<contact priority="1">sip:User1@raspberrypi.local</contact>
			</tuple>
			<dm:person />
		</presence>
		*/

				var xml = $($.parseXML(notification.request.body));
				buddy = xml.find("presence").find("tuple").attr("id");

				var Entity = xml.find("presence").attr("entity");
				var Contact = xml.find("presence").find("tuple").find("contact").text();
				var statusObj = xml.find("presence").find("tuple").find("status");
				var availability = xml
					.find("presence")
					.find("tuple")
					.find("status")
					.find("basic")
					.text();

				Presence = xml.find("presence").find("note").text();
			} else if (ContentType == "application/dialog-info+xml") {
				// Handle "Dialog" State

				var xml = $($.parseXML(notification.request.body));

				/*
		<?xml version="1.0"?>
		<dialog-info
			xmlns="urn:ietf:params:xml:ns:dialog-info"
			version="0-99999"
			state="full|partial"
			entity="sip:xxxx@XXX.XX.XX.XX">
			<dialog id="xxxx">
				<state>trying | proceeding | early | terminated | confirmed</state>
			</dialog>
		</dialog-info>
		*/

				var ObservedUser = xml.find("dialog-info").attr("entity");
				buddy = ObservedUser.split("@")[0].split(":")[1];

				var version = xml.find("dialog-info").attr("version");
				var DialogState = xml.find("dialog-info").attr("state");
				var extId = xml.find("dialog-info").find("dialog").attr("id");

				var state = xml.find("dialog-info").find("dialog").find("state").text();
				if (state == "terminated") Presence = "Ready";
				if (state == "trying") Presence = "On the phone";
				if (state == "proceeding") Presence = "On the phone";
				if (state == "early") Presence = "Ringing";
				if (state == "confirmed") Presence = "On the phone";

				// The dialog states only report devices states, and cant say online or offline.
			}

			var buddyObj = FindBuddyByExtNo(buddy);
			if (buddyObj == null) {
				console.warn("Buddy not found");
				return;
			}

			// dotOnline | dotOffline | dotRinging | dotInUse | dotReady | dotOnHold
			if (Presence == "Not online") dotClass = "dotOffline";
			if (Presence == "Ready") dotClass = "dotOnline";
			if (Presence == "On the phone") dotClass = "dotInUse";
			if (Presence == "Ringing") dotClass = "dotRinging";
			if (Presence == "On hold") dotClass = "dotOnHold";
			if (Presence == "Unavailable") dotClass = "dotOffline";

			// SIP Device Sate indicators
			console.log(
				"Setting DevSate State for " + buddyObj.CallerIDName + " to " + dotClass
			);
			buddyObj.devState = dotClass;
			$("#contact-" + buddyObj.identity + "-devstate").prop("class", dotClass);
			$("#contact-" + buddyObj.identity + "-devstate-main").prop(
				"class",
				dotClass
			);

			// Presence (SIP / XMPP)
			// SIP uses Devices states only
			// XMPP uses Device states, and Presence, but only XMMP Presence will display a text message
			if (buddyObj.type != "xmpp") {
				console.log(
					"Setting Presence for " + buddyObj.CallerIDName + " to " + Presence
				);

				buddyObj.presence = Presence;
				if (Presence == "Not online") Presence = lang.state_not_online;
				if (Presence == "Ready") Presence = lang.state_ready;
				if (Presence == "On the phone") Presence = lang.state_on_the_phone;
				if (Presence == "Ringing") Presence = lang.state_ringing;
				if (Presence == "On hold") Presence = lang.state_on_hold;
				if (Presence == "Unavailable") Presence = lang.state_unavailable;
				$("#contact-" + buddyObj.identity + "-presence").html(Presence);
				$("#contact-" + buddyObj.identity + "-presence-main").html(Presence);
			}
		}

		// Buddy: Chat / Instant Message / XMPP
		// ====================================
		function InitinaliseStream(buddy) {
			var template = { TotalRows: 0, DataCollection: [] };
			localDB.setItem(buddy + "-stream", JSON.stringify(template));
			return JSON.parse(localDB.getItem(buddy + "-stream"));
		}
		function SendChatMessage(buddy) {
			if (userAgent == null) return;
			if (!userAgent.isRegistered()) return;

			$("#contact-" + buddy + "-ChatMessage").focus(); // refocus on the textare

			var message = $("#contact-" + buddy + "-ChatMessage").val();
			message = $.trim(message);
			if (message == "") {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: lang.alert_empty_text_message,
					type: "error",
				});
				return;
			}
			// Note: AMI has this limit, but only if you use AMI to transmit
			// if(message.length > 755){
			//     Alert("Asterisk has a limit on the message size (755). This message is too long, and connot be delivered.", "Message Too Long");
			//     return;
			// }

			var messageId = uID();
			var buddyObj = FindBuddyByIdentity(buddy);

			// Update Stream
			var DateTime = moment.utc().format("YYYY-MM-DD HH:mm:ss UTC");
			var currentStream = JSON.parse(localDB.getItem(buddy + "-stream"));
			if (currentStream == null) currentStream = InitinaliseStream(buddy);

			// Add New Message
			var newMessageJson = {
				ItemId: messageId,
				ItemType: "MSG",
				ItemDate: DateTime,
				SrcUserId: profileUserID,
				Src: '"' + profileName + '" <' + profileUser + ">",
				DstUserId: buddy,
				Dst: "",
				MessageData: message,
			};

			currentStream.DataCollection.push(newMessageJson);
			currentStream.TotalRows = currentStream.DataCollection.length;
			localDB.setItem(buddy + "-stream", JSON.stringify(currentStream));

			// SIP Messages (Note, this may not work as required)
			// ============
			if (buddyObj.type == "extension") {
				var chatBuddy = SIP.UserAgent.makeURI(
					"sip:" + buddyObj.ExtNo + "@" + wssServer
				);
				console.log("MESSAGE: " + chatBuddy + " (extension)");

				var MessagerMessageOptions = {
					requestDelegate: {
						onAccept: function (sip) {
							console.log("Message Accepted:", messageId);
							MarkMessageSent(buddyObj, messageId, true);
						},
						onReject: function (sip) {
							console.warn("Message Error", sip.message.reasonPhrase);
							MarkMessageNotSent(buddyObj, messageId, true);
						},
					},
					requestOptions: {
						extraHeaders: [],
					},
				};
				var messageObj = new SIP.Messager(
					userAgent,
					chatBuddy,
					message,
					"text/plain"
				);
				messageObj.message(MessagerMessageOptions).then(function () {
					// Custom Web hook
					if (typeof web_hook_on_message !== "undefined")
						web_hook_on_message(messageObj);
				});
			}

			// XMPP Messages
			// =============
			if (buddyObj.type == "xmpp") {
				console.log("MESSAGE: " + buddyObj.jid + " (xmpp)");
				XmppSendMessage(buddyObj, message, messageId);

				// Custom Web hook
				if (typeof web_hook_on_message !== "undefined")
					web_hook_on_message(message);
			}

			// Group Chat
			// ==========
			if (buddyObj.type == "group") {
				// TODO
			}

			// Post Add Activity
			$("#contact-" + buddy + "-ChatMessage").val("");
			$("#contact-" + buddy + "-dictate-message").hide();
			$("#contact-" + buddy + "-emoji-menu").hide();
			$("#contact-" + buddy + "-ChatMessage").focus();

			if (buddyObj.recognition != null) {
				buddyObj.recognition.abort();
				buddyObj.recognition = null;
			}

			UpdateBuddyActivity(buddy);
			RefreshStream(buddyObj);
		}
		function MarkMessageSent(buddyObj, messageId, refresh) {
			var currentStream = JSON.parse(
				localDB.getItem(buddyObj.identity + "-stream")
			);
			if (currentStream != null || currentStream.DataCollection != null) {
				$.each(currentStream.DataCollection, function (i, item) {
					if (item.ItemType == "MSG" && item.ItemId == messageId) {
						// Found
						item.Sent = true;
						return false;
					}
				});
				localDB.setItem(
					buddyObj.identity + "-stream",
					JSON.stringify(currentStream)
				);

				if (refresh) RefreshStream(buddyObj);
			}
		}
		function MarkMessageNotSent(buddyObj, messageId, refresh) {
			var currentStream = JSON.parse(
				localDB.getItem(buddyObj.identity + "-stream")
			);
			if (currentStream != null || currentStream.DataCollection != null) {
				$.each(currentStream.DataCollection, function (i, item) {
					if (item.ItemType == "MSG" && item.ItemId == messageId) {
						// Found
						item.Sent = false;
						return false;
					}
				});
				localDB.setItem(
					buddyObj.identity + "-stream",
					JSON.stringify(currentStream)
				);

				if (refresh) RefreshStream(buddyObj);
			}
		}
		function MarkDeliveryReceipt(buddyObj, messageId, refresh) {
			var currentStream = JSON.parse(
				localDB.getItem(buddyObj.identity + "-stream")
			);
			if (currentStream != null || currentStream.DataCollection != null) {
				$.each(currentStream.DataCollection, function (i, item) {
					if (item.ItemType == "MSG" && item.ItemId == messageId) {
						// Found
						item.Delivered = { state: true, eventTime: utcDateNow() };
						return false;
					}
				});
				localDB.setItem(
					buddyObj.identity + "-stream",
					JSON.stringify(currentStream)
				);

				if (refresh) RefreshStream(buddyObj);
			}
		}
		function MarkDisplayReceipt(buddyObj, messageId, refresh) {
			var currentStream = JSON.parse(
				localDB.getItem(buddyObj.identity + "-stream")
			);
			if (currentStream != null || currentStream.DataCollection != null) {
				$.each(currentStream.DataCollection, function (i, item) {
					if (item.ItemType == "MSG" && item.ItemId == messageId) {
						// Found
						item.Displayed = { state: true, eventTime: utcDateNow() };
						return false;
					}
				});
				localDB.setItem(
					buddyObj.identity + "-stream",
					JSON.stringify(currentStream)
				);

				if (refresh) RefreshStream(buddyObj);
			}
		}
		function MarkMessageRead(buddyObj, messageId) {
			var currentStream = JSON.parse(
				localDB.getItem(buddyObj.identity + "-stream")
			);
			if (currentStream != null || currentStream.DataCollection != null) {
				$.each(currentStream.DataCollection, function (i, item) {
					if (item.ItemType == "MSG" && item.ItemId == messageId) {
						// Found
						item.Read = { state: true, eventTime: utcDateNow() };
						// return false; /// Mark all messages matching that id to avoid
						// duplicate id issue
					}
				});
				localDB.setItem(
					buddyObj.identity + "-stream",
					JSON.stringify(currentStream)
				);
				console.log("Set message (" + messageId + ") as Read");
			}
		}

		function ReceiveOutOfDialogMessage(message) {
			var callerID = message.request.from.displayName;
			var did = message.request.from.uri.normal.user;

			// Out of dialog Message Receiver
			var messageType =
				message.request.headers["Content-Type"].length >= 1
					? message.request.headers["Content-Type"][0].parsed
					: "Unknown";
			if (messageType.indexOf("text/plain") > -1) {
				// Plain Text Messages SIP SIMPLE
				console.log(
					"New Incoming Message!",
					'"' + callerID + '" <' + did + ">"
				);

				if (did.length > DidLength) {
					// Contacts cannot receive Test Messages, because they cannot reply
					// This may change with FAX, Email, WhatsApp etc
					console.warn("DID length greater then extensions length");
					return;
				}

				var CurrentCalls = countSessions("0");

				var buddyObj = FindBuddyByDid(did);
				// Make new contact of its not there
				if (buddyObj == null) {
					var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
					if (json == null) json = InitUserBuddies();

					// Add Extension
					var id = uID();
					var dateNow = utcDateNow();
					json.DataCollection.push({
						Type: "extension",
						LastActivity: dateNow,
						ExtensionNumber: did,
						MobileNumber: "",
						ContactNumber1: "",
						ContactNumber2: "",
						uID: id,
						cID: null,
						gID: null,
						jid: null,
						DisplayName: callerID,
						Description: "",
						Email: "",
						MemberCount: 0,
						EnableDuringDnd: false,
						Subscribe: false,
					});
					buddyObj = new Buddy(
						"extension",
						id,
						callerID,
						did,
						"",
						"",
						"",
						dateNow,
						"",
						"",
						jid,
						false,
						false
					);

					// Add memory object
					AddBuddy(buddyObj, true, CurrentCalls == 0, false);

					// Update Size:
					json.TotalRows = json.DataCollection.length;
					// Save To DB
					localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));
				}

				var origionalMessage = message.request.body;
				var messageId = uID();
				var DateTime = utcDateNow();

				message.accept();

				AddMessageToStream(
					buddyObj,
					messageId,
					"MSG",
					origionalMessage,
					DateTime
				);
				UpdateBuddyActivity(buddyObj.identity);
				RefreshStream(buddyObj);
				ActivateStream(buddyObj, origionalMessage);
			} else {
				console.warn("Unknown Out Of Dialog Message Type: ", messageType);
				message.reject();
			}
			// Custom Web hook
			if (typeof web_hook_on_message !== "undefined")
				web_hook_on_message(message);
		}
		function AddMessageToStream(buddyObj, messageId, type, message, DateTime) {
			var currentStream = JSON.parse(
				localDB.getItem(buddyObj.identity + "-stream")
			);
			if (currentStream == null)
				currentStream = InitinaliseStream(buddyObj.identity);

			// Add New Message
			var newMessageJson = {
				ItemId: messageId,
				ItemType: type,
				ItemDate: DateTime,
				SrcUserId: buddyObj.identity,
				Src: '"' + buddyObj.CallerIDName + '" <' + buddyObj.ExtNo + ">",
				DstUserId: profileUserID,
				Dst: "",
				MessageData: message,
			};

			currentStream.DataCollection.push(newMessageJson);
			currentStream.TotalRows = currentStream.DataCollection.length;
			localDB.setItem(
				buddyObj.identity + "-stream",
				JSON.stringify(currentStream)
			);

			// Data Cleanup
			if (MaxDataStoreDays && MaxDataStoreDays > 0) {
				console.log("Cleaning up data: ", MaxDataStoreDays);
				RemoveBuddyMessageStream(FindBuddyByIdentity(buddy), MaxDataStoreDays);
			}
		}
		function ActivateStream(buddyObj, message) {
			// Handle Stream Not visible
			// =========================
			var streamVisible = $("#stream-" + buddyObj.identity).is(":visible");
			if (!streamVisible) {
				// Add or Increase the Badge
				IncreaseMissedBadge(buddyObj.identity);
				if ("Notification" in window) {
					if (Notification.permission === "granted") {
						var imageUrl = getPicture(buddyObj.identity);
						var noticeOptions = {
							body: message.substring(0, 250),
							icon: imageUrl,
						};
						var inComingChatNotification = new Notification(
							lang.message_from + " : " + buddyObj.CallerIDName,
							noticeOptions
						);
						inComingChatNotification.onclick = function (event) {
							// Show Message
							SelectBuddy(buddyObj.identity);
						};
					}
				}
				// Play Alert
				console.log("Audio:", audioBlobs.Alert.url);
				var rinnger = new Audio(audioBlobs.Alert.blob);
				rinnger.preload = "auto";
				rinnger.loop = false;
				rinnger.oncanplaythrough = function (e) {
					if (
						typeof rinnger.sinkId !== "undefined" &&
						getRingerOutputID() != "default"
					) {
						rinnger
							.setSinkId(getRingerOutputID())
							.then(function () {
								console.log("Set sinkId to:", getRingerOutputID());
							})
							.catch(function (e) {
								console.warn("Failed not apply setSinkId.", e);
							});
					}
					// If there has been no interaction with the page at all... this page will not work
					rinnger
						.play()
						.then(function () {
							// Audio Is Playing
						})
						.catch(function (e) {
							console.warn("Unable to play audio file.", e);
						});
				};
				// message.data.rinngerObj = rinnger;
			} else {
				// Message window is active.
			}
		}
		function AddCallMessage(buddy, session) {
			var currentStream = JSON.parse(localDB.getItem(buddy + "-stream"));
			if (currentStream == null) currentStream = InitinaliseStream(buddy);

			var CallEnd = moment.utc(); // Take Now as the Hangup Time
			var callDuration = 0;
			var totalDuration = 0;
			var ringTime = 0;

			var CallStart = moment.utc(session.data.callstart.replace(" UTC", "")); // Actual start (both inbound and outbound)
			var CallAnswer = null; // On Accept when inbound, Remote Side when Outbound
			if (session.data.startTime) {
				// The time when WE answered the call (May be null - no answer)
				// or
				// The time when THEY answered the call (May be null - no answer)
				CallAnswer = moment.utc(session.data.startTime); // Local Time gets converted to UTC

				callDuration = moment.duration(CallEnd.diff(CallAnswer));
				ringTime = moment.duration(CallAnswer.diff(CallStart));
			} else {
				// There was no start time, but on inbound/outbound calls, this would indicate the ring time
				ringTime = moment.duration(CallEnd.diff(CallStart));
			}
			totalDuration = moment.duration(CallEnd.diff(CallStart));

			var srcId = "";
			var srcCallerID = "";
			var dstId = "";
			var dstCallerID = "";
			if (session.data.calldirection == "inbound") {
				srcId = buddy;
				dstId = profileUserID;
				srcCallerID =
					"<" +
					session.remoteIdentity.uri.user +
					"> " +
					session.remoteIdentity.displayName;
				dstCallerID = "<" + profileUser + "> " + profileName;
			} else if (session.data.calldirection == "outbound") {
				srcId = profileUserID;
				dstId = buddy;
				srcCallerID = "<" + profileUser + "> " + profileName;
				dstCallerID = session.remoteIdentity.uri.user;
			}

			var callDirection = session.data.calldirection;
			var withVideo = session.data.withvideo;
			var sessionId = session.id;
			var hanupBy = session.data.terminateby;

			var newMessageJson = {
				CdrId: uID(),
				ItemType: "CDR",
				ItemDate: CallStart.format("YYYY-MM-DD HH:mm:ss UTC"),
				CallAnswer: CallAnswer
					? CallAnswer.format("YYYY-MM-DD HH:mm:ss UTC")
					: null,
				CallEnd: CallEnd.format("YYYY-MM-DD HH:mm:ss UTC"),
				SrcUserId: srcId,
				Src: srcCallerID,
				DstUserId: dstId,
				Dst: dstCallerID,
				RingTime: ringTime != 0 ? ringTime.asSeconds() : 0,
				Billsec: callDuration != 0 ? callDuration.asSeconds() : 0,
				TotalDuration: totalDuration != 0 ? totalDuration.asSeconds() : 0,
				ReasonCode: session.data.reasonCode,
				ReasonText: session.data.reasonText,
				WithVideo: withVideo,
				SessionId: sessionId,
				CallDirection: callDirection,
				Terminate: hanupBy,
				// CRM
				MessageData: null,
				Tags: [],
				//Reporting
				Transfers: session.data.transfer ? session.data.transfer : [],
				Mutes: session.data.mute ? session.data.mute : [],
				Holds: session.data.hold ? session.data.hold : [],
				Recordings: session.data.recordings ? session.data.recordings : [],
				ConfCalls: session.data.confcalls ? session.data.confcalls : [],
				ConfbridgeEvents: session.data.ConfbridgeEvents
					? session.data.ConfbridgeEvents
					: [],
				QOS: [],
			};

			console.log("New CDR", newMessageJson);

			currentStream.DataCollection.push(newMessageJson);
			currentStream.TotalRows = currentStream.DataCollection.length;
			localDB.setItem(buddy + "-stream", JSON.stringify(currentStream));

			UpdateBuddyActivity(buddy);

			// Data Cleanup
			if (MaxDataStoreDays && MaxDataStoreDays > 0) {
				console.log("Cleaning up data: ", MaxDataStoreDays);
				RemoveBuddyMessageStream(FindBuddyByIdentity(buddy), MaxDataStoreDays);
			}
		}
		// TODO
		function SendImageDataMessage(buddy, ImgDataUrl) {
			if (userAgent == null) return;
			if (!userAgent.isRegistered()) return;

			// Ajax Upload
			// ===========

			var DateTime = moment.utc().format("YYYY-MM-DD HH:mm:ss UTC");
			var formattedMessage =
				'<IMG class=previewImage onClick="PreviewImage(this)" src="' +
				ImgDataUrl +
				'">';
			var messageString =
				'<table class=ourChatMessage cellspacing=0 cellpadding=0><tr><td style="width: 80px">' +
				"<div class=messageDate>" +
				DateTime +
				"</div>" +
				"</td><td>" +
				"<div class=ourChatMessageText>" +
				formattedMessage +
				"</div>" +
				"</td></tr></table>";
			$("#contact-" + buddy + "-ChatHistory").append(messageString);
			updateScroll(buddy);

			ImageEditor_Cancel(buddy);

			UpdateBuddyActivity(buddy);
		}
		// TODO
		function SendFileDataMessage(buddy, FileDataUrl, fileName, fileSize) {
			if (userAgent == null) return;
			if (!userAgent.isRegistered()) return;

			var fileID = uID();

			// Ajax Upload
			// ===========
			$.ajax({
				type: "POST",
				url: "/api/",
				data: "<XML>" + FileDataUrl + "</XML>",
				xhr: function (e) {
					var myXhr = $.ajaxSettings.xhr();
					if (myXhr.upload) {
						myXhr.upload.addEventListener(
							"progress",
							function (event) {
								var percent = (event.loaded / event.total) * 100;
								console.log(
									"Progress for upload to " +
										buddy +
										" (" +
										fileID +
										"):" +
										percent
								);
								$("#FileProgress-Bar-" + fileID).css("width", percent + "%");
							},
							false
						);
					}
					return myXhr;
				},
				success: function (data, status, jqXHR) {
					// console.log(data);
					$("#FileUpload-" + fileID).html("Sent");
					$("#FileProgress-" + fileID).hide();
					$("#FileProgress-Bar-" + fileID).css("width", "0%");
				},
				error: function (data, status, error) {
					// console.log(data);
					$("#FileUpload-" + fileID).html("Failed (" + data.status + ")");
					$("#FileProgress-" + fileID).hide();
					$("#FileProgress-Bar-" + fileID).css("width", "100%");
				},
			});

			// Add To Message Stream
			// =====================
			var DateTime = utcDateNow();

			var showReview = false;
			var fileIcon = '<i class="fa fa-file"></i>';
			// Image Icons
			if (fileName.toLowerCase().endsWith(".png")) {
				fileIcon = '<i class="fa fa-file-image-o"></i>';
				showReview = true;
			}
			if (fileName.toLowerCase().endsWith(".jpg")) {
				fileIcon = '<i class="fa fa-file-image-o"></i>';
				showReview = true;
			}
			if (fileName.toLowerCase().endsWith(".jpeg")) {
				fileIcon = '<i class="fa fa-file-image-o"></i>';
				showReview = true;
			}
			if (fileName.toLowerCase().endsWith(".bmp")) {
				fileIcon = '<i class="fa fa-file-image-o"></i>';
				showReview = true;
			}
			if (fileName.toLowerCase().endsWith(".gif")) {
				fileIcon = '<i class="fa fa-file-image-o"></i>';
				showReview = true;
			}
			// video Icons
			if (fileName.toLowerCase().endsWith(".mov"))
				fileIcon = '<i class="fa fa-file-video-o"></i>';
			if (fileName.toLowerCase().endsWith(".avi"))
				fileIcon = '<i class="fa fa-file-video-o"></i>';
			if (fileName.toLowerCase().endsWith(".mpeg"))
				fileIcon = '<i class="fa fa-file-video-o"></i>';
			if (fileName.toLowerCase().endsWith(".mp4"))
				fileIcon = '<i class="fa fa-file-video-o"></i>';
			if (fileName.toLowerCase().endsWith(".mvk"))
				fileIcon = '<i class="fa fa-file-video-o"></i>';
			if (fileName.toLowerCase().endsWith(".webm"))
				fileIcon = '<i class="fa fa-file-video-o"></i>';
			// Audio Icons
			if (fileName.toLowerCase().endsWith(".wav"))
				fileIcon = '<i class="fa fa-file-audio-o"></i>';
			if (fileName.toLowerCase().endsWith(".mp3"))
				fileIcon = '<i class="fa fa-file-audio-o"></i>';
			if (fileName.toLowerCase().endsWith(".ogg"))
				fileIcon = '<i class="fa fa-file-audio-o"></i>';
			// Compressed Icons
			if (fileName.toLowerCase().endsWith(".zip"))
				fileIcon = '<i class="fa fa-file-archive-o"></i>';
			if (fileName.toLowerCase().endsWith(".rar"))
				fileIcon = '<i class="fa fa-file-archive-o"></i>';
			if (fileName.toLowerCase().endsWith(".tar.gz"))
				fileIcon = '<i class="fa fa-file-archive-o"></i>';
			// Pdf Icons
			if (fileName.toLowerCase().endsWith(".pdf"))
				fileIcon = '<i class="fa fa-file-pdf-o"></i>';

			var formattedMessage =
				'<DIV><SPAN id="FileUpload-' +
				fileID +
				'">Sending</SPAN>: ' +
				fileIcon +
				" " +
				fileName +
				"</DIV>";
			formattedMessage +=
				'<DIV id="FileProgress-' +
				fileID +
				'" class="progressBarContainer"><DIV id="FileProgress-Bar-' +
				fileID +
				'" class="progressBarTrack"></DIV></DIV>';
			if (showReview) {
				formattedMessage +=
					'<DIV><IMG class=previewImage onClick="PreviewImage(this)" src="' +
					FileDataUrl +
					'"></DIV>';
			}

			var messageString =
				'<table class=ourChatMessage cellspacing=0 cellpadding=0><tr><td style="width: 80px">' +
				"<div class=messageDate>" +
				DateTime +
				"</div>" +
				"</td><td>" +
				"<div class=ourChatMessageText>" +
				formattedMessage +
				"</div>" +
				"</td></tr></table>";
			$("#contact-" + buddy + "-ChatHistory").append(messageString);
			updateScroll(buddy);

			ImageEditor_Cancel(buddy);

			// Update Last Activity
			// ====================
			UpdateBuddyActivity(buddy);
		}
		function updateLineScroll(lineNum) {
			RefreshLineActivity(lineNum);

			var element = $("#line-" + lineNum + "-CallDetails").get(0);
			if (element) element.scrollTop = element.scrollHeight;
		}
		function updateScroll(buddy) {
			var history = $("#contact-" + buddy + "-ChatHistory");
			if (history.children().length > 0)
				history.children().last().get(0).scrollIntoView(false);
			//history.scrollTop = history.scrollTop;
		}
		function PreviewImage(obj) {
			OpenWindow(obj.src, "Preview Image", 600, 800, false, true); //no close, no resize
		}

		// Missed Item Notification
		// ========================
		function IncreaseMissedBadge(buddy) {
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) return;

			// Up the Missed Count
			// ===================
			buddyObj.missed += 1;

			// Take Out
			var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
			if (json != null) {
				$.each(json.DataCollection, function (i, item) {
					if (item.uID == buddy || item.cID == buddy || item.gID == buddy) {
						item.missed = item.missed + 1;
						return false;
					}
				});
				// Put Back
				localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));
			}

			// Update Badge
			// ============
			$("#contact-" + buddy + "-missed").text(buddyObj.missed);
			$("#contact-" + buddy + "-missed").show();

			console.log(
				"Set Missed badge for " +
					buddyObj.CallerIDName +
					" to: " +
					buddyObj.missed
			);
		}
		function UpdateBuddyActivity(buddy, lastAct) {
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) return;

			// Update Last Activity Time
			// =========================
			if (lastAct) {
				buddyObj.lastActivity = lastAct;
			} else {
				var timeStamp = utcDateNow();
				buddyObj.lastActivity = timeStamp;
			}
			console.log(
				"Last Activity for " +
					buddyObj.CallerIDName +
					" is now: " +
					buddyObj.lastActivity
			);

			// Take Out
			var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
			if (json != null) {
				$.each(json.DataCollection, function (i, item) {
					if (item.uID == buddy || item.cID == buddy || item.gID == buddy) {
						item.LastActivity = timeStamp;
						return false;
					}
				});
				// Put Back
				localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));
			}

			// List Update
			// ===========
			UpdateBuddyList();
		}
		function ClearMissedBadge(buddy) {
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) return;

			buddyObj.missed = 0;

			// Take Out
			var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
			if (json != null) {
				$.each(json.DataCollection, function (i, item) {
					if (item.uID == buddy || item.cID == buddy || item.gID == buddy) {
						item.missed = 0;
						return false;
					}
				});
				// Put Back
				localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));
			}

			$("#contact-" + buddy + "-missed").text(buddyObj.missed);
			$("#contact-" + buddy + "-missed").hide(400);
		}

		// Outbound Calling
		// ================

		function AudioCallMenu(buddy, obj) {
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) return;

			var items = [];
			if (buddyObj.type == "extension" || buddyObj.type == "xmpp") {
				items.push({
					icon: "fa fa-phone-square",
					text: lang.call_extension + " (" + buddyObj.ExtNo + ")",
					value: buddyObj.ExtNo,
				});
				if (buddyObj.MobileNumber != null && buddyObj.MobileNumber != "") {
					items.push({
						icon: "fa fa-mobile",
						text: lang.call_mobile + " (" + buddyObj.MobileNumber + ")",
						value: buddyObj.MobileNumber,
					});
				}
				if (buddyObj.ContactNumber1 != null && buddyObj.ContactNumber1 != "") {
					items.push({
						icon: "fa fa-phone",
						text: lang.call_number + " (" + buddyObj.ContactNumber1 + ")",
						value: buddyObj.ContactNumber1,
					});
				}
				if (buddyObj.ContactNumber2 != null && buddyObj.ContactNumber2 != "") {
					items.push({
						icon: "fa fa-phone",
						text: lang.call_number + " (" + buddyObj.ContactNumber2 + ")",
						value: buddyObj.ContactNumber2,
					});
				}
			} else if (buddyObj.type == "contact") {
				if (buddyObj.MobileNumber != null && buddyObj.MobileNumber != "") {
					items.push({
						icon: "fa fa-mobile",
						text: lang.call_mobile + " (" + buddyObj.MobileNumber + ")",
						value: buddyObj.MobileNumber,
					});
				}
				if (buddyObj.ContactNumber1 != null && buddyObj.ContactNumber1 != "") {
					items.push({
						icon: "fa fa-phone",
						text: lang.call_number + " (" + buddyObj.ContactNumber1 + ")",
						value: buddyObj.ContactNumber1,
					});
				}
				if (buddyObj.ContactNumber2 != null && buddyObj.ContactNumber2 != "") {
					items.push({
						icon: "fa fa-phone",
						text: lang.call_number + " (" + buddyObj.ContactNumber2 + ")",
						value: buddyObj.ContactNumber2,
					});
				}
			} else if (buddyObj.type == "group") {
				if (buddyObj.MobileNumber != null && buddyObj.MobileNumber != "") {
					items.push({
						icon: "fa fa-users",
						text: lang.call_group,
						value: buddyObj.ExtNo,
					});
				}
			}
			if (items.length == 0) {
				console.error("No numbers to dial");
				EditBuddyWindow(buddy);
				return;
			}
			if (items.length == 1) {
				// only one number provided, call it
				console.log(
					"Automatically calling only number - AudioCall(" +
						buddy +
						", " +
						items[0].value +
						")"
				);

				DialByLine("audio", items[0].value, buddy);
			} else {
				// Show numbers to dial

				var menu = {
					selectEvent: function (event, ui) {
						var number = ui.item.attr("value");
						HidePopup();
						if (number != null) {
							console.log(
								"Menu click AudioCall(" + buddy + ", " + number + ")"
							);
							DialByLine("audio", number, buddy);
						}
					},
					createEvent: null,
					autoFocus: true,
					items: items,
				};
				PopupMenu(obj, menu);
			}
		}
		function AudioCall(lineObj, dialledNumber, extraHeaders) {
			if (userAgent == null) return;
			if (userAgent.isRegistered() == false) return;
			if (lineObj == null) return;

			if (HasAudioDevice == false) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Lo sentimos, no tienes ningún micrófono conectado a este ordenador. No puede recibir llamadas.",
					type: "error",
				});
				return;
			}

			var supportedConstraints =
				navigator.mediaDevices.getSupportedConstraints();

			var spdOptions = {
				earlyMedia: true,
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: { deviceId: "default" },
						video: false,
					},
				},
			};
			// Configure Audio
			var currentAudioDevice = getAudioSrcID();
			if (currentAudioDevice != "default") {
				var confirmedAudioDevice = false;
				for (var i = 0; i < global.AudioinputDevices.length; ++i) {
					if (currentAudioDevice == global.AudioinputDevices[i].deviceId) {
						confirmedAudioDevice = true;
						break;
					}
				}
				if (confirmedAudioDevice) {
					spdOptions.sessionDescriptionHandlerOptions.constraints.audio.deviceId =
						{ exact: currentAudioDevice };
				} else {
					console.warn(
						"The audio device you used before is no longer available, default settings applied."
					);
					localDB.setItem("AudioSrcId", "default");
				}
			}
			// Add additional Constraints
			if (supportedConstraints.autoGainControl) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl =
					AutoGainControl;
			}
			if (supportedConstraints.echoCancellation) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation =
					EchoCancellation;
			}
			if (supportedConstraints.noiseSuppression) {
				spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression =
					NoiseSuppression;
			}
			// Extra Headers
			if (extraHeaders) {
				spdOptions.extraHeaders = extraHeaders;
			}

			$("#line-" + lineObj.LineNumber + "-msg").html(lang.starting_audio_call);
			$("#line-" + lineObj.LineNumber + "-timer").show();

			var startTime = moment.utc();

			// Invite
			console.log("INVITE (audio): " + dialledNumber + "@" + wssServer);

			var targetURI = SIP.UserAgent.makeURI(
				"sip:" + dialledNumber + "@" + wssServer
			);
			lineObj.SipSession = new SIP.Inviter(userAgent, targetURI, spdOptions);
			lineObj.SipSession.data = {};
			lineObj.SipSession.data.line = lineObj.LineNumber;
			lineObj.SipSession.data.buddyId = lineObj.BuddyObj.identity;
			lineObj.SipSession.data.calldirection = "outbound";
			lineObj.SipSession.data.dst = dialledNumber;
			lineObj.SipSession.data.callstart = startTime.format(
				"YYYY-MM-DD HH:mm:ss UTC"
			);
			lineObj.SipSession.data.callTimer = window.setInterval(function () {
				var now = moment.utc();
				var duration = moment.duration(now.diff(startTime));
				var timeStr = formatShortDuration(duration.asSeconds());
				$("#line-" + lineObj.LineNumber + "-timer").html(timeStr);
				$("#line-" + lineObj.LineNumber + "-datetime").html(timeStr);
				global.tiempo_llamada = timeStr;
				global.datos_lineas_activas[lineObj.LineNumber].llamada_tiempo =
					timeStr;
			}, 1000);
			lineObj.SipSession.data.VideoSourceDevice = null;
			lineObj.SipSession.data.AudioSourceDevice = getAudioSrcID();
			lineObj.SipSession.data.AudioOutputDevice = getAudioOutputID();
			lineObj.SipSession.data.terminateby = "them";
			lineObj.SipSession.data.withvideo = false;
			lineObj.SipSession.data.earlyReject = false;
			lineObj.SipSession.isOnHold = false;
			lineObj.SipSession.delegate = {
				onBye: function (sip) {
					onSessionRecievedBye(lineObj, sip);
				},
				onMessage: function (sip) {
					onSessionRecievedMessage(lineObj, sip);
				},
				onInvite: function (sip) {
					onSessionReinvited(lineObj, sip);
				},
				onSessionDescriptionHandler: function (sdh, provisional) {
					onSessionDescriptionHandlerCreated(lineObj, sdh, provisional, false);
				},
			};
			var inviterOptions = {
				requestDelegate: {
					// OutgoingRequestDelegate
					onTrying: function (sip) {
						onInviteTrying(lineObj, sip);
					},
					onProgress: function (sip) {
						onInviteProgress(lineObj, sip);
					},
					onRedirect: function (sip) {
						onInviteRedirected(lineObj, sip);
					},
					onAccept: function (sip) {
						global.onInviteAccepted(lineObj, false, sip);
					},
					onReject: function (sip) {
						onInviteRejected(lineObj, sip);
					},
				},
			};
			lineObj.SipSession.invite(inviterOptions).catch(function (e) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Error al evniar invitación " + e,
					type: "error",
				});
				console.warn("Failed to send INVITE:", e);
			});

			$("#line-" + lineObj.LineNumber + "-btn-settings").removeAttr("disabled");
			$("#line-" + lineObj.LineNumber + "-btn-audioCall").prop(
				"disabled",
				"disabled"
			);
			$("#line-" + lineObj.LineNumber + "-btn-videoCall").prop(
				"disabled",
				"disabled"
			);
			$("#line-" + lineObj.LineNumber + "-btn-search").removeAttr("disabled");
			$("#line-" + lineObj.LineNumber + "-btn-remove").prop(
				"disabled",
				"disabled"
			);

			$("#line-" + lineObj.LineNumber + "-progress").show();
			$("#line-" + lineObj.LineNumber + "-msg").show();

			//this.UpdateUI();
			UpdateBuddyList();
			updateLineScroll(lineObj.LineNumber);

			// Custom Web hook
			if (typeof web_hook_on_invite !== "undefined")
				web_hook_on_invite(lineObj.SipSession);
		}

		// Sessions & During Call Activity
		// ===============================
		function getSession(buddy) {
			if (userAgent == null) {
				console.warn("userAgent is null");
				return null;
			}
			if (userAgent.isRegistered() == false) {
				console.warn("userAgent is not registered");
				return null;
			}

			var rtnSession = null;
			$.each(userAgent.sessions, function (i, session) {
				if (session.data.buddyId == buddy) {
					rtnSession = session;
					return false;
				}
			});
			return rtnSession;
		}
		function countSessions(id) {
			var rtn = 0;
			if (userAgent == null) {
				console.warn("userAgent is null");
				return 0;
			}
			$.each(userAgent.sessions, function (i, session) {
				if (id != session.id) rtn++;
			});
			return rtn;
		}
		function StartRecording(lineNum) {
			if (CallRecordingPolicy == "disabled") {
				console.warn("Policy Disabled: Call Recording");
				return;
			}
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null) return;

			$("#line-" + lineObj.LineNumber + "-btn-start-recording").hide();
			$("#line-" + lineObj.LineNumber + "-btn-stop-recording").show();

			var session = lineObj.SipSession;
			if (session == null) {
				console.warn("Could not find session");
				return;
			}

			var id = uID();

			if (!session.data.recordings) session.data.recordings = [];
			session.data.recordings.push({
				uID: id,
				startTime: utcDateNow(),
				stopTime: utcDateNow(),
			});

			if (
				session.data.mediaRecorder &&
				session.data.mediaRecorder.state == "recording"
			) {
				console.warn(
					"Call Recording was somehow on... stopping call recording"
				);
				StopRecording(lineNum, true);
				// State should be inactive now, but the dataavailable event will fire
				// Note: potential race condition here if someone hits the stop, and start quite quickly.
			}
			console.log("Creating call recorder...");

			session.data.recordingAudioStreams = new MediaStream();
			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getSenders().forEach(function (RTCRtpSender) {
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
					console.log(
						"Adding sender audio track to record:",
						RTCRtpSender.track.label
					);
					session.data.recordingAudioStreams.addTrack(RTCRtpSender.track);
				}
			});
			pc.getReceivers().forEach(function (RTCRtpReceiver) {
				if (RTCRtpReceiver.track && RTCRtpReceiver.track.kind == "audio") {
					console.log(
						"Adding receiver audio track to record:",
						RTCRtpReceiver.track.label
					);
					session.data.recordingAudioStreams.addTrack(RTCRtpReceiver.track);
				}
			});

			// Resample the Video Recording
			if (session.data.withvideo) {
				var recordingWidth = 640;
				var recordingHeight = 360;
				var pnpVideSize = 100;
				if (RecordingVideoSize == "HD") {
					recordingWidth = 1280;
					recordingHeight = 720;
					pnpVideSize = 144;
				}
				if (RecordingVideoSize == "FHD") {
					recordingWidth = 1920;
					recordingHeight = 1080;
					pnpVideSize = 240;
				}
				// Create Canvas
				session.data.recordingCanvas = $("<canvas/>").get(0);
				session.data.recordingCanvas.width =
					RecordingLayout == "side-by-side"
						? recordingWidth * 2 + 5
						: recordingWidth;
				session.data.recordingCanvas.height = recordingHeight;
				session.data.recordingContext =
					session.data.recordingCanvas.getContext("2d");

				// Capture Interval
				window.clearInterval(session.data.recordingRedrawInterval);
				session.data.recordingRedrawInterval = window.setInterval(function () {
					// Video Source
					var pnpVideo = $("#line-" + lineObj.LineNumber + "-localVideo").get(
						0
					);

					var mainVideo = null;
					var validVideos = [];
					var talkingVideos = [];
					var videoContainer = $(
						"#line-" + lineObj.LineNumber + "-remote-videos"
					);
					var potentialVideos = videoContainer.find("video").length;
					if (potentialVideos == 0) {
						// Nothing to render
						// console.log("Nothing to render in this frame")
					} else if (potentialVideos == 1) {
						mainVideo = videoContainer.find("video")[0];
						// console.log("Only one video element", mainVideo);
					} else if (potentialVideos > 1) {
						// Decide what video to record
						videoContainer.find("video").each(function (i, video) {
							var videoTrack = video.srcObject.getVideoTracks()[0];
							if (
								videoTrack.readyState == "live" &&
								video.videoWidth > 10 &&
								video.videoHeight >= 10
							) {
								if (video.srcObject.isPinned == true) {
									mainVideo = video;
									// console.log("Multiple Videos using last PINNED frame");
								}
								if (video.srcObject.isTalking == true) {
									talkingVideos.push(video);
								}
								validVideos.push(video);
							}
						});

						// Check if we found something
						if (mainVideo == null && talkingVideos.length >= 1) {
							// Nothing pinned use talking
							mainVideo = talkingVideos[0];
							// console.log("Multiple Videos using first TALING frame");
						}
						if (mainVideo == null && validVideos.length >= 1) {
							// Nothing pinned or talking use valid
							mainVideo = validVideos[0];
							// console.log("Multiple Videos using first VALID frame");
						}
					}

					// Main Video
					var videoWidth =
						mainVideo && mainVideo.videoWidth > 0
							? mainVideo.videoWidth
							: recordingWidth;
					var videoHeight =
						mainVideo && mainVideo.videoHeight > 0
							? mainVideo.videoHeight
							: recordingHeight;
					if (videoWidth >= videoHeight) {
						// Landscape / Square
						var scale = recordingWidth / videoWidth;
						videoWidth = recordingWidth;
						videoHeight = videoHeight * scale;
						if (videoHeight > recordingHeight) {
							var scale = recordingHeight / videoHeight;
							videoHeight = recordingHeight;
							videoWidth = videoWidth * scale;
						}
					} else {
						// Portrait
						var scale = recordingHeight / videoHeight;
						videoHeight = recordingHeight;
						videoWidth = videoWidth * scale;
					}
					var offsetX =
						videoWidth < recordingWidth ? (recordingWidth - videoWidth) / 2 : 0;
					var offsetY =
						videoHeight < recordingHeight
							? (recordingHeight - videoHeight) / 2
							: 0;
					if (RecordingLayout == "side-by-side")
						offsetX = recordingWidth + 5 + offsetX;

					// Picture-in-Picture Video
					var pnpVideoHeight = pnpVideo.videoHeight;
					var pnpVideoWidth = pnpVideo.videoWidth;
					if (pnpVideoHeight > 0) {
						if (pnpVideoWidth >= pnpVideoHeight) {
							var scale = pnpVideSize / pnpVideoHeight;
							pnpVideoHeight = pnpVideSize;
							pnpVideoWidth = pnpVideoWidth * scale;
						} else {
							var scale = pnpVideSize / pnpVideoWidth;
							pnpVideoWidth = pnpVideSize;
							pnpVideoHeight = pnpVideoHeight * scale;
						}
					}
					var pnpOffsetX = 10;
					var pnpOffsetY = 10;
					if (RecordingLayout == "side-by-side") {
						pnpVideoWidth = pnpVideo.videoWidth;
						pnpVideoHeight = pnpVideo.videoHeight;
						if (pnpVideoWidth >= pnpVideoHeight) {
							// Landscape / Square
							var scale = recordingWidth / pnpVideoWidth;
							pnpVideoWidth = recordingWidth;
							pnpVideoHeight = pnpVideoHeight * scale;
							if (pnpVideoHeight > recordingHeight) {
								var scale = recordingHeight / pnpVideoHeight;
								pnpVideoHeight = recordingHeight;
								pnpVideoWidth = pnpVideoWidth * scale;
							}
						} else {
							// Portrait
							var scale = recordingHeight / pnpVideoHeight;
							pnpVideoHeight = recordingHeight;
							pnpVideoWidth = pnpVideoWidth * scale;
						}
						pnpOffsetX =
							pnpVideoWidth < recordingWidth
								? (recordingWidth - pnpVideoWidth) / 2
								: 0;
						pnpOffsetY =
							pnpVideoHeight < recordingHeight
								? (recordingHeight - pnpVideoHeight) / 2
								: 0;
					}

					// Draw Background
					session.data.recordingContext.fillRect(
						0,
						0,
						session.data.recordingCanvas.width,
						session.data.recordingCanvas.height
					);

					// Draw Main Video
					if (mainVideo && mainVideo.videoHeight > 0) {
						session.data.recordingContext.drawImage(
							mainVideo,
							offsetX,
							offsetY,
							videoWidth,
							videoHeight
						);
					}

					// Draw PnP
					if (
						pnpVideo.videoHeight > 0 &&
						(RecordingLayout == "side-by-side" || RecordingLayout == "them-pnp")
					) {
						// Only Draw the Pnp Video when needed
						session.data.recordingContext.drawImage(
							pnpVideo,
							pnpOffsetX,
							pnpOffsetY,
							pnpVideoWidth,
							pnpVideoHeight
						);
					}
				}, Math.floor(1000 / RecordingVideoFps));

				// Start Video Capture
				session.data.recordingVideoMediaStream =
					session.data.recordingCanvas.captureStream(RecordingVideoFps);
			}

			session.data.recordingMixedAudioVideoRecordStream = new MediaStream();
			session.data.recordingMixedAudioVideoRecordStream.addTrack(
				MixAudioStreams(session.data.recordingAudioStreams).getAudioTracks()[0]
			);
			if (session.data.withvideo) {
				session.data.recordingMixedAudioVideoRecordStream.addTrack(
					session.data.recordingVideoMediaStream.getVideoTracks()[0]
				);
			}

			var mediaType = "audio/webm"; // audio/mp4 | audio/webm;
			if (session.data.withvideo) mediaType = "video/webm";
			var options = {
				mimeType: mediaType,
			};
			// Note: It appears that mimeType is optional, but... Safari is truly dreadfull at recording in mp4, and doesnt have webm yet
			// You you can leave this as default, or force webm, however know that Safari will be no good at this either way.
			// session.data.mediaRecorder = new MediaRecorder(session.data.recordingMixedAudioVideoRecordStream, options);
			session.data.mediaRecorder = new MediaRecorder(
				session.data.recordingMixedAudioVideoRecordStream
			);
			session.data.mediaRecorder.data = {};
			session.data.mediaRecorder.data.id = "" + id;
			session.data.mediaRecorder.data.sessionId = "" + session.id;
			session.data.mediaRecorder.data.buddyId = "" + lineObj.BuddyObj.identity;
			session.data.mediaRecorder.ondataavailable = function (event) {
				console.log(
					"Got Call Recording Data: ",
					event.data.size + "Bytes",
					this.data.id,
					this.data.buddyId,
					this.data.sessionId
				);
				// Save the Audio/Video file
				SaveCallRecording(
					event.data,
					this.data.id,
					this.data.buddyId,
					this.data.sessionId
				);
			};

			console.log("Starting Call Recording", id);
			session.data.mediaRecorder.start(); // Safari does not support timeslice
			session.data.recordings[session.data.recordings.length - 1].startTime =
				utcDateNow();

			$("#line-" + lineObj.LineNumber + "-msg").html(
				lang.call_recording_started
			);

			updateLineScroll(lineNum);
		}
		function SaveCallRecording(blob, id, buddy, sessionid) {
			var indexedDB = window.indexedDB;
			var request = indexedDB.open("CallRecordings", 1);
			request.onerror = function (event) {
				console.error("IndexDB Request Error:", event);
			};
			request.onupgradeneeded = function (event) {
				console.warn(
					"Upgrade Required for IndexDB... probably because of first time use."
				);
				var IDB = event.target.result;

				// Create Object Store
				if (IDB.objectStoreNames.contains("Recordings") == false) {
					var objectStore = IDB.createObjectStore("Recordings", {
						keyPath: "uID",
					});
					objectStore.createIndex("sessionid", "sessionid", { unique: false });
					objectStore.createIndex("bytes", "bytes", { unique: false });
					objectStore.createIndex("type", "type", { unique: false });
					objectStore.createIndex("mediaBlob", "mediaBlob", { unique: false });
				} else {
					console.warn(
						"IndexDB requested upgrade, but object store was in place."
					);
				}
			};
			request.onsuccess = function (event) {
				console.log("IndexDB connected to CallRecordings");

				var IDB = event.target.result;
				if (IDB.objectStoreNames.contains("Recordings") == false) {
					console.warn(
						"IndexDB CallRecordings.Recordings does not exists, this call recoding will not be saved."
					);
					IDB.close();
					window.indexedDB.deleteDatabase("CallRecordings"); // This should help if the table structure has not been created.
					return;
				}
				IDB.onerror = function (event) {
					console.error("IndexDB Error:", event);
				};

				// Prepare data to write
				var data = {
					uID: id,
					sessionid: sessionid,
					bytes: blob.size,
					type: blob.type,
					mediaBlob: blob,
				};
				// Commit Transaction
				var transaction = IDB.transaction(["Recordings"], "readwrite");
				var objectStoreAdd = transaction.objectStore("Recordings").add(data);
				objectStoreAdd.onsuccess = function (event) {
					console.log(
						"Call Recording Sucess: ",
						id,
						blob.size,
						blob.type,
						buddy,
						sessionid
					);
				};
			};
		}
		function StopRecording(lineNum, noConfirm) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;

			var session = lineObj.SipSession;
			if (noConfirm == true) {
				// Called at the end of a caill
				$("#line-" + lineObj.LineNumber + "-btn-start-recording").show();
				$("#line-" + lineObj.LineNumber + "-btn-stop-recording").hide();

				if (session.data.mediaRecorder) {
					if (session.data.mediaRecorder.state == "recording") {
						console.log("Stopping Call Recording");
						session.data.mediaRecorder.stop(lineNum);
						session.data.recordings[
							session.data.recordings.length - 1
						].stopTime = utcDateNow();
						window.clearInterval(session.data.recordingRedrawInterval);

						$("#line-" + lineObj.LineNumber + "-msg").html(
							lang.call_recording_stopped
						);

						updateLineScroll(lineNum);
					} else {
						console.warn("Recorder is in an unknow state");
					}
				}
				return;
			} else {
				// User attempts to end call recording
				if (CallRecordingPolicy == "enabled") {
					console.warn("Policy Enabled: Call Recording");
					return;
				}

				Confirm(lang.confirm_stop_recording, lang.stop_recording, function () {
					StopRecording(lineNum, true);
				});
			}
		}
		function PlayAudioCallRecording(obj, cdrId, uID) {
			var container = $(obj).parent();
			container.empty();

			var audioObj = new Audio();
			audioObj.autoplay = false;
			audioObj.controls = true;

			// Make sure you are playing out via the correct device
			var sinkId = getAudioOutputID();
			if (typeof audioObj.sinkId !== "undefined") {
				audioObj
					.setSinkId(sinkId)
					.then(function () {
						console.log("sinkId applied: " + sinkId);
					})
					.catch(function (e) {
						console.warn("Error using setSinkId: ", e);
					});
			} else {
				console.warn("setSinkId() is not possible using this browser.");
			}

			container.append(audioObj);

			// Get Call Recording
			var indexedDB = window.indexedDB;
			var request = indexedDB.open("CallRecordings", 1);
			request.onerror = function (event) {
				console.error("IndexDB Request Error:", event);
			};
			request.onupgradeneeded = function (event) {
				console.warn(
					"Upgrade Required for IndexDB... probably because of first time use."
				);
			};
			request.onsuccess = function (event) {
				console.log("IndexDB connected to CallRecordings");

				var IDB = event.target.result;
				if (IDB.objectStoreNames.contains("Recordings") == false) {
					console.warn("IndexDB CallRecordings.Recordings does not exists");
					return;
				}

				var transaction = IDB.transaction(["Recordings"]);
				var objectStoreGet = transaction.objectStore("Recordings").get(uID);
				objectStoreGet.onerror = function (event) {
					console.error("IndexDB Get Error:", event);
				};
				objectStoreGet.onsuccess = function (event) {
					$("#cdr-media-meta-size-" + cdrId + "-" + uID).html(
						" Size: " + formatBytes(event.target.result.bytes)
					);
					$("#cdr-media-meta-codec-" + cdrId + "-" + uID).html(
						" Codec: " + event.target.result.type
					);

					// Play
					audioObj.src = window.URL.createObjectURL(
						event.target.result.mediaBlob
					);
					audioObj.oncanplaythrough = function () {
						audioObj
							.play()
							.then(function () {
								console.log("Playback started");
							})
							.catch(function (e) {
								console.error("Error playing back file: ", e);
							});
					};
				};
			};
		}
		function PlayVideoCallRecording(obj, cdrId, uID, buddy) {
			var container = $(obj).parent();
			container.empty();

			var videoObj = $("<video>").get(0);
			videoObj.id = "callrecording-video-" + cdrId;
			videoObj.autoplay = false;
			videoObj.controls = true;
			videoObj.ontimeupdate = function (event) {
				$("#cdr-video-meta-width-" + cdrId + "-" + uID).html(
					lang.width + " : " + event.target.videoWidth + "px"
				);
				$("#cdr-video-meta-height-" + cdrId + "-" + uID).html(
					lang.height + " : " + event.target.videoHeight + "px"
				);
			};

			var sinkId = getAudioOutputID();
			if (typeof videoObj.sinkId !== "undefined") {
				videoObj
					.setSinkId(sinkId)
					.then(function () {
						console.log("sinkId applied: " + sinkId);
					})
					.catch(function (e) {
						console.warn("Error using setSinkId: ", e);
					});
			} else {
				console.warn("setSinkId() is not possible using this browser.");
			}

			container.append(videoObj);

			// Get Call Recording
			var indexedDB = window.indexedDB;
			var request = indexedDB.open("CallRecordings", 1);
			request.onerror = function (event) {
				console.error("IndexDB Request Error:", event);
			};
			request.onupgradeneeded = function (event) {
				console.warn(
					"Upgrade Required for IndexDB... probably because of first time use."
				);
			};
			request.onsuccess = function (event) {
				console.log("IndexDB connected to CallRecordings");

				var IDB = event.target.result;
				if (IDB.objectStoreNames.contains("Recordings") == false) {
					console.warn("IndexDB CallRecordings.Recordings does not exists");
					return;
				}

				var transaction = IDB.transaction(["Recordings"]);
				var objectStoreGet = transaction.objectStore("Recordings").get(uID);
				objectStoreGet.onerror = function (event) {
					console.error("IndexDB Get Error:", event);
				};
				objectStoreGet.onsuccess = function (event) {
					$("#cdr-media-meta-size-" + cdrId + "-" + uID).html(
						" Size: " + formatBytes(event.target.result.bytes)
					);
					$("#cdr-media-meta-codec-" + cdrId + "-" + uID).html(
						" Codec: " + event.target.result.type
					);

					// Play
					videoObj.src = window.URL.createObjectURL(
						event.target.result.mediaBlob
					);
					videoObj.oncanplaythrough = function () {
						try {
							videoObj.scrollIntoViewIfNeeded(false);
						} catch (e) {
							console.log(e);
						}
						videoObj
							.play()
							.then(function () {
								console.log("Playback started");
							})
							.catch(function (e) {
								console.error("Error playing back file: ", e);
							});

						// Create a Post Image after a second
						if (buddy) {
							window.setTimeout(function () {
								var canvas = $("<canvas>").get(0);
								var videoWidth = videoObj.videoWidth;
								var videoHeight = videoObj.videoHeight;
								if (videoWidth > videoHeight) {
									// Landscape
									if (videoHeight > 225) {
										var p = 225 / videoHeight;
										videoHeight = 225;
										videoWidth = videoWidth * p;
									}
								} else {
									// Portrait
									if (videoHeight > 225) {
										var p = 225 / videoWidth;
										videoWidth = 225;
										videoHeight = videoHeight * p;
									}
								}
								canvas.width = videoWidth;
								canvas.height = videoHeight;
								canvas
									.getContext("2d")
									.drawImage(videoObj, 0, 0, videoWidth, videoHeight);
								canvas.toBlob(
									function (blob) {
										var reader = new FileReader();
										reader.readAsDataURL(blob);
										reader.onloadend = function () {
											var Poster = {
												width: videoWidth,
												height: videoHeight,
												posterBase64: reader.result,
											};
											console.log("Capturing Video Poster...");

											// Update DB
											var currentStream = JSON.parse(
												localDB.getItem(buddy + "-stream")
											);
											if (
												currentStream != null ||
												currentStream.DataCollection != null
											) {
												$.each(
													currentStream.DataCollection,
													function (i, item) {
														if (item.ItemType == "CDR" && item.CdrId == cdrId) {
															// Found
															if (
																item.Recordings &&
																item.Recordings.length >= 1
															) {
																$.each(
																	item.Recordings,
																	function (r, recording) {
																		if (recording.uID == uID)
																			recording.Poster = Poster;
																	}
																);
															}
															return false;
														}
													}
												);
												localDB.setItem(
													buddy + "-stream",
													JSON.stringify(currentStream)
												);
												console.log("Capturing Video Poster, Done");
											}
										};
									},
									"image/jpeg",
									PosterJpegQuality
								);
							}, 1000);
						}
					};
				};
			};
		}

		// Stream Manipulations
		// ====================
		function MixAudioStreams(MultiAudioTackStream) {
			// Takes in a MediaStream with any mumber of audio tracks and mixes them together

			var audioContext = null;
			try {
				window.AudioContext = window.AudioContext || window.webkitAudioContext;
				audioContext = new AudioContext();
			} catch (e) {
				console.warn("AudioContext() not available, cannot record");
				return MultiAudioTackStream;
			}
			var mixedAudioStream = audioContext.createMediaStreamDestination();
			MultiAudioTackStream.getAudioTracks().forEach(function (audioTrack) {
				var srcStream = new MediaStream();
				srcStream.addTrack(audioTrack);
				var streamSourceNode = audioContext.createMediaStreamSource(srcStream);
				streamSourceNode.connect(mixedAudioStream);
			});

			return mixedAudioStream.stream;
		}

		// Call Transfer & Conference
		// ============================
		function QuickFindBuddy(obj) {
			var filter = obj.value;
			if (filter == "") return;

			console.log("Find Buddy: ", filter);

			Buddies.sort(function (a, b) {
				if (a.CallerIDName < b.CallerIDName) return -1;
				if (a.CallerIDName > b.CallerIDName) return 1;
				return 0;
			});

			var items = [];
			var visibleItems = 0;
			for (var b = 0; b < Buddies.length; b++) {
				var buddyObj = Buddies[b];

				// Perform Filter Display
				var display = false;
				if (
					buddyObj.CallerIDName.toLowerCase().indexOf(filter.toLowerCase()) > -1
				)
					display = true;
				if (buddyObj.ExtNo.toLowerCase().indexOf(filter.toLowerCase()) > -1)
					display = true;
				if (buddyObj.Desc.toLowerCase().indexOf(filter.toLowerCase()) > -1)
					display = true;
				if (
					buddyObj.MobileNumber.toLowerCase().indexOf(filter.toLowerCase()) > -1
				)
					display = true;
				if (
					buddyObj.ContactNumber1.toLowerCase().indexOf(filter.toLowerCase()) >
					-1
				)
					display = true;
				if (
					buddyObj.ContactNumber2.toLowerCase().indexOf(filter.toLowerCase()) >
					-1
				)
					display = true;
				if (display) {
					// Filtered Results
					var iconColor = "#404040";
					if (
						buddyObj.presence == "Unknown" ||
						buddyObj.presence == "Not online" ||
						buddyObj.presence == "Unavailable"
					)
						iconColor = "#666666";
					if (buddyObj.presence == "Ready") iconColor = "#3fbd3f";
					if (
						buddyObj.presence == "On the phone" ||
						buddyObj.presence == "Ringing" ||
						buddyObj.presence == "On hold"
					)
						iconColor = "#c99606";

					if (visibleItems > 0) items.push({ value: null, text: "-" });
					items.push({
						value: null,
						text: buddyObj.CallerIDName,
						isHeader: true,
					});
					if (buddyObj.ExtNo != "") {
						items.push({
							icon: "fa fa-phone-square",
							text:
								lang.extension +
								" (" +
								buddyObj.presence +
								"): " +
								buddyObj.ExtNo,
							value: buddyObj.ExtNo,
						});
					}
					if (buddyObj.MobileNumber != "") {
						items.push({
							icon: "fa fa-mobile",
							text: lang.mobile + ": " + buddyObj.MobileNumber,
							value: buddyObj.MobileNumber,
						});
					}
					if (buddyObj.ContactNumber1 != "") {
						items.push({
							icon: "fa fa-phone",
							text: lang.call + ": " + buddyObj.ContactNumber1,
							value: buddyObj.ContactNumber1,
						});
					}
					if (buddyObj.ContactNumber2 != "") {
						items.push({
							icon: "fa fa-phone",
							text: lang.call + ": " + buddyObj.ContactNumber2,
							value: buddyObj.ContactNumber2,
						});
					}
					visibleItems++;
				}
				if (visibleItems >= 5) break;
			}

			if (items.length > 1) {
				var menu = {
					selectEvent: function (event, ui) {
						var number = ui.item.attr("value");
						if (number == null) HidePopup();
						if (number != "null" && number != "" && number != undefined) {
							HidePopup();
							obj.value = number;
						}
					},
					createEvent: null,
					autoFocus: false,
					items: items,
				};
				PopupMenu(obj, menu);
			} else {
				HidePopup();
			}
		}

		// Conference Calls
		// ================
		// In-Session Call Functionality
		// =============================
		function holdSession(lineNum) {
			var lineObj = FindLineByNumber(lineNum);

			if (lineObj == null || lineObj.SipSession == null) return;
			var session = lineObj.SipSession;

			if (session.isOnHold == true) {
				console.log("Call is is already on hold:", lineNum);
				return;
			}
			console.log("Putting Call on hold:", lineNum);
			session.isOnHold = true;

			var sessionDescriptionHandlerOptions =
				session.sessionDescriptionHandlerOptionsReInvite;
			sessionDescriptionHandlerOptions.hold = true;
			session.sessionDescriptionHandlerOptionsReInvite =
				sessionDescriptionHandlerOptions;

			var options = {
				requestDelegate: {
					onAccept: function () {
						if (
							session &&
							session.sessionDescriptionHandler &&
							session.sessionDescriptionHandler.peerConnection
						) {
							var pc = session.sessionDescriptionHandler.peerConnection;
							// Stop all the inbound streams
							pc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = false;
							});
							// Stop all the outbound streams (especially usefull for Conference Calls!!)
							pc.getSenders().forEach(function (RTCRtpSender) {
								// Mute Audio
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									if (RTCRtpSender.track.IsMixedTrack == true) {
										if (
											session.data.AudioSourceTrack &&
											session.data.AudioSourceTrack.kind == "audio"
										) {
											console.log(
												"Muting Mixed Audio Track : " +
													session.data.AudioSourceTrack.label
											);
											session.data.AudioSourceTrack.enabled = false;
										}
									}
									console.log(
										"Muting Audio Track : " + RTCRtpSender.track.label
									);
									RTCRtpSender.track.enabled = false;
								}
								// Stop Video
								else if (
									RTCRtpSender.track &&
									RTCRtpSender.track.kind == "video"
								) {
									RTCRtpSender.track.enabled = false;
								}
							});
						}
						session.isOnHold = true;
						console.log("Call is is on hold:", lineNum);

						$("#line-" + lineNum + "-btn-Hold").hide();
						$("#line-" + lineNum + "-btn-Unhold").show();
						$("#line-" + lineNum + "-msg").html(lang.call_on_hold);

						// Log Hold
						if (!session.data.hold) session.data.hold = [];
						session.data.hold.push({ event: "hold", eventTime: utcDateNow() });

						updateLineScroll(lineNum);

						// Custom Web hook
						if (typeof web_hook_on_modify !== "undefined")
							web_hook_on_modify("hold", session);
					},
					onReject: function () {
						session.isOnHold = false;
						console.warn("Failed to put the call on hold:", lineNum);
					},
				},
			};
			session.invite(options).catch(function (error) {
				session.isOnHold = false;
				console.warn("Error attempting to put the call on hold:", error);
			});
		}
		function unholdSession(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;
			var session = lineObj.SipSession;
			if (session.isOnHold == false) {
				console.log("Call is already off hold:", lineNum);
				return;
			}
			console.log("Taking call off hold:", lineNum);
			session.isOnHold = false;

			var sessionDescriptionHandlerOptions =
				session.sessionDescriptionHandlerOptionsReInvite;
			sessionDescriptionHandlerOptions.hold = false;
			session.sessionDescriptionHandlerOptionsReInvite =
				sessionDescriptionHandlerOptions;

			var options = {
				requestDelegate: {
					onAccept: function () {
						if (
							session &&
							session.sessionDescriptionHandler &&
							session.sessionDescriptionHandler.peerConnection
						) {
							var pc = session.sessionDescriptionHandler.peerConnection;
							// Restore all the inbound streams
							pc.getReceivers().forEach(function (RTCRtpReceiver) {
								if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = true;
							});
							// Restorte all the outbound streams
							pc.getSenders().forEach(function (RTCRtpSender) {
								// Unmute Audio
								if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
									if (RTCRtpSender.track.IsMixedTrack == true) {
										if (
											session.data.AudioSourceTrack &&
											session.data.AudioSourceTrack.kind == "audio"
										) {
											console.log(
												"Unmuting Mixed Audio Track : " +
													session.data.AudioSourceTrack.label
											);
											session.data.AudioSourceTrack.enabled = true;
										}
									}
									console.log(
										"Unmuting Audio Track : " + RTCRtpSender.track.label
									);
									RTCRtpSender.track.enabled = true;
								} else if (
									RTCRtpSender.track &&
									RTCRtpSender.track.kind == "video"
								) {
									RTCRtpSender.track.enabled = true;
								}
							});
						}
						session.isOnHold = false;
						console.log("Call is off hold:", lineNum);

						$("#line-" + lineNum + "-btn-Hold").show();
						$("#line-" + lineNum + "-btn-Unhold").hide();
						$("#line-" + lineNum + "-msg").html(lang.call_in_progress);

						// Log Hold
						if (!session.data.hold) session.data.hold = [];
						session.data.hold.push({
							event: "unhold",
							eventTime: utcDateNow(),
						});

						updateLineScroll(lineNum);

						// Custom Web hook
						if (typeof web_hook_on_modify !== "undefined")
							web_hook_on_modify("unhold", session);
					},
					onReject: function () {
						session.isOnHold = true;
						console.warn("Failed to put the call on hold", lineNum);
					},
				},
			};
			session.invite(options).catch(function (error) {
				session.isOnHold = true;
				console.warn("Error attempting to take to call off hold", error);
			});
		}
		function MuteSession(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;

			$("#line-" + lineNum + "-btn-Unmute").show();
			$("#line-" + lineNum + "-btn-Mute").hide();

			var session = lineObj.SipSession;
			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getSenders().forEach(function (RTCRtpSender) {
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
					if (RTCRtpSender.track.IsMixedTrack == true) {
						if (
							session.data.AudioSourceTrack &&
							session.data.AudioSourceTrack.kind == "audio"
						) {
							console.log(
								"Muting Mixed Audio Track : " +
									session.data.AudioSourceTrack.label
							);
							session.data.AudioSourceTrack.enabled = false;
						}
					}
					console.log("Muting Audio Track : " + RTCRtpSender.track.label);
					RTCRtpSender.track.enabled = false;
				}
			});

			if (!session.data.mute) session.data.mute = [];
			session.data.mute.push({ event: "mute", eventTime: utcDateNow() });
			session.data.ismute = true;

			$("#line-" + lineNum + "-msg").html(lang.call_on_mute);

			updateLineScroll(lineNum);

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("mute", session);
		}
		function UnmuteSession(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) return;

			$("#line-" + lineNum + "-btn-Unmute").hide();
			$("#line-" + lineNum + "-btn-Mute").show();

			var session = lineObj.SipSession;
			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getSenders().forEach(function (RTCRtpSender) {
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
					if (RTCRtpSender.track.IsMixedTrack == true) {
						if (
							session.data.AudioSourceTrack &&
							session.data.AudioSourceTrack.kind == "audio"
						) {
							console.log(
								"Unmuting Mixed Audio Track : " +
									session.data.AudioSourceTrack.label
							);
							session.data.AudioSourceTrack.enabled = true;
						}
					}
					console.log("Unmuting Audio Track : " + RTCRtpSender.track.label);
					RTCRtpSender.track.enabled = true;
				}
			});

			if (!session.data.mute) session.data.mute = [];
			session.data.mute.push({ event: "unmute", eventTime: utcDateNow() });
			session.data.ismute = false;

			$("#line-" + lineNum + "-msg").html(lang.call_off_mute);

			updateLineScroll(lineNum);

			// Custom Web hook
			if (typeof web_hook_on_modify !== "undefined")
				web_hook_on_modify("unmute", session);
		}

		function switchVideoSource(lineNum, srcId) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null");
				return;
			}
			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-msg").html(lang.switching_video_source);

			var supportedConstraints =
				navigator.mediaDevices.getSupportedConstraints();
			var constraints = {
				audio: false,
				video: { deviceId: "default" },
			};
			if (srcId != "default") {
				constraints.video.deviceId = { exact: srcId };
			}

			// Add additional Constraints
			if (supportedConstraints.frameRate && maxFrameRate != "") {
				constraints.video.frameRate = maxFrameRate;
			}
			if (supportedConstraints.height && videoHeight != "") {
				constraints.video.height = videoHeight;
			}
			if (supportedConstraints.aspectRatio && videoAspectRatio != "") {
				constraints.video.aspectRatio = videoAspectRatio;
			}

			session.data.VideoSourceDevice = srcId;

			var pc = session.sessionDescriptionHandler.peerConnection;

			var localStream = new MediaStream();
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(function (newStream) {
					var newMediaTrack = newStream.getVideoTracks()[0];
					// var pc = session.sessionDescriptionHandler.peerConnection;
					pc.getSenders().forEach(function (RTCRtpSender) {
						if (RTCRtpSender.track && RTCRtpSender.track.kind == "video") {
							console.log(
								"Switching Video Track : " +
									RTCRtpSender.track.label +
									" to " +
									newMediaTrack.label
							);
							RTCRtpSender.track.stop(lineNum);
							RTCRtpSender.replaceTrack(newMediaTrack);
							localStream.addTrack(newMediaTrack);
						}
					});
				})
				.catch(function (e) {
					console.error("Error on getUserMedia", e, constraints);
				});

			// Restore Audio Stream is it was changed
			if (
				session.data.AudioSourceTrack &&
				session.data.AudioSourceTrack.kind == "audio"
			) {
				pc.getSenders().forEach(function (RTCRtpSender) {
					if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
						RTCRtpSender.replaceTrack(session.data.AudioSourceTrack)
							.then(function () {
								if (session.data.ismute) {
									RTCRtpSender.track.enabled = false;
								} else {
									RTCRtpSender.track.enabled = true;
								}
							})
							.catch(function () {
								console.error(e);
							});
						session.data.AudioSourceTrack = null;
					}
				});
			}

			// Set Preview
			console.log("Showing as preview...");
			var localVideo = $("#line-" + lineNum + "-localVideo").get(0);
			localVideo.srcObject = localStream;
			localVideo.onloadedmetadata = function (e) {
				localVideo.play();
			};
		}

		// function SendCanvas(lineNum) {
		// 	var lineObj = FindLineByNumber(lineNum);
		// 	if (lineObj == null || lineObj.SipSession == null) {
		// 		console.warn("Line or Session is Null");
		// 		return;
		// 	}
		// 	var session = lineObj.SipSession;

		// 	$("#line-" + lineNum + "-msg").html(lang.switching_to_canvas);

		// 	// Create scratch Pad
		// 	RemoveScratchpad(lineNum);

		// 	// TODO: This needs work!
		// 	var newCanvas = $("<canvas/>");
		// 	newCanvas.prop("id", "line-" + lineNum + "-scratchpad");
		// 	$("#line-" + lineNum + "-scratchpad-container").append(newCanvas);
		// 	$("#line-" + lineNum + "-scratchpad").css("display", "inline-block");
		// 	$("#line-" + lineNum + "-scratchpad").css("width", "100%"); // SD
		// 	$("#line-" + lineNum + "-scratchpad").css("height", "100%"); // SD
		// 	$("#line-" + lineNum + "-scratchpad").prop("width", 640); // SD
		// 	$("#line-" + lineNum + "-scratchpad").prop("height", 360); // SD
		// 	$("#line-" + lineNum + "-scratchpad-container").show();

		// 	console.log("Canvas for Scratchpad created...");

		// 	scratchpad = new fabric.Canvas("line-" + lineNum + "-scratchpad");
		// 	scratchpad.id = "line-" + lineNum + "-scratchpad";
		// 	scratchpad.backgroundColor = "#FFFFFF";
		// 	scratchpad.isDrawingMode = true;
		// 	scratchpad.renderAll();
		// 	scratchpad.redrawIntrtval = window.setInterval(function () {
		// 		scratchpad.renderAll();
		// 	}, 1000);

		// 	CanvasCollection.push(scratchpad);

		// 	// Get The Canvas Stream
		// 	var canvasMediaStream = $("#line-" + lineNum + "-scratchpad")
		// 		.get(0)
		// 		.captureStream(25);
		// 	var canvasMediaTrack = canvasMediaStream.getVideoTracks()[0];

		// 	// Switch Tracks
		// 	var pc = session.sessionDescriptionHandler.peerConnection;
		// 	pc.getSenders().forEach(function (RTCRtpSender) {
		// 		if (RTCRtpSender.track && RTCRtpSender.track.kind == "video") {
		// 			console.log(
		// 				"Switching Track : " +
		// 					RTCRtpSender.track.label +
		// 					" to Scratchpad Canvas"
		// 			);
		// 			RTCRtpSender.track.stop(-1);
		// 			RTCRtpSender.replaceTrack(canvasMediaTrack);
		// 		}
		// 	});

		// 	// Restore Audio Stream is it was changed
		// 	if (
		// 		session.data.AudioSourceTrack &&
		// 		session.data.AudioSourceTrack.kind == "audio"
		// 	) {
		// 		pc.getSenders().forEach(function (RTCRtpSender) {
		// 			if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
		// 				RTCRtpSender.replaceTrack(session.data.AudioSourceTrack)
		// 					.then(function () {
		// 						if (session.data.ismute) {
		// 							RTCRtpSender.track.enabled = false;
		// 						} else {
		// 							RTCRtpSender.track.enabled = true;
		// 						}
		// 					})
		// 					.catch(function () {
		// 						console.error(e);
		// 					});
		// 				session.data.AudioSourceTrack = null;
		// 			}
		// 		});
		// 	}

		// 	// Set Preview
		// 	// ===========
		// 	console.log("Showing as preview...");
		// 	var localVideo = $("#line-" + lineNum + "-localVideo").get(0);
		// 	localVideo.srcObject = canvasMediaStream;
		// 	localVideo.onloadedmetadata = function (e) {
		// 		localVideo.play();
		// 	};
		// }
		function SendVideo(lineNum, src) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null");
				return;
			}

			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-src-camera").prop("disabled", false);
			$("#line-" + lineNum + "-src-canvas").prop("disabled", false);
			$("#line-" + lineNum + "-src-desktop").prop("disabled", false);
			$("#line-" + lineNum + "-src-video").prop("disabled", true);
			$("#line-" + lineNum + "-src-blank").prop("disabled", false);

			$("#line-" + lineNum + "-msg").html(lang.switching_to_shared_video);

			$("#line-" + lineNum + "-scratchpad-container").hide();
			RemoveScratchpad(lineNum);
			$("#line-" + lineNum + "-sharevideo").hide();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.pause();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.removeAttribute("src");
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.load();

			$("#line-" + lineNum + "-localVideo").hide();
			$("#line-" + lineNum + "-remote-videos").hide();
			// $("#line-"+ lineNum +"-remoteVideo").appendTo("#line-" + lineNum + "-preview-container");

			// Create Video Object
			var newVideo = $("#line-" + lineNum + "-sharevideo");
			newVideo.prop("src", src);
			newVideo.off("loadedmetadata");
			newVideo.on("loadedmetadata", function () {
				console.log("Video can play now... ");

				// Resample Video
				var ResampleSize = 360;
				if (VideoResampleSize == "HD") ResampleSize = 720;
				if (VideoResampleSize == "FHD") ResampleSize = 1080;

				var videoObj = newVideo.get(0);
				var resampleCanvas = $("<canvas/>").get(0);

				var videoWidth = videoObj.videoWidth;
				var videoHeight = videoObj.videoHeight;
				if (videoWidth >= videoHeight) {
					// Landscape / Square
					if (videoHeight > ResampleSize) {
						var p = ResampleSize / videoHeight;
						videoHeight = ResampleSize;
						videoWidth = videoWidth * p;
					}
				} else {
					// Portrate... (phone turned on its side)
					if (videoWidth > ResampleSize) {
						var p = ResampleSize / videoWidth;
						videoWidth = ResampleSize;
						videoHeight = videoHeight * p;
					}
				}

				resampleCanvas.width = videoWidth;
				resampleCanvas.height = videoHeight;
				var resampleContext = resampleCanvas.getContext("2d");

				window.clearInterval(session.data.videoResampleInterval);
				session.data.videoResampleInterval = window.setInterval(function () {
					resampleContext.drawImage(videoObj, 0, 0, videoWidth, videoHeight);
				}, 40); // 25frames per second

				// Capture the streams
				var videoMediaStream = null;
				if ("captureStream" in videoObj) {
					videoMediaStream = videoObj.captureStream();
				} else if ("mozCaptureStream" in videoObj) {
					// This doesnt really work?
					// see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/captureStream
					videoMediaStream = videoObj.mozCaptureStream();
				} else {
					// This is not supported??.
					// videoMediaStream = videoObj.webkitCaptureStream();
					console.warn(
						"Cannot capture stream from video, this will result in no audio being transmitted."
					);
				}
				var resampleVideoMediaStream = resampleCanvas.captureStream(25);

				// Get the Tracks
				var videoMediaTrack = resampleVideoMediaStream.getVideoTracks()[0];
				var audioTrackFromVideo =
					videoMediaStream != null
						? videoMediaStream.getAudioTracks()[0]
						: null;

				// Switch & Merge Tracks
				var pc = session.sessionDescriptionHandler.peerConnection;
				pc.getSenders().forEach(function (RTCRtpSender) {
					if (RTCRtpSender.track && RTCRtpSender.track.kind == "video") {
						console.log("Switching Track : " + RTCRtpSender.track.label);
						RTCRtpSender.track.stop(-1);
						RTCRtpSender.replaceTrack(videoMediaTrack);
					}
					if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
						console.log("Switching to mixed Audio track on session");

						session.data.AudioSourceTrack = RTCRtpSender.track;

						var mixedAudioStream = new MediaStream();
						if (audioTrackFromVideo)
							mixedAudioStream.addTrack(audioTrackFromVideo);
						mixedAudioStream.addTrack(RTCRtpSender.track);
						var mixedAudioTrack =
							MixAudioStreams(mixedAudioStream).getAudioTracks()[0];
						mixedAudioTrack.IsMixedTrack = true;

						RTCRtpSender.replaceTrack(mixedAudioTrack);
					}
				});

				// Set Preview
				console.log("Showing as preview...");
				var localVideo = $("#line-" + lineNum + "-localVideo").get(0);
				localVideo.srcObject = videoMediaStream;
				localVideo.onloadedmetadata = function (e) {
					localVideo
						.play()
						.then(function () {
							console.log("Playing Preview Video File");
						})
						.catch(function (e) {
							console.error("Cannot play back video", e);
						});
				};
				// Play the video
				console.log("Starting Video...");
				$("#line-" + lineNum + "-sharevideo")
					.get(0)
					.play();
			});

			$("#line-" + lineNum + "-sharevideo").show();
			console.log("Video for Sharing created...");
		}
		function ShareScreen(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null");
				return;
			}
			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-msg").html(lang.switching_to_shared_screeen);

			var localStream = new MediaStream();
			var pc = session.sessionDescriptionHandler.peerConnection;

			// TODO: Remove legasy ones
			if (navigator.getDisplayMedia) {
				// EDGE, legasy support
				var screenShareConstraints = { video: true, audio: false };
				navigator
					.getDisplayMedia(screenShareConstraints)
					.then(function (newStream) {
						console.log("navigator.getDisplayMedia");
						var newMediaTrack = newStream.getVideoTracks()[0];
						pc.getSenders().forEach(function (RTCRtpSender) {
							if (RTCRtpSender.track && RTCRtpSender.track.kind == "video") {
								console.log(
									"Switching Video Track : " +
										RTCRtpSender.track.label +
										" to Screen"
								);
								RTCRtpSender.track.stop(-1);
								RTCRtpSender.replaceTrack(newMediaTrack);
								localStream.addTrack(newMediaTrack);
							}
						});

						// Set Preview
						// ===========
						console.log("Showing as preview...");
						var localVideo = $("#line-" + lineNum + "-localVideo").get(0);
						localVideo.srcObject = localStream;
						localVideo.onloadedmetadata = function (e) {
							localVideo.play();
						};
					})
					.catch(function (err) {
						console.error("Error on getUserMedia");
					});
			} else if (navigator.mediaDevices.getDisplayMedia) {
				// New standard
				var screenShareConstraints = { video: true, audio: false };
				navigator.mediaDevices
					.getDisplayMedia(screenShareConstraints)
					.then(function (newStream) {
						console.log("navigator.mediaDevices.getDisplayMedia");
						var newMediaTrack = newStream.getVideoTracks()[0];
						pc.getSenders().forEach(function (RTCRtpSender) {
							if (RTCRtpSender.track && RTCRtpSender.track.kind == "video") {
								console.log(
									"Switching Video Track : " +
										RTCRtpSender.track.label +
										" to Screen"
								);
								RTCRtpSender.track.stop(-1);
								RTCRtpSender.replaceTrack(newMediaTrack);
								localStream.addTrack(newMediaTrack);
							}
						});

						// Set Preview
						// ===========
						console.log("Showing as preview...");
						var localVideo = $("#line-" + lineNum + "-localVideo").get(0);
						localVideo.srcObject = localStream;
						localVideo.onloadedmetadata = function (e) {
							localVideo.play();
						};
					})
					.catch(function (err) {
						console.error("Error on getUserMedia");
					});
			} else {
				// Firefox, apparently
				var screenShareConstraints = {
					video: { mediaSource: "screen" },
					audio: false,
				};
				navigator.mediaDevices
					.getUserMedia(screenShareConstraints)
					.then(function (newStream) {
						console.log("navigator.mediaDevices.getUserMedia");
						var newMediaTrack = newStream.getVideoTracks()[0];
						pc.getSenders().forEach(function (RTCRtpSender) {
							if (RTCRtpSender.track && RTCRtpSender.track.kind == "video") {
								console.log(
									"Switching Video Track : " +
										RTCRtpSender.track.label +
										" to Screen"
								);
								RTCRtpSender.track.stop(-1);
								RTCRtpSender.replaceTrack(newMediaTrack);
								localStream.addTrack(newMediaTrack);
							}
						});

						// Set Preview
						console.log("Showing as preview...");
						var localVideo = $("#line-" + lineNum + "-localVideo").get(0);
						localVideo.srcObject = localStream;
						localVideo.onloadedmetadata = function (e) {
							localVideo.play();
						};
					})
					.catch(function (err) {
						console.error("Error on getUserMedia");
					});
			}

			// Restore Audio Stream is it was changed
			if (
				session.data.AudioSourceTrack &&
				session.data.AudioSourceTrack.kind == "audio"
			) {
				pc.getSenders().forEach(function (RTCRtpSender) {
					if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
						RTCRtpSender.replaceTrack(session.data.AudioSourceTrack)
							.then(function () {
								if (session.data.ismute) {
									RTCRtpSender.track.enabled = false;
								} else {
									RTCRtpSender.track.enabled = true;
								}
							})
							.catch(function () {
								console.error(e);
							});
						session.data.AudioSourceTrack = null;
					}
				});
			}
		}
		function DisableVideoStream(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null");
				return;
			}
			var session = lineObj.SipSession;

			var pc = session.sessionDescriptionHandler.peerConnection;
			pc.getSenders().forEach(function (RTCRtpSender) {
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "video") {
					console.log("Disable Video Track : " + RTCRtpSender.track.label + "");
					RTCRtpSender.track.enabled = false; //stop();
				}
				if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
					if (
						session.data.AudioSourceTrack &&
						session.data.AudioSourceTrack.kind == "audio"
					) {
						RTCRtpSender.replaceTrack(session.data.AudioSourceTrack)
							.then(function () {
								if (session.data.ismute) {
									RTCRtpSender.track.enabled = false;
								} else {
									RTCRtpSender.track.enabled = true;
								}
							})
							.catch(function () {
								console.error(e);
							});
						session.data.AudioSourceTrack = null;
					}
				}
			});

			// Set Preview
			console.log("Showing as preview...");
			var localVideo = $("#line-" + lineNum + "-localVideo").get(0);
			localVideo.pause();
			localVideo.removeAttribute("src");
			localVideo.load();

			$("#line-" + lineNum + "-msg").html(lang.video_disabled);
		}

		function HideCallTimeline(lineNum) {
			console.log("Hide Timeline");
			HidePopup();

			$("#line-" + lineNum + "-CallDetails").hide();
			$("#line-" + lineNum + "-AudioOrVideoCall").show();

			$("#line-" + lineNum + "-btn-ShowTimeline").show();
			$("#line-" + lineNum + "-btn-HideTimeline").hide();
		}

		function HideCallStats(lineNum) {
			console.log("Hide Call Stats");

			HidePopup();
			$("#line-" + lineNum + "-AudioOrVideoCall").show();
			$("#line-" + lineNum + "-AudioStats").hide();

			$("#line-" + lineNum + "-btn-ShowCallStats").show();
			$("#line-" + lineNum + "-btn-HideCallStats").hide();
		}

		// Phone Lines
		// ===========
		var Line = function (lineNumber, displayName, displayNumber, buddyObj) {
			console.group("<---Creando nueva linea--->");
			console.log("displayName-->", lineNumber);
			console.log("displayName-->", displayName);
			console.log("displayName-->", displayNumber);
			console.log("displayName-->", displayNumber);
			console.log("displayName-->", buddyObj);
			console.groupEnd();
			this.LineNumber = lineNumber;
			this.DisplayName = displayName;
			this.DisplayNumber = displayNumber;
			this.IsSelected = false;
			this.BuddyObj = buddyObj;
			this.SipSession = null;
			this.LocalSoundMeter = null;
			this.RemoteSoundMeter = null;
		};
		function ShowDial() {
			ShowContacts();
		}
		function ShowContacts() {
			var localVideo = $("#local-video-preview").get(0);
			try {
				var tracks = localVideo.srcObject.getTracks();
				tracks.forEach(function (track) {
					track.stop(-1);
				});
				localVideo.srcObject = null;
			} catch (e) {
				console.log(e);
			}

			// Microphone Preview
			try {
				var tracks = window.SettingsMicrophoneStream.getTracks();
				tracks.forEach(function (track) {
					track.stop(-1);
				});
			} catch (e) {
				console.log(e);
			}
			window.SettingsMicrophoneStream = null;

			try {
				var soundMeter = window.SettingsMicrophoneSoundMeter;
				soundMeter.stop(-1);
			} catch (e) {
				console.log(e);
			}
			window.SettingsMicrophoneSoundMeter = null;

			// Speaker Preview
			try {
				window.SettingsOutputAudio.pause();
			} catch (e) {
				console.log(e);
			}
			window.SettingsOutputAudio = null;

			try {
				var tracks = window.SettingsOutputStream.getTracks();
				tracks.forEach(function (track) {
					track.stop(-1);
				});
			} catch (e) {
				console.log(e);
			}
			window.SettingsOutputStream = null;

			try {
				var soundMeter = window.SettingsOutputStreamMeter;
				soundMeter.stop(-1);
			} catch (e) {
				console.log(e);
			}
			window.SettingsOutputStreamMeter = null;

			// Ringer Preview
			try {
				window.SettingsRingerAudio.pause();
			} catch (e) {
				console.log(e);
			}
			window.SettingsRingerAudio = null;

			try {
				var tracks = window.SettingsRingerStream.getTracks();
				tracks.forEach(function (track) {
					track.stop(-1);
				});
			} catch (e) {
				console.log(e);
			}
			window.SettingsRingerStream = null;

			try {
				var soundMeter = window.SettingsRingerStreamMeter;
				soundMeter.stop(-1);
			} catch (e) {
				console.log(e);
			}
			window.SettingsRingerStreamMeter = null;

			$("#actionArea").hide();
			$("#actionArea").empty();

			$("#myContacts").show();
		}

		/**
		 * Primary method for making a call.
		 * @param {string} type = (required) Either "audio" or "video". Will setup UI according to this type.
		 * @param {Buddy} buddy = (optional) The buddy to dial if provided.
		 * @param {sting} numToDial = (required) The number to dial.
		 * @param {string} CallerID = (optional) If no buddy provided, one is generated automatically using this callerID and the numToDial
		 * @param {Array<string>} extraHeaders = (optinal) Array of headers to include in the INVITE eg: ["foo: bar"] (Note the space after the :)
		 */
		function SelectLine(lineNum) {
			console.log("Entrea select line function: ", lineNum);
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null) return;

			var displayLineNumber = 0;
			for (var l = 0; l < Lines.length; l++) {
				if (Lines[l].LineNumber == lineObj.LineNumber)
					displayLineNumber = l + 1;
				if (
					Lines[l].IsSelected == true &&
					Lines[l].LineNumber == lineObj.LineNumber
				) {
					// Nothing to do, you re-selected the same buddy;
					return;
				}
			}
			// Can only display one thing on the Right
			$(".streamSelected").each(function () {
				$(this).prop("class", "stream");
			});
			$("#line-ui-" + lineObj.LineNumber).prop("class", "streamSelected");

			$("#line-ui-" + lineObj.LineNumber + "-DisplayLineNo").html(
				'<i class="fa fa-phone"></i> ' + lang.line + " " + displayLineNumber
			);
			$("#line-ui-" + lineObj.LineNumber + "-LineIcon").html(displayLineNumber);

			// Switch the SIP Sessions
			SwitchLines(lineObj.LineNumber);

			// Update Lines List
			for (var l = 0; l < Lines.length; l++) {
				var classStr =
					Lines[l].LineNumber == lineObj.LineNumber ? "buddySelected" : "buddy";
				if (Lines[l].SipSession != null)
					classStr = Lines[l].SipSession.isOnHold
						? "buddyActiveCallHollding"
						: "buddyActiveCall";

				$("#line-" + Lines[l].LineNumber).prop("class", classStr);
				Lines[l].IsSelected = Lines[l].LineNumber == lineObj.LineNumber;
			}
			// Update Buddy List
			for (var b = 0; b < Buddies.length; b++) {
				$("#contact-" + Buddies[b].identity).prop("class", "buddy");
				Buddies[b].IsSelected = false;
			}

			// Change to Stream if in Narrow view
			//this.UpdateUI();
		}
		function AddLineHtml(lineObj) {
			console.log("Agregando botones de nueva llamada: ", lineObj);
			global.numero_linea = lineObj.LineNumber;
			global.drawer = true;
			global.telefono_tranferencia = "";
			global.lienea_uno_activa = true;
			global.contestar = true;
			global.finalizar_llamada = false;
			global.colgar = true;
			global.llamadaSaliente = false;
			global.respuesta_automatica = false;
			global.numero_telefonico = lineObj.DisplayNumber;
		}
		function RemoveLine(lineObj) {
			if (lineObj == null) return;

			var earlyReject = lineObj.SipSession.data.earlyReject;
			for (var l = 0; l < Lines.length; l++) {
				if (Lines[l].LineNumber == lineObj.LineNumber) {
					Lines.splice(l, 1);
					break;
				}
			}

			if (earlyReject != true) {
				CloseLine(lineObj.LineNumber);
				$("#line-ui-" + lineObj.LineNumber).remove();
			}

			UpdateBuddyList();

			if (earlyReject != true) {
				// Rather than showing nothing, go to the last Buddy Selected
				// Select Last user
				if (localDB.getItem("SelectedBuddy") != null) {
					console.log(
						"Selecting previously selected buddy...",
						localDB.getItem("SelectedBuddy")
					);
					SelectBuddy(localDB.getItem("SelectedBuddy"));
					//this.UpdateUI();
				}
			}
		}
		function CloseLine(lineNum) {
			global.numero_telefonico = "";
			global.tiempo_llamada = "";
			global.activarmute = false;
			global.desactivarmute = false;
			global.poner_en_espera = false;
			global.quitar_en_espera = false;
			global.transferencia = false;
			global.nueva_linea = false;
			global.cancelar_transferencia = false;
			global.cancelar_nueva_linea = false;
			global.conferencia = false;
			global.llamadaSaliente = true;
			global.linea_conferencia = false;
			global.desactivar_conferencia = false;
			global.digitar_conferencia = false;
			global.contestar = false;
			global.colgar = false;
			global.cancelar_llamada_saliente = true;
			global.datos_llamada = false;
			global.digitar_transferencia = false;
			global.digitar_nueva_linea = false;
			global.digitar_telefono = false;
			global.drawer = false;
			global.telefono_tranferencia = "";
			global.stopDuration();
			// Lines and Buddies (Left)
			$(".buddySelected").each(function () {
				$(this).prop("class", "buddy");
			});
			// Streams (Right)
			$(".streamSelected").each(function () {
				$(this).prop("class", "stream");
			});

			// SwitchLines(0);
			console.log("Closing Line: " + lineNum);
			for (var l = 0; l < Lines.length; l++) {
				Lines[l].IsSelected = false;
			}
			selectedLine = null;
			for (var b = 0; b < Buddies.length; b++) {
				Buddies[b].IsSelected = false;
			}
			selectedBuddy = null;

			// Save Selected
			// localDB.setItem("SelectedBuddy", null);

			// Change to Stream if in Narrow view
			//this.UpdateUI();
		}
		function SwitchLines(lineNum) {
			$.each(userAgent.sessions, function (i, session) {
				// All the other calls, not on hold
				if (session.state == SIP.SessionState.Established) {
					if (session.isOnHold == false && session.data.line != lineNum) {
						holdSession(session.data.line);
					}
				}
				session.data.IsCurrentCall = false;
			});

			var lineObj = FindLineByNumber(lineNum);
			if (lineObj != null && lineObj.SipSession != null) {
				var session = lineObj.SipSession;
				if (session.state == SIP.SessionState.Established) {
					if (session.isOnHold == true) {
						unholdSession(lineNum);
					}
				}
				session.data.IsCurrentCall = true;
			}
			selectedLine = lineNum;

			RefreshLineActivity(lineNum);
		}
		function RefreshLineActivity(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				return;
			}
			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-CallDetails").empty();

			var callDetails = [];

			var ringTime = 0;
			var CallStart = moment.utc(session.data.callstart.replace(" UTC", ""));
			var CallAnswer = null;
			if (session.data.startTime) {
				CallAnswer = moment.utc(session.data.startTime);
				ringTime = moment.duration(CallAnswer.diff(CallStart));
			}
			CallStart = CallStart.format("YYYY-MM-DD HH:mm:ss UTC");
			(CallAnswer = CallAnswer
				? CallAnswer.format("YYYY-MM-DD HH:mm:ss UTC")
				: null),
				(ringTime = ringTime != 0 ? ringTime.asSeconds() : 0);

			var srcCallerID = "";
			var dstCallerID = "";
			if (session.data.calldirection == "inbound") {
				srcCallerID =
					"<" +
					session.remoteIdentity.uri.user +
					"> " +
					session.remoteIdentity.displayName;
			} else if (session.data.calldirection == "outbound") {
				dstCallerID = session.data.dst;
			}

			var withVideo = session.data.withvideo ? "(" + lang.with_video + ")" : "";
			var startCallMessage =
				session.data.calldirection == "inbound"
					? lang.you_received_a_call_from + " " + srcCallerID + " " + withVideo
					: lang.you_made_a_call_to + " " + dstCallerID + " " + withVideo;
			callDetails.push({
				Message: startCallMessage,
				TimeStr: CallStart,
			});
			if (CallAnswer) {
				var answerCallMessage =
					session.data.calldirection == "inbound"
						? lang.you_answered_after +
						  " " +
						  ringTime +
						  " " +
						  lang.seconds_plural
						: lang.they_answered_after +
						  " " +
						  ringTime +
						  " " +
						  lang.seconds_plural;
				callDetails.push({
					Message: answerCallMessage,
					TimeStr: CallAnswer,
				});
			}

			var Transfers = session.data.transfer ? session.data.transfer : [];
			$.each(Transfers, function (item, transfer) {
				var msg =
					transfer.type == "Blind"
						? lang.you_started_a_blind_transfer_to + " " + transfer.to + ". "
						: lang.you_started_an_attended_transfer_to +
						  " " +
						  transfer.to +
						  ". ";
				if (transfer.accept && transfer.accept.complete == true) {
					msg += lang.the_call_was_completed;
				} else if (transfer.accept.disposition != "") {
					msg +=
						lang.the_call_was_not_completed +
						" (" +
						transfer.accept.disposition +
						")";
				}
				callDetails.push({
					Message: msg,
					TimeStr: transfer.transferTime,
				});
			});
			var Mutes = session.data.mute ? session.data.mute : [];
			$.each(Mutes, function (item, mute) {
				callDetails.push({
					Message:
						mute.event == "mute"
							? lang.you_put_the_call_on_mute
							: lang.you_took_the_call_off_mute,
					TimeStr: mute.eventTime,
				});
			});
			var Holds = session.data.hold ? session.data.hold : [];
			$.each(Holds, function (item, hold) {
				callDetails.push({
					Message:
						hold.event == "hold"
							? lang.you_put_the_call_on_hold
							: lang.you_took_the_call_off_hold,
					TimeStr: hold.eventTime,
				});
			});
			var ConfbridgeEvents = session.data.ConfbridgeEvents
				? session.data.ConfbridgeEvents
				: [];
			$.each(ConfbridgeEvents, function (item, event) {
				callDetails.push({
					Message: event.event,
					TimeStr: event.eventTime,
				});
			});
			var Recordings = session.data.recordings ? session.data.recordings : [];
			$.each(Recordings, function (item, recording) {
				var msg = lang.call_is_being_recorded;
				if (recording.startTime != recording.stopTime) {
					msg += "(" + lang.now_stopped + ")";
				}
				callDetails.push({
					Message: msg,
					TimeStr: recording.startTime,
				});
			});
			var ConfCalls = session.data.confcalls ? session.data.confcalls : [];
			$.each(ConfCalls, function (item, confCall) {
				var msg =
					lang.you_started_a_conference_call_to + " " + confCall.to + ". ";
				if (confCall.accept && confCall.accept.complete == true) {
					msg += lang.the_call_was_completed;
				} else if (confCall.accept.disposition != "") {
					msg +=
						lang.the_call_was_not_completed +
						" (" +
						confCall.accept.disposition +
						")";
				}
				callDetails.push({
					Message: msg,
					TimeStr: confCall.startTime,
				});
			});

			callDetails.sort(function (a, b) {
				var aMo = moment.utc(a.TimeStr.replace(" UTC", ""));
				var bMo = moment.utc(b.TimeStr.replace(" UTC", ""));
				if (aMo.isSameOrAfter(bMo, "second")) {
					return -1;
				} else return 1;
			});

			$.each(callDetails, function (item, detail) {
				var Time = moment
					.utc(detail.TimeStr.replace(" UTC", ""))
					.local()
					.format(DisplayTimeFormat);
				var messageString =
					"<table class=timelineMessage cellspacing=0 cellpadding=0><tr>";
				messageString += "<td class=timelineMessageArea>";
				messageString +=
					'<div class=timelineMessageDate><i class="fa fa-circle timelineMessageDot"></i>' +
					Time +
					"</div>";
				messageString +=
					"<div class=timelineMessageText>" + detail.Message + "</div>";
				messageString += "</td>";
				messageString += "</tr></table>";
				$("#line-" + lineNum + "-CallDetails").prepend(messageString);
			});
		}

		// Buddy & Contacts
		// ================
		var Buddy = function (
			type,
			identity,
			CallerIDName,
			ExtNo,
			MobileNumber,
			ContactNumber1,
			ContactNumber2,
			lastActivity,
			desc,
			Email,
			jid,
			dnd,
			subscribe
		) {
			this.type = type; // extension | contact | group
			this.identity = identity;
			this.jid = jid;
			this.CallerIDName = CallerIDName ? CallerIDName : "";
			this.Email = Email;
			this.Desc = desc;
			this.ExtNo = ExtNo;
			this.MobileNumber = MobileNumber;
			this.ContactNumber1 = ContactNumber1;
			this.ContactNumber2 = ContactNumber2;
			this.lastActivity = lastActivity; // Full Date as string eg "1208-03-21 15:34:23 UTC"
			this.devState = "dotOffline";
			this.presence = "Unknown";
			this.missed = 0;
			this.IsSelected = false;
			this.imageObjectURL = "";
			this.presenceText = lang.default_status;
			this.EnableDuringDnd = dnd;
			this.EnableSubscribe = subscribe;
		};
		function InitUserBuddies() {
			var template = { TotalRows: 0, DataCollection: [] };
			localDB.setItem(profileUserID + "-Buddies", JSON.stringify(template));
			return JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
		}
		function MakeBuddy(
			type,
			update,
			focus,
			subscribe,
			callerID,
			did,
			jid,
			AllowDuringDnd
		) {
			console.log(
				"entrante",
				type,
				update,
				focus,
				subscribe,
				callerID,
				did,
				jid,
				AllowDuringDnd
			);

			var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
			if (json == null) json = InitUserBuddies();
			var dateNow = utcDateNow();
			var buddyObj = null;
			var id = uID();
			console.log("id", id);

			if (type == "extension") {
				json.DataCollection.push({
					Type: "extension",
					LastActivity: dateNow,
					ExtensionNumber: did,
					MobileNumber: "",
					ContactNumber1: "",
					ContactNumber2: "",
					uID: id,
					cID: null,
					gID: null,
					jid: null,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: AllowDuringDnd,
					Subscribe: subscribe,
				});
				buddyObj = new Buddy(
					"extension",
					id,
					callerID,
					did,
					"",
					"",
					"",
					dateNow,
					"",
					"",
					null,
					AllowDuringDnd,
					subscribe
				);
				AddBuddy(buddyObj, update, focus, subscribe);
			}
			if (type == "xmpp") {
				json.DataCollection.push({
					Type: "xmpp",
					LastActivity: dateNow,
					ExtensionNumber: did,
					MobileNumber: "",
					ContactNumber1: "",
					ContactNumber2: "",
					uID: id,
					cID: null,
					gID: null,
					jid: jid,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: AllowDuringDnd,
					Subscribe: subscribe,
				});
				buddyObj = new Buddy(
					"xmpp",
					id,
					callerID,
					did,
					"",
					"",
					"",
					dateNow,
					"",
					"",
					jid,
					AllowDuringDnd,
					subscribe
				);
				AddBuddy(buddyObj, update, focus, subscribe);
			}
			if (type == "contact") {
				json.DataCollection.push({
					Type: "contact",
					LastActivity: dateNow,
					ExtensionNumber: "",
					MobileNumber: "",
					ContactNumber1: did,
					ContactNumber2: "",
					uID: null,
					cID: id,
					gID: null,
					jid: null,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: AllowDuringDnd,
					Subscribe: false,
				});
				buddyObj = new Buddy(
					"contact",
					id,
					callerID,
					"",
					"",
					did,
					"",
					dateNow,
					"",
					"",
					null,
					AllowDuringDnd,
					false
				);
				AddBuddy(buddyObj, update, focus, false);
			}
			if (type == "group") {
				json.DataCollection.push({
					Type: "group",
					LastActivity: dateNow,
					ExtensionNumber: did,
					MobileNumber: "",
					ContactNumber1: "",
					ContactNumber2: "",
					uID: null,
					cID: null,
					gID: id,
					jid: null,
					DisplayName: callerID,
					Description: "",
					Email: "",
					MemberCount: 0,
					EnableDuringDnd: false,
					Subscribe: false,
				});
				buddyObj = new Buddy(
					"group",
					id,
					callerID,
					did,
					"",
					"",
					"",
					dateNow,
					"",
					"",
					null,
					false,
					false
				);
				AddBuddy(buddyObj, update, focus, false);
			}
			// Update Size:
			json.TotalRows = json.DataCollection.length;

			// Save To DB
			localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));

			// Return new buddy
			return buddyObj;
		}
		function UpdateBuddyCalerID(buddyObj, callerID) {
			buddyObj.CallerIDName = callerID;

			var buddy = buddyObj.identity;
			// Update DB
			var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
			if (json != null) {
				$.each(json.DataCollection, function (i, item) {
					if (item.uID == buddy || item.cID == buddy || item.gID == buddy) {
						item.DisplayName = callerID;
						return false;
					}
				});
				// Save To DB
				localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));
			}

			UpdateBuddyList();
		}
		function AddBuddy(buddyObj, update, focus, subscribe) {
			Buddies.push(buddyObj);
			global.Buddies.push(buddyObj);
			if (update == true) UpdateBuddyList();
			AddBuddyMessageStream(buddyObj);
			if (subscribe == true) SubscribeBuddy(buddyObj);
			if (focus == true) SelectBuddy(buddyObj.identity);
		}
		function PopulateBuddyList() {
			console.log("Clearing Buddies...");
			Buddies = [];
			console.log("Adding Buddies...");
			var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
			if (json == null) return;

			console.log("Total Buddies: " + json.TotalRows);
			$.each(json.DataCollection, function (i, item) {
				if (item.Type == "extension") {
					// extension
					var buddy = new Buddy(
						"extension",
						item.uID,
						item.DisplayName,
						item.ExtensionNumber,
						item.MobileNumber,
						item.ContactNumber1,
						item.ContactNumber2,
						item.LastActivity,
						item.Description,
						item.Email,
						null,
						item.EnableDuringDnd,
						item.Subscribe
					);
					AddBuddy(buddy, false, false, false);
				} else if (item.Type == "xmpp") {
					// xmpp
					var buddy = new Buddy(
						"xmpp",
						item.uID,
						item.DisplayName,
						item.ExtensionNumber,
						"",
						"",
						"",
						item.LastActivity,
						"",
						"",
						item.jid,
						item.EnableDuringDnd,
						item.Subscribe
					);
					AddBuddy(buddy, false, false, false);
				} else if (item.Type == "contact") {
					// contact
					var buddy = new Buddy(
						"contact",
						item.cID,
						item.DisplayName,
						"",
						item.MobileNumber,
						item.ContactNumber1,
						item.ContactNumber2,
						item.LastActivity,
						item.Description,
						item.Email,
						null,
						item.EnableDuringDnd,
						item.Subscribe
					);
					AddBuddy(buddy, false, false, false);
				} else if (item.Type == "group") {
					// group
					var buddy = new Buddy(
						"group",
						item.gID,
						item.DisplayName,
						item.ExtensionNumber,
						"",
						"",
						"",
						item.LastActivity,
						item.MemberCount + " member(s)",
						item.Email,
						null,
						item.EnableDuringDnd,
						item.Subscribe
					);
					AddBuddy(buddy, false, false, false);
				}
			});

			// Update List (after add)
			console.log("Updating Buddy List...");
			UpdateBuddyList();
		}
		function UpdateBuddyList() {
			var filter = $("#txtFindBuddy").val();
			$("#myContacts").empty();
			// Show Lines
			var callCount = 0;
			for (var l = 0; l < Lines.length; l++) {
				//ACA VAMOS A HACER LO DE LAS LINEAS MAN
				var classStr = Lines[l].IsSelected ? "buddySelected" : "buddy";
				if (Lines[l].SipSession != null)
					classStr = Lines[l].SipSession.isOnHold
						? "buddyActiveCallHollding"
						: "buddyActiveCall";

				var html =
					'<div id="line-' +
					Lines[l].LineNumber +
					'" class=' +
					classStr +
					" onclick=\"SelectLine('" +
					Lines[l].LineNumber +
					"')\">";
				if (
					Lines[l].IsSelected == false &&
					Lines[l].SipSession &&
					Lines[l].SipSession.data.started != true &&
					Lines[l].SipSession.data.calldirection == "inbound"
				) {
					html +=
						'<span id="line-' +
						Lines[l].LineNumber +
						'-ringing" class=missedNotifyer style="padding-left: 5px; padding-right: 5px; width:unset"><i class="fa fa-phone"></i> ' +
						lang.state_ringing +
						"</span>";
				}
				html += "<div class=lineIcon>" + (l + 1) + "</div>";
				html +=
					'<div class=contactNameText><i class="fa fa-phone"></i> ' +
					lang.line +
					" " +
					(l + 1) +
					"</div>";
				html +=
					'<div id="line-' +
					Lines[l].LineNumber +
					'-datetime" class=contactDate>&nbsp;</div>';
				html +=
					"<div class=presenceText>" +
					Lines[l].DisplayName +
					" <" +
					Lines[l].DisplayNumber +
					">" +
					"</div>";
				html += "</div>";
				// SIP.Session.C.STATUS_TERMINATED
				if (
					Lines[l].SipSession &&
					Lines[l].SipSession.data.earlyReject != true
				) {
					$("#myContacts").append(html);
					callCount++;
				}
			}

			// End here if they are not using the buddy system
			if (DisableBuddies == true) {
				// If there are no calls, this could look fi=unny
				if (callCount == 0) {
					ShowDial();
				}
				return;
			}

			// Draw a line if there are calls
			if (callCount > 0) {
				$("#myContacts").append("<hr class=hrline>");
			}

			// Sort and shuffle Buddy List
			// ===========================
			Buddies.sort(function (a, b) {
				var aMo = moment.utc(a.lastActivity.replace(" UTC", ""));
				var bMo = moment.utc(b.lastActivity.replace(" UTC", ""));
				if (aMo.isSameOrAfter(bMo, "second")) {
					return -1;
				} else return 1;
			});

			for (var b = 0; b < Buddies.length; b++) {
				var buddyObj = Buddies[b];

				if (filter && filter.length >= 1) {
					// Perform Filter Display
					var display = false;
					if (
						buddyObj.CallerIDName.toLowerCase().indexOf(filter.toLowerCase()) >
						-1
					)
						display = true;
					if (buddyObj.ExtNo.toLowerCase().indexOf(filter.toLowerCase()) > -1)
						display = true;
					if (buddyObj.Desc.toLowerCase().indexOf(filter.toLowerCase()) > -1)
						display = true;
					if (!display) continue;
				}

				var today = moment.utc();
				var lastActivity = moment.utc(
					buddyObj.lastActivity.replace(" UTC", "")
				);
				var displayDateTime = "";
				if (lastActivity.isSame(today, "day")) {
					displayDateTime = lastActivity.local().format(DisplayTimeFormat);
				} else {
					displayDateTime = lastActivity.local().format(DisplayDateFormat);
				}

				var classStr = buddyObj.IsSelected ? "buddySelected" : "buddy";
				if (buddyObj.type == "extension") {
					var friendlyState = buddyObj.presence;
					if (friendlyState == "Unknown") friendlyState = lang.state_unknown;
					if (friendlyState == "Not online")
						friendlyState = lang.state_not_online;
					if (friendlyState == "Ready") friendlyState = lang.state_ready;
					if (friendlyState == "On the phone")
						friendlyState = lang.state_on_the_phone;
					if (friendlyState == "Ringing") friendlyState = lang.state_ringing;
					if (friendlyState == "On hold") friendlyState = lang.state_on_hold;
					if (friendlyState == "Unavailable")
						friendlyState = lang.state_unavailable;
					if (buddyObj.EnableSubscribe != true) friendlyState = buddyObj.Desc;
				} else if (buddyObj.type == "xmpp") {
					var friendlyState = buddyObj.presenceText;
				}
				// else if (buddyObj.type == "contact") {
				// } else if (buddyObj.type == "group") {
				// }
			}

			// Make Select
			// ===========
			for (var b = 0; b < Buddies.length; b++) {
				if (Buddies[b].IsSelected) {
					SelectBuddy(Buddies[b].identity, Buddies[b].type);
					break;
				}
			}
		}
		function AddBuddyMessageStream(buddyObj) {
			// Profile Etc Row
			// ----------------------------------------------------------

			// Right Content - Action Buttons
			var buttonsWidth = 80; // 1 button = 34px ~40px
			if (
				(buddyObj.type == "extension" || buddyObj.type == "xmpp") &&
				EnableVideoCalling
			) {
				buttonsWidth = 120;
			}
			var fullButtonsWidth = 200;
			if (
				(buddyObj.type == "extension" || buddyObj.type == "xmpp") &&
				EnableVideoCalling
			) {
				fullButtonsWidth = 240;
			}
			// Interaction row
			// ----------------------------------------------------------
			// if (
			// 	(buddyObj.type == "extension" ||
			// 		buddyObj.type == "xmpp" ||
			// 		buddyObj.type == "group") &&
			// 	EnableTextMessaging
			// ) {
			// }

			if (UiMessageLayout == "top") {
				$("#contact-" + buddyObj.identity + "-MessagesCell").addClass("");
				$("#contact-" + buddyObj.identity + "-ProfileCell").addClass(
					"sectionBorderTop"
				);
				$("#contact-" + buddyObj.identity + "-InteractionCell").addClass("");
			} else {
				$("#contact-" + buddyObj.identity + "-ProfileCell").addClass(
					"sectionBorderBottom"
				);
				$("#contact-" + buddyObj.identity + "-MessagesCell").addClass("");
				$("#contact-" + buddyObj.identity + "-InteractionCell").addClass(
					"sectionBorderTop"
				);
			}
		}
		function RemoveBuddyMessageStream(buddyObj, days) {
			// use days to specify how many days back must the records be cleared
			// eg: 30, will only remove records older than 30 day from now
			// and leave the buddy in place.
			// Must be greater then 0 or the entire buddy will be removed.
			if (buddyObj == null) return;

			// Grab a copy of the stream
			var stream = JSON.parse(localDB.getItem(buddyObj.identity + "-stream"));
			if (days && days > 0) {
				if (
					stream &&
					stream.DataCollection &&
					stream.DataCollection.length >= 1
				) {
					// Create Trim Stream
					var trimmedStream = {
						TotalRows: 0,
						DataCollection: [],
					};
					trimmedStream.DataCollection = stream.DataCollection.filter(function (
						item
					) {
						// Apply Date Filter
						var itemDate = moment.utc(item.ItemDate.replace(" UTC", ""));
						var expiredDate = moment().utc().subtract(days, "days");
						// Condition
						if (itemDate.isSameOrAfter(expiredDate, "second")) {
							return true; // return true to include;
						} else {
							return false; // return false to exclude;
						}
					});
					trimmedStream.TotalRows = trimmedStream.DataCollection.length;
					localDB.setItem(
						buddyObj.identity + "-stream",
						JSON.stringify(trimmedStream)
					);

					// Create Delete Stream
					var deleteStream = {
						TotalRows: 0,
						DataCollection: [],
					};
					deleteStream.DataCollection = stream.DataCollection.filter(function (
						item
					) {
						// Apply Date Filter
						var itemDate = moment.utc(item.ItemDate.replace(" UTC", ""));
						var expiredDate = moment().utc().subtract(days, "days");
						// Condition
						if (itemDate.isSameOrAfter(expiredDate, "second")) {
							return false; // return false to exclude;
						} else {
							return true; // return true to include;
						}
					});
					deleteStream.TotalRows = deleteStream.DataCollection.length;

					// Re-assign stream so that the normal delete action can apply
					stream = deleteStream;

					RefreshStream(buddyObj);
				}
			} else {
				CloseBuddy(buddyObj.identity);

				// Remove From UI
				$("#stream-" + buddyObj.identity).remove();

				// Remove Stream (CDRs & Messages etc)
				localDB.removeItem(buddyObj.identity + "-stream");

				// Remove Buddy
				var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
				var x = 0;
				$.each(json.DataCollection, function (i, item) {
					if (
						item.uID == buddyObj.identity ||
						item.cID == buddyObj.identity ||
						item.gID == buddyObj.identity
					) {
						x = i;
						return false;
					}
				});
				json.DataCollection.splice(x, 1);
				json.TotalRows = json.DataCollection.length;
				localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));

				// Remove Images
				localDB.removeItem("img-" + buddyObj.identity + "-extension");
				localDB.removeItem("img-" + buddyObj.identity + "-contact");
				localDB.removeItem("img-" + buddyObj.identity + "-group");
			}
			UpdateBuddyList();

			// Remove Call Recordings
			if (
				stream &&
				stream.DataCollection &&
				stream.DataCollection.length >= 1
			) {
				DeleteCallRecordings(buddyObj.identity, stream);
			}

			// Remove QOS Data
			if (
				stream &&
				stream.DataCollection &&
				stream.DataCollection.length >= 1
			) {
				DeleteQosData(buddyObj.identity, stream);
			}
		}
		function DeleteCallRecordings(buddy, stream) {
			var indexedDB = window.indexedDB;
			var request = indexedDB.open("CallRecordings", 1);
			request.onerror = function (event) {
				console.error("IndexDB Request Error:", event);
			};
			request.onupgradeneeded = function (event) {
				console.warn(
					"Upgrade Required for IndexDB... probably because of first time use."
				);
				// If this is the case, there will be no call recordings
			};
			request.onsuccess = function (event) {
				console.log("IndexDB connected to CallRecordings");

				var IDB = event.target.result;
				if (IDB.objectStoreNames.contains("Recordings") == false) {
					console.warn("IndexDB CallRecordings.Recordings does not exists");
					return;
				}
				IDB.onerror = function (event) {
					console.error("IndexDB Error:", event);
				};

				// Loop and Delete
				// Note: This database can only delete based on Primary Key
				// The Primary Key is arbitary, but is saved in item.Recordings.uID
				$.each(stream.DataCollection, function (i, item) {
					if (
						item.ItemType == "CDR" &&
						item.Recordings &&
						item.Recordings.length
					) {
						$.each(item.Recordings, function (i, recording) {
							console.log("Deleting Call Recording: ", recording.uID);
							var objectStore = IDB.transaction(
								["Recordings"],
								"readwrite"
							).objectStore("Recordings");
							try {
								var deleteRequest = objectStore.delete(recording.uID);
								deleteRequest.onsuccess = function (event) {
									console.log("Call Recording Deleted: ", recording.uID);
								};
							} catch (e) {
								console.log("Call Recording Delete failed: ", e);
							}
						});
					}
				});
			};
		}
		function ToggleExtraButtons(lineNum, normal, expanded) {
			var extraButtons = $("#contact-" + lineNum + "-extra-buttons");
			if (extraButtons.is(":visible")) {
				// Restore
				extraButtons.hide();
				$("#contact-" + lineNum + "-action-buttons").css(
					"width",
					normal + "px"
				);
			} else {
				// Expand
				extraButtons.show();
				$("#contact-" + lineNum + "-action-buttons").css(
					"width",
					expanded + "px"
				);
			}
		}

		function MakeUpName() {
			var shortname = 4;
			var longName = 12;
			var letters = [
				"A",
				"B",
				"C",
				"D",
				"E",
				"F",
				"G",
				"H",
				"I",
				"J",
				"K",
				"L",
				"M",
				"N",
				"O",
				"P",
				"Q",
				"R",
				"S",
				"T",
				"U",
				"V",
				"W",
				"X",
				"Y",
				"Z",
			];
			var rtn = "";
			rtn += letters[Math.floor(Math.random() * letters.length)];
			for (
				var n = 0;
				n < Math.floor(Math.random() * longName) + shortname;
				n++
			) {
				rtn +=
					letters[Math.floor(Math.random() * letters.length)].toLowerCase();
			}
			rtn += " ";
			rtn += letters[Math.floor(Math.random() * letters.length)];
			for (
				var n = 0;
				n < Math.floor(Math.random() * longName) + shortname;
				n++
			) {
				rtn +=
					letters[Math.floor(Math.random() * letters.length)].toLowerCase();
			}
			return rtn;
		}
		function MakeUpNumber() {
			var numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
			var rtn = "0";
			for (var n = 0; n < 9; n++) {
				rtn += numbers[Math.floor(Math.random() * numbers.length)];
			}
			return rtn;
		}
		function MakeUpBuddies(int) {
			for (var i = 0; i < int; i++) {
				var buddyObj = new Buddy(
					"contact",
					uID(),
					MakeUpName(),
					"",
					"",
					MakeUpNumber(),
					"",
					utcDateNow(),
					"Testing",
					""
				);
				AddBuddy(buddyObj, false, false);
			}
			UpdateBuddyList();
		}

		function SelectBuddy(buddy) {
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) return;

			var presence = "";

			if (buddyObj.type == "extension") {
				presence += buddyObj.presence;
				if (presence == "Unknown") presence = lang.state_unknown;
				if (presence == "Not online") presence = lang.state_not_online;
				if (presence == "Ready") presence = lang.state_ready;
				if (presence == "On the phone") presence = lang.state_on_the_phone;
				if (presence == "Ringing") presence = lang.state_ringing;
				if (presence == "On hold") presence = lang.state_on_hold;
				if (presence == "Unavailable") presence = lang.state_unavailable;
				if (buddyObj.EnableSubscribe != true) presence = buddyObj.Desc;
			} else if (buddyObj.type == "xmpp") {
				presence += '<i class="fa fa-comments"></i> ';
				presence += buddyObj.presenceText;
			} else if (buddyObj.type == "contact") {
				presence += buddyObj.Desc;
			} else if (buddyObj.type == "group") {
				presence += buddyObj.Desc;
			}
			$("#contact-" + buddyObj.identity + "-presence-main").html(presence);

			$("#contact-" + buddyObj.identity + "-picture-main").css(
				"background-image",
				$("#contact-" + buddyObj.identity + "-picture-main").css(
					"background-image"
				)
			);

			for (var b = 0; b < Buddies.length; b++) {
				if (Buddies[b].IsSelected == true && Buddies[b].identity == buddy) {
					// Nothing to do, you re-selected the same buddy;
					return;
				}
			}

			console.log("Selecting Buddy: " + buddyObj.CallerIDName);
			selectedBuddy = buddyObj;
			// Can only display one thing on the Right
			$(".streamSelected").each(function () {
				$(this).prop("class", "stream");
			});
			$("#stream-" + buddy).prop("class", "streamSelected");

			// Update Lines List
			for (var l = 0; l < Lines.length; l++) {
				var classStr = "buddy";
				if (Lines[l].SipSession != null)
					classStr = Lines[l].SipSession.isOnHold
						? "buddyActiveCallHollding"
						: "buddyActiveCall";
				$("#line-" + Lines[l].LineNumber).prop("class", classStr);
				Lines[l].IsSelected = false;
			}

			ClearMissedBadge(buddy);
			// Update Buddy List
			for (var b = 0; b < Buddies.length; b++) {
				var classStr = Buddies[b].identity == buddy ? "buddySelected" : "buddy";
				$("#contact-" + Buddies[b].identity).prop("class", classStr);

				$("#contact-" + Buddies[b].identity + "-ChatHistory").empty();

				Buddies[b].IsSelected = Buddies[b].identity == buddy;
			}

			// Change to Stream if in Narrow view
			////this.UpdateUI();

			// Refresh Stream
			// console.log("Refreshing Stream for you(" + profileUserID + ") and : " + buddyObj.identity);
			RefreshStream(buddyObj);

			// try {
			// 	$("#contact-" + buddy)
			// 		.get(0)
			// 		.scrollIntoViewIfNeeded();
			// } catch (e) {
			// 	console.log(e);
			// }

			// Save Selected
			localDB.setItem("SelectedBuddy", buddy);
		}
		function CloseBuddy(buddy) {
			// Lines and Buddies (Left)
			$(".buddySelected").each(function () {
				$(this).prop("class", "buddy");
			});
			// Streams (Right)
			$(".streamSelected").each(function () {
				$(this).prop("class", "stream");
			});

			console.log("Closing Buddy: " + buddy);
			for (var b = 0; b < Buddies.length; b++) {
				Buddies[b].IsSelected = false;
			}
			selectedBuddy = null;
			for (var l = 0; l < Lines.length; l++) {
				Lines[l].IsSelected = false;
			}
			selectedLine = null;

			// Save Selected
			localDB.setItem("SelectedBuddy", null);

			// Change to Stream if in Narrow view
			////this.UpdateUI();
		}
		function RemoveBuddy(buddy) {
			// Check if you are on the phone etc
			Confirm(lang.confirm_remove_buddy, lang.remove_buddy, function () {
				for (var b = 0; b < Buddies.length; b++) {
					if (Buddies[b].identity == buddy) {
						RemoveBuddyMessageStream(Buddies[b]);
						UnsubscribeBuddy(Buddies[b]);
						if (Buddies[b].type == "xmpp")
							XmppRemoveBuddyFromRoster(Buddies[b]);
						Buddies.splice(b, 1);
						break;
					}
				}
				UpdateBuddyList();
			});
		}
		function FindBuddyByDid(did) {
			// Used only in Inboud
			for (var b = 0; b < Buddies.length; b++) {
				if (
					Buddies[b].ExtNo == did ||
					Buddies[b].MobileNumber == did ||
					Buddies[b].ContactNumber1 == did ||
					Buddies[b].ContactNumber2 == did
				) {
					return Buddies[b];
				}
			}
			return null;
		}
		function FindBuddyByExtNo(ExtNo) {
			for (var b = 0; b < Buddies.length; b++) {
				if (Buddies[b].ExtNo == ExtNo) return Buddies[b];
			}
			return null;
		}
		function FindBuddyByNumber(number) {
			// Number could be: +XXXXXXXXXX
			// Any special characters must be removed prior to adding
			for (var b = 0; b < Buddies.length; b++) {
				if (
					Buddies[b].MobileNumber == number ||
					Buddies[b].ContactNumber1 == number ||
					Buddies[b].ContactNumber2 == number
				) {
					return Buddies[b];
				}
			}
			return null;
		}
		function FindBuddyByIdentity(identity) {
			for (var b = 0; b < Buddies.length; b++) {
				if (Buddies[b].identity == identity) return Buddies[b];
			}
			return null;
		}
		function FindBuddyByJid(jid) {
			for (var b = 0; b < Buddies.length; b++) {
				if (Buddies[b].jid == jid) return Buddies[b];
			}
			console.warn("Buddy not found on jid: " + jid);
			return null;
		}
		function SearchStream(obj, buddy) {
			var q = obj.value;

			var buddyObj = FindBuddyByIdentity(buddy);
			if (q == "") {
				console.log("Restore Stream");
				RefreshStream(buddyObj);
			} else {
				RefreshStream(buddyObj, q);
			}
		}
		function RefreshStream(buddyObj, filter) {
			$("#contact-" + buddyObj.identity + "-ChatHistory").empty();

			var json = JSON.parse(localDB.getItem(buddyObj.identity + "-stream"));
			if (json == null || json.DataCollection == null) return;

			// Sort DataCollection (Newest items first)
			json.DataCollection.sort(function (a, b) {
				var aMo = moment.utc(a.ItemDate.replace(" UTC", ""));
				var bMo = moment.utc(b.ItemDate.replace(" UTC", ""));
				if (aMo.isSameOrAfter(bMo, "second")) {
					return -1;
				} else return 1;
			});

			// Filter
			if (filter && filter != "") {
				// TODO: Maybe some room for improvement here
				console.log(
					"Rows without filter (" + filter + "): ",
					json.DataCollection.length
				);
				json.DataCollection = json.DataCollection.filter(function (item) {
					if (filter.indexOf("date: ") != -1) {
						// Apply Date Filter
						var dateFilter = getFilter(filter, "date");
						if (dateFilter != "" && item.ItemDate.indexOf(dateFilter) != -1)
							return true;
					}
					if (item.MessageData && item.MessageData.length > 1) {
						if (
							item.MessageData.toLowerCase().indexOf(filter.toLowerCase()) != -1
						)
							return true;
						if (
							filter.toLowerCase().indexOf(item.MessageData.toLowerCase()) != -1
						)
							return true;
					}
					if (item.ItemType == "MSG") {
						// Special search??
					} else if (item.ItemType == "CDR") {
						// Tag Search
						if (item.Tags && item.Tags.length > 1) {
							var tagFilter = getFilter(filter, "tag");
							if (tagFilter != "") {
								if (
									item.Tags.some(function (i) {
										if (
											tagFilter.toLowerCase().indexOf(i.value.toLowerCase()) !=
											-1
										)
											return true;
										if (
											i.value.toLowerCase().indexOf(tagFilter.toLowerCase()) !=
											-1
										)
											return true;
										return false;
									}) == true
								)
									return true;
							}
						}
					} else if (item.ItemType == "FILE") {
						// Not yest implemented
					} else if (item.ItemType == "SMS") {
						// Not yest implemented
					}
					// return true to keep;
					return false;
				});
				console.log("Rows After Filter: ", json.DataCollection.length);
			}

			// Create Buffer
			if (json.DataCollection.length > StreamBuffer) {
				console.log(
					"Rows:",
					json.DataCollection.length,
					" (will be trimed to " + StreamBuffer + ")"
				);
				// Always limit the Stream to {StreamBuffer}, users much search for messages further back
				json.DataCollection.splice(StreamBuffer);
			}

			$.each(json.DataCollection, function (i, item) {
				var IsToday = moment
					.utc(item.ItemDate.replace(" UTC", ""))
					.isSame(moment.utc(), "day");
				var DateTime = moment
					.utc(item.ItemDate.replace(" UTC", ""))
					.local()
					.calendar(null, { sameElse: DisplayDateFormat });
				if (IsToday)
					DateTime = moment
						.utc(item.ItemDate.replace(" UTC", ""))
						.local()
						.format(DisplayTimeFormat);

				if (item.ItemType == "MSG") {
					// Add Chat Message
					// ===================

					//Billsec: "0"
					//Dst: "sip:800"
					//DstUserId: "8D68C1D442A96B4"
					//ItemDate: "2019-05-14 09:42:15"
					//ItemId: "89"
					//ItemType: "MSG"
					//MessageData: "........."
					//Src: ""Keyla James" <100>"
					//SrcUserId: "8D68B3EFEC8D0F5"

					var deliveryStatus =
						'<i class="fa fa-question-circle-o SendingMessage"></i>';
					if (item.Sent == true)
						deliveryStatus = '<i class="fa fa-check SentMessage"></i>';
					if (item.Sent == false)
						deliveryStatus =
							'<i class="fa fa-exclamation-circle FailedMessage"></i>';
					if (item.Delivered && item.Delivered.state == true) {
						deliveryStatus += ' <i class="fa fa-check DeliveredMessage"></i>';
					}
					if (item.Displayed && item.Displayed.state == true) {
						deliveryStatus = '<i class="fa fa-check CompletedMessage"></i>';
					}

					var formattedMessage = ReformatMessage(item.MessageData);
					var longMessage = formattedMessage.length > 1000;

					if (item.SrcUserId == profileUserID) {
						// You are the source (sending)
						var messageString =
							"<table class=ourChatMessage cellspacing=0 cellpadding=0><tr>";
						messageString +=
							'<td class=ourChatMessageText onmouseenter="ShowChatMenu(this)" onmouseleave="HideChatMenu(this)">';
						messageString +=
							"<span onclick=\"ShowMessgeMenu(this,'MSG','" +
							item.ItemId +
							"', '" +
							buddyObj.identity +
							'\')" class=chatMessageDropdown style="display:none"><i class="fa fa-chevron-down"></i></span>';
						messageString +=
							"<div id=msg-text-" +
							item.ItemId +
							' class=messageText style="' +
							(longMessage ? "max-height:190px; overflow:hidden" : "") +
							'">' +
							formattedMessage +
							"</div>";
						if (longMessage) {
							messageString +=
								"<div id=msg-readmore-" +
								item.ItemId +
								" class=messageReadMore><span onclick=\"ExpandMessage(this,'" +
								item.ItemId +
								"', '" +
								buddyObj.identity +
								"')\">" +
								lang.read_more +
								"</span></div>";
						}
						messageString +=
							"<div class=messageDate>" +
							DateTime +
							" " +
							deliveryStatus +
							"</div>";
						messageString += "</td>";
						messageString += "</tr></table>";
					} else {
						// You are the destination (receiving)
						var ActualSender = ""; //TODO
						var messageString =
							"<table class=theirChatMessage cellspacing=0 cellpadding=0><tr>";
						messageString +=
							'<td class=theirChatMessageText onmouseenter="ShowChatMenu(this)" onmouseleave="HideChatMenu(this)">';
						messageString +=
							"<span onclick=\"ShowMessgeMenu(this,'MSG','" +
							item.ItemId +
							"', '" +
							buddyObj.identity +
							'\')" class=chatMessageDropdown style="display:none"><i class="fa fa-chevron-down"></i></span>';
						if (buddyObj.type == "group") {
							messageString +=
								"<div class=messageDate>" + ActualSender + "</div>";
						}
						messageString +=
							"<div id=msg-text-" +
							item.ItemId +
							' class=messageText style="' +
							(longMessage ? "max-height:190px; overflow:hidden" : "") +
							'">' +
							formattedMessage +
							"</div>";
						if (longMessage) {
							messageString +=
								"<div id=msg-readmore-" +
								item.ItemId +
								" class=messageReadMore><span onclick=\"ExpandMessage(this,'" +
								item.ItemId +
								"', '" +
								buddyObj.identity +
								"')\">" +
								lang.read_more +
								"</span></div>";
						}
						messageString += "<div class=messageDate>" + DateTime + "</div>";
						messageString += "</td>";
						messageString += "</tr></table>";

						// Update any received messages
						if (buddyObj.type == "xmpp") {
							var streamVisible = $("#stream-" + buddyObj.identity).is(
								":visible"
							);
							if (streamVisible && !item.Read) {
								console.log(
									"Buddy stream is now visible, marking XMPP message(" +
										item.ItemId +
										") as read"
								);
								MarkMessageRead(buddyObj, item.ItemId);
								XmppSendDisplayReceipt(buddyObj, item.ItemId);
							}
						}
					}
					$("#contact-" + buddyObj.identity + "-ChatHistory").prepend(
						messageString
					);
				} else if (item.ItemType == "CDR") {
					// Add CDR
					// =======

					// CdrId = uID(),
					// ItemType: "CDR",
					// ItemDate: "...",
					// SrcUserId: srcId,
					// Src: srcCallerID,
					// DstUserId: dstId,
					// Dst: dstCallerID,
					// Billsec: duration.asSeconds(),
					// MessageData: ""
					// ReasonText:
					// ReasonCode:
					// Flagged
					// Tags: [""", "", "", ""]
					// Transfers: [{}],
					// Mutes: [{}],
					// Holds: [{}],
					// Recordings: [{ uID, startTime, mediaType, stopTime: utcDateNow, size}],
					// QOS: [{}]

					var iconColor = item.Billsec > 0 ? "green" : "red";
					var formattedMessage = "";

					// Flagged
					var flag =
						"<span id=cdr-flagged-" +
						item.CdrId +
						' style="' +
						(item.Flagged ? "" : "display:none") +
						'">';
					flag += '<i class="fa fa-flag FlagCall"></i> ';
					flag += "</span>";

					// Comment
					var callComment = "";
					if (item.MessageData) callComment = item.MessageData;

					// Tags
					if (!item.Tags) item.Tags = [];
					var CallTags =
						"<ul id=cdr-tags-" +
						item.CdrId +
						' class=tags style="' +
						(item.Tags && item.Tags.length > 0 ? "" : "display:none") +
						'">';
					$.each(item.Tags, function (i, tag) {
						CallTags +=
							"<li onclick=\"TagClick(this, '" +
							item.CdrId +
							"', '" +
							buddyObj.identity +
							"')\">" +
							tag.value +
							"</li>";
					});
					CallTags +=
						"<li class=tagText><input maxlength=24 type=text onkeypress=\"TagKeyPress(event, this, '" +
						item.CdrId +
						"', '" +
						buddyObj.identity +
						'\')" onfocus="TagFocus(this)"></li>';
					CallTags += "</ul>";

					// Call Type
					var callIcon = item.WithVideo ? "fa-video-camera" : "fa-phone";
					formattedMessage +=
						'<i class="fa ' +
						callIcon +
						'" style="color:' +
						iconColor +
						'"></i>';
					var audioVideo = item.WithVideo
						? lang.a_video_call
						: lang.an_audio_call;

					// Recordings
					var recordingsHtml = "";
					if (item.Recordings && item.Recordings.length >= 1) {
						$.each(item.Recordings, function (i, recording) {
							if (recording.uID) {
								var StartTime = moment
									.utc(recording.startTime.replace(" UTC", ""))
									.local();
								var StopTime = moment
									.utc(recording.stopTime.replace(" UTC", ""))
									.local();
								var recordingDuration = moment.duration(
									StopTime.diff(StartTime)
								);
								recordingsHtml += "<div class=callRecording>";
								if (item.WithVideo) {
									if (recording.Poster) {
										var posterWidth = recording.Poster.width;
										var posterHeight = recording.Poster.height;
										var posterImage = recording.Poster.posterBase64;
										recordingsHtml +=
											'<div><IMG src="' +
											posterImage +
											'"><button onclick="PlayVideoCallRecording(this, \'' +
											item.CdrId +
											"', '" +
											recording.uID +
											'\')" class=videoPoster><i class="fa fa-phone-alt"></i></button></div>';
									} else {
										recordingsHtml +=
											"<div><button class=roundButtons onclick=\"PlayVideoCallRecording(this, '" +
											item.CdrId +
											"', '" +
											recording.uID +
											"', '" +
											buddyObj.identity +
											'\')"><i class="fa fa-video-camera"></i></button></div>';
									}
								} else {
									recordingsHtml +=
										"<div><button class=roundButtons onclick=\"PlayAudioCallRecording(this, '" +
										item.CdrId +
										"', '" +
										recording.uID +
										"', '" +
										buddyObj.identity +
										'\')"><i class="fa fa-phone-alt"></i></button></div>';
								}
								recordingsHtml +=
									"<div>" +
									lang.started +
									": " +
									StartTime.format(DisplayTimeFormat) +
									' <i class="fa fa-long-arrow-right"></i> ' +
									lang.stopped +
									": " +
									StopTime.format(DisplayTimeFormat) +
									"</div>";
								recordingsHtml +=
									"<div>" +
									lang.recording_duration +
									": " +
									formatShortDuration(recordingDuration.asSeconds()) +
									"</div>";
								recordingsHtml += "<div>";
								recordingsHtml +=
									'<span id="cdr-video-meta-width-' +
									item.CdrId +
									"-" +
									recording.uID +
									'"></span>';
								recordingsHtml +=
									'<span id="cdr-video-meta-height-' +
									item.CdrId +
									"-" +
									recording.uID +
									'"></span>';
								recordingsHtml +=
									'<span id="cdr-media-meta-size-' +
									item.CdrId +
									"-" +
									recording.uID +
									'"></span>';
								recordingsHtml +=
									'<span id="cdr-media-meta-codec-' +
									item.CdrId +
									"-" +
									recording.uID +
									'"></span>';
								recordingsHtml += "</div>";
								recordingsHtml += "</div>";
							}
						});
					}

					if (item.SrcUserId == profileUserID) {
						// (Outbound) You(profileUserID) initiated a call
						if (item.Billsec == "0") {
							formattedMessage +=
								" " +
								lang.you_tried_to_make +
								" " +
								audioVideo +
								" (" +
								item.ReasonText +
								").";
						} else {
							formattedMessage +=
								" " +
								lang.you_made +
								" " +
								audioVideo +
								", " +
								lang.and_spoke_for +
								" " +
								formatDuration(item.Billsec) +
								".";
						}
						var messageString =
							"<table class=ourChatMessage cellspacing=0 cellpadding=0><tr>";
						messageString += '<td style="padding-right:4px;">' + flag + "</td>";
						messageString +=
							'<td class=ourChatMessageText onmouseenter="ShowChatMenu(this)" onmouseleave="HideChatMenu(this)">';
						messageString +=
							"<span onClick=\"ShowMessgeMenu(this,'CDR','" +
							item.CdrId +
							"', '" +
							buddyObj.identity +
							'\')" class=chatMessageDropdown style="display:none"><i class="fa fa-chevron-down"></i></span>';
						messageString += "<div>" + formattedMessage + "</div>";
						messageString += "<div>" + CallTags + "</div>";
						messageString +=
							"<div id=cdr-comment-" +
							item.CdrId +
							" class=cdrComment>" +
							callComment +
							"</div>";
						messageString +=
							"<div class=callRecordings>" + recordingsHtml + "</div>";
						messageString += "<div class=messageDate>" + DateTime + "</div>";
						messageString += "</td>";
						messageString += "</tr></table>";
					} else {
						// (Inbound) you(profileUserID) received a call
						if (item.Billsec == "0") {
							formattedMessage +=
								" " + lang.you_missed_a_call + " (" + item.ReasonText + ").";
						} else {
							formattedMessage +=
								" " +
								lang.you_recieved +
								" " +
								audioVideo +
								", " +
								lang.and_spoke_for +
								" " +
								formatDuration(item.Billsec) +
								".";
						}
						var messageString =
							"<table class=theirChatMessage cellspacing=0 cellpadding=0><tr>";
						messageString +=
							'<td class=theirChatMessageText onmouseenter="ShowChatMenu(this)" onmouseleave="HideChatMenu(this)">';
						messageString +=
							"<span onClick=\"ShowMessgeMenu(this,'CDR','" +
							item.CdrId +
							"', '" +
							buddyObj.identity +
							'\')" class=chatMessageDropdown style="display:none"><i class="fa fa-chevron-down"></i></span>';
						messageString +=
							'<div style="text-align:left">' + formattedMessage + "</div>";
						messageString += "<div>" + CallTags + "</div>";
						messageString +=
							"<div id=cdr-comment-" +
							item.CdrId +
							" class=cdrComment>" +
							callComment +
							"</div>";
						messageString +=
							"<div class=callRecordings>" + recordingsHtml + "</div>";
						messageString += "<div class=messageDate> " + DateTime + "</div>";
						messageString += "</td>";
						messageString += '<td style="padding-left:4px">' + flag + "</td>";
						messageString += "</tr></table>";
					}
					// Messges are repended here, and appended when logging
					$("#contact-" + buddyObj.identity + "-ChatHistory").prepend(
						messageString
					);
				} else if (item.ItemType == "FILE") {
					// TODO
				} else if (item.ItemType == "SMS") {
					// TODO
				}
			});

			// For some reason, the first time this fires, it doesnt always work
			updateScroll(buddyObj.identity);
			window.setTimeout(function () {
				updateScroll(buddyObj.identity);
			}, 300);
		}
		function ShowChatMenu(obj) {
			$(obj).children("span").show();
		}
		function HideChatMenu(obj) {
			$(obj).children("span").hide();
		}
		function ExpandMessage(obj, ItemId, buddy) {
			$("#msg-text-" + ItemId).css("max-height", "");
			$("#msg-text-" + ItemId).css("overflow", "");
			$("#msg-readmore-" + ItemId).remove();

			HidePopup(500);
		}

		// Video Conference Stage
		// ======================
		function RedrawStage(lineNum, videoChanged) {
			var stage = $("#line-" + lineNum + "-VideoCall");
			var container = $("#line-" + lineNum + "-stage-container");
			var previewContainer = $("#line-" + lineNum + "-preview-container");
			var videoContainer = $("#line-" + lineNum + "-remote-videos");

			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null) return;
			var session = lineObj.SipSession;
			if (session == null) return;

			var isVideoPinned = false;
			var pinnedVideoID = "";

			// Preview Area
			previewContainer.find("video").each(function (i, video) {
				$(video).hide();
			});
			previewContainer.css("width", "");

			// Count and Tag Videos
			var videoCount = 0;
			videoContainer.find("video").each(function (i, video) {
				var thisRemoteVideoStream = video.srcObject;
				var videoTrack = thisRemoteVideoStream.getVideoTracks()[0];
				var videoTrackSettings = videoTrack.getSettings();
				var srcVideoWidth = videoTrackSettings.width
					? videoTrackSettings.width
					: video.videoWidth;
				var srcVideoHeight = videoTrackSettings.height
					? videoTrackSettings.height
					: video.videoHeight;

				if (thisRemoteVideoStream.mid) {
					thisRemoteVideoStream.channel = "unknown"; // Asterisk Channel
					thisRemoteVideoStream.CallerIdName = "";
					thisRemoteVideoStream.CallerIdNumber = "";
					thisRemoteVideoStream.isAdminMuted = false;
					thisRemoteVideoStream.isAdministrator = false;
					if (session && session.data && session.data.videoChannelNames) {
						session.data.videoChannelNames.forEach(function (videoChannelName) {
							if (thisRemoteVideoStream.mid == videoChannelName.mid) {
								thisRemoteVideoStream.channel = videoChannelName.channel;
							}
						});
					}
					if (session && session.data && session.data.ConfbridgeChannels) {
						session.data.ConfbridgeChannels.forEach(function (
							ConfbridgeChannel
						) {
							if (ConfbridgeChannel.id == thisRemoteVideoStream.channel) {
								thisRemoteVideoStream.CallerIdName =
									ConfbridgeChannel.caller.name;
								thisRemoteVideoStream.CallerIdNumber =
									ConfbridgeChannel.caller.number;
								thisRemoteVideoStream.isAdminMuted = ConfbridgeChannel.muted;
								thisRemoteVideoStream.isAdministrator = ConfbridgeChannel.admin;
							}
						});
					}
					// console.log("Track MID :", thisRemoteVideoStream.mid, thisRemoteVideoStream.channel);
				}

				// Remove any in the preview area
				if (videoChanged) {
					$("#line-" + lineNum + "-preview-container")
						.find("video")
						.each(function (i, video) {
							if (video.id.indexOf("copy-") == 0) {
								video.remove();
							}
						});
				}

				// Prep Videos
				$(video).parent().off("click");
				$(video).parent().css("width", "1px");
				$(video).parent().css("height", "1px");
				$(video).hide();
				$(video).parent().hide();

				// Count Videos
				if (
					lineObj.pinnedVideo &&
					lineObj.pinnedVideo == thisRemoteVideoStream.trackID &&
					videoTrack.readyState == "live" &&
					srcVideoWidth > 10 &&
					srcVideoHeight >= 10
				) {
					// A valid and live video is pinned
					isVideoPinned = true;
					pinnedVideoID = lineObj.pinnedVideo;
				}
				// Count All the videos
				if (
					videoTrack.readyState == "live" &&
					srcVideoWidth > 10 &&
					srcVideoHeight >= 10
				) {
					videoCount++;
					console.log(
						"Display Video - ",
						videoTrack.readyState,
						"MID:",
						thisRemoteVideoStream.mid,
						"channel:",
						thisRemoteVideoStream.channel,
						"src width:",
						srcVideoWidth,
						"src height",
						srcVideoHeight
					);
				} else {
					console.log(
						"Hide Video - ",
						videoTrack.readyState,
						"MID:",
						thisRemoteVideoStream.mid
					);
				}
			});
			if (videoCount == 0) {
				// If you are the only one in the conference, just display your self
				previewContainer.css("width", previewWidth + "px");
				previewContainer.find("video").each(function (i, video) {
					$(video).show();
				});
				return;
			}
			if (isVideoPinned) videoCount = 1;

			if (!videoContainer.outerWidth() > 0) return;
			if (!videoContainer.outerHeight() > 0) return;

			// videoAspectRatio (1|1.33|1.77) is for the peer video, so can tencianlly be used here
			// default ia 4:3
			var Margin = 3;
			var videoRatio = 0.75; // 0.5625 = 9/16 (16:9) | 0.75   = 3/4 (4:3)
			if (videoAspectRatio == "" || videoAspectRatio == "1.33")
				videoRatio = 0.75;
			if (videoAspectRatio == "1.77") videoRatio = 0.5625;
			if (videoAspectRatio == "1") videoRatio = 1;
			var stageWidth = videoContainer.outerWidth() - Margin * 2;
			var stageHeight = videoContainer.outerHeight() - Margin * 2;
			var previewWidth = previewContainer.outerWidth();
			var maxWidth = 0;
			let i = 1;
			while (i < 5000) {
				let w = StageArea(
					i,
					videoCount,
					stageWidth,
					stageHeight,
					Margin,
					videoRatio
				);
				if (w === false) {
					maxWidth = i - 1;
					break;
				}
				i++;
			}
			maxWidth = maxWidth - Margin * 2;

			// Layout Videos
			videoContainer.find("video").each(function (i, video) {
				var thisRemoteVideoStream = video.srcObject;
				var videoTrack = thisRemoteVideoStream.getVideoTracks()[0];
				var videoTrackSettings = videoTrack.getSettings();
				var srcVideoWidth = videoTrackSettings.width
					? videoTrackSettings.width
					: video.videoWidth;
				var srcVideoHeight = videoTrackSettings.height
					? videoTrackSettings.height
					: video.videoHeight;

				var videoWidth = maxWidth;
				var videoHeight = maxWidth * videoRatio;

				// Set & Show
				if (isVideoPinned) {
					// One of the videos are pinned
					if (pinnedVideoID == video.srcObject.trackID) {
						$(video)
							.parent()
							.css("width", videoWidth + "px");
						$(video)
							.parent()
							.css("height", videoHeight + "px");
						$(video).show();
						$(video).parent().show();
						// Pinned Actions
						var unPinButton = $("<button />", {
							class: "videoOverlayButtons",
						});
						unPinButton.html('<i class="fa fa-th-large"></i>');
						unPinButton.on("click", function () {
							UnPinVideo(lineNum, video);
						});
						$(video).parent().find(".Actions").empty();
						$(video).parent().find(".Actions").append(unPinButton);
					} else {
						// Put the videos in the preview area
						if (
							videoTrack.readyState == "live" &&
							srcVideoWidth > 10 &&
							srcVideoHeight >= 10
						) {
							if (videoChanged) {
								var videoEl = $("<video />", {
									id: "copy-" + thisRemoteVideoStream.id,
									muted: true,
									autoplay: true,
									playsinline: true,
									controls: false,
								});
								var videoObj = videoEl.get(0);
								videoObj.srcObject = thisRemoteVideoStream;
								$("#line-" + lineNum + "-preview-container").append(videoEl);
							}
						}
					}
				} else {
					// None of the videos are pinned
					if (
						videoTrack.readyState == "live" &&
						srcVideoWidth > 10 &&
						srcVideoHeight >= 10
					) {
						// Unpinned
						$(video)
							.parent()
							.css("width", videoWidth + "px");
						$(video)
							.parent()
							.css("height", videoHeight + "px");
						$(video).show();
						$(video).parent().show();
						// Unpinned Actions
						var pinButton = $("<button />", {
							class: "videoOverlayButtons",
						});
						pinButton.html('<i class="fa fa-thumb-tack"></i>');
						pinButton.on("click", function () {
							PinVideo(lineNum, video, video.srcObject.trackID);
						});
						$(video).parent().find(".Actions").empty();
						if (videoCount > 1) {
							// More then one video, nothing pinned
							$(video).parent().find(".Actions").append(pinButton);
						}
					}
				}

				// Polulate Caller ID
				var adminMuteIndicator = "";
				var administratorIndicator = "";
				if (thisRemoteVideoStream.isAdminMuted == true) {
					adminMuteIndicator =
						'<i class="fa fa-microphone-slash" style="color:red"></i>&nbsp;';
				}
				if (thisRemoteVideoStream.isAdministrator == true) {
					administratorIndicator =
						'<i class="fa fa-user" style="color:orange"></i>&nbsp;';
				}
				if (thisRemoteVideoStream.CallerIdName == "") {
					thisRemoteVideoStream.CallerIdName = FindBuddyByIdentity(
						session.data.buddyId
					).CallerIDName;
				}
				$(video)
					.parent()
					.find(".callerID")
					.html(
						administratorIndicator +
							adminMuteIndicator +
							thisRemoteVideoStream.CallerIdName
					);
			});

			// Preview Area
			previewContainer.css("width", previewWidth + "px");
			previewContainer.find("video").each(function (i, video) {
				$(video).show();
			});
		}
		function StageArea(Increment, Count, Width, Height, Margin, videoRatio) {
			// Thnaks:  https://github.com/Alicunde/Videoconference-Dish-CSS-JS
			let i = (w = 0);
			let h = Increment * videoRatio + Margin * 2;
			while (i < Count) {
				if (w + Increment > Width) {
					w = 0;
					h = h + Increment * videoRatio + Margin * 2;
				}
				w = w + Increment + Margin * 2;
				i++;
			}
			if (h > Height) return false;
			else return Increment;
		}
		function PinVideo(lineNum, videoEl, trackID) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null) return;

			console.log("Setting Pinned Video:", trackID);
			lineObj.pinnedVideo = trackID;
			videoEl.srcObject.isPinned = true;
			RedrawStage(lineNum, true);
		}
		function UnPinVideo(lineNum, videoEl) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null) return;

			console.log("Removing Pinned Video");
			lineObj.pinnedVideo = "";
			videoEl.srcObject.isPinned = false;
			RedrawStage(lineNum, true);
		}

		// Stream Functionality
		// =====================
		function ShowMessgeMenu(obj, typeStr, cdrId, buddy) {
			var items = [];
			if (typeStr == "CDR") {
				var TagState = $("#cdr-flagged-" + cdrId).is(":visible");
				var TagText = TagState ? lang.clear_flag : lang.flag_call;

				items.push({
					value: 1,
					icon: "fa fa-external-link",
					text: lang.show_call_detail_record,
				});
				items.push({ value: 2, icon: "fa fa-tags", text: lang.tag_call });
				items.push({ value: 3, icon: "fa fa-flag", text: TagText });
				items.push({
					value: 4,
					icon: "fa fa-quote-left",
					text: lang.edit_comment,
				});
				// items.push({ value: 20, icon: null, text: "Delete CDR" });
				// items.push({ value: 21, icon: null, text: "Remove Poster Images" });
			} else if (typeStr == "MSG") {
				items.push({
					value: 10,
					icon: "fa fa-clipboard",
					text: lang.copy_message,
				});
				// items.push({ value: 11, icon: "fa fa-pencil", text: "Edit Message" });
				items.push({
					value: 12,
					icon: "fa fa-quote-left",
					text: lang.quote_message,
				});
			}

			var menu = {
				selectEvent: function (event, ui) {
					var id = ui.item.attr("value");
					HidePopup();

					if (id != null) {
						console.log("Menu click (" + id + ")");

						// CDR messages
						if (id == 1) {
							var cdr = null;
							var currentStream = JSON.parse(
								localDB.getItem(buddy + "-stream")
							);
							if (
								currentStream != null ||
								currentStream.DataCollection != null
							) {
								$.each(currentStream.DataCollection, function (i, item) {
									if (item.ItemType == "CDR" && item.CdrId == cdrId) {
										// Found
										cdr = item;
										return false;
									}
								});
							}
							if (cdr == null) return;

							var callDetails = [];
							var html = '<div class="UiWindowField">';

							// Billsec: 2.461
							// CallAnswer: "2020-06-22 09:47:52 UTC" | null
							// CallDirection: "outbound"
							// CallEnd: "2020-06-22 09:47:54 UTC"
							// CdrId: "15928192748351E9D"
							// ConfCalls: [{…}]
							// Dst: "*65"
							// DstUserId: "15919450411467CC"
							// Holds: [{…}]
							// ItemDate: "2020-06-22 09:47:50 UTC"
							// ItemType: "CDR"
							// MessageData: null
							// Mutes: [{…}]
							// QOS: [{…}]
							// ReasonCode: 16
							// ReasonText: "Normal Call clearing"
							// Recordings: [{…}]
							// RingTime: 2.374
							// SessionId: "67sv8o86msa7df23bulpnjrca7fton"
							// Src: "<100> Conrad de Wet"
							// SrcUserId: "17186D5983F"
							// Tags: [{…}]
							// Terminate: "us"
							// TotalDuration: 4.835
							// Transfers: [{…}]
							// WithVideo: false

							var CallDate = moment
								.utc(cdr.ItemDate.replace(" UTC", ""))
								.local()
								.format(DisplayDateFormat + " " + DisplayTimeFormat);
							var CallAnswer = cdr.CallAnswer
								? moment
										.utc(cdr.CallAnswer.replace(" UTC", ""))
										.local()
										.format(DisplayDateFormat + " " + DisplayTimeFormat)
								: null;
							var ringTime = cdr.RingTime ? cdr.RingTime : 0;
							var CallEnd = moment
								.utc(cdr.CallEnd.replace(" UTC", ""))
								.local()
								.format(DisplayDateFormat + " " + DisplayTimeFormat);

							var srcCallerID = "";
							var dstCallerID = "";
							if (cdr.CallDirection == "inbound") {
								srcCallerID = cdr.Src;
							} else if (cdr.CallDirection == "outbound") {
								dstCallerID = cdr.Dst;
							}
							html +=
								"<div class=UiText><b>SIP CallID</b> : " +
								cdr.SessionId +
								"</div>";
							html +=
								"<div class=UiText><b>" +
								lang.call_direction +
								"</b> : " +
								cdr.CallDirection +
								"</div>";
							html +=
								"<div class=UiText><b>" +
								lang.call_date_and_time +
								"</b> : " +
								CallDate +
								"</div>";
							html +=
								"<div class=UiText><b>" +
								lang.ring_time +
								"</b> : " +
								formatDuration(ringTime) +
								" (" +
								ringTime +
								")</div>";
							html +=
								"<div class=UiText><b>" +
								lang.talk_time +
								"</b> : " +
								formatDuration(cdr.Billsec) +
								" (" +
								cdr.Billsec +
								")</div>";
							html +=
								"<div class=UiText><b>" +
								lang.call_duration +
								"</b> : " +
								formatDuration(cdr.TotalDuration) +
								" (" +
								cdr.TotalDuration +
								")</div>";
							html +=
								"<div class=UiText><b>" +
								lang.video_call +
								"</b> : " +
								(cdr.WithVideo ? lang.yes : lang.no) +
								"</div>";
							html +=
								"<div class=UiText><b>" +
								lang.flagged +
								"</b> : " +
								(cdr.Flagged
									? '<i class="fa fa-flag FlagCall"></i> ' + lang.yes
									: lang.no) +
								"</div>";
							html += "<hr>";
							html += '<h2 style="font-size: 16px">' + lang.call_tags + "</h2>";
							html += "<hr>";
							$.each(cdr.Tags, function (item, tag) {
								html += "<span class=cdrTag>" + tag.value + "</span>";
							});

							html +=
								'<h2 style="font-size: 16px">' + lang.call_notes + "</h2>";
							html += "<hr>";
							if (cdr.MessageData) {
								html += '"' + cdr.MessageData + '"';
							}

							html +=
								'<h2 style="font-size: 16px">' +
								lang.activity_timeline +
								"</h2>";
							html += "<hr>";

							var withVideo = cdr.WithVideo ? "(" + lang.with_video + ")" : "";
							var startCallMessage =
								cdr.CallDirection == "inbound"
									? lang.you_received_a_call_from +
									  " " +
									  srcCallerID +
									  " " +
									  withVideo
									: lang.you_made_a_call_to +
									  " " +
									  dstCallerID +
									  " " +
									  withVideo;
							callDetails.push({
								Message: startCallMessage,
								TimeStr: cdr.ItemDate,
							});
							if (CallAnswer) {
								var answerCallMessage =
									cdr.CallDirection == "inbound"
										? lang.you_answered_after +
										  " " +
										  ringTime +
										  " " +
										  lang.seconds_plural
										: lang.they_answered_after +
										  " " +
										  ringTime +
										  " " +
										  lang.seconds_plural;
								callDetails.push({
									Message: answerCallMessage,
									TimeStr: cdr.CallAnswer,
								});
							}
							$.each(cdr.Transfers, function (item, transfer) {
								var msg =
									transfer.type == "Blind"
										? lang.you_started_a_blind_transfer_to +
										  " " +
										  transfer.to +
										  ". "
										: lang.you_started_an_attended_transfer_to +
										  " " +
										  transfer.to +
										  ". ";
								if (transfer.accept && transfer.accept.complete == true) {
									msg += lang.the_call_was_completed;
								} else if (transfer.accept.disposition != "") {
									msg +=
										lang.the_call_was_not_completed +
										" (" +
										transfer.accept.disposition +
										")";
								}
								callDetails.push({
									Message: msg,
									TimeStr: transfer.transferTime,
								});
							});
							$.each(cdr.Mutes, function (item, mute) {
								callDetails.push({
									Message:
										mute.event == "mute"
											? lang.you_put_the_call_on_mute
											: lang.you_took_the_call_off_mute,
									TimeStr: mute.eventTime,
								});
							});
							$.each(cdr.Holds, function (item, hold) {
								callDetails.push({
									Message:
										hold.event == "hold"
											? lang.you_put_the_call_on_hold
											: lang.you_took_the_call_off_hold,
									TimeStr: hold.eventTime,
								});
							});
							$.each(cdr.ConfbridgeEvents, function (item, event) {
								callDetails.push({
									Message: event.event,
									TimeStr: event.eventTime,
								});
							});
							$.each(cdr.ConfCalls, function (item, confCall) {
								var msg =
									lang.you_started_a_conference_call_to +
									" " +
									confCall.to +
									". ";
								if (confCall.accept && confCall.accept.complete == true) {
									msg += lang.the_call_was_completed;
								} else if (confCall.accept.disposition != "") {
									msg +=
										lang.the_call_was_not_completed +
										" (" +
										confCall.accept.disposition +
										")";
								}
								callDetails.push({
									Message: msg,
									TimeStr: confCall.startTime,
								});
							});
							$.each(cdr.Recordings, function (item, recording) {
								var StartTime = moment
									.utc(recording.startTime.replace(" UTC", ""))
									.local();
								var StopTime = moment
									.utc(recording.stopTime.replace(" UTC", ""))
									.local();
								var recordingDuration = moment.duration(
									StopTime.diff(StartTime)
								);

								var msg = lang.call_is_being_recorded;
								if (recording.startTime != recording.stopTime) {
									msg +=
										"(" +
										formatShortDuration(recordingDuration.asSeconds()) +
										")";
								}
								callDetails.push({
									Message: msg,
									TimeStr: recording.startTime,
								});
							});
							callDetails.push({
								Message:
									cdr.Terminate == "us"
										? lang.you_ended_the_call
										: lang.they_ended_the_call,
								TimeStr: cdr.CallEnd,
							});

							callDetails.sort(function (a, b) {
								var aMo = moment.utc(a.TimeStr.replace(" UTC", ""));
								var bMo = moment.utc(b.TimeStr.replace(" UTC", ""));
								if (aMo.isSameOrAfter(bMo, "second")) {
									return 1;
								} else return -1;
							});
							$.each(callDetails, function (item, detail) {
								var Time = moment
									.utc(detail.TimeStr.replace(" UTC", ""))
									.local()
									.format(DisplayTimeFormat);
								var messageString =
									"<table class=timelineMessage cellspacing=0 cellpadding=0><tr>";
								messageString += "<td class=timelineMessageArea>";
								messageString +=
									'<div class=timelineMessageDate style="color: #333333"><i class="fa fa-circle timelineMessageDot"></i>' +
									Time +
									"</div>";
								messageString +=
									'<div class=timelineMessageText style="color: #000000">' +
									detail.Message +
									"</div>";
								messageString += "</td>";
								messageString += "</tr></table>";
								html += messageString;
							});

							html +=
								'<h2 style="font-size: 16px">' + lang.call_recordings + "</h2>";
							html += "<hr>";
							var recordingsHtml = "";
							$.each(cdr.Recordings, function (r, recording) {
								if (recording.uID) {
									var StartTime = moment
										.utc(recording.startTime.replace(" UTC", ""))
										.local();
									var StopTime = moment
										.utc(recording.stopTime.replace(" UTC", ""))
										.local();
									var recordingDuration = moment.duration(
										StopTime.diff(StartTime)
									);
									recordingsHtml += "<div>";
									if (cdr.WithVideo) {
										recordingsHtml +=
											'<div><video id="callrecording-video-' +
											recording.uID +
											'" controls style="width: 100%"></div>';
									} else {
										recordingsHtml +=
											'<div><audio id="callrecording-audio-' +
											recording.uID +
											'" controls style="width: 100%"></div>';
									}
									recordingsHtml +=
										"<div>" +
										lang.started +
										": " +
										StartTime.format(DisplayTimeFormat) +
										' <i class="fa fa-long-arrow-right"></i> ' +
										lang.stopped +
										": " +
										StopTime.format(DisplayTimeFormat) +
										"</div>";
									recordingsHtml +=
										"<div>" +
										lang.recording_duration +
										": " +
										formatShortDuration(recordingDuration.asSeconds()) +
										"</div>";
									recordingsHtml +=
										'<div><a id="download-' +
										recording.uID +
										'">' +
										lang.save_as +
										"</a> (" +
										lang.right_click_and_select_save_link_as +
										")</div>";
									recordingsHtml += "</div>";
								}
							});
							html += recordingsHtml;
							if (cdr.CallAnswer) {
								html +=
									'<h2 style="font-size: 16px">' +
									lang.send_statistics +
									"</h2>";
								html += "<hr>";
								html +=
									'<div style="position: relative; margin: auto; height: 160px; width: 100%;"><canvas id="cdr-AudioSendBitRate"></canvas></div>';
								html +=
									'<div style="position: relative; margin: auto; height: 160px; width: 100%;"><canvas id="cdr-AudioSendPacketRate"></canvas></div>';

								html +=
									'<h2 style="font-size: 16px">' +
									lang.receive_statistics +
									"</h2>";
								html += "<hr>";
								html +=
									'<div style="position: relative; margin: auto; height: 160px; width: 100%;"><canvas id="cdr-AudioReceiveBitRate"></canvas></div>';
								html +=
									'<div style="position: relative; margin: auto; height: 160px; width: 100%;"><canvas id="cdr-AudioReceivePacketRate"></canvas></div>';
								html +=
									'<div style="position: relative; margin: auto; height: 160px; width: 100%;"><canvas id="cdr-AudioReceivePacketLoss"></canvas></div>';
								html +=
									'<div style="position: relative; margin: auto; height: 160px; width: 100%;"><canvas id="cdr-AudioReceiveJitter"></canvas></div>';
								html +=
									'<div style="position: relative; margin: auto; height: 160px; width: 100%;"><canvas id="cdr-AudioReceiveLevels"></canvas></div>';
							}

							html += "<br><br></div>";
							OpenWindow(
								html,
								lang.call_detail_record,
								480,
								640,
								false,
								true,
								null,
								null,
								lang.cancel,
								function () {
									CloseWindow();
								},
								function () {
									// Queue video and audio
									$.each(cdr.Recordings, function (r, recording) {
										var mediaObj = null;
										if (cdr.WithVideo) {
											mediaObj = $("#callrecording-video-" + recording.uID).get(
												0
											);
										} else {
											mediaObj = $("#callrecording-audio-" + recording.uID).get(
												0
											);
										}
										var downloadURL = $("#download-" + recording.uID);

										// Playback device
										var sinkId = getAudioOutputID();
										if (typeof mediaObj.sinkId !== "undefined") {
											mediaObj
												.setSinkId(sinkId)
												.then(function () {
													console.log("sinkId applied: " + sinkId);
												})
												.catch(function (e) {
													console.warn("Error using setSinkId: ", e);
												});
										} else {
											console.warn(
												"setSinkId() is not possible using this browser."
											);
										}

										// Get Call Recording
										var indexedDB = window.indexedDB;
										var request = indexedDB.open("CallRecordings", 1);
										request.onerror = function (event) {
											console.error("IndexDB Request Error:", event);
										};
										request.onupgradeneeded = function (event) {
											console.warn(
												"Upgrade Required for IndexDB... probably because of first time use."
											);
										};
										request.onsuccess = function (event) {
											console.log("IndexDB connected to CallRecordings");

											var IDB = event.target.result;
											if (
												IDB.objectStoreNames.contains("Recordings") == false
											) {
												console.warn(
													"IndexDB CallRecordings.Recordings does not exists"
												);
												return;
											}

											var transaction = IDB.transaction(["Recordings"]);
											var objectStoreGet = transaction
												.objectStore("Recordings")
												.get(recording.uID);
											objectStoreGet.onerror = function (event) {
												console.error("IndexDB Get Error:", event);
											};
											objectStoreGet.onsuccess = function (event) {
												var mediaBlobUrl = window.URL.createObjectURL(
													event.target.result.mediaBlob
												);
												mediaObj.src = mediaBlobUrl;

												// Download Link
												if (cdr.WithVideo) {
													downloadURL.prop(
														"download",
														"Video-Call-Recording-" + recording.uID + ".webm"
													);
												} else {
													downloadURL.prop(
														"download",
														"Audio-Call-Recording-" + recording.uID + ".webm"
													);
												}
												downloadURL.prop("href", mediaBlobUrl);
											};
										};
									});

									// Display QOS data
									if (cdr.CallAnswer) DisplayQosData(cdr.SessionId);
								}
							);
						}
						if (id == 2) {
							$("#cdr-tags-" + cdrId).show();
						}
						if (id == 3) {
							// Tag / Untag Call
							var TagState = $("#cdr-flagged-" + cdrId).is(":visible");
							if (TagState) {
								console.log("Clearing Flag from: ", cdrId);
								$("#cdr-flagged-" + cdrId).hide();

								// Update DB
								var currentStream = JSON.parse(
									localDB.getItem(buddy + "-stream")
								);
								if (
									currentStream != null ||
									currentStream.DataCollection != null
								) {
									$.each(currentStream.DataCollection, function (i, item) {
										if (item.ItemType == "CDR" && item.CdrId == cdrId) {
											// Found
											item.Flagged = false;
											return false;
										}
									});
									localDB.setItem(
										buddy + "-stream",
										JSON.stringify(currentStream)
									);
								}
							} else {
								console.log("Flag Call: ", cdrId);
								$("#cdr-flagged-" + cdrId).show();

								// Update DB
								var currentStream = JSON.parse(
									localDB.getItem(buddy + "-stream")
								);
								if (
									currentStream != null ||
									currentStream.DataCollection != null
								) {
									$.each(currentStream.DataCollection, function (i, item) {
										if (item.ItemType == "CDR" && item.CdrId == cdrId) {
											// Found
											item.Flagged = true;
											return false;
										}
									});
									localDB.setItem(
										buddy + "-stream",
										JSON.stringify(currentStream)
									);
								}
							}
						}
						if (id == 4) {
							var currentText = $("#cdr-comment-" + cdrId).text();
							$("#cdr-comment-" + cdrId).empty();

							var textboxObj = $("<input maxlength=500 type=text>").appendTo(
								"#cdr-comment-" + cdrId
							);
							textboxObj.on("focus", function () {
								HidePopup(500);
							});
							textboxObj.on("blur", function () {
								var newText = $(this).val();
								SaveComment(cdrId, buddy, newText);
							});
							textboxObj.keypress(function (event) {
								var keycode = event.keyCode ? event.keyCode : event.which;
								if (keycode == "13") {
									event.preventDefault();

									var newText = $(this).val();
									SaveComment(cdrId, buddy, newText);
								}
							});
							textboxObj.val(currentText);
							textboxObj.focus();
						}

						// Text Messages
						if (id == 10) {
							var msgtext = $("#msg-text-" + cdrId).text();
							navigator.clipboard
								.writeText(msgtext)
								.then(function () {
									console.log("Text coppied to the clipboard:", msgtext);
								})
								.catch(function () {
									console.error("Error writing to the clipboard:", e);
								});
						}
						if (id == 11) {
							// TODO...
							// Involves sharing a message ID, then on change, sent update request
							// So that both parties share the same update.
						}
						if (id == 12) {
							var msgtext = $("#msg-text-" + cdrId).text();
							msgtext = '"' + msgtext + '"';
							var textarea = $("#contact-" + buddy + "-ChatMessage");
							console.log("Quote Message:", msgtext);
							textarea.val(msgtext + "\n" + textarea.val());
						}

						// Delete CDR
						// TODO: This doesnt look for the cdr or the QOS, dont use this
						if (id == 20) {
							var currentStream = JSON.parse(
								localDB.getItem(buddy + "-stream")
							);
							if (
								currentStream != null ||
								currentStream.DataCollection != null
							) {
								$.each(currentStream.DataCollection, function (i, item) {
									if (item.ItemType == "CDR" && item.CdrId == cdrId) {
										// Found
										currentStream.DataCollection.splice(i, 1);
										return false;
									}
								});
								localDB.setItem(
									buddy + "-stream",
									JSON.stringify(currentStream)
								);
								RefreshStream(FindBuddyByIdentity(buddy));
							}
						}
						// Delete Poster Image
						if (id == 21) {
							var currentStream = JSON.parse(
								localDB.getItem(buddy + "-stream")
							);
							if (
								currentStream != null ||
								currentStream.DataCollection != null
							) {
								$.each(currentStream.DataCollection, function (i, item) {
									if (item.ItemType == "CDR" && item.CdrId == cdrId) {
										// Found
										if (item.Recordings && item.Recordings.length >= 1) {
											$.each(item.Recordings, function (r, recording) {
												recording.Poster = null;
											});
										}
										console.log("Poster Imagers Deleted");
										return false;
									}
								});
								localDB.setItem(
									buddy + "-stream",
									JSON.stringify(currentStream)
								);
								RefreshStream(FindBuddyByIdentity(buddy));
							}
						}
					}
				},
				createEvent: null,
				autoFocus: true,
				items: items,
			};
			PopupMenu(obj, menu);
		}
		function SaveComment(cdrId, buddy, newText) {
			console.log("Setting Comment:", newText);

			$("#cdr-comment-" + cdrId).empty();
			$("#cdr-comment-" + cdrId).append(newText);

			// Update DB
			var currentStream = JSON.parse(localDB.getItem(buddy + "-stream"));
			if (currentStream != null || currentStream.DataCollection != null) {
				$.each(currentStream.DataCollection, function (i, item) {
					if (item.ItemType == "CDR" && item.CdrId == cdrId) {
						// Found
						item.MessageData = newText;
						return false;
					}
				});
				localDB.setItem(buddy + "-stream", JSON.stringify(currentStream));
			}
		}
		function TagKeyPress(event, obj, cdrId, buddy) {
			HidePopup(500);

			var keycode = event.keyCode ? event.keyCode : event.which;
			if (keycode == "13" || keycode == "44") {
				event.preventDefault();

				if ($(obj).val() == "") return;

				console.log("Adding Tag:", $(obj).val());

				$("#cdr-tags-" + cdrId + " li:last").before(
					"<li onclick=\"TagClick(this, '" +
						cdrId +
						"', '" +
						buddy +
						"')\">" +
						$(obj).val() +
						"</li>"
				);
				$(obj).val("");

				// Update DB
				UpdateTags(cdrId, buddy);
			}
		}
		function TagClick(obj, cdrId, buddy) {
			console.log("Removing Tag:", $(obj).text());
			$(obj).remove();

			// Dpdate DB
			UpdateTags(cdrId, buddy);
		}
		function UpdateTags(cdrId, buddy) {
			var currentStream = JSON.parse(localDB.getItem(buddy + "-stream"));
			if (currentStream != null || currentStream.DataCollection != null) {
				$.each(currentStream.DataCollection, function (i, item) {
					if (item.ItemType == "CDR" && item.CdrId == cdrId) {
						// Found
						item.Tags = [];
						$("#cdr-tags-" + cdrId)
							.children("li")
							.each(function () {
								if ($(this).prop("class") != "tagText")
									item.Tags.push({ value: $(this).text() });
							});
						return false;
					}
				});
				localDB.setItem(buddy + "-stream", JSON.stringify(currentStream));
			}
		}

		function TagFocus(obj) {
			HidePopup(500);
		}
		function AddMenu(obj, buddy) {
			if (UiCustomMessageAction) {
				if (typeof web_hook_on_message_action !== "undefined") {
					web_hook_on_message_action(buddy, obj);
				}
				return;
			}

			var items = [];
			if (EnableTextExpressions)
				items.push({
					value: 1,
					icon: "fa fa-smile-o",
					text: lang.select_expression,
				});
			if (EnableTextDictate)
				items.push({
					value: 2,
					icon: "fa fa-microphone",
					text: lang.dictate_message,
				});
			// TODO
			if (EnableSendFiles)
				menu.push({
					value: 3,
					name: '<i class="fa fa-share-alt"></i> Share File',
				});
			if (EnableSendImages)
				menu.push({
					value: 4,
					name: '<i class="fa fa-camera"></i> Take/Share Picture',
				});
			if (EnableAudioRecording)
				menu.push({
					value: 5,
					name: '<i class="fa fa-file-audio-o"></i> Record Audio Message',
				});
			if (EnableVideoRecording)
				menu.push({
					value: 6,
					name: '<i class="fa fa-file-video-o"></i> Record Video Message',
				});
			// items.push();
			// items.push();
			// items.push();
			// items.push();
			// items.push();

			var menu = {
				selectEvent: function (event, ui) {
					var id = ui.item.attr("value");
					HidePopup();
					if (id != null) {
						// Emoji Bar
						if (id == "1") {
							ShowEmojiBar(buddy);
						}
						// Disctate Message
						if (id == "2") {
							ShowDictate(buddy);
						}
						//
					}
				},
				createEvent: null,
				autoFocus: true,
				items: items,
			};
			PopupMenu(obj, menu);
		}
		function ShowEmojiBar(buddy) {
			var messageContainer = $("#contact-" + buddy + "-emoji-menu");
			var textarea = $("#contact-" + buddy + "-ChatMessage");

			var menuBar = $("<div/>");
			menuBar.prop("class", "emojiButton");
			var emojis = [
				"😀",
				"😁",
				"😂",
				"😃",
				"😄",
				"😅",
				"😆",
				"😇",
				"😈",
				"😉",
				"😊",
				"😋",
				"😌",
				"😍",
				"😎",
				"😏",
				"😐",
				"😑",
				"😒",
				"😓",
				"😔",
				"😕",
				"😖",
				"😗",
				"😘",
				"😙",
				"😚",
				"😛",
				"😜",
				"😝",
				"😞",
				"😟",
				"😠",
				"😡",
				"😢",
				"😣",
				"😤",
				"😥",
				"😦",
				"😧",
				"😨",
				"😩",
				"😪",
				"😫",
				"😬",
				"😭",
				"😮",
				"😯",
				"😰",
				"😱",
				"😲",
				"😳",
				"😴",
				"😵",
				"😶",
				"😷",
				"🙁",
				"🙂",
				"🙃",
				"🙄",
				"🤐",
				"🤑",
				"🤒",
				"🤓",
				"🤔",
				"🤕",
				"🤠",
				"🤡",
				"🤢",
				"🤣",
				"🤤",
				"🤥",
				"🤧",
				"🤨",
				"🤩",
				"🤪",
				"🤫",
				"🤬",
				"🤭",
				"🤮",
				"🤯",
				"🧐",
			];
			$.each(emojis, function (i, e) {
				var emoji = $("<button>");
				emoji.html(e);
				emoji.on("click", function () {
					var i = textarea.prop("selectionStart");
					var v = textarea.val();
					textarea.val(
						v.substring(0, i) + $(this).html() + v.substring(i, v.length)
					);
					messageContainer.hide();

					updateScroll(buddy);
				});
				menuBar.append(emoji);
			});

			messageContainer.empty();
			messageContainer.append(menuBar);
			messageContainer.show();

			updateScroll(buddy);
		}
		function ShowDictate(buddy) {
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) {
				return;
			}

			if (buddyObj.recognition != null) {
				buddyObj.recognition.abort();
				buddyObj.recognition = null;
			}
			try {
				// Limitation: This opbject can only be made once on the page
				// Generally this is fine, as you can only really dictate one message at a time.
				// It will use the most recently created object.
				var SpeechRecognition =
					window.SpeechRecognition || window.webkitSpeechRecognition;
				buddyObj.recognition = new SpeechRecognition();
			} catch (e) {
				console.error(e);
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: lang.alert_speech_recognition,
					type: "error",
				});
				return;
			}

			var instructions = $("<div/>");
			var messageContainer = $("#contact-" + buddy + "-dictate-message");
			var textarea = $("#contact-" + buddy + "-ChatMessage");

			buddyObj.recognition.continuous = true;
			buddyObj.recognition.onstart = function () {
				instructions.html(
					'<i class="fa fa-microphone" style="font-size: 21px"></i><i class="fa fa-cog fa-spin" style="font-size:10px; vertical-align:text-bottom; margin-left:2px"></i> ' +
						lang.im_listening
				);
				updateScroll(buddy);
			};
			buddyObj.recognition.onspeechend = function () {
				instructions.html(lang.msg_silence_detection);
				window.setTimeout(function () {
					messageContainer.hide();
					updateScroll(buddy);
				}, 1000);
			};
			buddyObj.recognition.onerror = function (event) {
				if (event.error == "no-speech") {
					instructions.html(lang.msg_no_speech);
				} else {
					if (buddyObj.recognition) {
						console.warn("SpeechRecognition Error: ", event);
						buddyObj.recognition.abort();
					}
					buddyObj.recognition = null;
				}
				window.setTimeout(function () {
					messageContainer.hide();
					updateScroll(buddy);
				}, 1000);
			};
			buddyObj.recognition.onresult = function (event) {
				var transcript = event.results[event.resultIndex][0].transcript;
				if (
					(event.resultIndex == 1 &&
						transcript == event.results[0][0].transcript) == false
				) {
					if (
						$.trim(textarea.val()).endsWith(".") ||
						$.trim(textarea.val()) == ""
					) {
						if (
							transcript == "\r" ||
							transcript == "\n" ||
							transcript == "\r\n" ||
							transcript == "\t"
						) {
							// WHITESPACE ONLY
						} else {
							transcript = $.trim(transcript);
							transcript = transcript.replace(
								/^./,
								" " + transcript[0].toUpperCase()
							);
						}
					}
					console.log("Dictate:", transcript);
					textarea.val(textarea.val() + transcript);
				}
			};

			messageContainer.empty();
			messageContainer.append(instructions);
			messageContainer.show();

			updateScroll(buddy);

			buddyObj.recognition.start();
		}

		// My Profile
		// ==========
		function ShowMyProfile() {
			// Buttons
			var buttons = [];
			buttons.push({
				text: lang.save,
				action: function () {
					var chatEng = $("#chat_type_sip").is(":checked") ? "SIMPLE" : "XMPP";

					if (EnableAccountSettings) {
						if ($("#Configure_Account_wssServer").val() == "") {
							console.warn("Validation Failed");
							return;
						}
						if ($("#Configure_Account_WebSocketPort").val() == "") {
							console.warn("Validation Failed");
							return;
						}
						if ($("#Configure_Account_profileUser").val() == "") {
							console.warn("Validation Failed");
							return;
						}
						if ($("#Configure_Account_profileName").val() == "") {
							console.warn("Validation Failed");
							return;
						}
						if ($("#Configure_Account_SipUsername").val() == "") {
							console.warn("Validation Failed");
							return;
						}
						if ($("#Configure_Account_SipPassword").val() == "") {
							console.warn("Validation Failed");
							return;
						}
						if (chatEng == "XMPP") {
							if ($("#Configure_Account_xmpp_domain").val() == "") {
								console.warn("Validation Failed");
								return;
							}
							if ($("#Configure_Account_xmpp_address").val() == "") {
								console.warn("Validation Failed");
								return;
							}
							if ($("#Configure_Account_xmpp_port").val() == "") {
								console.warn("Validation Failed");
								return;
							}
						}
					}

					// The profileUserID identifies users
					if (localDB.getItem("profileUserID") == null)
						localDB.setItem("profileUserID", uID()); // For first time only

					// 1 Account
					if (EnableAccountSettings) {
						localDB.setItem(
							"wssServer",
							$("#Configure_Account_wssServer").val()
						);
						localDB.setItem(
							"WebSocketPort",
							$("#Configure_Account_WebSocketPort").val()
						);
						localDB.setItem(
							"ServerPath",
							$("#Configure_Account_ServerPath").val()
						);
						localDB.setItem(
							"profileUser",
							$("#Configure_Account_profileUser").val()
						);
						localDB.setItem(
							"profileName",
							$("#Configure_Account_profileName").val()
						);
						localDB.setItem(
							"SipUsername",
							$("#Configure_Account_SipUsername").val()
						);
						localDB.setItem(
							"SipPassword",
							$("#Configure_Account_SipPassword").val()
						);

						localDB.setItem("ChatEngine", chatEng);

						localDB.setItem(
							"XmppDomain",
							$("#Configure_Account_xmpp_domain").val()
						);
						localDB.setItem(
							"XmppServer",
							$("#Configure_Account_xmpp_address").val()
						);
						localDB.setItem(
							"XmppWebsocketPort",
							$("#Configure_Account_xmpp_port").val()
						);
						localDB.setItem(
							"XmppWebsocketPath",
							$("#Configure_Account_xmpp_path").val()
						);
					}

					// 2 Audio & Video
					localDB.setItem("AudioOutputId", $("#playbackSrc").val());
					localDB.setItem("VideoSrcId", $("#previewVideoSrc").val());
					localDB.setItem(
						"VideoHeight",
						$("input[name=Settings_Quality]:checked").val()
					);
					localDB.setItem(
						"FrameRate",
						$("input[name=Settings_FrameRate]:checked").val()
					);
					localDB.setItem(
						"AspectRatio",
						$("input[name=Settings_AspectRatio]:checked").val()
					);
					localDB.setItem(
						"VideoOrientation",
						$("input[name=Settings_Oriteation]:checked").val()
					);
					localDB.setItem("AudioSrcId", $("#microphoneSrc").val());
					localDB.setItem(
						"AutoGainControl",
						$("#Settings_AutoGainControl").is(":checked") ? "1" : "0"
					);
					localDB.setItem(
						"EchoCancellation",
						$("#Settings_EchoCancellation").is(":checked") ? "1" : "0"
					);
					localDB.setItem(
						"NoiseSuppression",
						$("#Settings_NoiseSuppression").is(":checked") ? "1" : "0"
					);
					localDB.setItem("RingOutputId", $("#ringDevice").val());

					// 3 Appearance
					if (EnableAppearanceSettings) {
						var vCard = {
							TitleDesc: $("#Configure_Profile_TitleDesc").val(),
							Mobile: $("#Configure_Profile_Mobile").val(),
							Email: $("#Configure_Profile_Email").val(),
							Number1: $("#Configure_Profile_Number1").val(),
							Number2: $("#Configure_Profile_Number2").val(),
						};
						localDB.setItem("profileVcard", JSON.stringify(vCard));

						var options = {
							type: "base64",
							size: "viewport",
							format: "png",
							quality: 1,
							circle: false,
						};
						$("#Appearance_Html").show(); // Bug, only works if visible
						$("#ImageCanvas")
							.croppie("result", options)
							.then(function (base64) {
								localDB.setItem("profilePicture", base64);
								$("#Appearance_Html").hide();

								// Notify Changes
								AlertModule.SOCKET_PUSH_NOTIFICATION({
									text: lang.alert_settings,
									type: "error",
								});
							});
					} else {
						// Notify Changes
						AlertModule.SOCKET_PUSH_NOTIFICATION({
							text: lang.alert_settings,
							type: "error",
						});
					}

					// 4 Notifications
					if (EnableNotificationSettings) {
						localDB.setItem(
							"Notifications",
							$("#Settings_Notifications").is(":checked") ? "0" : "1"
						);
					}
				},
			});
			buttons.push({
				text: lang.cancel,
				action: function () {
					ShowContacts();
				},
			});
			$.each(buttons, function (i, obj) {
				var button = $("<button>" + obj.text + "</button>").click(obj.action);
				$("#ButtonBar").append(button);
			});

			// Show
			$("#actionArea").show();

			// DoOnload
			window.setTimeout(function () {
				// Audio Video
				var selectAudioScr = $("#playbackSrc");

				var playButton = $("#preview_output_play");

				var playRingButton = $("#preview_ringer_play");

				// Microphone
				var selectMicScr = $("#microphoneSrc");
				$("#Settings_AutoGainControl").prop("unchecked", AutoGainControl);
				$("#Settings_EchoCancellation").prop("unchecked", EchoCancellation);
				$("#Settings_NoiseSuppression").prop("unchecked", NoiseSuppression);

				// Frame Rate
				var frameRateSel = $("input[name=Settings_FrameRate]");
				frameRateSel.each(function () {
					if (this.value == maxFrameRate) $(this).prop("checked", true);
				});

				// Quality
				var QualitySel = $("input[name=Settings_Quality]");
				QualitySel.each(function () {
					if (this.value == videoHeight) $(this).prop("checked", true);
				});

				// Aspect Ratio
				var AspectRatioSel = $("input[name=Settings_AspectRatio]");
				AspectRatioSel.each(function () {
					if (this.value == videoAspectRatio) $(this).prop("checked", true);
				});

				// Ring Tone
				var selectRingTone = $("#ringTone");
				// TODO

				// Ring Device
				var selectRingDevice = $("#ringDevice");

				// Handle Aspect Ratio Change
				AspectRatioSel.change(function () {
					console.log("Call to change Aspect Ratio (" + this.value + ")");

					var localVideo = [];
					localVideo.muted = true;
					localVideo.playsinline = true;
					localVideo.autoplay = true;

					var tracks = localVideo.srcObject.getTracks();
					tracks.forEach(function (track) {
						track.stop(-1);
					});

					var constraints = {
						audio: false,
						video: {
							deviceId:
								selectVideoScr.val() != "default"
									? { exact: selectVideoScr.val() }
									: "default",
						},
					};
					if ($("input[name=Settings_FrameRate]:checked").val() != "") {
						constraints.video.frameRate = $(
							"input[name=Settings_FrameRate]:checked"
						).val();
					}
					if ($("input[name=Settings_Quality]:checked").val() != "") {
						constraints.video.height = $(
							"input[name=Settings_Quality]:checked"
						).val();
					}
					if (this.value != "") {
						constraints.video.aspectRatio = this.value;
					}
					console.log("Constraints:", constraints);
					var localStream = new MediaStream();
					if (navigator.mediaDevices) {
						navigator.mediaDevices
							.getUserMedia(constraints)
							.then(function (newStream) {
								var videoTrack = newStream.getVideoTracks()[0];
								localStream.addTrack(videoTrack);
								localVideo.srcObject = localStream;
								localVideo.onloadedmetadata = function (e) {
									localVideo.play();
								};
							})
							.catch(function (e) {
								console.error(e);
								AlertModule.SOCKET_PUSH_NOTIFICATION({
									text: lang.alert_error_user_media,
									type: "error",
								});
							});
					}
				});

				// Handle Frame Rate Change
				frameRateSel.change(function () {
					console.log("Call to change Frame Rate (" + this.value + ")");

					var localVideo = [];
					localVideo.muted = true;
					localVideo.playsinline = true;
					localVideo.autoplay = true;

					var tracks = localVideo.srcObject.getTracks();
					tracks.forEach(function (track) {
						track.stop(-1);
					});

					var constraints = {
						audio: false,
						video: {
							deviceId:
								selectVideoScr.val() != "default"
									? { exact: selectVideoScr.val() }
									: "default",
						},
					};
					if (this.value != "") {
						constraints.video.frameRate = this.value;
					}
					if ($("input[name=Settings_Quality]:checked").val() != "") {
						constraints.video.height = $(
							"input[name=Settings_Quality]:checked"
						).val();
					}
					if ($("input[name=Settings_AspectRatio]:checked").val() != "") {
						constraints.video.aspectRatio = $(
							"input[name=Settings_AspectRatio]:checked"
						).val();
					}
					console.log("Constraints:", constraints);
					var localStream = new MediaStream();
					if (navigator.mediaDevices) {
						navigator.mediaDevices
							.getUserMedia(constraints)
							.then(function (newStream) {
								var videoTrack = newStream.getVideoTracks()[0];
								localStream.addTrack(videoTrack);
								localVideo.srcObject = localStream;
								localVideo.onloadedmetadata = function (e) {
									localVideo.play();
								};
							})
							.catch(function (e) {
								console.error(e);
								AlertModule.SOCKET_PUSH_NOTIFICATION({
									text: lang.alert_error_user_media,
									type: "error",
								});
							});
					}
				});

				// Handle Audio Source changes (Microphone)
				selectMicScr.change(function () {
					console.log("Call to change Microphone (" + this.value + ")");

					// Change and update visual preview
					try {
						var tracks = window.SettingsMicrophoneStream.getTracks();
						tracks.forEach(function (track) {
							track.stop(-1);
						});
						window.SettingsMicrophoneStream = null;
					} catch (e) {
						console.log(e);
					}

					try {
						soundMeter = window.SettingsMicrophoneSoundMeter;
						soundMeter.stop(-1);
						window.SettingsMicrophoneSoundMeter = null;
					} catch (e) {
						console.log(e);
					}

					// Get Microphone
					var constraints = {
						audio: {
							deviceId: { exact: this.value },
						},
						video: false,
					};
				});

				// Handle output change (speaker)
				selectAudioScr.change(function () {
					console.log("Call to change Speaker (" + this.value + ")");

					var audioObj = window.SettingsOutputAudio;
					if (audioObj != null) {
						if (typeof audioObj.sinkId !== "undefined") {
							audioObj
								.setSinkId(this.value)
								.then(function () {
									console.log("sinkId applied to audioObj:", this.value);
								})
								.catch(function (e) {
									console.warn("Failed not apply setSinkId.", e);
								});
						}
					}
				});

				// play button press
				playButton.click(function () {
					try {
						window.SettingsOutputAudio.pause();
					} catch (e) {
						console.log(e);
					}
					window.SettingsOutputAudio = null;

					try {
						var tracks = window.SettingsOutputStream.getTracks();
						tracks.forEach(function (track) {
							track.stop(-1);
						});
					} catch (e) {
						console.log(e);
					}
					window.SettingsOutputStream = null;

					try {
						var soundMeter = window.SettingsOutputStreamMeter;
						soundMeter.stop(-1);
					} catch (e) {
						console.log(e);
					}
					window.SettingsOutputStreamMeter = null;

					// Load Sample
					console.log("Audio:", audioBlobs.speech_orig.url);
					var audioObj = new Audio(audioBlobs.speech_orig.blob);
					audioObj.preload = "auto";
					audioObj.onplay = function () {
						var outputStream = new MediaStream();
						if (typeof audioObj.captureStream !== "undefined") {
							outputStream = audioObj.captureStream();
						} else if (typeof audioObj.mozCaptureStream !== "undefined") {
							return;
							// BUG: mozCaptureStream() in Firefox does not work the same way as captureStream()
							// the actual sound does not play out to the speakers... its as if the mozCaptureStream
							// removes the stream from the <audio> object.
							// outputStream = audioObj.mozCaptureStream();
						} else if (typeof audioObj.webkitCaptureStream !== "undefined") {
							outputStream = audioObj.webkitCaptureStream();
						} else {
							console.warn("Cannot display Audio Levels");
							return;
						}
						// Monitor Output
						window.SettingsOutputStream = outputStream;
						window.SettingsOutputStreamMeter = MeterSettingsOutput(
							outputStream,
							"Settings_SpeakerOutput",
							"width",
							50
						);
					};
					audioObj.oncanplaythrough = function (e) {
						if (typeof audioObj.sinkId !== "undefined") {
							audioObj
								.setSinkId(selectAudioScr.val())
								.then(function () {
									console.log("Set sinkId to:", selectAudioScr.val());
								})
								.catch(function (e) {
									console.warn("Failed not apply setSinkId.", e);
								});
						}
						// Play
						audioObj
							.play()
							.then(function () {
								// Audio Is Playing
							})
							.catch(function (e) {
								console.warn("Unable to play audio file", e);
							});
						console.log("Playing sample audio file... ");
					};

					window.SettingsOutputAudio = audioObj;
				});

				playRingButton.click(function () {
					try {
						window.SettingsRingerAudio.pause();
					} catch (e) {
						console.log(e);
					}
					window.SettingsRingerAudio = null;

					try {
						var tracks = window.SettingsRingerStream.getTracks();
						tracks.forEach(function (track) {
							track.stop(-1);
						});
					} catch (e) {
						console.log(e);
					}
					window.SettingsRingerStream = null;

					try {
						var soundMeter = window.SettingsRingerStreamMeter;
						soundMeter.stop(-1);
					} catch (e) {
						console.log(e);
					}
					window.SettingsRingerStreamMeter = null;

					// Load Sample
					//console.log("Audio:", audioBlobs.Ringtone.url);
					var rinnger = new Audio("/media/Ringtone_2.mp3");
					rinnger.volume = getRingerVolume();
					audioObj.preload = "auto";
					audioObj.onplay = function () {
						var outputStream = new MediaStream();
						if (typeof audioObj.captureStream !== "undefined") {
							outputStream = audioObj.captureStream();
						} else if (typeof audioObj.mozCaptureStream !== "undefined") {
							return;
							// BUG: mozCaptureStream() in Firefox does not work the same way as captureStream()
							// the actual sound does not play out to the speakers... its as if the mozCaptureStream
							// removes the stream from the <audio> object.
							//outputStream = audioObj.mozCaptureStream();
						} else if (typeof audioObj.webkitCaptureStream !== "undefined") {
							outputStream = audioObj.webkitCaptureStream();
						} else {
							console.warn("Cannot display Audio Levels");
							return;
						}
						// Monitor Output
						window.SettingsRingerStream = outputStream;
						window.SettingsRingerStreamMeter = MeterSettingsOutput(
							outputStream,
							"Settings_RingerOutput",
							"width",
							50
						);
					};
					audioObj.oncanplaythrough = function (e) {
						if (typeof audioObj.sinkId !== "undefined") {
							audioObj
								.setSinkId(selectRingDevice.val())
								.then(function () {
									console.log("Set sinkId to:", selectRingDevice.val());
								})
								.catch(function (e) {
									console.warn("Failed not apply setSinkId.", e);
								});
						}
						// Play
						audioObj
							.play()
							.then(function () {
								// Audio Is Playing
							})
							.catch(function (e) {
								console.warn("Unable to play audio file", e);
							});
						console.log("Playing sample audio file... ");
					};

					window.SettingsRingerAudio = audioObj;
				});

				// Note: Only works over HTTPS or via localhost!!
				var localMicrophoneStream = new MediaStream();

				if (navigator.mediaDevices) {
					navigator.mediaDevices
						.enumerateDevices()
						.then(function (deviceInfos) {
							var savedAudioDevice = getAudioSrcID();
							var audioDeviceFound = true;
							var MicrophoneFound = true;
							var SpeakerFound = true;
							var VideoFound = false;
							var contraints = {
								audio: MicrophoneFound,
								video: VideoFound,
							};
							if (MicrophoneFound) {
								contraints.audio = { deviceId: "default" };
								if (audioDeviceFound)
									contraints.audio.deviceId = { exact: savedAudioDevice };
							}
							console.log("Get User Media", contraints);
							// Get User Media
							navigator.mediaDevices
								.getUserMedia(contraints)
								.then(function (mediaStream) {
									return navigator.mediaDevices.enumerateDevices();
								})
								.catch(function (e) {
									console.error(e);
									AlertModule.SOCKET_PUSH_NOTIFICATION({
										text: lang.alert_error_user_media,
										type: "error",
									});
								});
						})
						.catch(function (e) {
							console.error("Error getting Media Devices", e);
						});
				} else {
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: lang.alert_media_devices,
						type: "error",
					});
				}

				// Notifications
				if (Notification.permission != "granted") {
					p = "granted";
					if (checkNotificationPromise()) {
						Notification.requestPermission().then(function (p) {
							console.log(p);
						});
					} else {
						Notification.requestPermission(function (p) {
							console.log(p);
						});
					}
				}
			}, 0);
		}
		function RefreshRegistration() {
			Unregister();
			console.log("Unregister complete...");
			window.setTimeout(function () {
				console.log("Starting registration...");
				Register();
			}, 1000);
		}
		function ToggleHeading(obj, div) {
			$("#" + div).toggle();
		}
		function ToggleAutoAnswer() {
			if (AutoAnswerPolicy == "disabled") {
				AutoAnswerEnabled = false;
				console.warn("Policy AutoAnswer: Disabled");
				return;
			}
			AutoAnswerEnabled = AutoAnswerEnabled == true ? false : true;
			if (AutoAnswerPolicy == "enabled") AutoAnswerEnabled = true;
			localDB.setItem(
				"AutoAnswerEnabled",
				AutoAnswerEnabled == true ? "1" : "0"
			);
			console.log("AutoAnswer:", AutoAnswerEnabled);
		}
		function ToggleDoNoDisturb() {
			if (DoNotDisturbPolicy == "disabled") {
				DoNotDisturbEnabled = false;
				console.warn("Policy DoNotDisturb: Disabled");
				return;
			}
			DoNotDisturbEnabled = DoNotDisturbEnabled == true ? false : true;
			if (DoNotDisturbPolicy == "enabled") DoNotDisturbEnabled = true;
			localDB.setItem(
				"DoNotDisturbEnabled",
				DoNotDisturbEnabled == true ? "1" : "0"
			);
			$("#dereglink").attr(
				"class",
				DoNotDisturbEnabled == true ? "dotDoNotDisturb" : "dotOnline"
			);
			console.log("DoNotDisturb", DoNotDisturbEnabled);
		}
		function ToggleCallWaiting() {
			if (CallWaitingPolicy == "disabled") {
				CallWaitingEnabled = false;
				console.warn("Policy CallWaiting: Disabled");
				return;
			}
			CallWaitingEnabled = CallWaitingEnabled == true ? false : true;
			if (CallWaitingPolicy == "enabled") CallWaitingPolicy = true;
			localDB.setItem(
				"CallWaitingEnabled",
				CallWaitingEnabled == true ? "1" : "0"
			);
			console.log("CallWaiting", CallWaitingEnabled);
		}
		function ToggleRecordAllCalls() {
			if (CallRecordingPolicy == "disabled") {
				RecordAllCalls = false;
				console.warn("Policy CallRecording: Disabled");
				return;
			}
			RecordAllCalls = RecordAllCalls == true ? false : true;
			if (CallRecordingPolicy == "enabled") RecordAllCalls = true;
			localDB.setItem("RecordAllCalls", RecordAllCalls == true ? "1" : "0");
			console.log("RecordAllCalls", RecordAllCalls);
		}

		// Device and Settings
		// ===================
		function ChangeSettings(lineNum, obj) {
			if (UiCustomMediaSettings) {
				if (typeof web_hook_on_edit_media !== "undefined") {
					web_hook_on_edit_media(lineNum, obj);
				}
				return;
			}

			// Check if you are in a call
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("SIP Session is NULL.");
				return;
			}
			var session = lineObj.SipSession;

			// Load Devices
			if (!navigator.mediaDevices) {
				console.warn("navigator.mediaDevices not possible.");
				return;
			}

			var items = [];

			// Microphones
			items.push({
				value: "",
				icon: null,
				text: lang.microphone,
				isHeader: true,
			});
			for (var i = 0; i < global.AudioinputDevices.length; ++i) {
				var deviceInfo = global.AudioinputDevices[i];
				var devideId = deviceInfo.deviceId;
				var DisplayName = deviceInfo.label ? deviceInfo.label : "Microphone";
				if (DisplayName.indexOf("(") > 0)
					DisplayName = DisplayName.substring(0, DisplayName.indexOf("("));
				var disabled = session.data.AudioSourceDevice == devideId;

				items.push({
					value: "input-" + devideId,
					icon: "fa fa-microphone",
					text: DisplayName,
					isDisabled: disabled,
				});
			}
			// Speakers
			if (HasSpeakerDevice) {
				items.push({ value: "", icon: null, text: "-" });
				items.push({
					value: "",
					icon: null,
					text: lang.speaker,
					isHeader: true,
				});
				for (var i = 0; i < SpeakerDevices.length; ++i) {
					var deviceInfo = SpeakerDevices[i];
					var devideId = deviceInfo.deviceId;
					var DisplayName = deviceInfo.label ? deviceInfo.label : "Speaker";
					if (DisplayName.indexOf("(") > 0)
						DisplayName = DisplayName.substring(0, DisplayName.indexOf("("));
					var disabled = session.data.AudioOutputDevice == devideId;

					items.push({
						value: "output-" + devideId,
						icon: "fa fa-volume-up",
						text: DisplayName,
						isDisabled: disabled,
					});
				}
			}
			// Cameras
			if (session.data.withvideo == true) {
				items.push({ value: "", icon: null, text: "-" });
				items.push({
					value: "",
					icon: null,
					text: lang.camera,
					isHeader: true,
				});
				for (var i = 0; i < VideoinputDevices.length; ++i) {
					var deviceInfo = VideoinputDevices[i];
					var devideId = deviceInfo.deviceId;
					var DisplayName = deviceInfo.label ? deviceInfo.label : "Webcam";
					if (DisplayName.indexOf("(") > 0)
						DisplayName = DisplayName.substring(0, DisplayName.indexOf("("));
					var disabled = session.data.VideoSourceDevice == devideId;

					items.push({
						value: "video-" + devideId,
						icon: "fa fa-video-camera",
						text: DisplayName,
						isDisabled: disabled,
					});
				}
			}

			var menu = {
				selectEvent: function (event, ui) {
					var id = ui.item.attr("value");
					if (id != null) {
						// Microphone Device Change
						if (id.indexOf("input-") > -1) {
							var newid = id.replace("input-", "");

							console.log("Call to change Microphone: ", newid);

							HidePopup();

							// First Stop Recording the call
							var mustRestartRecording = false;
							if (
								session.data.mediaRecorder &&
								session.data.mediaRecorder.state == "recording"
							) {
								StopRecording(lineNum, true);
								mustRestartRecording = true;
							}

							// Stop Monitoring
							if (lineObj.LocalSoundMeter) lineObj.LocalSoundMeter.stop(-1);

							// Save Setting
							session.data.AudioSourceDevice = newid;

							var constraints = {
								audio: {
									deviceId: newid != "default" ? { exact: newid } : "default",
								},
								video: false,
							};
							navigator.mediaDevices
								.getUserMedia(constraints)
								.then(function (newStream) {
									// Assume that since we are selecting from a dropdown, this is possible
									var newMediaTrack = newStream.getAudioTracks()[0];
									var pc = session.sessionDescriptionHandler.peerConnection;
									pc.getSenders().forEach(function (RTCRtpSender) {
										if (
											RTCRtpSender.track &&
											RTCRtpSender.track.kind == "audio"
										) {
											console.log(
												"Switching Audio Track : " +
													RTCRtpSender.track.label +
													" to " +
													newMediaTrack.label
											);
											RTCRtpSender.track.stop(-1); // Must stop, or this mic will stay in use
											RTCRtpSender.replaceTrack(newMediaTrack)
												.then(function () {
													// Start Recording again
													if (mustRestartRecording) StartRecording(lineNum);
													// Monitor Adio Stream
													lineObj.LocalSoundMeter =
														StartLocalAudioMediaMonitoring(lineNum, session);
												})
												.catch(function (e) {
													console.error("Error replacing track: ", e);
												});
										}
									});
								})
								.catch(function (e) {
									console.error("Error on getUserMedia");
								});
						}

						// Speaker
						if (id.indexOf("output-") > -1) {
							var newid = id.replace("output-", "");

							console.log("Call to change Speaker: ", newid);

							HidePopup();

							// Save Setting
							session.data.AudioOutputDevice = newid;

							// Also change the sinkId
							// ======================
							var sinkId = newid;
							console.log(
								"Attempting to set Audio Output SinkID for line " +
									lineNum +
									" [" +
									sinkId +
									"]"
							);

							// Remote Audio
							var element = $("#line-" + lineNum + "-remoteAudio").get(0);
							if (element) {
								if (typeof element.sinkId !== "undefined") {
									element
										.setSinkId(sinkId)
										.then(function () {
											console.log("sinkId applied: " + sinkId);
										})
										.catch(function (e) {
											console.warn("Error using setSinkId: ", e);
										});
								} else {
									console.warn(
										"setSinkId() is not possible using this browser."
									);
								}
							}
						}

						// Camera
						if (id.indexOf("video-") > -1) {
							var newid = id.replace("video-", "");

							console.log("Call to change WebCam");

							HidePopup();

							switchVideoSource(lineNum, newid);
						}
					} else {
						HidePopup();
					}
				},
				createEvent: null,
				autoFocus: true,
				items: items,
			};
			PopupMenu(obj, menu);
		}

		// Media Presentation
		// ==================
		function PresentCamera(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null.");
				return;
			}
			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-src-camera").prop("disabled", true);
			$("#line-" + lineNum + "-src-canvas").prop("disabled", false);
			$("#line-" + lineNum + "-src-desktop").prop("disabled", false);
			$("#line-" + lineNum + "-src-video").prop("disabled", false);
			$("#line-" + lineNum + "-src-blank").prop("disabled", false);

			$("#line-" + lineNum + "-scratchpad-container").hide();
			RemoveScratchpad(lineNum);
			$("#line-" + lineNum + "-sharevideo").hide();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.pause();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.removeAttribute("src");
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.load();
			window.clearInterval(session.data.videoResampleInterval);

			$("#line-" + lineNum + "-localVideo").show();
			$("#line-" + lineNum + "-remote-videos").show();
			RedrawStage(lineNum, true);
			// $("#line-"+ lineNum + "-remoteVideo").appendTo("#line-"+ lineNum + "-stage-container");

			switchVideoSource(lineNum, session.data.VideoSourceDevice);
		}
		function PresentScreen(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null.");
				return;
			}
			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-src-camera").prop("disabled", false);
			$("#line-" + lineNum + "-src-canvas").prop("disabled", false);
			$("#line-" + lineNum + "-src-desktop").prop("disabled", true);
			$("#line-" + lineNum + "-src-video").prop("disabled", false);
			$("#line-" + lineNum + "-src-blank").prop("disabled", false);

			$("#line-" + lineNum + "-scratchpad-container").hide();
			RemoveScratchpad(lineNum);
			$("#line-" + lineNum + "-sharevideo").hide();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.pause();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.removeAttribute("src");
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.load();
			window.clearInterval(session.data.videoResampleInterval);

			$("#line-" + lineNum + "-localVideo").show();
			$("#line-" + lineNum + "-remote-videos").show();
			// $("#line-"+ lineNum + "-remoteVideo").appendTo("#line-"+ lineNum + "-stage-container");

			ShareScreen(lineNum);
		}
		// function PresentScratchpad(lineNum) {
		// 	var lineObj = FindLineByNumber(lineNum);
		// 	if (lineObj == null || lineObj.SipSession == null) {
		// 		console.warn("Line or Session is Null.");
		// 		return;
		// 	}
		// 	var session = lineObj.SipSession;

		// 	$("#line-" + lineNum + "-src-camera").prop("disabled", false);
		// 	$("#line-" + lineNum + "-src-canvas").prop("disabled", true);
		// 	$("#line-" + lineNum + "-src-desktop").prop("disabled", false);
		// 	$("#line-" + lineNum + "-src-video").prop("disabled", false);
		// 	$("#line-" + lineNum + "-src-blank").prop("disabled", false);

		// 	$("#line-" + lineNum + "-scratchpad-container").hide();
		// 	RemoveScratchpad(lineNum);
		// 	$("#line-" + lineNum + "-sharevideo").hide();
		// 	$("#line-" + lineNum + "-sharevideo")
		// 		.get(0)
		// 		.pause();
		// 	$("#line-" + lineNum + "-sharevideo")
		// 		.get(0)
		// 		.removeAttribute("src");
		// 	$("#line-" + lineNum + "-sharevideo")
		// 		.get(0)
		// 		.load();
		// 	window.clearInterval(session.data.videoResampleInterval);

		// 	$("#line-" + lineNum + "-localVideo").show();
		// 	$("#line-" + lineNum + "-remote-videos").hide();
		// 	// $("#line-"+ lineNum + "-remoteVideo").appendTo("#line-"+ lineNum + "-preview-container");

		// 	SendCanvas(lineNum);
		// }
		function PresentVideo(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null.");
				return;
			}
			var session = lineObj.SipSession;

			var html =
				'<div class="UiWindowField"><input type=file  accept="video/*" id=SelectVideoToSend></div>';
			OpenWindow(
				html,
				lang.select_video,
				150,
				360,
				false,
				false,
				null,
				null,
				lang.cancel,
				function () {
					// Cancel
					CloseWindow();
				},
				function () {
					// Do OnLoad
					$("#SelectVideoToSend").on("change", function (event) {
						var input = event.target;
						if (input.files.length >= 1) {
							CloseWindow();

							// Send Video (Can only send one file)
							SendVideo(lineNum, URL.createObjectURL(input.files[0]));
						} else {
							console.warn("Please Select a file to present.");
						}
					});
				},
				null
			);
		}
		function PresentBlank(lineNum) {
			var lineObj = FindLineByNumber(lineNum);
			if (lineObj == null || lineObj.SipSession == null) {
				console.warn("Line or Session is Null.");
				return;
			}
			var session = lineObj.SipSession;

			$("#line-" + lineNum + "-src-camera").prop("disabled", false);
			$("#line-" + lineNum + "-src-canvas").prop("disabled", false);
			$("#line-" + lineNum + "-src-desktop").prop("disabled", false);
			$("#line-" + lineNum + "-src-video").prop("disabled", false);
			$("#line-" + lineNum + "-src-blank").prop("disabled", true);

			$("#line-" + lineNum + "-scratchpad-container").hide();
			RemoveScratchpad(lineNum);
			$("#line-" + lineNum + "-sharevideo").hide();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.pause();
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.removeAttribute("src");
			$("#line-" + lineNum + "-sharevideo")
				.get(0)
				.load();
			window.clearInterval(session.data.videoResampleInterval);

			$("#line-" + lineNum + "-localVideo").hide();
			$("#line-" + lineNum + "-remote-videos").show();
			// $("#line-"+ lineNum + "-remoteVideo").appendTo("#line-"+ lineNum + "-stage-container");

			DisableVideoStream(lineNum);
		}
		function RemoveScratchpad(lineNum) {
			var scratchpad = GetCanvas("line-" + lineNum + "-scratchpad");
			if (scratchpad != null) {
				window.clearInterval(scratchpad.redrawIntrtval);

				RemoveCanvas("line-" + lineNum + "-scratchpad");
				$("#line-" + lineNum + "-scratchpad-container").empty();

				scratchpad = null;
			}
		}

		// Chatting
		// ========
		function chatOnbeforepaste(event, obj, buddy) {
			console.log("Handle paste, checking for Images...");
			var items = (event.clipboardData || event.originalEvent.clipboardData)
				.items;

			// find pasted image among pasted items
			var preventDefault = false;
			for (var i = 0; i < items.length; i++) {
				if (items[i].type.indexOf("image") === 0) {
					console.log("Image found! Opening image editor...");

					var blob = items[i].getAsFile();

					// read the image in
					var reader = new FileReader();
					reader.onload = function (event) {
						// Image has loaded, open Image Preview editer
						// ===========================================
						console.log("Image loaded... setting placeholder...");
						var placeholderImage = new Image();
						placeholderImage.onload = function () {
							console.log("Placeholder loaded... CreateImageEditor...");

							CreateImageEditor(buddy, placeholderImage);
						};
						placeholderImage.src = event.target.result;

						// $("#contact-" + buddy + "-msgPreviewhtml").html("<img src=\""+ event.target.result +"\" style=\"max-width:320px; max-height:240px\" />");
						// $("#contact-" + buddy + "-msgPreview").show();
					};
					reader.readAsDataURL(blob);

					preventDefault = true;
					continue;
				}
			}

			// Pevent default if you found an image
			if (preventDefault) event.preventDefault();
		}
		function chatOnkeydown(event, obj, buddy) {
			var keycode = event.keyCode ? event.keyCode : event.which;
			if (keycode == "13") {
				if (event.shiftKey || event.ctrlKey) {
					// Leave as is
					// Windows and Mac react differently here.
				} else {
					event.preventDefault();

					SendChatMessage(buddy);
					return false;
				}
			} else {
				// Consult the chatstates
				var buddyObj = FindBuddyByIdentity(buddy);
				if (buddyObj != null && buddyObj.type == "xmpp")
					XmppStartComposing(buddyObj);
			}
		}
		function chatOnInput(event, obj, buddy) {
			var str = $.trim($(obj).val());
			if (str != "") {
				$("#contact-" + buddy + "-sendMessageButtons").show();
			} else {
				$("#contact-" + buddy + "-sendMessageButtons").hide();
			}
		}

		function ReformatMessage(str) {
			var msg = str;
			// Simple tex=>HTML
			msg = msg.replace(/</gi, "&lt;");
			msg = msg.replace(/>/gi, "&gt;");
			msg = msg.replace(/\n/gi, "<br>");
			// Emojy
			// Skype: :) :( :D :O ;) ;( (:| :| :P :$ :^) |-) |-( :x ]:)
			// (cool) (hearteyes) (stareyes) (like) (unamused) (cwl) (xd) (pensive) (weary) (hysterical) (flushed) (sweatgrinning) (disappointed) (loudlycrying) (shivering) (expressionless) (relieved) (inlove) (kiss) (yawn) (puke) (doh) (angry) (wasntme) (worry) (confused) (veryconfused) (mm) (nerd) (rainbowsmile) (devil) (angel) (envy) (makeup) (think) (rofl) (happy) (smirk) (nod) (shake) (waiting) (emo) (donttalk) (idea) (talk) (swear) (headbang) (learn) (headphones) (morningafter) (selfie) (shock) (ttm) (dream)
			msg = msg.replace(/(:\)|:\-\)|:o\))/g, String.fromCodePoint(0x1f642)); // :) :-) :o)
			msg = msg.replace(/(:\(|:\-\(|:o\()/g, String.fromCodePoint(0x1f641)); // :( :-( :o(
			msg = msg.replace(/(;\)|;\-\)|;o\))/g, String.fromCodePoint(0x1f609)); // ;) ;-) ;o)
			msg = msg.replace(/(:'\(|:'\-\()/g, String.fromCodePoint(0x1f62a)); // :'( :'‑(
			msg = msg.replace(/(:'\(|:'\-\()/g, String.fromCodePoint(0x1f602)); // :') :'‑)
			msg = msg.replace(/(:\$)/g, String.fromCodePoint(0x1f633)); // :$
			msg = msg.replace(/(>:\()/g, String.fromCodePoint(0x1f623)); // >:(
			msg = msg.replace(/(:\×)/g, String.fromCodePoint(0x1f618)); // :×
			msg = msg.replace(/(:\O|:\‑O)/g, String.fromCodePoint(0x1f632)); // :O :‑O
			msg = msg.replace(/(:P|:\-P|:p|:\-p)/g, String.fromCodePoint(0x1f61b)); // :P :-P :p :-p
			msg = msg.replace(/(;P|;\-P|;p|;\-p)/g, String.fromCodePoint(0x1f61c)); // ;P ;-P ;p ;-p
			msg = msg.replace(/(:D|:\-D)/g, String.fromCodePoint(0x1f60d)); // :D :-D

			msg = msg.replace(/(\(like\))/g, String.fromCodePoint(0x1f44d)); // (like)

			// Make clickable Hyperlinks
			msg = msg.replace(
				/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi,
				function (x) {
					var niceLink = x.length > 50 ? x.substring(0, 47) + "..." : x;
					var rtn =
						'<A target=_blank class=previewHyperlink href="' +
						x +
						'">' +
						niceLink +
						"</A>";
					return rtn;
				}
			);
			return msg;
		}
		function getPicture(buddy, typestr, ignoreCache) {
			var rndInt = Math.floor(Math.random() * 8) + 1;
			var defaultImg = hostingPrefex + "";
			if (buddy == "profilePicture") {
				// Special handling for profile image
				var dbImg = localDB.getItem("profilePicture");
				if (dbImg == null) {
					return defaultImg;
				} else {
					return dbImg;
					// return URL.createObjectURL(base64toBlob(dbImg, 'image/png'));
				}
			}

			typestr = typestr ? typestr : "extension";
			var buddyObj = FindBuddyByIdentity(buddy);
			if (buddyObj == null) {
				return defaultImg;
			}
			if (ignoreCache != true && buddyObj.imageObjectURL != "") {
				// Use Cache
				return buddyObj.imageObjectURL;
			}
			var dbImg = localDB.getItem("img-" + buddy + "-" + typestr);
			if (dbImg == null) {
				buddyObj.imageObjectURL = defaultImg;
				return buddyObj.imageObjectURL;
			} else {
				buddyObj.imageObjectURL = URL.createObjectURL(
					base64toBlob(dbImg, "image/png")
				);
				return buddyObj.imageObjectURL;
			}
		}

		// Image Editor
		// ============

		function GetCanvas(canvasId) {
			for (var c = 0; c < CanvasCollection.length; c++) {
				try {
					if (CanvasCollection[c].id == canvasId) return CanvasCollection[c];
				} catch (e) {
					console.warn("CanvasCollection.id not available");
				}
			}
			return null;
		}
		function RemoveCanvas(canvasId) {
			for (var c = 0; c < CanvasCollection.length; c++) {
				try {
					if (CanvasCollection[c].id == canvasId) {
						console.log("Found Old Canvas, Disposing...");

						CanvasCollection[c].clear();
						CanvasCollection[c].dispose();

						CanvasCollection[c].id = "--deleted--";

						console.log("CanvasCollection.splice(" + c + ", 1)");
						CanvasCollection.splice(c, 1);
						break;
					}
				} catch (e) {
					console.log(e);
				}
			}
			console.log("There are " + CanvasCollection.length + " canvas now.");
		}
		var ImageEditor_Select = function (buddy) {
			var canvas = GetCanvas("contact-" + buddy + "-imageCanvas");
			if (canvas != null) {
				canvas.ToolSelected = "none";
				canvas.isDrawingMode = false;
				return true;
			}
			return false;
		};

		// Find something in the message stream
		// ====================================

		// FileShare an Upload
		// ===================

		function preventDefault(e) {
			e.preventDefault();
			e.stopPropagation();
		}

		// UI Elements
		// ===========
		// jQuery UI
		function OpenWindow(
			html,
			title,
			height,
			width,
			hideCloseButton,
			allowResize,
			button1_Text,
			button1_onClick,
			button2_Text,
			button2_onClick,
			DoOnLoad,
			OnClose
		) {
			console.log("Open Window: " + title);

			// Close any windows that may already be open
			if (windowObj != null) {
				windowObj.dialog("close");
				windowObj = null;
			}

			// Create Window
			windowObj = $("<div></div>")
				.html(html)
				.dialog({
					autoOpen: false,
					title: title,
					modal: true,
					width: width,
					height: height,
					resizable: allowResize,
					classes: { "ui-dialog-content": "cleanScroller" },
					close: function (event, ui) {
						$(this).dialog("destroy");
						windowObj = null;
					},
				});
			var buttons = [];
			if (button1_Text && button1_onClick) {
				buttons.push({
					text: button1_Text,
					click: function () {
						console.log("Button 1 (" + button1_Text + ") Clicked");
						button1_onClick();
					},
				});
			}
			if (button2_Text && button2_onClick) {
				buttons.push({
					text: button2_Text,
					click: function () {
						console.log("Button 2 (" + button2_Text + ") Clicked");
						button2_onClick();
					},
				});
			}
			if (buttons.length >= 1) windowObj.dialog("option", "buttons", buttons);

			if (OnClose)
				windowObj.on("dialogbeforeclose", function (event, ui) {
					return OnClose(this);
				});
			if (DoOnLoad)
				windowObj.on("dialogopen", function (event, ui) {
					DoOnLoad();
				});

			// Open the Window
			windowObj.dialog("open");

			if (hideCloseButton) windowObj.dialog({ dialogClass: "no-close" });

			var windowWidth = $(window).outerWidth();
			var windowHeight = $(window).outerHeight();
			var offsetTextHeight = windowObj.parent().outerHeight();

			if (windowWidth <= width || windowHeight <= offsetTextHeight) {
				windowObj.parent().css("top", "0px"); // option
				windowObj.parent().css("left", "0px");
				windowObj.dialog("option", "height", windowHeight); // option
				windowObj.dialog("option", "width", windowWidth);
			} else {
				windowObj.parent().css("left", windowWidth / 2 - width / 2 + "px");
				windowObj
					.parent()
					.css("top", windowHeight / 2 - offsetTextHeight / 2 + "px");
			}

			// Doubl Click to maximise
			$(".ui-dialog-titlebar").dblclick(function () {
				windowObj.parent().css("top", "0px"); // option
				windowObj.parent().css("left", "0px");
				windowObj.dialog("option", "height", windowHeight); // option
				windowObj.dialog("option", "width", windowWidth);
			});
		}
		function CloseWindow(all) {
			console.log("Call to close any open window");

			if (windowObj != null) {
				windowObj.dialog("close");
				windowObj = null;
			}
			if (all == true) {
				if (confirmObj != null) {
					confirmObj.dialog("close");
					confirmObj = null;
				}
				if (promptObj != null) {
					promptObj.dialog("close");
					promptObj = null;
				}
				if (alertObj != null) {
					alertObj.dialog("close");
					alertObj = null;
				}
			}
		}
		function WindowProgressOn() {
			//
		}
		function WindowProgressOff() {
			//
		}
		// function Alert(messageStr, TitleStr, onOk) {
		// 	if (confirmObj != null) {
		// 		confirmObj.dialog("close");
		// 		confirmObj = null;
		// 	}
		// 	if (promptObj != null) {
		// 		promptObj.dialog("close");
		// 		promptObj = null;
		// 	}
		// 	if (alertObj != null) {
		// 		console.error(
		// 			"Alert not null, while Alert called: " +
		// 				TitleStr +
		// 				", saying:" +
		// 				messageStr
		// 		);
		// 		return;
		// 	} else {
		// 		console.log(
		// 			"Alert called with Title: " + TitleStr + ", saying: " + messageStr
		// 		);
		// 	}
		// 	var html = "<div class=NoSelect>";
		// 	html +=
		// 		'<div class=UiText style="padding: 10px" id=AllertMessageText>' +
		// 		messageStr +
		// 		"</div>";
		// 	html += "</div>";
		// 	alertObj = $("<div>")
		// 		.html(html)
		// 		.dialog({
		// 			autoOpen: false,
		// 			title: TitleStr,
		// 			modal: true,
		// 			width: 300,
		// 			height: "auto",
		// 			resizable: false,
		// 			closeOnEscape: false,
		// 			close: function (event, ui) {
		// 				$(this).dialog("destroy");
		// 				alertObj = null;
		// 			},
		// 		});
		// 	var buttons = [];
		// 	buttons.push({
		// 		text: lang.ok,
		// 		click: function () {
		// 			console.log("Alert OK clicked");
		// 			if (onOk) onOk();
		// 			$(this).dialog("close");
		// 			alertObj = null;
		// 		},
		// 	});
		// 	alertObj.dialog("option", "buttons", buttons);
		// 	// Open the Window
		// 	alertObj.dialog("open");
		// 	alertObj.dialog({ dialogClass: "no-close" });
		// 	var windowWidth = $(window).outerWidth();
		// 	var windowHeight = $(window).outerHeight();
		// 	var offsetTextHeight = alertObj.parent().outerHeight();
		// 	alertObj.parent().css("left", windowWidth / 2 - 300 / 2 + "px");
		// 	if (windowHeight <= offsetTextHeight) {
		// 		alertObj.parent().css("top", "0px");
		// 		alertObj.dialog("option", "height", windowHeight);
		// 	} else {
		// 		alertObj
		// 			.parent()
		// 			.css("top", windowHeight / 2 - offsetTextHeight / 2 + "px");
		// 	}
		// }
		function Confirm(messageStr, TitleStr, onOk, onCancel) {
			if (alertObj != null) {
				alertObj.dialog("close");
				alertObj = null;
			}
			if (promptObj != null) {
				promptObj.dialog("close");
				promptObj = null;
			}
			if (confirmObj != null) {
				console.error(
					"Confirm not null, while Confrim called with Title: " +
						TitleStr +
						", saying: " +
						messageStr
				);
				return;
			} else {
				console.log(
					"Confirm called with Title: " + TitleStr + ", saying: " + messageStr
				);
			}

			var html = "<div class=NoSelect>";
			html +=
				'<div class=UiText style="padding: 10px" id=ConfrimMessageText>' +
				messageStr +
				"</div>";
			html += "</div>";

			confirmObj = $("<div>")
				.html(html)
				.dialog({
					autoOpen: false,
					title: TitleStr,
					modal: true,
					width: 300,
					height: "auto",
					resizable: false,
					closeOnEscape: false,
					close: function (event, ui) {
						$(this).dialog("destroy");
						confirmObj = null;
					},
				});

			var buttons = [];
			buttons.push({
				text: lang.ok,
				click: function () {
					console.log("Confrim OK clicked");
					if (onOk) onOk();
					$(this).dialog("close");
					confirmObj = null;
				},
			});
			buttons.push({
				text: lang.cancel,
				click: function () {
					console.log("Confirm Cancel clicked");
					if (onCancel) onCancel();
					$(this).dialog("close");
					confirmObj = null;
				},
			});

			confirmObj.dialog("option", "buttons", buttons);

			// Open the Window
			confirmObj.dialog("open");

			confirmObj.dialog({ dialogClass: "no-close" });

			var windowWidth = $(window).outerWidth();
			var windowHeight = $(window).outerHeight();
			var offsetTextHeight = confirmObj.parent().outerHeight();

			confirmObj.parent().css("left", windowWidth / 2 - 300 / 2 + "px");

			if (windowHeight <= offsetTextHeight) {
				confirmObj.parent().css("top", "0px");
				confirmObj.dialog("option", "height", windowHeight);
			} else {
				confirmObj
					.parent()
					.css("top", windowHeight / 2 - offsetTextHeight / 2 + "px");
			}
		}
		function Prompt(
			messageStr,
			TitleStr,
			FieldText,
			defaultValue,
			dataType,
			placeholderText,
			onOk,
			onCancel
		) {
			if (alertObj != null) {
				alertObj.dialog("close");
				alertObj = null;
			}
			if (confirmObj != null) {
				confirmObj.dialog("close");
				confirmObj = null;
			}
			if (promptObj != null) {
				console.error(
					"Prompt not null, while Prompt called with Title: " +
						TitleStr +
						", saying: " +
						messageStr
				);
				return;
			} else {
				console.log(
					"Prompt called with Title: " + TitleStr + ", saying: " + messageStr
				);
			}

			var html = "<div class=NoSelect>";
			html += '<div class=UiText style="padding: 10px" id=PromptMessageText>';
			html += messageStr;
			html += '<div style="margin-top:10px">' + FieldText + " : </div>";
			html +=
				'<div style="margin-top:5px"><INPUT id=PromptValueField type=' +
				dataType +
				' value="' +
				defaultValue +
				'" placeholder="' +
				placeholderText +
				'" style="width:98%"></div>';
			html += "</div>";
			html += "</div>";

			promptObj = $("<div>")
				.html(html)
				.dialog({
					autoOpen: false,
					title: TitleStr,
					modal: true,
					width: 300,
					height: "auto",
					resizable: false,
					closeOnEscape: false,
					close: function (event, ui) {
						$(this).dialog("destroy");
						promptObj = null;
					},
				});

			var buttons = [];
			buttons.push({
				text: lang.ok,
				click: function () {
					console.log(
						"Prompt OK clicked, with value: " + $("#PromptValueField").val()
					);
					if (onOk) onOk($("#PromptValueField").val());
					$(this).dialog("close");
					promptObj = null;
				},
			});
			buttons.push({
				text: lang.cancel,
				click: function () {
					console.log("Prompt Cancel clicked");
					if (onCancel) onCancel();
					$(this).dialog("close");
					promptObj = null;
				},
			});
			promptObj.dialog("option", "buttons", buttons);

			// Open the Window
			promptObj.dialog("open");

			promptObj.dialog({ dialogClass: "no-close" });

			var windowWidth = $(window).outerWidth();
			var windowHeight = $(window).outerHeight();
			var offsetTextHeight = promptObj.parent().outerHeight();

			promptObj.parent().css("left", windowWidth / 2 - 300 / 2 + "px");

			if (windowHeight <= offsetTextHeight) {
				promptObj.parent().css("top", "0px");
				promptObj.dialog("option", "height", windowHeight);
			} else {
				promptObj
					.parent()
					.css("top", windowHeight / 2 - offsetTextHeight / 2 + "px");
			}
		}
		function PopupMenu(obj, menu) {
			console.log("Show Popup Menu");

			// Close any menu that may already be open
			if (menuObj != null) {
				menuObj.menu("destroy");
				menuObj.empty();
				menuObj.remove();
				menuObj = null;
			}

			var x = $(obj).offset().left - $(document).scrollLeft();
			var y = $(obj).offset().top - $(document).scrollTop();
			var w = $(obj).outerWidth();
			var h = $(obj).outerHeight();

			menuObj = $("<ul></ul>");
			if (menu && menu.items) {
				$.each(menu.items, function (i, item) {
					var header = item.isHeader == true ? ' class="ui-widget-header"' : "";
					var disabled =
						item.isDisabled == true ? ' class="ui-state-disabled"' : "";
					if (item.icon != null) {
						menuObj.append(
							'<li value="' +
								item.value +
								'" ' +
								header +
								" " +
								disabled +
								'><div><span class="' +
								item.icon +
								' ui-icon"></span>' +
								item.text +
								"</div></li>"
						);
					} else {
						menuObj.append(
							'<li value="' +
								item.value +
								'" ' +
								header +
								" " +
								disabled +
								"><div>" +
								item.text +
								"</div></li>"
						);
					}
				});
			}
			menuObj.append("<li><div>-</div></li>");
			menuObj.append(
				'<li><div style="text-align:center; padding-right: 2em">' +
					lang.cancel +
					"</div></li>"
			);

			// Attach UL to body
			menuObj.appendTo(document.body);

			// Create Menu
			menuObj.menu({});

			// Event wireup
			if (menu && menu.selectEvent) {
				menuObj.on("menuselect", menu.selectEvent);
			}
			if (menu && menu.createEvent) {
				menuObj.on("menucreate", menu.createEvent);
			}
			menuObj.on("blur", function () {
				HidePopup();
			});
			if (menu && menu.autoFocus == true) menuObj.focus();

			// Final Positions
			var menuWidth = menuObj.outerWidth();
			var left = x - (menuWidth / 2 - w / 2);
			if (left + menuWidth + 10 > window.innerWidth) {
				left = window.innerWidth - menuWidth - 10;
			}
			if (left < 0) left = 0;
			menuObj.css("left", left + "px");

			var menuHeight = menuObj.outerHeight();
			var top = y + h;
			if (top + menuHeight + 10 > window.innerHeight) {
				top = window.innerHeight - menuHeight - 10;
			}
			if (top < 0) top = 0;
			menuObj.css("top", top + "px");
		}

		function HidePopup(timeout) {
			if (timeout) {
				window.setTimeout(function () {
					if (menuObj != null) {
						menuObj.menu("destroy");
						try {
							menuObj.empty();
						} catch (e) {
							console.log(e);
						}
						try {
							menuObj.remove();
						} catch (e) {
							console.log(e);
						}
						menuObj = null;
					}
				}, timeout);
			} else {
				if (menuObj != null) {
					menuObj.menu("destroy");
					try {
						menuObj.empty();
					} catch (e) {
						console.log(e);
					}
					try {
						menuObj.remove();
					} catch (e) {
						console.log(e);
					}
					menuObj = null;
				}
			}
		}

		// Device Detection
		// ================
		function DetectDevices() {
			navigator.mediaDevices
				.enumerateDevices()
				.then(function (deviceInfos) {
					// deviceInfos will not have a populated lable unless to accept the permission
					// during getUserMedia. This normally happens at startup/setup
					// so from then on these devices will be with lables.
					HasVideoDevice = false;
					HasAudioDevice = false;
					HasSpeakerDevice = false; // Safari and Firefox don't have these
					global.AudioinputDevices = [];
					VideoinputDevices = [];
					SpeakerDevices = [];
					for (var i = 0; i < deviceInfos.length; ++i) {
						if (deviceInfos[i].kind === "audioinput") {
							HasAudioDevice = true;
							global.AudioinputDevices.push(deviceInfos[i]);
						} else if (deviceInfos[i].kind === "audiooutput") {
							HasSpeakerDevice = true;
							SpeakerDevices.push(deviceInfos[i]);
						} else if (deviceInfos[i].kind === "videoinput") {
							HasVideoDevice = true;
							VideoinputDevices.push(deviceInfos[i]);
						}
					}
					// console.log(AudioinputDevices, VideoinputDevices);
				})
				.catch(function (e) {
					console.error("Error enumerating devices", e);
				});
		}
		DetectDevices();
		window.setInterval(function () {
			DetectDevices();
		}, 10000);

		// STATUS_NULL: 0
		// STATUS_INVITE_SENT: 1
		// STATUS_1XX_RECEIVED: 2
		// STATUS_INVITE_RECEIVED: 3
		// STATUS_WAITING_FOR_ANSWER: 4
		// STATUS_ANSWERED: 5
		// STATUS_WAITING_FOR_PRACK: 6
		// STATUS_WAITING_FOR_ACK: 7
		// STATUS_CANCELED: 8
		// STATUS_TERMINATED: 9
		// STATUS_ANSWERED_WAITING_FOR_PRACK: 10
		// STATUS_EARLY_MEDIA: 11
		// STATUS_CONFIRMED: 12

		// =================================================================================
		// End Of File

		function onStatusChange(status) {
			// Strophe.ConnectionStatus = status;
			if (status == Strophe.Status.CONNECTING) {
				console.log("XMPP is connecting...");
			} else if (status == Strophe.Status.CONNFAIL) {
				console.warn("XMPP failed to connect.");
			} else if (status == Strophe.Status.DISCONNECTING) {
				console.log("XMPP is disconnecting.");
			} else if (status == Strophe.Status.DISCONNECTED) {
				console.log("XMPP is disconnected.");

				// Keep connected
				window.setTimeout(function () {
					// reconnectXmpp();
				}, 5 * 1000);
			} else if (status == Strophe.Status.CONNECTED) {
				console.log("XMPP is connected!");

				// Re-publish my vCard
				XmppSetMyVcard();

				// Get buddies
				XmppGetBuddies();

				XMPP.ping = window.setTimeout(function () {
					XmppSendPing();
				}, 45 * 1000);
			} else {
				console.log("XMPP is: ", Strophe.Status);
			}
		}

		function XmppSendPing() {
			// return;

			if (!XMPP || XMPP.connected == false) reconnectXmpp();

			var iq_request = $iq({
				type: "get",
				id: XMPP.getUniqueId(),
				to: XmppDomain,
				from: XMPP.jid,
			});
			iq_request.c("ping", { xmlns: "urn:xmpp:ping" });

			XMPP.sendIQ(
				iq_request,
				function (result) {
					// console.log("XmppSendPing Response: ", result);
				},
				function (e) {
					console.warn("Error in Ping", e);
				},
				30 * 1000
			);

			XMPP.ping = window.setTimeout(function () {
				XmppSendPing();
			}, 45 * 1000);
		}

		// XMPP Presence
		// =============
		function XmppSetMyPresence(str, desc, updateVcard) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			// ["away", "chat", "dnd", "xa"] => ["Away", "Available", "Busy", "Gone"]

			console.log("Setting My Own Presence to: " + str + "(" + desc + ")");

			if (desc == "") desc = lang.default_status;
			$("#regStatus").html('<i class="fa fa-comments"></i> ' + desc);

			var pres_request = $pres({ id: XMPP.getUniqueId(), from: XMPP.jid });
			pres_request.c("show").t(str);
			if (desc && desc != "") {
				pres_request.root();
				pres_request.c("status").t(desc);
			}
			if (updateVcard == true) {
				var base64 = getPicture("profilePicture");
				var imgBase64 = base64.split(",")[1];
				var photoHash = $.md5(imgBase64);

				pres_request.root();
				pres_request.c("x", { xmlns: "vcard-temp:x:update" });
				if (photoHash) {
					pres_request.c("photo", {}, photoHash);
				}
			}

			XMPP.sendPresence(
				pres_request,
				function (result) {
					// console.log("XmppSetMyPresence Response: ", result);
				},
				function (e) {
					console.warn("Error in XmppSetMyPresence", e);
				},
				30 * 1000
			);
		}
		function onPresenceChange(presence) {
			// console.log('onPresenceChange', presence);

			var from = presence.getAttribute("from");
			var to = presence.getAttribute("to");

			var subscription = presence.getAttribute("subscription");
			var type = presence.getAttribute("type")
				? presence.getAttribute("type")
				: "presence"; // subscribe | subscribed | unavailable
			var pres = "";
			var status = "";
			var xmlns = "";
			Strophe.forEachChild(presence, "show", function (elem) {
				pres = elem.textContent;
			});
			Strophe.forEachChild(presence, "status", function (elem) {
				status = elem.textContent;
			});
			Strophe.forEachChild(presence, "x", function (elem) {
				xmlns = elem.getAttribute("xmlns");
			});

			var fromJid = Strophe.getBareJidFromJid(from);

			// Presence notification from me to me
			if (from == to) {
				// Either my vCard updated, or my Presence updated
				return true;
			}

			// Find the buddy this message is coming from
			var buddyObj = FindBuddyByJid(fromJid);
			if (buddyObj == null) {
				// TODO: What to do here?

				console.warn("Buddy Not Found: ", fromJid);
				return true;
			}

			if (type == "subscribe") {
				// <presence xmlns="jabber:client" type="subscribe" from="58347g3721h~800@...com" id="1" subscription="both" to="58347g3721h~100@...com"/>
				// <presence xmlns="jabber:client" type="subscribe" from="58347g3721h~800@...com" id="1" subscription="both" to="58347g3721h~100@...com"/>

				// One of your buddies is requestion subscription
				console.log(
					"Presence: " + buddyObj.CallerIDName + " requesting subscrption"
				);

				XmppConfirmSubscription(buddyObj);

				// Also Subscribe to them
				XmppSendSubscriptionRequest(buddyObj);

				UpdateBuddyList();
				return true;
			}
			if (type == "subscribed") {
				// One of your buddies has confimed subscription
				console.log(
					"Presence: " + buddyObj.CallerIDName + " confimed subscrption"
				);

				UpdateBuddyList();
				return true;
			}
			if (type == "unavailable") {
				// <presence xmlns="jabber:client" type="unavailable" from="58347g3721h~800@...com/63zy33arw5" to="yas43lag8l@...com"/>
				console.log("Presence: " + buddyObj.CallerIDName + " unavailable");

				UpdateBuddyList();
				return true;
			}

			if (xmlns == "vcard-temp:x:update") {
				// This is a presence update for the picture change
				console.log(
					"Presence: " +
						buddyObj.ExtNo +
						" - " +
						buddyObj.CallerIDName +
						" vCard change"
				);

				// Should check if the hash is different, could have been a non-picture change..
				// However, either way you would need to update the vCard, as there isnt a awy to just get the picture
				XmppGetBuddyVcard(buddyObj);

				UpdateBuddyList();
			}

			if (pres != "") {
				// This is a regulare
				console.log(
					"Presence: " +
						buddyObj.ExtNo +
						" - " +
						buddyObj.CallerIDName +
						" is now: " +
						pres +
						"(" +
						status +
						")"
				);

				buddyObj.presence = pres;
				buddyObj.presenceText = status == "" ? lang.default_status : status;

				UpdateBuddyList();
			}

			return true;
		}
		function XmppConfirmSubscription(buddyObj) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var pres_request = $pres({
				to: buddyObj.jid,
				from: XMPP.jid,
				type: "subscribed",
			});
			XMPP.sendPresence(pres_request);
			// Responses are handled in the main handler
		}
		function XmppSendSubscriptionRequest(buddyObj) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var pres_request = $pres({
				to: buddyObj.jid,
				from: XMPP.jid,
				type: "subscribe",
			});
			XMPP.sendPresence(pres_request);
			// Responses are handled in the main handler
		}

		// XMPP Roster
		// ===========
		function XmppRemoveBuddyFromRoster(buddyObj) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var iq_request = $iq({
				type: "set",
				id: XMPP.getUniqueId(),
				from: XMPP.jid,
			});
			iq_request.c("query", { xmlns: "jabber:iq:roster" });
			iq_request.c("item", { jid: buddyObj.jid, subscription: "remove" });
			if (buddyObj.jid == null) {
				console.warn("Missing JID", buddyObj);
				return;
			}
			console.log("Removing " + buddyObj.CallerIDName + "  from roster...");

			XMPP.sendIQ(iq_request, function (result) {
				// console.log(result);
			});
		}
		function XmppAddBuddyToRoster(buddyObj) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var iq_request = $iq({
				type: "set",
				id: XMPP.getUniqueId(),
				from: XMPP.jid,
			});
			iq_request.c("query", { xmlns: "jabber:iq:roster" });
			iq_request.c("item", { jid: buddyObj.jid, name: buddyObj.CallerIDName });
			if (buddyObj.jid == null) {
				console.warn("Missing JID", buddyObj);
				return;
			}
			console.log("Adding " + buddyObj.CallerIDName + "  to roster...");

			XMPP.sendIQ(iq_request, function (result) {
				// console.log(result);
				XmppGetBuddyVcard(buddyObj);

				XmppSendSubscriptionRequest(buddyObj);
			});
		}

		function XmppGetBuddies() {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var iq_request = $iq({
				type: "get",
				id: XMPP.getUniqueId(),
				from: XMPP.jid,
			});
			iq_request.c("query", { xmlns: "jabber:iq:roster" });
			console.log("Getting Buddy List (roster)...");

			XMPP.sendIQ(
				iq_request,
				function (result) {
					// console.log("XmppGetBuddies Response: ", result);

					// Clear out only XMPP

					Strophe.forEachChild(result, "query", function (query) {
						Strophe.forEachChild(query, "item", function (buddyItem) {
							// console.log("Register Buddy", buddyItem);

							// <item xmlns="jabber:iq:roster" jid="58347g3721h~800@xmpp-eu-west-1.innovateasterisk.com" name="Alfredo Dixon" subscription="both"/>
							// <item xmlns="jabber:iq:roster" jid="58347g3721h~123456@conference.xmpp-eu-west-1.innovateasterisk.com" name="Some Group Name" subscription="both"/>

							var jid = buddyItem.getAttribute("jid");
							var displayName = buddyItem.getAttribute("name");
							var node = Strophe.getNodeFromJid(jid);
							var buddyDid = node;
							if (XmppRealm != "" && XmppRealmSeperator != "") {
								buddyDid = node.split(XmppRealmSeperator, 2)[1];
							}
							var ask = buddyItem.getAttribute("ask")
								? buddyItem.getAttribute("ask")
								: "none";
							var sub = buddyItem.getAttribute("subscription")
								? buddyItem.getAttribute("subscription")
								: "none";
							var isGroup = jid.indexOf("@" + XmppChatGroupService + ".") > -1;

							var buddyObj = FindBuddyByJid(jid);
							if (buddyObj == null) {
								// Create Cache
								if (isGroup == true) {
									console.log(
										"Adding roster (group):",
										buddyDid,
										"-",
										displayName
									);
									buddyObj = MakeBuddy(
										"group",
										false,
										false,
										false,
										displayName,
										buddyDid,
										jid
									);
								} else {
									console.log(
										"Adding roster (xmpp):",
										buddyDid,
										"-",
										displayName
									);
									buddyObj = MakeBuddy(
										"xmpp",
										false,
										false,
										true,
										displayName,
										buddyDid,
										jid
									);
								}

								// RefreshBuddyData(buddyObj);
								XmppGetBuddyVcard(buddyObj);
							} else {
								// Buddy cache exists
								console.log(
									"Existing roster item:",
									buddyDid,
									"-",
									displayName
								);

								// RefreshBuddyData(buddyObj);
								XmppGetBuddyVcard(buddyObj);
							}
						});
					});

					// Update your own status, and get the status of others
					XmppSetMyPresence(
						getDbItem("XmppLastPresence", "chat"),
						getDbItem("XmppLastStatus", ""),
						true
					);

					// Populate the buddy list
					UpdateBuddyList();
				},
				function (e) {
					console.warn("Error Getting Roster", e);
				},
				30 * 1000
			);
		}
		function onBuddySetRequest(iq) {
			console.log("onBuddySetRequest", iq);

			// <iq xmlns="jabber:client" type="result" id="4e9dadc7-145b-4ea2-ae82-3220130231ba" to="yas43lag8l@xmpp-eu-west-1.innovateasterisk.com/4gte25lhkh">
			//     <query xmlns="jabber:iq:roster" ver="1386244571">
			//          <item jid="800@xmpp-eu-west-1.innovateasterisk.com" name="Alfredo Dixon" subscription="both"/>
			//     </query>
			// </iq>

			return true;
		}
		function onBuddyUpdate(iq) {
			return true;
		}
		function RefreshBuddyData(buddyObj) {
			// Get vCard

			return;

			// Get Last Activity
			// var iq_request = $iq({
			// 	type: "get",
			// 	id: XMPP.getUniqueId(),
			// 	to: buddyObj.jid,
			// 	from: XMPP.jid,
			// });
			// iq_request.c("query", { xmlns: "jabber:iq:last" });
			// XMPP.sendIQ(
			// 	iq_request,
			// 	function (result) {
			// 		console.log("jabber:iq:last Response: ", result);

			// 		if (result.children[0].getAttribute("seconds")) {
			// 			var seconds = Number(result.children[0].getAttribute("seconds"));
			// 			lastActivity = moment()
			// 				.utc()
			// 				.subtract(seconds, "seconds")
			// 				.format("YYYY-MM-DD HH:mm:ss UTC");

			// 			UpdateBuddyActivity(buddyObj.identity, lastActivity);
			// 		}
			// 	},
			// 	function (e) {
			// 		console.warn("Error in jabber:iq:last", e);
			// 	},
			// 	30 * 1000
			// );
		}

		// Profile (vCard)
		// ===============
		function XmppGetMyVcard() {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var iq_request = $iq({
				type: "get",
				id: XMPP.getUniqueId(),
				from: XMPP.jid,
			});
			iq_request.c("vCard", { xmlns: "vcard-temp" });

			XMPP.sendIQ(
				iq_request,
				function (result) {
					console.log("XmppGetMyVcard Response: ", result);
				},
				function (e) {
					console.warn("Error in XmppGetMyVcard", e);
				},
				30 * 1000
			);
		}
		function XmppSetMyVcard() {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var profileVcard = getDbItem("profileVcard", null);
			if (profileVcard == null || profileVcard == "") {
				console.warn("No vCard created yet");
				return;
			}
			profileVcard = JSON.parse(profileVcard);

			var base64 = getPicture("profilePicture");
			var imgBase64 = base64.split(",")[1];

			var iq_request = $iq({
				type: "set",
				id: XMPP.getUniqueId(),
				from: XMPP.jid,
			});
			iq_request.c("vCard", { xmlns: "vcard-temp" });
			iq_request.c("FN", {}, profileName);
			iq_request.c("TITLE", {}, profileVcard.TitleDesc);
			iq_request.c("TEL");
			iq_request.c("NUMBER", {}, profileUser);
			iq_request.up();
			iq_request.c("TEL");
			iq_request.c("CELL", {}, profileVcard.Mobile);
			iq_request.up();
			iq_request.c("TEL");
			iq_request.c("VOICE", {}, profileVcard.Number1);
			iq_request.up();
			iq_request.c("TEL");
			iq_request.c("FAX", {}, profileVcard.Number2);
			iq_request.up();
			iq_request.c("EMAIL");
			iq_request.c("USERID", {}, profileVcard.Email);
			iq_request.up();
			iq_request.c("PHOTO");
			iq_request.c("TYPE", {}, "image/png");
			iq_request.c("BINVAL", {}, imgBase64);
			iq_request.up();
			iq_request.c("JABBERID", {}, Strophe.getBareJidFromJid(XMPP.jid));

			console.log("Sending vCard update");
			XMPP.sendIQ(
				iq_request,
				function (result) {
					// console.log("XmppSetMyVcard Response: ", result);
				},
				function (e) {
					console.warn("Error in XmppSetMyVcard", e);
				},
				30 * 1000
			);
		}
		function XmppGetBuddyVcard(buddyObj) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			if (buddyObj == null) return;
			if (buddyObj.jid == null) return;

			var iq_request = $iq({
				type: "get",
				id: XMPP.getUniqueId(),
				from: XMPP.jid,
				to: buddyObj.jid,
			});
			iq_request.c("vCard", { xmlns: "vcard-temp" });
			XMPP.sendIQ(
				iq_request,
				function (result) {
					var jid = result.getAttribute("from");
					console.log("Got vCard for: " + jid);

					var buddyObj = FindBuddyByJid(jid);
					if (buddyObj == null) {
						console.warn("Received a vCard for non-existing buddy", jid);
						return;
					}

					var imgBase64 = "";

					Strophe.forEachChild(result, "vCard", function (vcard) {
						Strophe.forEachChild(vcard, null, function (element) {
							if (element.tagName == "FN") {
								buddyObj.CallerIDName = element.textContent;
							}
							if (element.tagName == "TITLE") {
								buddyObj.Desc = element.textContent;
							}
							if (element.tagName == "JABBERID") {
								if (element.textContent != jid) {
									console.warn(
										"JID does not match: ",
										element.textContent,
										jid
									);
								}
							}
							if (element.tagName == "TEL") {
								Strophe.forEachChild(element, "NUMBER", function (ExtNo) {
									// Voip Number (Subscribe)
									if (ExtNo.textContent != buddyObj.ExtNo) {
										console.warn(
											"Subscribe Extension does not match: ",
											ExtNo.textContent,
											buddyObj.ExtNo
										);
									}
								});
								Strophe.forEachChild(element, "CELL", function (cell) {
									// Mobile
									buddyObj.MobileNumber = cell.textContent;
								});
								Strophe.forEachChild(element, "VOICE", function (Alt1) {
									// Alt1
									buddyObj.ContactNumber1 = Alt1.textContent;
								});
								Strophe.forEachChild(element, "FAX", function (Alt2) {
									// Alt2
									buddyObj.ContactNumber2 = Alt2.textContent;
								});
							}
							if (element.tagName == "EMAIL") {
								Strophe.forEachChild(element, "USERID", function (email) {
									buddyObj.Email = email.textContent;
								});
							}
							if (element.tagName == "PHOTO") {
								Strophe.forEachChild(element, "BINVAL", function (base64) {
									imgBase64 = "data:image/png;base64," + base64.textContent;
								});
							}
						});
					});

					// Save To DB
					var buddyJson = {};
					var itemId = -1;
					var json = JSON.parse(localDB.getItem(profileUserID + "-Buddies"));
					$.each(json.DataCollection, function (i, item) {
						if (item.uID == buddyObj.identity) {
							buddyJson = item;
							itemId = i;
							return false;
						}
					});

					if (itemId != -1) {
						buddyJson.MobileNumber = buddyObj.MobileNumber;
						buddyJson.ContactNumber1 = buddyObj.ContactNumber1;
						buddyJson.ContactNumber2 = buddyObj.ContactNumber2;
						buddyJson.DisplayName = buddyObj.CallerIDName;
						buddyJson.Description = buddyObj.Desc;
						buddyJson.Email = buddyObj.Email;

						json.DataCollection[itemId] = buddyJson;
						localDB.setItem(profileUserID + "-Buddies", JSON.stringify(json));
					}

					if (imgBase64 != "") {
						// console.log(buddyObj);
						console.log("Buddy: " + buddyObj.CallerIDName + " picture updated");

						localDB.setItem(
							"img-" + buddyObj.identity + "-" + buddyObj.type,
							imgBase64
						);
						$("#contact-" + buddyObj.identity + "-picture-main").css(
							"background-image",
							"url(" + getPicture(buddyObj.identity, buddyObj.type, true) + ")"
						);
					}
					UpdateBuddyList();
				},
				function (e) {
					console.warn("Error in XmppGetBuddyVcard", e);
				},
				30 * 1000
			);
		}

		// XMPP Messaging
		// ==============
		function onMessage(message) {
			// console.log('onMessage', message);

			var from = message.getAttribute("from");
			var fromJid = Strophe.getBareJidFromJid(from);
			var to = message.getAttribute("to");
			var messageId = message.getAttribute("id");

			// Determin Buddy
			var buddyObj = FindBuddyByJid(fromJid);
			if (buddyObj == null) {
				// You don't appear to be a buddy of mine

				// TODO: Handle this
				console.warn("Spam!"); // LOL :)
				return true;
			}

			var isDelayed = false;
			var DateTime = utcDateNow();
			Strophe.forEachChild(message, "delay", function (elem) {
				// Delay message received
				if (elem.getAttribute("xmlns") == "urn:xmpp:delay") {
					isDelayed = true;
					DateTime = moment(elem.getAttribute("stamp"))
						.utc()
						.format("YYYY-MM-DD HH:mm:ss UTC");
				}
			});
			var origionalMessage = "";
			Strophe.forEachChild(message, "body", function (elem) {
				// For simplicity, this code is assumed to take the last body
				origionalMessage = elem.textContent;
			});

			// chatstate
			var chatstate = "";
			Strophe.forEachChild(message, "composing", function (elem) {
				if (
					elem.getAttribute("xmlns") == "http://jabber.org/protocol/chatstates"
				) {
					chatstate = "composing";
				}
			});
			Strophe.forEachChild(message, "paused", function (elem) {
				if (
					elem.getAttribute("xmlns") == "http://jabber.org/protocol/chatstates"
				) {
					chatstate = "paused";
				}
			});
			Strophe.forEachChild(message, "active", function (elem) {
				if (
					elem.getAttribute("xmlns") == "http://jabber.org/protocol/chatstates"
				) {
					chatstate = "active";
				}
			});
			if (chatstate == "composing") {
				if (!isDelayed) XmppShowComposing(buddyObj);
				return true;
			} else {
				XmppHideComposing(buddyObj);
			}

			// Message Correction
			var isCorrection = false;
			var targetCorrectionMsg = "";
			Strophe.forEachChild(message, "replace", function (elem) {
				if (elem.getAttribute("xmlns") == "urn:xmpp:message-correct:0") {
					isCorrection = true;
					Strophe.forEachChild(elem, "id", function (idElem) {
						targetCorrectionMsg = idElem.textContent;
					});
				}
			});
			if (isCorrection && targetCorrectionMsg != "") {
				console.log(
					"Message " +
						targetCorrectionMsg +
						" for " +
						buddyObj.CallerIDName +
						" was corrected"
				);
				CorrectMessage(buddyObj, targetCorrectionMsg, origionalMessage);
			}

			// Delivery Events
			var eventStr = "";
			var targetDeliveryMsg = "";
			Strophe.forEachChild(message, "x", function (elem) {
				if (elem.getAttribute("xmlns") == "jabber:x:event") {
					// One of the delivery events occured
					Strophe.forEachChild(elem, "delivered", function (delElem) {
						eventStr = "delivered";
					});
					Strophe.forEachChild(elem, "displayed", function (delElem) {
						eventStr = "displayed";
					});
					Strophe.forEachChild(elem, "id", function (idElem) {
						targetDeliveryMsg = idElem.textContent;
					});
				}
			});
			if (eventStr == "delivered" && targetDeliveryMsg != "") {
				console.log(
					"Message " +
						targetDeliveryMsg +
						" for " +
						buddyObj.CallerIDName +
						" was delivered"
				);
				MarkDeliveryReceipt(buddyObj, targetDeliveryMsg, true);

				return true;
			}
			if (eventStr == "displayed" && targetDeliveryMsg != "") {
				console.log(
					"Message " +
						targetDeliveryMsg +
						" for " +
						buddyObj.CallerIDName +
						" was displayed"
				);
				MarkDisplayReceipt(buddyObj, targetDeliveryMsg, true);

				return true;
			}

			// Messages
			if (origionalMessage == "") {
				// Not a full message
			} else {
				if (messageId) {
					// Although XMPP does not require message ID's, this application does
					XmppSendDeliveryReceipt(buddyObj, messageId);

					AddMessageToStream(
						buddyObj,
						messageId,
						"MSG",
						origionalMessage,
						DateTime
					);
					UpdateBuddyActivity(buddyObj.identity);
					var streamVisible = $("#stream-" + buddyObj.identity).is(":visible");
					if (streamVisible) {
						MarkMessageRead(buddyObj, messageId);
						XmppSendDisplayReceipt(buddyObj, messageId);
					}
					RefreshStream(buddyObj);
					ActivateStream(buddyObj, origionalMessage);
				} else {
					console.warn("Sorry, messages must have an id ", message);
				}
			}

			return true;
		}
		function XmppShowComposing(buddyObj) {
			console.log("Buddy is composing a message...");
			$("#contact-" + buddyObj.identity + "-chatstate").show();
			$("#contact-" + buddyObj.identity + "-presence").hide();
			$("#contact-" + buddyObj.identity + "-presence-main").hide();
			$("#contact-" + buddyObj.identity + "-chatstate-menu").show();
			$("#contact-" + buddyObj.identity + "-chatstate-main").show();

			updateScroll(buddyObj.identity);
		}
		function XmppHideComposing(buddyObj) {
			console.log("Buddy composing is done...");
			$("#contact-" + buddyObj.identity + "-chatstate").hide();
			$("#contact-" + buddyObj.identity + "-chatstate-menu").hide();
			$("#contact-" + buddyObj.identity + "-chatstate-main").hide();
			$("#contact-" + buddyObj.identity + "-presence").show();
			$("#contact-" + buddyObj.identity + "-presence-main").show();

			updateScroll(buddyObj.identity);
		}
		function XmppSendMessage(
			buddyObj,
			message,
			messageId,
			thread,
			markable,
			type
		) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			if (!type) type = "normal"; // chat | error | normal | groupchat | headline
			var msg = $msg({
				to: buddyObj.jid,
				type: type,
				id: messageId,
				from: XMPP.jid,
			});
			if (thread && thread != "") {
				msg.c("thread").t(thread);
				msg.up();
			}
			msg.c("body").t(message);
			// XHTML-IM
			msg.up();
			msg.c("active", { xmlns: "http://jabber.org/protocol/chatstates" });
			msg.up();
			msg.c("x", { xmlns: "jabber:x:event" });
			msg.c("delivered");
			msg.up();
			msg.c("displayed");

			console.log("sending message...");
			buddyObj.chatstate = "active";
			if (buddyObj.chatstateTimeout) {
				window.clearTimeout(buddyObj.chatstateTimeout);
			}
			buddyObj.chatstateTimeout = null;

			try {
				XMPP.send(msg);
				MarkMessageSent(buddyObj, messageId, false);
			} catch (e) {
				MarkMessageNotSent(buddyObj, messageId, false);
			}
		}
		function XmppStartComposing(buddyObj, thread) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			if (buddyObj.jid == null || buddyObj.jid == "") return;

			if (buddyObj.chatstateTimeout) {
				window.clearTimeout(buddyObj.chatstateTimeout);
			}
			buddyObj.chatstateTimeout = window.setTimeout(function () {
				XmppPauseComposing(buddyObj, thread);
			}, 10 * 1000);

			if (buddyObj.chatstate && buddyObj.chatstate == "composing") return;

			var msg = $msg({ to: buddyObj.jid, from: XMPP.jid });
			if (thread && thread != "") {
				msg.c("thread").t(thread);
				msg.up();
			}
			msg.c("composing", { xmlns: "http://jabber.org/protocol/chatstates" });

			console.log("you are composing a message...");
			buddyObj.chatstate = "composing";

			XMPP.send(msg);
		}
		function XmppPauseComposing(buddyObj, thread) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			if (buddyObj.jid == null || buddyObj.jid == "") return;

			if (buddyObj.chatstate && buddyObj.chatstate == "paused") return;

			var msg = $msg({ to: buddyObj.jid, from: XMPP.jid });
			if (thread && thread != "") {
				msg.c("thread").t(thread);
				msg.up();
			}
			msg.c("paused", { xmlns: "http://jabber.org/protocol/chatstates" });

			console.log("You have paused your message...");
			buddyObj.chatstate = "paused";
			if (buddyObj.chatstateTimeout) {
				window.clearTimeout(buddyObj.chatstateTimeout);
			}
			buddyObj.chatstateTimeout = null;

			XMPP.send(msg);
		}
		function XmppSendDeliveryReceipt(buddyObj, id) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var msg = $msg({ to: buddyObj.jid, from: XMPP.jid });
			msg.c("x", { xmlns: "jabber:x:event" });
			msg.c("delivered");
			msg.up();
			msg.c("id").t(id);

			console.log("sending delivery notice for " + id + "...");

			XMPP.send(msg);
		}
		function XmppSendDisplayReceipt(buddyObj, id) {
			if (!XMPP || XMPP.connected == false) {
				console.warn("XMPP not connected");
				return;
			}

			var msg = $msg({ to: buddyObj.jid, from: XMPP.jid });
			msg.c("x", { xmlns: "jabber:x:event" });
			msg.c("displayed");
			msg.up();
			msg.c("id").t(id);

			console.log("sending display notice for " + id + "...");

			XMPP.send(msg);
		}

		// XMPP Other
		// ==========
		function onPingRequest(iq) {
			var id = iq.getAttribute("id");
			var to = iq.getAttribute("to");
			var from = iq.getAttribute("from");

			var iq_response = $iq({ type: "result", id: id, to: from, from: to });
			XMPP.send(iq_response);

			return true;
		}
		function onVersionRequest(iq) {
			// Handle Request for our version etc
			// <iq xmlns="jabber:client" type="get" id="419-24" to=".../..." from="innovateasterisk.com">
			//     <query xmlns="jabber:iq:version"/>
			// </iq>
			var id = iq.getAttribute("id");
			var to = iq.getAttribute("to");
			var from = iq.getAttribute("from");

			var iq_response = $iq({ type: "result", id: id, to: from, from: to });
			iq_response.c("query", { xmlns: "jabber:iq:version" });
			iq_response.c("name", null, "Browser Phone");
			iq_response.c("version", null, "0.0.1");
			iq_response.c("os", null, "Browser");
			XMPP.send(iq_response);

			return true;
		}

		var XMPP = null;
		var reconnectXmpp = function () {
			console.log("Connect/Reconnect XMPP connection...");

			if (XMPP) XMPP.disconnect("");
			if (XMPP) XMPP.reset();

			var xmpp_websocket_uri =
				"wss://" +
				XmppServer +
				":" +
				XmppWebsocketPort +
				"" +
				XmppWebsocketPath;
			var xmpp_username = profileUser + "@" + XmppDomain;
			if (XmppRealm != "" && XmppRealmSeperator)
				xmpp_username = XmppRealm + XmppRealmSeperator + xmpp_username;
			var xmpp_password = SipPassword;

			XMPP = null;
			if (
				XmppDomain == "" ||
				XmppServer == "" ||
				XmppWebsocketPort == "" ||
				XmppWebsocketPath == ""
			) {
				return;
			}
			XMPP = new Strophe.Connection(xmpp_websocket_uri);

			// XMPP.rawInput = function(data){
			//     console.log('RECV:', data);
			// }
			// XMPP.rawOutput = function(data){
			//     console.log('SENT:', data);
			// }

			// Information Query
			XMPP.addHandler(onPingRequest, "urn:xmpp:ping", "iq", "get");
			XMPP.addHandler(onVersionRequest, "jabber:iq:version", "iq", "get");

			// Presence
			XMPP.addHandler(onPresenceChange, null, "presence", null);
			// Message
			XMPP.addHandler(onMessage, null, "message", null);

			console.log("XMPP connect...");

			XMPP.connect(xmpp_username, xmpp_password, onStatusChange);
		};
	},
};
</script>
